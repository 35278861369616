import React from "react";
import { ILLUSTRATION__1 } from "../../../config/globals";

const HomeBanner = () => {
  return (
    <section className="section-container pd-lr hide-on-small-only v-banner">
      <div className="banner-wrapper">
        <div className="banner-items">
          <div className="banner-illustration hide-on-med-only">
            <img src={ILLUSTRATION__1} draggable={false} alt={`banner`} />
          </div>
          <div className="banner-description">
            <h2 className="banner-title">
              Explore stories, skills, and housing in the northern part of
              Cyprus.
            </h2>
            <div className="banner-desc">
              <p className="mini-desc">
                By international students, for international students.
              </p>
              <ul className="mini-lists">
                <li className="mini-item">
                  Connect and share experiences with your local community in the
                  forum.
                </li>
                <li className="mini-item">
                  Share individual skills or hire skilled individuals from the
                  skillshop.
                </li>
                <li className="mini-item">
                  Get insights from previous occupants of local apartments in
                  housing.
                </li>
              </ul>
            </div>
            <div className="banner-action">
              <a href="/forum">Explore Community</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeBanner;

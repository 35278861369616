import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import M from "materialize-css";
import { useSelector } from "react-redux";
import { compose } from "redux";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import withRouter from "./WithRouter";
import {
  DEV__BADGE,
  ROYALTY__BADGE,
  VOLUNTEER__BADGE,
} from "../../config/globals";

const VerifiedInfo = ({ userId }) => {
  const badgeRef = useRef(null);

  const user = useSelector(
    ({ firestore: { ordered } }) => ordered[userId] && ordered[userId]
  );

  useEffect(() => {
    const badge = document.querySelector(".badge__member");
    badgeRef.current = M.Dropdown.init(badge, {
      coverTrigger: false,
      alignment: "right",
      constrainWidth: false,
    });

    const reCalculate = () => {
      badgeRef.current.recalculateDimensions();
    };

    if (badge) {
      window.addEventListener("resize", reCalculate);
      return () => window.removeEventListener("resize", reCalculate);
    }
  }, [user]);

  const getCustomMonth = (date) => {
    const month = new Date(date.toDate());
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return months[month.getMonth()];
  };

  if (isLoaded(user)) {
    return (
      <>
        {user[0].user.badgeType !== "" && (
          <>
            <img
              src={
                user[0].user.badgeType === "volunteer"
                  ? VOLUNTEER__BADGE
                  : user[0].user.badgeType === "member"
                  ? ROYALTY__BADGE
                  : user[0].user.badgeType === "royalty"
                  ? DEV__BADGE
                  : ""
              }
              alt={
                user[0].user.badgeType === "volunteer"
                  ? "Volunteer badge"
                  : user[0].user.badgeType === "member"
                  ? "Member badge"
                  : user[0].user.badgeType === "royalty"
                  ? "Developer "
                  : ""
              }
              className="badge__member"
              style={{ cursor: "pointer" }}
              onClick={() => badgeRef.current.open()}
              data-target="badge-member"
            />
            <div id="badge-member" className="dropdown-content badge">
              <div className="dp-badge-info">
                <h3 className="dp-badge-title">
                  {user[0].user.badgeType === "volunteer"
                    ? "Volunteer "
                    : user[0].user.badgeType === "member"
                    ? "Member "
                    : user[0].user.badgeType === "member"
                    ? "Developer "
                    : ""}
                  badge
                </h3>
                <p>
                  <img
                    src={
                      user[0].user.badgeType === "volunteer"
                        ? VOLUNTEER__BADGE
                        : user[0].user.badgeType === "member"
                        ? ROYALTY__BADGE
                        : user[0].user.badgeType === "royalty"
                        ? DEV__BADGE
                        : ""
                    }
                    alt={
                      user[0].user.badgeType === "volunteer"
                        ? "Volunteer badge"
                        : user[0].user.badgeType === "member"
                        ? "Member badge"
                        : user[0].user.badgeType === "dev"
                        ? "Developer "
                        : ""
                    }
                    className="badge__member"
                    style={{ cursor: "pointer" }}
                  />
                  <span>
                    This user is a
                    {user[0].user.badgeType === "volunteer"
                      ? " Volunteer "
                      : user[0].user.badgeType === "member"
                      ? " Member "
                      : user[0].user.badgeType === "dev"
                      ? " Developer "
                      : ""}
                    of VOIS Cyprus
                  </span>
                </p>
                <p>
                  <i className="fa fa-calendar" aria-hidden="true"></i>
                  {user[0].user.badgeType === "volunteer"
                    ? "Volunteer "
                    : user[0].user.badgeType === "member"
                    ? "Member "
                    : user[0].user.badgeType === "dev"
                    ? "Developer "
                    : ""}
                  since {getCustomMonth(user[0].account.createdAt)}{" "}
                  {new Date(user[0].account.createdAt.toDate()).getFullYear()}
                </p>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
};

VerifiedInfo.propTypes = {
  userId: PropTypes.string,
};

export default compose(
  withRouter,
  firestoreConnect((props) => {
    if (props.userId) {
      return [
        {
          collection: "users",
          doc: props.userId,
          storeAs: props.userId,
        },
      ];
    }
  })
)(VerifiedInfo);

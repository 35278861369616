import React, { useEffect, useRef } from "react";
import { DeleteIcon, ReportIcon, ThreeDots } from "../../../assets/icons/Icons";
import { CommentInfo } from "../forum/components/CommentInfo";
import RateStarGenerator from "../../../general/components/RateStarGenerator";
import { useDispatch, useSelector } from "react-redux";
import M from "materialize-css";
import { SET__REVIEW__REPORT__MODAL } from "../../../stores/modals/ModalSlice";
import DeleteModal from "../../../modals/DeleteModal";
import ReportReview from "../../../modals/ReportReview";
import validator from "validator";

const Review = ({
  cardId,
  cardAvatar,
  cardUsername,
  ratingStarCount,
  ratingNumberCount,
  ratingComment,
  cardUserId,
  houseId,
  setHouseData,
  index,
  auth,
  createdAt,
  mainView,
  setMainView,
  handleReviewDelete,
  updateFormValue,
  handleReviewReport,
  houseData,
}) => {
  const dotRef = useRef();
  const authRef = useRef();
  const dispatch = useDispatch();

  const { reviewReportModal } = useSelector((state) => state.modal);
  const reviewRef = useRef();

  useEffect(() => {
    const nav = document.querySelectorAll("#main__main");
    dotRef.current = M.Dropdown.init(nav, {
      coverTrigger: false,
      alignment: "right",
      constrainWidth: false,
      onOpenStart: (el) => {
        setHouseData((state) => ({
          ...state,
          hid: houseId,
          id: el.dataset.value,
          type: "house",
          content: el.parentNode.nextSibling.textContent,
        }));
      },
    });

    const reCalculate = () => {
      dotRef.current.forEach((d) => d.recalculateDimensions());
    };

    if (nav) {
      window.addEventListener("resize", reCalculate);
      return () => window.removeEventListener("resize", reCalculate);
    }
  }, [index]);

  useEffect(() => {
    const nav = document.querySelectorAll("#main__auth");
    authRef.current = M.Dropdown.init(nav, {
      coverTrigger: false,
      alignment: "right",
      constrainWidth: false,
      onOpenStart: (el) => {
        setHouseData((state) => ({
          ...state,
          hid: houseId,
          id: el.dataset.value,
        }));
      },
    });

    const reCalculate = () => {
      authRef.current.forEach((d) => d.recalculateDimensions());
    };

    if (nav) {
      window.addEventListener("resize", reCalculate);
      return () => window.removeEventListener("resize", reCalculate);
    }
  }, [index, auth]);
  return (
    <>
      <li className="comment-list" key={cardId}>
        <div className="blob-desc-area">
          <img src={cardAvatar} alt="" className="user-avatar" />
          <div className="wrapper-fx-col">
            <div className="wrapper-fx-row">
              <div className="user-info">
                <p className="user-name-info">
                  <a href={`/@${cardUsername}`} className="user-name">
                    {cardUsername}
                  </a>
                  <CommentInfo userId={cardUserId} index={index} />
                </p>
                <div className="blob-properties">
                  <RateStarGenerator
                    ratingStarCount={Number(ratingStarCount)}
                    ratingNumberCount={Number(ratingNumberCount)}
                  />
                  <span className="blob-lt">{createdAt}</span>
                </div>
              </div>
              {auth && auth.uid === cardUserId ? (
                <>
                  <button
                    className="comment-action"
                    id="main__auth"
                    data-value={cardId}
                    data-target={index}
                  >
                    <ThreeDots width={20} />
                  </button>
                  <ul id={index} className="dropdown-content badge">
                    <li className="custom__list">
                      <button
                        className="comment-action"
                        style={{
                          justifyContent: "flex-start",
                          gap: "10px",
                          padding: "7px 10px",
                        }}
                        onClick={() => setMainView(true)}
                      >
                        <DeleteIcon width={15} theme={`cl-icon-light`} />
                        Delete review
                      </button>
                    </li>
                  </ul>
                </>
              ) : (
                <>
                  <button
                    className="comment-action"
                    id="main__main"
                    data-value={cardId}
                    data-target={index}
                  >
                    <ThreeDots width={20} />
                  </button>
                  <ul id={index} className="dropdown-content badge">
                    <li className="custom__list">
                      <button
                        className="comment-action"
                        style={{
                          justifyContent: "flex-start",
                          gap: "10px",
                          padding: "7px 10px",
                        }}
                        onClick={() =>
                          dispatch(SET__REVIEW__REPORT__MODAL(true))
                        }
                      >
                        <ReportIcon width={15} theme={`cl-icon-light`} />
                        Report review
                      </button>
                    </li>
                  </ul>
                </>
              )}
            </div>
            <div className="comment-textbox">
              {validator.unescape(ratingComment)}
            </div>
          </div>
        </div>
      </li>
      {mainView && (
        <DeleteModal
          view={mainView}
          setView={setMainView}
          text={"Review"}
          callback={handleReviewDelete}
        />
      )}
      {reviewReportModal && (
        <ReportReview
          modalRef={reviewRef}
          setPostData={setHouseData}
          postData={houseData}
          reportModal={reviewReportModal}
          handleSubmit={handleReviewReport}
          updateFormValue={updateFormValue}
          text={`Review`}
        />
      )}
    </>
  );
};

export default Review;

import React, { useEffect, useRef } from "react";
import RateStarGenerator from "../../../general/components/RateStarGenerator";
import {
  FbIcon,
  FilledHeartIcon,
  HeartIcon,
  LinkIcon,
  RemoveIcon,
  ReportIcon,
  ShareIcon,
  ThreeDots,
  XIcon,
} from "../../../assets/icons/Icons";
import { compose } from "redux";
import withRouter from "../../../general/components/WithRouter";
import { firestoreConnect, isEmpty, isLoaded } from "react-redux-firebase";
import { useDispatch, useSelector } from "react-redux";
import btnStyles from "../../../css/auth-btn.module.css";
import M from "materialize-css";
import ReportModal from "../../../modals/ReportModal";
import { useNavigate } from "react-router-dom";
import { SET__REPORT__MODAL } from "../../../stores/modals/ModalSlice";
import { likeSkill, unLikeSkill } from "../../../stores/crud/CrudSlice";
import DeleteModal from "../../../modals/DeleteModal";

const SkillActions = ({
  location,
  auth,
  skillId,
  handleReviewTotal,
  copyToClipboard,
  shareOnFacebook,
  shareOnTwitter,
  disableActions,
  skillData,
  setSkillData,
  handleSubmit,
  updateFormValue,
  userLikes,
  likeCount,
  userId,
  mainView,
  setMainView,
  handleDelete,
}) => {
  const { reportModal } = useSelector((state) => state.modal);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const reportRef = useRef();
  const shareRef = useRef();
  const editRef = useRef();

  const reviews = useSelector(
    ({ firestore: { ordered } }) =>
      ordered[`${skillId}-reviews`] && ordered[`${skillId}-reviews`]
  );

  useEffect(() => {
    const share = document.querySelector(".btn__share");
    shareRef.current = M.Dropdown.init(share, {
      coverTrigger: false,
      alignment: "right",
      constrainWidth: false,
    });

    const reCalculate = () => {
      shareRef.current.recalculateDimensions();
    };

    if (share) {
      window.addEventListener("resize", reCalculate);
      return () => window.removeEventListener("resize", reCalculate);
    }
  }, [reviews]);

  useEffect(() => {
    const edit = document.querySelector(".btn__edit");
    editRef.current = M.Dropdown.init(edit, {
      coverTrigger: false,
      alignment: "right",
      constrainWidth: false,
      onOpenStart: () => {
        setSkillData((state) => ({
          ...state,
          sid: skillId,
        }));
      },
    });

    const reCalculate = () => {
      editRef.current.recalculateDimensions();
    };

    if (edit) {
      window.addEventListener("resize", reCalculate);
      return () => window.removeEventListener("resize", reCalculate);
    }
  }, [window.screen.width, reviews, auth.uid]);

  const handleReportClick = () => {
    setSkillData((state) => ({
      ...state,
      sid: skillId,
      type: "skill",
    }));
    dispatch(SET__REPORT__MODAL(true));
  };

  if (isLoaded(reviews))
    return (
      <>
        <section className="blob-item fx-fade">
          <div className="blob-action-area">
            <div className="action-lr">
              <div className="l">
                <span>
                  <i className="fa fa-map-marker" aria-hidden="true"></i>
                  {location}
                </span>
              </div>
              <div className="l">
                <RateStarGenerator
                  ratingNumberCount={Number(
                    handleReviewTotal(reviews).toFixed(1)
                  )}
                  ratingStarCount={
                    reviews.length === 0
                      ? 0
                      : Number(handleReviewTotal(reviews).toFixed(1))
                  }
                  ratingReviewCount={Number(reviews.length)}
                />
              </div>
            </div>
            <div className="action-rl">
              <div className="r">
                {isEmpty(userLikes) ? (
                  <button
                    className={`${btnStyles["auth-blob-btn"]}`}
                    onClick={() => {
                      auth.uid
                        ? dispatch(likeSkill(skillId))
                        : navigate(
                            `/auth/signin?nav=${window.location.pathname}`
                          );
                    }}
                    title={"Like skill"}
                    disabled={disableActions}
                  >
                    <HeartIcon width={20} theme={`cl-icon-light`} />
                    {likeCount}
                  </button>
                ) : userLikes && !userLikes.skills.includes(skillId) ? (
                  <button
                    onClick={() => {
                      auth.uid
                        ? dispatch(likeSkill(skillId))
                        : navigate(
                            `/auth/signin?nav=${window.location.pathname}`
                          );
                    }}
                    className={`${btnStyles["auth-blob-btn"]}`}
                    title={"Like skill"}
                    disabled={disableActions}
                  >
                    <HeartIcon width={20} theme={`cl-icon-light`} />
                    {likeCount}
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      auth.uid
                        ? dispatch(unLikeSkill(skillId))
                        : navigate(
                            `/auth/signin?nav=${window.location.pathname}`
                          );
                    }}
                    className={`${btnStyles["auth-blob-btn"]}`}
                    title={"Unlike skill"}
                    disabled={disableActions}
                  >
                    <FilledHeartIcon width={20} theme={`cl-icon-light`} />
                    {likeCount}
                  </button>
                )}
              </div>
              <div className="r">
                <button
                  className={`${btnStyles["auth-blob-btn"]}`}
                  title="Report skill"
                  onClick={() => {
                    auth.uid
                      ? handleReportClick()
                      : navigate(
                          `/auth/signin?nav=${window.location.pathname}`
                        );
                  }}
                  disabled={disableActions}
                >
                  <ReportIcon width={22} theme={`cl-icon-light`} />
                </button>
              </div>
              <div className="r">
                <button
                  className={`${btnStyles["auth-blob-btn"]} btn__share`}
                  title="Share skills"
                  data-target={`btn-share`}
                  disabled={disableActions}
                >
                  <ShareIcon width={22} theme={`cl-icon-light`} />
                </button>
                <ul id="btn-share" className="dropdown-content">
                  <li
                    className="custom__list"
                    style={{ width: "100%", gap: "10px", padding: "7px 14px" }}
                  >
                    <button
                      className={`${btnStyles["auth-blob-btn"]}`}
                      style={{ width: "100%", justifyContent: "flex-start" }}
                      onClick={copyToClipboard}
                    >
                      <LinkIcon width={20} theme={`cl-icon-light`} />
                      Copy link
                    </button>
                  </li>
                  <li
                    className="custom__list"
                    style={{ width: "100%", gap: "10px", padding: "7px 14px" }}
                  >
                    <button
                      className={`${btnStyles["auth-blob-btn"]}`}
                      style={{ width: "100%", justifyContent: "flex-start" }}
                      onClick={shareOnFacebook}
                    >
                      <FbIcon width={20} />
                      Share on Facebook
                    </button>
                  </li>
                  <li
                    className="custom__list"
                    style={{ width: "100%", gap: "10px", padding: "7px 14px" }}
                  >
                    <button
                      className={`${btnStyles["auth-blob-btn"]}`}
                      style={{ width: "100%", justifyContent: "flex-start" }}
                      onClick={shareOnTwitter}
                    >
                      <XIcon width={20} />
                      Share on X (Twitter)
                    </button>
                  </li>
                </ul>
              </div>
              {auth.uid === userId && (
                <div className="r">
                  <button
                    className={`${btnStyles["auth-blob-btn"]} btn__edit`}
                    data-target={`btn-edit`}
                  >
                    <ThreeDots width={22} theme={`cl-icon-light`} />
                  </button>
                  <ul id="btn-edit" className="dropdown-content">
                    <li
                      className="custom__list"
                      style={{
                        width: "100%",
                        gap: "10px",
                        padding: "7px 14px",
                      }}
                    >
                      <button
                        className={`${btnStyles["auth-blob-btn"]}`}
                        style={{ width: "100%", justifyContent: "flex-start" }}
                        title="Delete skill"
                        onClick={() => setMainView(true)}
                      >
                        <RemoveIcon width={20} theme={`cl-icon-light`} />
                        Delete skill
                      </button>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </section>
        {reportModal && (
          <ReportModal
            modalRef={reportRef}
            reportModal={reportModal}
            postData={skillData}
            setPostData={setSkillData}
            handleSubmit={handleSubmit}
            updateFormValue={updateFormValue}
            text={`Skill`}
          />
        )}
        {mainView && (
          <DeleteModal
            view={mainView}
            setView={setMainView}
            text={"Skill"}
            callback={handleDelete}
          />
        )}
      </>
    );
};

export default compose(
  withRouter,
  firestoreConnect((props) => {
    if (props.skillId) {
      return [
        {
          collection: "skills",
          doc: props.skillId,
          subcollections: [{ collection: "reviews" }],
          orderBy: ["createdAt", "desc"],
          storeAs: `${props.skillId}-reviews`,
        },
      ];
    } else {
      return [];
    }
  })
)(SkillActions);

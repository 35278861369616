import React from "react";
import { CancelIcon } from "../../../assets/icons/Icons";

const SurveyPopUp = ({ handleRedirect, handleCancel, link }) => {
  return (
    <section className="section-container v-survey fx-fade">
      <div className="dialog-wrapper">
        <div className="dialog-info">
          <div className="dialog-text">
            <p className="title">
              <b>We are asking people to participate in a survey</b>
            </p>
            <p>Could you take a few minutes to answer a short survey?</p>
          </div>
          <div className="dialog-actions">
            <button onClick={() => handleRedirect(link)}>Start survey</button>
          </div>
        </div>
        <button className="dialog-cancel r-float" onClick={handleCancel}>
          <CancelIcon width={20} />
        </button>
      </div>
    </section>
  );
};

export default SurveyPopUp;

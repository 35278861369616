import React from "react";
import { isEmpty, isLoaded } from "react-redux-firebase";
import ForumCardBox from "../../../general/components/ForumCardBox";
import moment from "moment";
import Loader from "../../../general/components/Loader";

const PostProfile = ({ userPosts }) => {
  return (
    <div className="v-forum-lists">
      <section className="section-container">
        <div className="section-row row">
          {!isLoaded(userPosts) ? (
            <Loader type={`cover`} />
          ) : isEmpty(userPosts) ? (
            <div>No post available</div>
          ) : (
            userPosts.map((s) => {
              return (
                <div
                  className="section-col section-card col s12 m6 l4 xl3"
                  key={s.id}
                >
                  <ForumCardBox
                    cardId={s.id}
                    cardBanner={s.properties.banner}
                    cardTag={s.properties.tags[0]}
                    cardTitle={s.properties.title}
                    cardAvatar={s.author.photoURL}
                    cardUserName={s.author.username}
                    cardDate={moment(s.createdAt.toDate()).fromNow()}
                  />
                </div>
              );
            })
          )}
        </div>
      </section>
    </div>
  );
};

export default PostProfile;

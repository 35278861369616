import React from "react";
import {
  FbIcon,
  InstaIcon,
  LinkIcon,
  LinkedInIcon,
  WhatsAppIcon,
  XIcon,
} from "../../../assets/icons/Icons";
import { compose } from "redux";
import withRouter from "../../../general/components/WithRouter";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import { useSelector } from "react-redux";

const SkillConnect = ({
  userId,
  skillPrice,
  filterUrl,
  isJob,
  skillLocation,
}) => {
  const user = useSelector(
    ({ firestore: { ordered } }) => ordered[userId] && ordered[userId]
  );
  if (isLoaded(user)) {
    const _user = user[0].socials;
    return (
      <section className="blob-item divide">
        <div className="blob-tag-area">
          <h4>Are you interested?</h4>
          <div className="blob-connect">
            {isJob ? (
              <>
                <h5>Job Salary</h5>
                <p className="price">TRY. {skillPrice}</p>
                <h5>Job Location</h5>
                <p className="price">{skillLocation}</p>
              </>
            ) : (
              <>
                <h5>Starting price</h5>
                <p className="price">TRY. {skillPrice}</p>
              </>
            )}
            <h5>Connect with {isJob ? "job" : "skill"} owner via</h5>
            <ul className="blob-tag-lists">
              {_user.twitter && (
                <li className="blob-list">
                  <a
                    target="blank"
                    referrerPolicy="no-referrer"
                    href={filterUrl(_user.twitter)}
                  >
                    <XIcon width={20} theme={`cl-icon-light`} />{" "}
                  </a>
                </li>
              )}
              {_user.facebook && (
                <li className="blob-list">
                  <a
                    target="blank"
                    referrerPolicy="no-referrer"
                    href={filterUrl(_user.facebook)}
                  >
                    <FbIcon width={20} theme={`cl-icon-light`} />{" "}
                  </a>
                </li>
              )}
              {_user.linkedIn && (
                <li className="blob-list">
                  <a
                    target="blank"
                    referrerPolicy="no-referrer"
                    href={filterUrl(_user.linkedIn)}
                  >
                    <LinkedInIcon width={20} theme={`cl-icon-light`} />{" "}
                  </a>
                </li>
              )}
              {_user.portfolio && (
                <li className="blob-list">
                  <a
                    target="blank"
                    referrerPolicy="no-referrer"
                    href={filterUrl(_user.portfolio)}
                  >
                    <LinkIcon width={20} theme={`cl-icon-light`} />{" "}
                  </a>
                </li>
              )}
              {_user.whatsapp && (
                <li className="blob-list">
                  <a
                    target="blank"
                    referrerPolicy="no-referrer"
                    href={filterUrl(_user.whatsapp)}
                  >
                    <WhatsAppIcon width={20} theme={`cl-icon-light`} />{" "}
                  </a>
                </li>
              )}
              {_user.instagram && (
                <li className="blob-list">
                  <a
                    target="blank"
                    referrerPolicy="no-referrer"
                    href={filterUrl(_user.instagram)}
                  >
                    <InstaIcon width={20} theme={`cl-icon-light`} />{" "}
                  </a>
                </li>
              )}
            </ul>
            <p className="disclaimer">
              <i>Disclaimer: </i>
              <span style={{ maxWidth: "85ch" }}>
                VOIS is not responsible for any transaction that occurs between
                you and the skill owner as stated in our
                <a href="/">community guidelines.</a>
              </span>
            </p>
          </div>
        </div>
      </section>
    );
  }
};

export default compose(
  withRouter,
  firestoreConnect((props) => {
    if (props.userId) {
      return [
        {
          collection: "users",
          where: [["user.id", "==", props.userId]],
          storeAs: props.userId,
        },
      ];
    } else {
      return [];
    }
  })
)(SkillConnect);

import React, { useEffect, useState } from "react";
import authStyles from "../css/auth.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useLocation} from "react-router-dom";
import { signOut } from "../stores/auth/AuthSlice";
import Loader from "../general/components/Loader";

const AuthSignOut = () => {
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const location = useLocation();
  const { auth } = useSelector((state) => state.firebase);
  const [view, setView] = useState(false);

  useEffect(() => {
    if (auth.uid) {
      setView(true);
      setTimeout(() => {
        // console.log("i ran");
        dispatch(signOut(""));
      }, 400);
    } else {
      setTimeout(() => {
        window.location.href = "/auth";
      }, 1000);
    }
  }, [auth.uid, auth, location.path]);

  return (
    <>
      {view ? (
        <main className={`${authStyles["auth-main"]}`}>
          <div className={`${authStyles["auth-main-items"]}`}>
            <h4 className={`${authStyles["auth-main-title"]}`}>
              You are now Signing Out.
            </h4>
            <p className={`${authStyles["auth-main-sub"]}`}>
              You can no longer interact with the app. Until next time champ.
            </p>
          </div>
        </main>
      ) : (
        <Loader type="cover" />
      )}
    </>
  );
};

export default AuthSignOut;

import React, { useEffect, useState } from "react";
import "../../../css/blob-content.css";
import SliderContainer from "../../../general/components/SliderContainer";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, isLoaded } from "react-redux-firebase";
import AbortableToast from "../../../general/components/AbortableToast";
import Loader from "../../../general/components/Loader";
import { replaceSpaces } from "../../../general/functions";
import HouseActions from "./HouseActions";
import HouseBreakDown from "./HouseBreakDown";
import HouseReviewWrapper from "./HouseReviewWrapper";
import {
  SET__CRUD__ERROR,
  createHouseReview,
  deleteHouseReview,
  reportHouseReview,
} from "../../../stores/crud/CrudSlice";
import { SET__REVIEW__REPORT__MODAL } from "../../../stores/modals/ModalSlice";
import MoreFromTags from "./MoreFromTags";
import MoreFromHousing from "./MoreFromHousing";
import Error404 from "../../Error404";

const HouseByID = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { houses, posts } = useOutletContext();
  const [loading, setIsLoading] = useState(false);
  const { auth } = useSelector((state) => state.firebase);
  const [mainView, setMainView] = useState(false);
  const [subView, setSubView] = useState(false);

  // report state
  const [houseData, setHouseData] = useState({
    id: "",
    type: "",
    report: "",
    content: "",
    hid: "",
  });
  // pagination variables
  const maxLimit = 5;
  const [indexDisp, setIndexDisp] = useState(0);
  const [maxDispLimit, setMaxDispLimit] = useState(maxLimit);

  const handleLoadMore = () => {
    setMaxDispLimit(maxDispLimit + maxLimit);
  };

  // update post value
  const updateFormValue = (evt) => {
    setHouseData((state) => ({
      ...state,
      [evt.target.name]: evt.target.value,
    }));
  };

  const filterHouseByID = React.useMemo(() => {
    if (isLoaded(houses)) {
      return houses.filter((data) => {
        return (
          [data.building.city, data.id.slice(10)]
            .join("")
            .toLowerCase()
            .indexOf([params.tag, params.hid].join("").toLowerCase()) !== -1
        );
      });
    }
  }, [houses, params]);

  useEffect(() => {
    if (isLoaded(filterHouseByID)) {
      setIsLoading(true);
      window.scrollTo(0, 0, "smooth");
      setTimeout(() => {
        setIsLoading(false);
      }, 300);
    }
  }, [params.hid]);

  const copyToClipboard = () => {
    const link = document.querySelector(".copy__url");
    navigator.clipboard
      .writeText(`https://${window.location.host}${link.value}`)
      .then(() => {
        AbortableToast("Link copied successfully");
      });
  };

  const shareOnFacebook = () => {
    const link = document.querySelector(".copy__url");
    const navUrl =
      "https://www.facebook.com/sharer/sharer.php?u=" +
      `https://${window.location.host}${window.encodeURIComponent(link.value)}`;
    window.open(navUrl, "_blank");
  };

  const shareOnTwitter = () => {
    const link = document.querySelector(".copy__url");
    const navUrl =
      "https://twitter.com/intent/tweet?text=" +
      `https://${window.location.host}${link.value}`;
    window.open(navUrl, "_blank");
  };

  const reviews = useSelector(({ firestore: { ordered } }) => {
    if (isLoaded(filterHouseByID) && !isEmpty(filterHouseByID))
      return (
        ordered[`${filterHouseByID[0].id}-reviews`] &&
        ordered[`${filterHouseByID[0].id}-reviews`]
      );
  });

  const handleReviewTotal = (docs) => {
    let total = 0;

    docs.forEach((doc) => {
      total +=
        Number(doc.rating.exterior) +
        Number(doc.rating.interior) +
        Number(doc.rating.communication) +
        Number(doc.rating.services) +
        Number(doc.rating.security) +
        Number(doc.rating.vfm) +
        Number(doc.rating.hApp) +
        Number(doc.rating.facility);
    });

    if (total === 0) {
      return 0;
    }
    return total / (8 * docs.length);
  };

  const handleReviewBreakDown = (type, docs) => {
    let total = 0;

    if (isLoaded(docs))
      if (type === "communication") {
        docs.forEach((doc) => {
          total +=
            Number(doc.rating.communication) /
            filterHouseByID[0].interactions.reviewCount;
        });
      } else if (type === "services") {
        docs.forEach((doc) => {
          total +=
            Number(doc.rating.services) /
            filterHouseByID[0].interactions.reviewCount;
        });
      } else if (type === "security") {
        docs.forEach((doc) => {
          total +=
            Number(doc.rating.security) /
            filterHouseByID[0].interactions.reviewCount;
        });
      } else if (type === "facility") {
        docs.forEach((doc) => {
          total +=
            Number(doc.rating.facility) /
            filterHouseByID[0].interactions.reviewCount;
        });
      } else if (type === "hApp") {
        docs.forEach((doc) => {
          total +=
            Number(doc.rating.hApp) /
            filterHouseByID[0].interactions.reviewCount;
        });
      } else if (type === "vfm") {
        docs.forEach((doc) => {
          total +=
            Number(doc.rating.vfm) /
            filterHouseByID[0].interactions.reviewCount;
        });
      } else if (type === "interior") {
        docs.forEach((doc) => {
          total +=
            Number(doc.rating.interior) /
            filterHouseByID[0].interactions.reviewCount;
        });
      } else {
        docs.forEach((doc) => {
          total +=
            Number(doc.rating.exterior) /
            filterHouseByID[0].interactions.reviewCount;
        });
      }
    if (total === 0) {
      return 0.0;
    }
    return total.toFixed(1);
  };

  // form state
  const [reviewState, setReviewState] = useState({
    exterior: 1,
    communication: 1,
    interior: 1,
    hApp: 1,
    facility: 1,
    services: 1,
    security: 1,
    vfm: 1,
    comment: "",
  });

  const updateReviewValue = (evt) => {
    setReviewState((state) => ({
      ...state,
      [evt.target.name]: evt.target.value,
    }));
  };

  const handleSubmitReview = () => {
    const data = {
      id: filterHouseByID[0].id,
      city: filterHouseByID[0].building.city,
      area: filterHouseByID[0].building.area,
      building: filterHouseByID[0].building.name,
      exterior: reviewState.exterior,
      communication: reviewState.communication,
      interior: reviewState.interior,
      hApp: reviewState.hApp,
      facility: reviewState.facility,
      services: reviewState.services,
      security: reviewState.security,
      vfm: reviewState.vfm,
      comment: reviewState.comment,
    };

    // console.log(data);

    dispatch(createHouseReview(data))
      .then((data) => {
        dispatch(SET__CRUD__ERROR(null));
        setSubView(false);
        setReviewState({
          comment: "",
          exterior: 1,
          communication: 1,
          interior: 1,
          hApp: 1,
          facility: 1,
          services: 1,
          security: 1,
          vfm: 1,
        });
      })
      .catch((err) => {
        // console.log(err.code);
        dispatch(SET__CRUD__ERROR(err.code));
      });
  };

  const handleReviewDelete = () => {
    setMainView(false);
    dispatch(deleteHouseReview(houseData))
      .then((data) => {
        dispatch(SET__CRUD__ERROR(null));
        setTimeout(() => {
          AbortableToast("Your review has been deleted.");
        }, 1500);
      })
      .catch((err) => {
        setTimeout(() => {
          AbortableToast("Your review could not be deleted.");
        }, 1500);
        dispatch(SET__CRUD__ERROR(err.code));
      });
  };

  const handleReviewReport = () => {
    // console.log(houseData);
    dispatch(reportHouseReview(houseData))
      .then((data) => {
        dispatch(SET__CRUD__ERROR(null));
        dispatch(SET__REVIEW__REPORT__MODAL(false));
        setTimeout(() => {
          AbortableToast("Your report has been submitted.");
        }, 1500);
      })
      .catch((err) => {
        dispatch(SET__REVIEW__REPORT__MODAL(false));
        setTimeout(() => {
          AbortableToast("Your report could not be submitted.");
        }, 1500);
        dispatch(SET__CRUD__ERROR(err.code));
      });
  };

  return (
    <>
      {loading && (
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "calc(100vh - 120px)",
          }}
        >
          <Loader type={`cover`} />
        </div>
      )}
      {!isLoaded(filterHouseByID) ? (
        <div
          style={{
            width: "100%",
            height: "calc(100vh - 120px)",
          }}
        >
          <Loader type={`cover`} />
        </div>
      ) : isEmpty(filterHouseByID) ? (
        <Error404
          posts={posts}
          text={"Restricted or deleted file"}
          code={401}
          unset={params.pid !== undefined}
        />
      ) : isLoaded(filterHouseByID) && filterHouseByID[0].status !== 0 ? (
        <Error404
          posts={posts}
          text={"This house review is currently under review."}
          code={402}
          unset={params.pid !== undefined}
        />
      ) : isLoaded(filterHouseByID) &&
        filterHouseByID[0].interactions.hidden ? (
        <Error404
          posts={posts}
          text={"House under review"}
          code={401}
          unset={params.pid !== undefined}
        />
      ) : (
        <div className="blob-wrapper">
          <div className="blob-content container">
            <div className="blob-content-items">
              <section className="blob-item ">
                <div className="blob-info-area">
                  <h2
                    className="blob-title"
                    data-header-for="ratings"
                    style={{ marginBottom: "0" }}
                  >
                    {filterHouseByID[0].building.name}
                    <span>
                      {reviews && Number(handleReviewTotal(reviews).toFixed(1))}
                    </span>
                  </h2>
                  <input
                    type="hidden"
                    name="link"
                    value={`/housing/${replaceSpaces(
                      filterHouseByID[0].building.city
                    ).toLowerCase()}/${filterHouseByID[0].id
                      .slice(10)
                      .toLowerCase()}`}
                    className="copy__url"
                  />
                </div>
              </section>
              <HouseActions
                houseId={filterHouseByID[0].id}
                location={`${filterHouseByID[0].building.city}, ${filterHouseByID[0].building.area}`}
                disableActions={false}
                handleReviewTotal={handleReviewTotal}
                copyToClipboard={copyToClipboard}
                shareOnFacebook={shareOnFacebook}
                shareOnTwitter={shareOnTwitter}
              />
            </div>
          </div>

          {filterHouseByID[0].building.photos.length > 0 && (
            <div className="blob-hero-slider">
              <div className="blob-break-view">
                <section className="blob-item blob-br">
                  <div className="blob-img-area">
                    <SliderContainer
                      imgArr={filterHouseByID[0].building.photos}
                    />
                  </div>
                </section>
              </div>
            </div>
          )}
          <div className="blob-content container">
            <div className="blob-content-items">
              <section className="blob-item divide">
                {reviews && reviews.length > 0 && (
                  <HouseBreakDown
                    handleReviewBreakdown={handleReviewBreakDown}
                    reviews={reviews}
                  />
                )}
              </section>
              <HouseReviewWrapper
                subView={subView}
                setSubView={setSubView}
                auth={auth}
                navigate={navigate}
                reviews={reviews}
                reviewState={reviewState}
                handleReviewSubmit={handleSubmitReview}
                updateReviewValue={updateReviewValue}
                houseData={houseData}
                setHouseData={setHouseData}
                mainView={mainView}
                setMainView={setMainView}
                updateFormValue={updateFormValue}
                maxLimit={maxLimit}
                maxDispLimit={maxDispLimit}
                indexDisp={indexDisp}
                houseId={filterHouseByID[0].id}
                handleLoadMore={handleLoadMore}
                handleReviewDelete={handleReviewDelete}
                handleReviewReport={handleReviewReport}
              />
            </div>
          </div>
          <div className="blob-extra">
            <div className="blob-extra-content container">
              <div className="blob-extra-items">
                <MoreFromTags
                  housing={houses}
                  houseId={filterHouseByID[0].id}
                  houseName={filterHouseByID[0].building.name}
                />
                <MoreFromHousing
                  housing={houses}
                  houseId={filterHouseByID[0].id}
                  houseName={filterHouseByID[0].building.name}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default HouseByID;

import React from "react";
import { ExternalLinkIcon, MemberIcon } from "../../../assets/icons/Icons";

export const BecomeMember = () => {
  return (
    <a
      href="https://docs.google.com/forms/d/e/1FAIpQLSfVG-A6PmM8ngO6Yvey_fsGvUEzf9rywdGqehKM2RaLfHvN_A/viewform"
      target="blank"
      referrerPolicy="no-referrer"
    >
      <li className="collapsible-item">
        <div className="collapsible-header">
          <MemberIcon width={20} theme={`cl-icon-light`} />
          <p>
            Become a member
            <span>
              This is an external application. You will be redirected.
            </span>
          </p>
          <div className="caret-area link">
            <ExternalLinkIcon width={20} theme={`cl-icon-light`} />
          </div>
        </div>
      </li>
    </a>
  );
};

import React, { useEffect, useState } from "react";
import "../../../css/blob-content.css";
import PropTypes from "prop-types";
import { FbIcon, LinkIcon, XIcon } from "../../../assets/icons/Icons";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { isEmpty, isLoaded } from "react-redux-firebase";
import Loader from "../../../general/components/Loader";
import PartialUserInfo from "./PartialUserInfo";
import moment from "moment";
import { ForumActions } from "./ForumActions";
import MoreFromAuthor from "./MoreFromAuthor";
import MoreFromForum from "./MoreFromForum";
import { useDispatch, useSelector } from "react-redux";
import { replaceSpaces } from "../../../general/functions";
import AbortableToast from "../../../general/components/AbortableToast";
import {
  SET__CRUD__ERROR,
  createComment,
  createCommentReport,
  createPostReport,
  createSubComment,
  deleteComment,
  deletePost,
  deleteSubComment,
  disableComment,
  enableComment,
} from "../../../stores/crud/CrudSlice";
import {
  SET__COMMENT__REPORT__MODAL,
  SET__REPORT__MODAL,
} from "../../../stores/modals/ModalSlice";
import ForumCommentWrapper from "./ForumCommentWrapper";
import Error404 from "../../Error404";

const ForumByID = ({ nmg }) => {
  const params = useParams();
  const dispatch = useDispatch();
  const { posts } = useOutletContext();
  const [loading, setIsLoading] = useState(false);
  const { auth } = useSelector((state) => state.firebase);
  const { userSaves, userLikes } = useSelector(
    (state) => state.firebase.profile
  );
  // pagination variables
  const maxLimit = 5;
  const [indexDisp, setIndexDisp] = useState(0);
  const [maxDispLimit, setMaxDispLimit] = useState(maxLimit);
  // report state
  const [postData, setPostData] = useState({
    id: "",
    type: "",
    report: "",
  });

  const [delData, setDelData] = useState({
    pid: "",
    cid: "",
    scid: "",
  });
  const [view, setView] = useState(false);
  const [mainView, setMainView] = useState(false);
  const [subView, setSubView] = useState(false);
  const [commentData, setCommentData] = useState({
    id: "",
    pid: "",
    postId: "",
    type: "",
    content: "",
    report: "",
  });

  // update post value
  const updateFormValue = (evt) => {
    setPostData((state) => ({ ...state, [evt.target.name]: evt.target.value }));
  };
  // update post value
  const updateCommentDataValue = (evt) => {
    setCommentData((state) => ({
      ...state,
      [evt.target.name]: evt.target.value,
    }));
  };
  // callback action for report
  const handlePostReport = () => {
    dispatch(createPostReport(postData))
      .then((data) => {
        dispatch(SET__CRUD__ERROR("success"));
        dispatch(SET__REPORT__MODAL(false));
        setTimeout(() => {
          AbortableToast("Your report has been submitted.");
        }, 1500);
      })
      .catch((err) => {
        dispatch(SET__REPORT__MODAL(false));
        setTimeout(() => {
          AbortableToast("Your report could not be submitted.");
        }, 1500);
        dispatch(SET__CRUD__ERROR(err.code));
      });
  };

  const handleLoadMore = () => {
    setMaxDispLimit(maxDispLimit + maxLimit);
  };

  const navigate = useNavigate();

  const filterPostByID = React.useMemo(() => {
    if (isLoaded(posts)) {
      return posts.filter((data) => {
        if (params.fid) {
          return (
            [data.properties.tags[0], data.id.slice(10)]
              .join("")
              .toLowerCase()
              .indexOf(
                [
                  params.tag,
                  params.fid.split("-")[params.fid.split("-").length - 1],
                ]
                  .join("")
                  .toLowerCase()
              ) !== -1
          );
        } else {
          return (
            data.author.id === auth.uid &&
            data.id.toLowerCase() === params.pid.toLowerCase()
          );
        }
      });
    }
  }, [posts, params.fid]);

  useEffect(() => {
    if (isLoaded(filterPostByID)) {
      setIsLoading(true);
      window.scrollTo(0, 0, "smooth");
      setTimeout(() => {
        setIsLoading(false);
      }, 300);
    }
  }, [params.fid]);

  const copyToClipboard = () => {
    const link = document.querySelector(".copy__url");
    navigator.clipboard
      .writeText(`https://${window.location.host}${link.value}`)
      .then(() => {
        AbortableToast("Link copied successfully");
      });
  };

  const shareOnFacebook = () => {
    const link = document.querySelector(".copy__url");
    const navUrl =
      "https://www.facebook.com/sharer/sharer.php?u=" +
      `https://${window.location.host}${window.encodeURIComponent(link.value)}`;
    window.open(navUrl, "_blank");
  };

  const shareOnTwitter = () => {
    const link = document.querySelector(".copy__url");
    const navUrl =
      "https://twitter.com/intent/tweet?text=" +
      `https://${window.location.host}${link.value}`;
    window.open(navUrl, "_blank");
  };

  const handleDelete = () => {
    setIsLoading(true);
    dispatch(deletePost(postData)).then(() => {
      AbortableToast("Post successfully deleted.");
      setTimeout(() => {
        setMainView(false);
        window.location.replace("/me/posts");
      }, 1500);
    });
  };

  const handleDisable = () => {
    dispatch(disableComment(postData)).then(() => {
      setTimeout(() => {
        AbortableToast("Comments successfully disabled.");
      }, 2000);
    });
  };

  const handleEnable = () => {
    dispatch(enableComment(postData)).then(() => {
      setTimeout(() => {
        AbortableToast("Comments successfully enabled.");
      }, 2000);
    });
  };

  // comment submit
  const [textCount, setTextCount] = useState(null);
  const [comment, setComment] = useState("");
  const updateCommentValue = (evt) => {
    setComment(evt.target.value);
  };

  const handleSubmitComment = () => {
    const data = {
      docId: isLoaded(filterPostByID) ? filterPostByID[0].id : null,
      comment: comment,
    };

    dispatch(createComment(data))
      .then((data) => {
        dispatch(SET__CRUD__ERROR(null));
        setComment("");
        setTextCount(0);
      })
      .catch((err) => {
        // console.log(err.code);
        dispatch(SET__CRUD__ERROR(err.code));
      });
  };

  //sub comment submit
  const [subTextCount, setSubTextCount] = useState(null);
  const [subComment, setSubComment] = useState("");
  const updateSubCommentValue = (evt) => {
    setSubComment(evt.target.value);
  };

  const handleSubmitSubComment = (query) => {
    const data = {
      pid: query.split("?")[0],
      id: query.split("?")[1],
      subcomment: subComment,
    };

    // console.log(data)

    dispatch(createSubComment(data))
      .then((data) => {
        dispatch(SET__CRUD__ERROR(null));
        setSubComment("");
        setSubTextCount(0);
      })
      .catch((err) => {
        // console.log(err.code);
        dispatch(SET__CRUD__ERROR(err.code));
      });
  };

  const handleCommentReport = () => {
    dispatch(createCommentReport(commentData))
      .then((data) => {
        dispatch(SET__CRUD__ERROR(null));
        dispatch(SET__COMMENT__REPORT__MODAL(false));
        setTimeout(() => {
          AbortableToast("Your report has been submitted.");
        }, 1500);
      })
      .catch((err) => {
        dispatch(SET__COMMENT__REPORT__MODAL(false));
        setTimeout(() => {
          AbortableToast("Your report could not be submitted.");
        }, 1500);
        dispatch(SET__CRUD__ERROR(err.code));
      });
  };

  const handleCommentDelete = () => {
    dispatch(deleteComment(delData))
      .then((data) => {
        dispatch(SET__CRUD__ERROR(null));
        setView(false);
        setTimeout(() => {
          AbortableToast("Your comment has been deleted.");
        }, 1500);
      })
      .catch((err) => {
        setView(false);
        setTimeout(() => {
          AbortableToast("Your comment could not be deleted.");
        }, 1500);
        dispatch(SET__CRUD__ERROR(err.code));
      });
  };

  const handleSubCommentDelete = () => {
    dispatch(deleteSubComment(delData))
      .then((data) => {
        dispatch(SET__CRUD__ERROR(null));
        setSubView(false);
        setTimeout(() => {
          AbortableToast("Your comment has been deleted.");
        }, 1500);
      })
      .catch((err) => {
        setSubView(false);
        setTimeout(() => {
          AbortableToast("Your comment could not be deleted.");
        }, 1500);
        dispatch(SET__CRUD__ERROR(err.code));
      });
  };
  return (
    <>
      {loading && (
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "calc(100vh - 120px)",
          }}
        >
          <Loader type={`cover`} />
        </div>
      )}
      {!isLoaded(filterPostByID) ? (
        <div
          style={{
            width: "100%",
            height: "calc(100vh - 120px)",
          }}
        >
          <Loader type={`cover`} />
        </div>
      ) : isEmpty(filterPostByID) ? (
        <Error404
          posts={posts}
          text={"Restricted or deleted file"}
          code={401}
          unset={params.pid !== undefined}
        />
      ) : isLoaded(filterPostByID) &&
        filterPostByID[0].status === 3 &&
        filterPostByID[0].author.id === auth.uid ? (
        <div className="blob-wrapper" style={{ marginTop: nmg && "0" }}>
          <div className={`blob-content ${params.fid ? "container" : ""}`}>
            <div className="blob-content-items">
              <section className="blob-item ">
                <div className="blob-info-area">
                  <h2
                    className="blob-title"
                    style={{ marginBottom: 0 }}
                    dangerouslySetInnerHTML={{
                      __html:
                        filterPostByID[0].properties.title !== ""
                          ? filterPostByID[0].properties.title
                          : "Untitled Draft",
                    }}
                  ></h2>
                  {filterPostByID[0].properties.subTitle && (
                    <p
                      className="blob-sub"
                      dangerouslySetInnerHTML={{
                        __html: filterPostByID[0].properties.subTitle,
                      }}
                    ></p>
                  )}
                </div>
              </section>
              <PartialUserInfo
                userId={filterPostByID[0].author.id}
                username={filterPostByID[0].author.username}
                userAvatar={filterPostByID[0].author.photoURL}
                category={
                  filterPostByID[0].properties.tags[0] !== "" &&
                  filterPostByID[0].properties.tags[0] !== undefined
                    ? filterPostByID[0].properties.tags[0]
                    : ""
                }
                duration={filterPostByID[0].properties.duration}
                createdAt={moment(
                  filterPostByID[0].createdAt.toDate()
                ).fromNow()}
              />
              <ForumActions
                disableActions={true}
                userSaves={userSaves}
                userLikes={userLikes}
                postId={filterPostByID[0].id}
                auth={auth}
                isCommentDisabled={
                  !filterPostByID[0].interactions.isCommentDisabled
                }
                navigate={navigate}
                likeCount={filterPostByID[0].interactions.likeCount}
                commentCount={filterPostByID[0].interactions.commentCount}
                copyToClipboard={copyToClipboard}
                shareOnFacebook={shareOnFacebook}
                shareOnTwitter={shareOnTwitter}
                userId={filterPostByID[0].author.id}
                setPostData={setPostData}
                postData={postData}
                handleSubmit={handlePostReport}
                updateFormValue={updateFormValue}
                postStatus={filterPostByID[0].status}
                handleDelete={handleDelete}
                handleEnable={handleEnable}
                handleDisable={handleDisable}
                mainView={mainView}
                setMainView={setMainView}
              />
              <section className="blob-item ">
                <div className="blob-desc-area"></div>
              </section>
            </div>
            {filterPostByID[0].properties.banner && (
              <div className="blob-break-view">
                <section className="blob-item blob-br">
                  <div className="blob-img-area">
                    <img
                      src={filterPostByID[0].properties.banner}
                      alt={`post-banner-${filterPostByID[0].properties.banner}`}
                      role="presentation"
                    />
                  </div>
                </section>
              </div>
            )}

            <div className="blob-content-items">
              <section className="blob-item ">
                <div
                  className="blob-text-area"
                  dangerouslySetInnerHTML={{
                    __html: filterPostByID[0].properties.contentHTML,
                  }}
                ></div>
              </section>
              <section className="blob-item divide">
                {filterPostByID[0].properties.tags.length > 0 ? (
                  <div className="blob-tag-area">
                    <h4>Author tags</h4>
                    <ul className="blob-tag-lists">
                      {filterPostByID[0].properties.tags.map((tag) => (
                        <li key={tag}>
                          <a
                            className="blob-list"
                            href={`/forum/${replaceSpaces(tag).toLowerCase()}`}
                          >
                            {tag}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <div className="blob-tag-area">
                    <h4>No tags yet</h4>
                  </div>
                )}
              </section>
              <section className="blob-item divide">
                <div className="blob-tag-area">
                  <h4>Like this post?</h4>
                  <div className="blob-connect">
                    <h5>Interact by sharing</h5>
                    <ul className="blob-tag-lists">
                      <li className="blob-list">
                        <button
                          onClick={shareOnTwitter}
                          disabled={true}
                          title="cannot interact in draft mode"
                        >
                          <XIcon width={20} theme={`cl-icon-light`} />
                        </button>
                      </li>
                      <li className="blob-list">
                        <button
                          onClick={shareOnFacebook}
                          disabled={true}
                          title="cannot interact in draft mode"
                        >
                          <FbIcon width={20} theme={`cl-icon-light`} />
                        </button>
                      </li>
                      <li className="blob-list">
                        <button
                          onClick={copyToClipboard}
                          disabled={true}
                          title="cannot interact in draft mode"
                        >
                          <LinkIcon width={20} theme={`cl-icon-light`} />
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </section>

              <section
                className="blob-item divide"
                style={{ marginBottom: "3rem" }}
              >
                <div className="blob-tag-area">
                  <div className="blog-mult">
                    <h4>Publish to add commenting</h4>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      ) : isLoaded(filterPostByID) && filterPostByID[0].status !== 0 ? (
        <Error404
          posts={posts}
          text={"This post is currently under review."}
          code={402}
          unset={params.pid !== undefined}
        />
      ) : isLoaded(filterPostByID) && filterPostByID[0].interactions.hidden ? (
        <Error404
          posts={posts}
          text={"Post restricted by author"}
          code={401}
          unset={params.pid !== undefined}
        />
      ) : (
        <div className="blob-wrapper" style={{ marginTop: nmg && "0" }}>
          <div className="blob-content container">
            <div className="blob-content-items">
              <section className="blob-item ">
                <div className="blob-info-area">
                  <h2
                    className="blob-title"
                    style={{ marginBottom: 0 }}
                    dangerouslySetInnerHTML={{
                      __html: filterPostByID[0].properties.title,
                    }}
                  ></h2>
                  {filterPostByID[0].properties.subTitle && (
                    <p
                      className="blob-sub"
                      dangerouslySetInnerHTML={{
                        __html: filterPostByID[0].properties.subTitle,
                      }}
                    ></p>
                  )}
                </div>
                <input
                  type="hidden"
                  name="link"
                  value={`/forum/${replaceSpaces(
                    filterPostByID[0].properties.tags[0]
                  ).toLowerCase()}/${replaceSpaces(
                    filterPostByID[0].properties.title
                  ).toLowerCase()}-${filterPostByID[0].id
                    .slice(10)
                    .toLowerCase()}`}
                  className="copy__url"
                />
              </section>
              <PartialUserInfo
                type={"forum"}
                userId={filterPostByID[0].author.id}
                username={filterPostByID[0].author.username}
                userAvatar={filterPostByID[0].author.photoURL}
                category={filterPostByID[0].properties.tags[0]}
                duration={filterPostByID[0].properties.duration}
                createdAt={moment(
                  filterPostByID[0].createdAt.toDate()
                ).fromNow()}
              />
              <ForumActions
                disableActions={false}
                userSaves={userSaves}
                userLikes={userLikes}
                postId={filterPostByID[0].id}
                auth={auth}
                isCommentDisabled={
                  !filterPostByID[0].interactions.isCommentDisabled
                }
                navigate={navigate}
                likeCount={filterPostByID[0].interactions.likeCount}
                commentCount={filterPostByID[0].interactions.commentCount}
                copyToClipboard={copyToClipboard}
                shareOnFacebook={shareOnFacebook}
                shareOnTwitter={shareOnTwitter}
                userId={filterPostByID[0].author.id}
                setPostData={setPostData}
                postData={postData}
                handleSubmit={handlePostReport}
                updateFormValue={updateFormValue}
                postStatus={filterPostByID[0].status}
                handleDelete={handleDelete}
                handleEnable={handleEnable}
                handleDisable={handleDisable}
                mainView={mainView}
                setMainView={setMainView}
              />
              <section className="blob-item ">
                <div className="blob-desc-area"></div>
              </section>
            </div>
            {filterPostByID[0].properties.banner && (
              <div className="blob-break-view">
                <section className="blob-item blob-br">
                  <div className="blob-img-area">
                    <img
                      src={filterPostByID[0].properties.banner}
                      alt={`post-banner-${filterPostByID[0].properties.banner}`}
                      role="presentation"
                    />
                  </div>
                </section>
              </div>
            )}

            <div className="blob-content-items">
              <section className="blob-item ">
                <div
                  className="blob-text-area"
                  dangerouslySetInnerHTML={{
                    __html: filterPostByID[0].properties.contentHTML,
                  }}
                ></div>
              </section>
              <section className="blob-item divide">
                <div className="blob-tag-area">
                  <h4>Author tags</h4>
                  <ul className="blob-tag-lists">
                    {filterPostByID[0].properties.tags.map((tag) => (
                      <li key={tag}>
                        <a
                          className="blob-list"
                          href={`/forum/${replaceSpaces(tag).toLowerCase()}`}
                        >
                          {tag}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </section>
              <section className="blob-item divide">
                <div className="blob-tag-area">
                  <h4>Like this post?</h4>
                  <div className="blob-connect">
                    <h5>Interact by sharing</h5>
                    <ul className="blob-tag-lists">
                      <li className="blob-list">
                        <button onClick={shareOnTwitter}>
                          <XIcon width={20} theme={`cl-icon-light`} />
                        </button>
                      </li>
                      <li className="blob-list">
                        <button onClick={shareOnFacebook}>
                          <FbIcon width={20} theme={`cl-icon-light`} />
                        </button>
                      </li>
                      <li className="blob-list">
                        <button onClick={copyToClipboard}>
                          <LinkIcon width={20} theme={`cl-icon-light`} />
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </section>
              {!filterPostByID[0].interactions.isCommentDisabled ? (
                <ForumCommentWrapper
                  navigate={navigate}
                  auth={auth}
                  postId={filterPostByID[0].id}
                  textCount={textCount}
                  setTextCount={setTextCount}
                  comment={comment}
                  subTextCount={subTextCount}
                  setSubTextCount={setSubTextCount}
                  subComment={subComment}
                  updateCommentValue={updateCommentValue}
                  updateSubCommentValue={updateSubCommentValue}
                  handleSubmitComment={handleSubmitComment}
                  handleSubmitSubComment={handleSubmitSubComment}
                  handleCommentReport={handleCommentReport}
                  commentData={commentData}
                  setCommentData={setCommentData}
                  updateCommentDataValue={updateCommentDataValue}
                  handleCommentDelete={handleCommentDelete}
                  handleSubCommentDelete={handleSubCommentDelete}
                  view={view}
                  setView={setView}
                  subView={subView}
                  setSubView={setSubView}
                  setDelData={setDelData}
                  maxLimit={maxLimit}
                  maxDispLimit={maxDispLimit}
                  indexDisp={indexDisp}
                  handleLoadMore={handleLoadMore}
                />
              ) : (
                <section className="blob-item divide">
                  <div className="blob-tag-area">
                    <div className="blog-mult">
                      <h4>
                        Comments
                        <span className="count">
                          (Comment has been disabled by the author)
                        </span>
                      </h4>
                    </div>
                  </div>
                </section>
              )}
            </div>
          </div>
          <div className="blob-extra">
            <div className="blob-extra-content container">
              <div className="blob-extra-items">
                <MoreFromAuthor
                  authorId={filterPostByID[0].author.id}
                  posts={posts}
                  postId={filterPostByID[0].id}
                />
                <MoreFromForum
                  posts={posts}
                  authorId={filterPostByID[0].author.id}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

ForumByID.propTypes = {
  nmg: PropTypes.bool,
};

export default ForumByID;

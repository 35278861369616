import React, { useEffect, useState } from "react";
import "../../css/community-content.css";
import { ResourceIcon } from "../../assets/icons/Icons";
import {
  NavLink,
  useLocation,
  useOutletContext,
  useParams,
} from "react-router-dom";
import ResourceCard from "../../general/components/ResourceCard";
import { isEmpty, isLoaded } from "react-redux-firebase";
import { replaceDashes } from "../../general/functions";
import moment from "moment";
import BannerCardSkeleton from "../../general/components/skeletons/BannerCardSkeleton";
import Error404 from "../Error404";

const Resources = () => {
  const params = useParams();
  const location = useLocation();
  const { resources, posts } = useOutletContext();
  const [view, setView] = useState(false);

  const filteredResources = React.useMemo(() => {
    if (isLoaded(resources) && params.tag) {
      return resources.filter((data) => {
        return (
          data.status === 0 &&
          [data.properties.type]
            .join("")
            .toLowerCase()
            .indexOf(replaceDashes(params.tag).toLowerCase()) !== -1
        );
      });
    }
  }, [params.tag, resources]);

  useEffect(() => {
    if (location.pathname === "/resources/") {
      window.location.href = "/resources";
    }
    if (location.pathname === "/resources/general/") {
      window.location.href = "/resources/general";
    }
    if (location.pathname === "/resources/press-release/") {
      window.location.href = "/resources/press-release";
    }
    if (location.pathname === "/resources/publication/") {
      window.location.href = "/resources/publication";
    }
  }, [location.pathname]);

  useEffect(() => {
    if (params.tag !== undefined) {
      const arr = ["General", "Press release", "Publication"];
      const data = arr.filter((d) => {
        return d.toLowerCase() === replaceDashes(params.tag).toLowerCase();
      });
      if (data.length === 0) {
        setView(true);
      }
    }
  }, [params]);

  if (view) {
    return (
      <Error404
        posts={posts}
        text={"Missing or broken link."}
        code={404}
        unset={params.pid !== undefined}
      />
    );
  }

  return (
    <div className="community-forum">
      <section className="section-container container v-forum-hero">
        <div className="hero-area">
          <div className="hero-content">
            <div className="hero-info">
              <ResourceIcon height={80} theme={`cl-icon-p`} />
              <h3 className="hero-title">Resources</h3>
              <p className="hero-desc">
                Explore in-depth research generated articles, publications etc.
              </p>
            </div>
          </div>
        </div>
      </section>
      <div className="container hero-actions">
        <ul className="hero-tag-lists">
          <li className="tag-list">
            <NavLink to={`/resources`} end>
              All
            </NavLink>
          </li>
          <li className="tag-list">
            <NavLink to={`/resources/general`} end>
              General
            </NavLink>
          </li>
          <li className="tag-list">
            <NavLink to={`/resources/press-release`} end>
              Press release
            </NavLink>
          </li>
          <li className="tag-list">
            <NavLink to={`/resources/publication`} end>
              Publication
            </NavLink>
          </li>
        </ul>
      </div>
      {!params.tag ? (
        <div className="v-forum-lists container">
          <section className="section-container">
            <div className="section-row row">
              {!isLoaded(resources) ? (
                [1, 2, 3, 4, 5, 6].map((d) => (
                  <div
                    className="section-col section-card col s12 m6 l4 xl3"
                    key={d}
                  >
                    <BannerCardSkeleton />
                  </div>
                ))
              ) : isEmpty(resources) ? (
                <div>No resources yet</div>
              ) : (
                resources.map((s) => {
                  return (
                    <div
                      className="section-col section-card col s12 m6 l4 xl3"
                      key={s.id}
                    >
                      <ResourceCard
                        cardId={s.id}
                        cardBanner={s.properties.poster}
                        cardTag={s.properties.category}
                        cardTitle={s.properties.title}
                        cardAvatar={s.author.photoURL}
                        cardUserName={s.author.username}
                        cardDate={moment(s.createdAt.toDate()).fromNow()}
                      />
                    </div>
                  );
                })
              )}
            </div>
          </section>
        </div>
      ) : (
        <div className="v-forum-lists container">
          <section className="section-container">
            <div className="section-row row">
              {!isLoaded(filteredResources) ? (
                [1, 2, 3, 4, 5, 6].map((d) => (
                  <div
                    className="section-col section-card col s12 m6 l4 xl3"
                    key={d}
                  >
                    <BannerCardSkeleton />
                  </div>
                ))
              ) : isEmpty(filteredResources) ? (
                <div>No resources yet</div>
              ) : (
                filteredResources.map((s) => {
                  return (
                    <div
                      className="section-col section-card col s12 m6 l4 xl3"
                      key={s.id}
                    >
                      <ResourceCard
                        cardId={s.id}
                        cardBanner={s.properties.poster}
                        cardTag={s.properties.category}
                        cardTitle={s.properties.title}
                        cardAvatar={s.author.photoURL}
                        cardUserName={s.author.username}
                        cardDate={moment(s.createdAt.toDate()).fromNow()}
                      />
                    </div>
                  );
                })
              )}
            </div>
          </section>
        </div>
      )}
    </div>
  );
};

export default Resources;

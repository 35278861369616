import validator from "validator";

export const ValidateData = (data, rules) => {
  let errors = {};

  Object.keys(data).forEach((dataField) => {
    if (rules.hasOwnProperty(dataField)) {
      let fieldErrs = [];
      let val = data[dataField];
      if (rules[dataField].required && validator.isEmpty(val)) {
        fieldErrs.push("Value required");
      }
      if (!validator.isEmpty(val)) {
        if (
          rules[dataField].minLength &&
          !validator.isLength(val, rules[dataField].minLength)
        ) {
          fieldErrs.push(
            `Enter at least ${rules[dataField].minLength} characters`
          );
        }

        if (
          rules[dataField].maxLength &&
          validator.isLength(val, rules[dataField].maxLength)
        ) {
          fieldErrs.push(
            `Maximum character allowed is ${rules[dataField].maxLength}`
          );
        }
        //check if value is alphabetic
        if (
          rules[dataField].alpha &&
          !validator.isAlpha(val, "en-US", { ignore: " " })
        ) {
          fieldErrs.push("Invalid input - (Enter only letters)");
        }
        if (
          rules[dataField].alphawithdot &&
          !validator.isAlpha(val, "en-US", { ignore: "." })
        ) {
          fieldErrs.push("Invalid input - (valid input includes letters and .");
        }
        //check if value is alpha nummeric
        if (rules[dataField].alphanumeric && !validator.isAlphanumeric(val)) {
          fieldErrs.push("Invalid input - (Alphanumeric input only)");
        }
        if (rules[dataField].email && !validator.isEmail(val)) {
          fieldErrs.push("Enter a valid email address");
        }
        if (
          rules[dataField].isStrongPassword &&
          !validator.isStrongPassword(val)
        ) {
          fieldErrs.push("Your password is too weak");
        }
        if (
          rules[dataField].isEqual &&
          !validator.equals(val, data[rules[dataField].isEqual])
        ) {
          fieldErrs.push(`${Object.keys(data)[0]} values don't match`);
        }
      }
      if (fieldErrs.length > 0) {
        errors[dataField] = fieldErrs;
      }
    }
  });
  return errors;
};

import React from "react";
import {
  AppliancesIcon,
  CommunicationIcon,
  ExteriorIcon,
  InteriorIcon,
  ServicesIcon,
  SurveillanceIcon,
  VFMIcon,
} from "../../../assets/icons/Icons";

const HouseBreakDown = ({ handleReviewBreakdown, reviews }) => {
  return (
    <div className="blob-connect">
      <h5>Overall breakdown</h5>
      <div className="blob-row row">
        <div className="blob-col col s12 m6 l12 x12">
          <div className="blob-progress-desc">
            <div className="blob-cr__desc">
              <div className="blob-cr">
                <div className="blob-cr__info" data-overview="housing">
                  <p className="blob-info__title">
                    <ExteriorIcon width={25} theme={`cl-icon-light`} />
                    Exterior
                  </p>
                  <p className="blob-info__rating">
                    <span className="fa fa-star"></span>
                    <span>{handleReviewBreakdown("exterior", reviews)}</span>
                  </p>
                </div>
                <div className="blob-cr__info" data-overview="housing">
                  <p className="blob-info__title">
                    <InteriorIcon width={25} theme={`cl-icon-light`} />
                    Interior
                  </p>
                  <p className="blob-info__rating">
                    <span className="fa fa-star"></span>
                    <span>{handleReviewBreakdown("interior", reviews)}</span>
                  </p>
                </div>
                <div className="blob-cr__info" data-overview="housing">
                  <p className="blob-info__title">
                    <InteriorIcon width={25} theme={`cl-icon-light`} />
                    Facilities
                  </p>
                  <p className="blob-info__rating">
                    <span className="fa fa-star"></span>
                    <span>{handleReviewBreakdown("facilty", reviews)}</span>
                  </p>
                </div>
                <div className="blob-cr__info" data-overview="housing">
                  <p className="blob-info__title">
                    <AppliancesIcon width={25} theme={`cl-icon-light`} />
                    Appliances
                  </p>
                  <p className="blob-info__rating">
                    <span className="fa fa-star"></span>
                    <span>{handleReviewBreakdown("hApp", reviews)}</span>
                  </p>
                </div>
                <div className="blob-cr__info" data-overview="housing">
                  <p
                    className="blob-info__title"
                    title="Communication between you and landlord."
                  >
                    <CommunicationIcon width={25} theme={`cl-icon-light`} />
                    Communication
                  </p>
                  <p className="blob-info__rating">
                    <span className="fa fa-star"></span>
                    <span>
                      {handleReviewBreakdown("communication", reviews)}
                    </span>
                  </p>
                </div>
                <div className="blob-cr__info" data-overview="housing">
                  <p className="blob-info__title">
                    <SurveillanceIcon width={25} theme={`cl-icon-light`} />
                    Security
                  </p>
                  <p className="blob-info__rating">
                    <span className="fa fa-star"></span>
                    <span>{handleReviewBreakdown("security", reviews)}</span>
                  </p>
                </div>
                <div className="blob-cr__info" data-overview="housing">
                  <p className="blob-info__title">
                    <ServicesIcon width={25} theme={`cl-icon-light`} />
                    Services
                  </p>
                  <p className="blob-info__rating">
                    <span className="fa fa-star"></span>
                    <span>{handleReviewBreakdown("services", reviews)}</span>
                  </p>
                </div>
                <div className="blob-cr__info" data-overview="housing">
                  <p className="blob-info__title">
                    <VFMIcon width={25} theme={`cl-icon-light`} />
                    Value for money
                  </p>
                  <p className="blob-info__rating">
                    <span className="fa fa-star"></span>
                    <span>{handleReviewBreakdown("vfm", reviews)}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HouseBreakDown;

import React from "react";
import { isEmpty, isLoaded } from "react-redux-firebase";
import Loader from "../../../general/components/Loader";
import SkillCard from "../../../general/components/SkillCard";

const SkillProfile = ({ userSkills }) => {
  return (
    <div className="v-forum-lists">
      <section className="section-container">
        <div className="section-row row">
          {!isLoaded(userSkills) ? (
            <Loader type={`cover`} />
          ) : isEmpty(userSkills) ? (
            <div>No skills available</div>
          ) : (
            userSkills.map((skill) => (
              <div
                className="section-col section-card col s12 m6 l4 xl3"
                key={skill.id}
              >
                <SkillCard
                  skillId={skill.id}
                  skillBanner={skill.properties.banners[0]}
                  skillTitle={skill.properties.title}
                  skillTag={skill.properties.category}
                  skillAvatar={skill.author.photoURL}
                  skillAuthor={skill.author.username}
                  skillRateScore={skill.interactions.reviewCount}
                  skillReviewCount={skill.interactions.reviewCount}
                  skillPrice={skill.properties.price}
                />
              </div>
            ))
          )}
        </div>
      </section>
    </div>
  );
};

export default SkillProfile;

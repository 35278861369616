import React from "react";
import { isEmpty, isLoaded } from "react-redux-firebase";
import moment from "moment";
import Loader from "../../../general/components/Loader";
import ResourceCard from "../../../general/components/ResourceCard";
import ForumCardBox from "../../../general/components/ForumCardBox";

const CollectionsProfile = ({ userPosts }) => {
  return (
    <div className="v-forum-lists">
      <section className="section-container">
        <div className="section-row row">
          {!isLoaded(userPosts) ? (
            <Loader type={`cover`} />
          ) : isEmpty(userPosts) ? (
            <div>No resources saved</div>
          ) : isLoaded(userPosts) &&
            userPosts[0].properties.category !== undefined ? (
            userPosts.map((s) => {
              return (
                <div
                  className="section-col section-card col s12 m6 l4 xl3"
                  key={s.id}
                >
                  <ResourceCard
                    cardId={s.id}
                    cardBanner={s.properties.poster}
                    cardTag={s.properties.category}
                    cardTitle={s.properties.title}
                    cardAvatar={s.author.photoURL}
                    cardUserName={s.author.username}
                    cardDate={moment(s.createdAt.toDate()).fromNow()}
                  />
                </div>
              );
            })
          ) : (
            userPosts.map((s) => {
              return (
                <div
                  className="section-col section-card col s12 m6 l4 xl3"
                  key={s.id}
                >
                  <ForumCardBox
                    cardId={s.id}
                    cardBanner={s.properties.banner}
                    cardTag={s.properties.tags[0]}
                    cardTitle={s.properties.title}
                    cardAvatar={s.author.photoURL}
                    cardUserName={s.author.username}
                    cardDate={moment(s.createdAt.toDate()).fromNow()}
                  />
                </div>
              );
            })
          )}
        </div>
      </section>
    </div>
  );
};

export default CollectionsProfile;

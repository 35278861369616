import React, { useRef } from "react";
import SkillReview from "./SkillReview";
import { SortDownIcon, SortUpIcon } from "../../../assets/icons/Icons";
import { reviewOptions } from "../../../assets/dummies/Static";
import { useSelector } from "react-redux";
import ReportReview from "../../../modals/ReportReview";

const SkillReviews = ({
  auth,
  reviewState,
  handleSubmit,
  updateReviewValue,
  navigate,
  reviews,
  subView,
  setSubView,
  skillData,
  setSkillData,
  updateFormValue,
  handleReviewReport,
  handleFilter,
  skillId,
  handleReviewDelete,
  maxLimit,
  maxDispLimit,
  indexDisp,
  handleLoadMore,
  formView,
  setFormView,
}) => {
  const { reviewReportModal } = useSelector((state) => state.modal);
  const { isCrudLoading } = useSelector((state) => state.crud);
  const reviewRef = useRef();
  return (
    <>
      <div className="blob-comment-area">
        {!auth.uid ? (
          <button
            className="blob-button"
            onClick={() => {
              navigate(`/auth/signin?nav=${window.location.pathname}`);
            }}
          >
            Sign in to review
          </button>
        ) : (
          <button className="blob-button" onClick={() => setFormView(true)}>
            Leave a review
          </button>
        )}

        {formView && (
          <div className="form-area">
            <h3 className="form-title sm">Rating categories</h3>
            {reviewOptions.map((option, ind) => (
              <div
                className="col s12 m6 l7"
                style={{ marginBottom: "1rem" }}
                key={ind}
              >
                <div className="blob-rate__type">{option.name}</div>
                <div className="blob-rate">
                  {[5, 4, 3, 2, 1].map((n, index) => (
                    <React.Fragment key={`${option.id}${index}`}>
                      <input
                        type="radio"
                        id={`${option.id}${n}`}
                        name={option.id}
                        value={n}
                        onChange={updateReviewValue}
                      />
                      <label htmlFor={`${option.id}${n}`} title={`${n} star`}>
                        {n} stars
                      </label>
                    </React.Fragment>
                  ))}
                </div>
              </div>
            ))}
            <div className="form-group">
              <label htmlFor="comment"></label>
              <textarea
                name="comment"
                placeholder="Additional comments"
                id="comment"
                value={reviewState.comment}
                onChange={updateReviewValue}
                style={{ resize: "vertical" }}
                maxLength={300}
              ></textarea>
            </div>

            <div className="form-btn">
              <button
                className="blob-button"
                style={{
                  backgroundColor: "transparent",
                  color: "var(--tx-main-light)",
                }}
                onClick={() => setFormView(false)}
              >
                cancel
              </button>
              <button
                className="blob-button"
                onClick={handleSubmit}
                disabled={isCrudLoading}
              >
                Submit
              </button>
            </div>
          </div>
        )}

        {reviews.length > 0 && (
          <div className="blob-sort">
            <p>sort by</p>
            <button
              className="comment-action sort"
              onClick={(e) => handleFilter(e, "up")}
            >
              <SortUpIcon width={20} theme={`cl-icon-light`} />
              Most recent
            </button>
            <button
              className="comment-action sort"
              onClick={(e) => handleFilter(e, "down")}
            >
              <SortDownIcon width={20} theme={`cl-icon-light`} />
              Older
            </button>
          </div>
        )}
        <SkillReview
          reviews={reviews}
          auth={auth}
          setSkillData={setSkillData}
          skillId={skillId}
          subView={subView}
          setSubView={setSubView}
          handleReviewDelete={handleReviewDelete}
          maxLimit={maxLimit}
          maxDispLimit={maxDispLimit}
          indexDisp={indexDisp}
          handleLoadMore={handleLoadMore}
        />
      </div>
      {reviewReportModal && (
        <ReportReview
          modalRef={reviewRef}
          setPostData={setSkillData}
          postData={skillData}
          reportModal={reviewReportModal}
          handleSubmit={handleReviewReport}
          updateFormValue={updateFormValue}
          text={`Review`}
        />
      )}
    </>
  );
};

export default SkillReviews;

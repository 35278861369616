import React from "react";
import { isEmpty, isLoaded } from "react-redux-firebase";
import HousingCard from "../../../general/components/HousingCard";

const MoreFromTags = ({ housing, houseName, houseId }) => {
  const houses = React.useMemo(() => {
    if (isLoaded(housing)) {
      return housing.filter((data) => {
        return (
          [data.building.name]
            .join("")
            .toLowerCase()
            .indexOf(houseName.toLowerCase()) !== -1
        );
      });
    }
  }, [housing, houseName]);

  //   if (isLoaded(houses) && houses.filter((h) => h.id !== houseId) > 0)
  if (!isEmpty(houses) && houses.filter((h) => h.id !== houseId).length > 0)
    return (
      <section className="blob-item divide" id="general__lists">
        <div className="blob-tag-area">
          <h4>More from {houseName}</h4>
          <div className="section-row row">
            {houses
              .filter((h) => h.id !== houseId)
              .slice(0, 4)
              .map((house) => (
                <div
                  className="section-col section-card col s12 m6 l6 xl6"
                  key={house.id}
                >
                  <HousingCard
                    cardId={house.id}
                    cardBanner={house.building.photos[0]}
                    cardHouseName={house.building.name}
                    cardRateScore={4.0}
                    cardReviews={house.interactions.reviewCount}
                    cardHouseCity={house.building.city}
                    cardHouseRegion={house.building.area}
                  />
                </div>
              ))}
          </div>
        </div>
      </section>
    );
};

export default MoreFromTags;

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../general/components/Loader";
import { useDispatch, useSelector } from "react-redux";
import { isLoaded } from "react-redux-firebase";
import queryString from "query-string";
import { updateDisableAccount } from "../../stores/crud/CrudSlice";

const AuthProtected = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { auth, profile } = useSelector((state) => state.firebase);
  const [view, setView] = useState(true);
  const parsed = queryString.parse(window.location.search);


  useEffect(() => {
    if (location.pathname === "/auth/signout") {
      setView(false);
      return;
    } else {
      if (isLoaded(profile)) {
        if (!auth.uid) {
          setView(false);
          return;
        }
        if (auth.uid && profile.account.isRegCompleted) {
          setView(true);
          if (profile.account.isDisabled) {
            dispatch(updateDisableAccount(false));
          }
          setTimeout(() => {
            setView(false);
          }, 500);
          parsed.nav
            ? navigate(`${parsed.nav}`, { replace: true })
            : navigate("/", { replace: true });
        } else {
          setView(true);
          setTimeout(() => {
            setView(false);
          }, 500);
          parsed.nav
            ? navigate(`/auth/step-username?nav${parsed.nav}`, {
                replace: true,
              })
            : navigate(`/auth/step-username`, { replace: true });
        }
      }
    }
  }, [auth, profile, location.pathname]);


  return (
    <>
      {view && <Loader type="cover" />}
      {children}
    </>
  );
};

export default AuthProtected;

import React from "react";

const Subscriptions = ({ isSubscription, updateFormValue }) => {
  return (
    <li className="collapsible-item">
      <div className="collapsible-header">
        <div className="switch page-switch">
          <label>
            <input
              type="checkbox"
              name="isSubscription"
              id="isSubscription"
              checked={isSubscription}
              onChange={updateFormValue}
            />
            <span className="lever"></span>
          </label>
        </div>
        <p>
          Mail subscriptions and newsletters
          <span>
            Custom messages and newsletter delivered to your account email.
          </span>
        </p>
      </div>
    </li>
  );
};

export default Subscriptions;

import React, { useEffect, useState } from "react";
import authStyles from "../css/auth.module.css";
import formStyles from "../css/forms.module.css";
import { useLocation} from "react-router-dom";
import Loader from "../general/components/Loader";
import queryString from "query-string";
import { useDispatch, useSelector } from "react-redux";
import FormValidate from "./components/FormValidate";
import DisplayValidationMessages from "./components/DisplayValidationMessages";
import {
  SET__RESET__STATUS,
  resetPassword,
  resetPasswordComplete,
  verifyResetCode,
} from "../stores/auth/AuthSlice";

const AuthRecovery = () => {
  const { resetStatus } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [view, setView] = useState(true);
  const location = useLocation();
  const parsed = queryString.parse(location.search);
  const [isStart, setIsStart] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const [isPassword, setIsPassword] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  // elements
  const [mailElement, setMailElement] = useState({
    email: "",
  });

  const mailrules = {
    email: { required: true, email: true },
  };

  const [passElement, setPassElement] = useState({
    password: "",
    rePassword: "",
  });

  const passRules = {
    password: {
      required: true,
      isStrongPassword: true,
      isEqual: "rePassword",
    },
    rePassword: {
      required: true,
      isStrongPassword: true,
      isEqual: "password",
    },
  };

  // listeners for path change
  useEffect(() => {
    if (Object.keys(parsed).length === 0) {
      setView(false);
      setIsInvalid(false);
      setIsStart(true);
    } else {
      dispatch(verifyResetCode(parsed.oobCode));
    }
  }, [location.pathname]);

  useEffect(() => {
    dispatch(SET__RESET__STATUS(null));
  }, []);

  // listeners for reset status
  useEffect(() => {
    switch (resetStatus) {
      case "fulfilled":
        setIsStart(false);
        setIsSent(true);
        break;
      case "code__confirmed":
        setView(false);
        setIsPassword(true);
        dispatch(SET__RESET__STATUS(null));
        break;
      case "code__invalid":
        setView(false);
        setIsInvalid(true);
        break;
      case "reset__confirmed":
        setView(true);
        setIsPassword(false);
        setTimeout(() => {
          setView(false);
          setIsSuccess(true);
        }, [500]);
        break;
      default:
        break;
    }
  }, [resetStatus]);

  // functions
  const updateMailElements = (e) => {
    setMailElement((els) => ({ ...els, [e.target.name]: e.target.value }));
  };
  const updatePassElements = (e) => {
    setPassElement((els) => ({ ...els, [e.target.name]: e.target.value }));
  };

  // console.log(resetStatus);

  return (
    <>
      {view && <Loader type={"cover"} />}
      <main className={`${authStyles["auth-main"]}`}>
        <div className={`${authStyles["auth-main-items"]}`}>
          {isStart && (
            <>
              <h4 className={`${authStyles["auth-main-title"]} fx-fade`}>
                Forgot password?
              </h4>
              <p className={`${authStyles["auth-main-sub"]} fx-fade`}>
                No problem. Enter your account email below and we’ll send you a
                link to reset your password.
              </p>
              <div
                className={`${authStyles["auth-main-content"]} ${formStyles["form-pd"]} fx-fade`}
              >
                <div className={`${formStyles["form-control"]}`}>
                  <FormValidate
                    btnText={`Submit`}
                    data={mailElement}
                    rules={mailrules}
                    callBack={resetPassword}
                  >
                    {resetStatus && (
                      <p className={`${formStyles["form-error"]}`}>
                        {typeof resetStatus !== "object" && resetStatus}
                      </p>
                    )}
                    <div className={`${formStyles["form-group"]}`}>
                      <label htmlFor="email">Email</label>
                      <input
                        type="text"
                        id="email"
                        name="email"
                        autoComplete="off"
                        onChange={updateMailElements}
                        className="browser-default"
                      />
                      <DisplayValidationMessages
                        el="email"
                        authError={resetStatus}
                      />
                    </div>
                  </FormValidate>
                </div>
              </div>
            </>
          )}
          {isSent && (
            <>
              <h4 className={`${authStyles["auth-main-title"]} fx-fade`}>
                Email sent!
              </h4>
              <p className={`${authStyles["auth-main-sub"]} fx-fade`}>
                Please check your email for password reset link.
              </p>
              <div
                className={`${authStyles["auth-main-content"]} ${formStyles["form-pd"]} fx-fade`}
              >
                <div className={`${formStyles["form-control"]}`}>
                  <div className={`${formStyles["form-group"]}`}></div>

                  <div className={`${formStyles["form-group"]}`}>
                    <button
                      className={`${formStyles["form-btn"]} browser-default`}
                      id={`${formStyles["submit__main"]}`}
                      style={{
                        background: "transparent",
                        color: "var(--tx-black)",
                      }}
                      type="submit"
                      onClick={() => window.location.replace("/auth/signin")}
                    >
                      Go to login
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
          {isInvalid && (
            <>
              <h4 className={`${authStyles["auth-main-title"]} fx-fade`}>
                Expired/Invalid authentication link.
              </h4>
              <p className={`${authStyles["auth-main-sub"]} fx-fade`}>
                You have entered an invalid/expired link.
              </p>
              <div
                className={`${authStyles["auth-main-content"]} ${formStyles["form-pd"]} fx-fade`}
              >
                <div className={`${formStyles["form-control"]}`}>
                  <div className={`${formStyles["form-group"]}`}></div>

                  <div className={`${formStyles["form-group"]}`}>
                    <button
                      className={`${formStyles["form-btn"]} browser-default`}
                      id={`${formStyles["submit__main"]}`}
                      style={{
                        background: "transparent",
                        color: "var(--tx-black)",
                      }}
                      type="submit"
                      onClick={() => window.location.replace("/auth/recovery")}
                    >
                      Start again
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
          {isPassword && (
            <>
              <h4 className={`${authStyles["auth-main-title"]}`}>
                Set a new password
              </h4>
              <div
                className={`${authStyles["auth-main-content"]} ${formStyles["form-pd"]}`}
              >
                <div className={`${formStyles["form-control"]}`}>
                  <FormValidate
                    btnText={`Reset`}
                    data={passElement}
                    rules={passRules}
                    oobCode={parsed.oobCode}
                    callBack={resetPasswordComplete}
                  >
                    {resetStatus && (
                      <p className={`${formStyles["form-error"]}`}>
                        {typeof resetStatus !== "object" && resetStatus}
                      </p>
                    )}
                    <div className={`${formStyles["form-group"]}`}>
                      <label htmlFor="password">New password</label>
                      <input
                        type="password"
                        id="password"
                        name="password"
                        autoComplete="none"
                        onChange={updatePassElements}
                        className="browser-default"
                      />
                      <DisplayValidationMessages el="password" />
                    </div>
                    <div className={`${formStyles["form-group"]}`}>
                      <label htmlFor="rePassword">Re-type new password</label>
                      <input
                        type="password"
                        id="rePassword"
                        name="rePassword"
                        autoComplete="none"
                        onChange={updatePassElements}
                        className="browser-default"
                      />
                      <DisplayValidationMessages el="rePassword" />
                    </div>
                  </FormValidate>
                </div>
              </div>
            </>
          )}
          {isSuccess && (
            <>
              <h4 className={`${authStyles["auth-main-title"]}`}>
                Password reset successful!
              </h4>
              <p className={`${authStyles["auth-main-sub"]}`}>
                You can login now with your new password.
              </p>
              <div
                className={`${authStyles["auth-main-content"]} ${formStyles["form-pd"]}`}
              >
                <div className={`${formStyles["form-control"]}`}>
                  <div className={`${formStyles["form-group"]}`}></div>

                  <div className={`${formStyles["form-group"]}`}>
                    <button
                      className={`${formStyles["form-btn"]} browser-default`}
                      id={`${formStyles["submit__main"]}`}
                      style={{
                        background: "transparent",
                        color: "var(--tx-black)",
                      }}
                      type="submit"
                      onClick={() => window.location.replace("/auth/signin")}
                    >
                      Go to login
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </main>
    </>
  );
};

export default AuthRecovery;

import React, { useEffect, useState } from "react";

const GetScreenSizeHoc = (Component) => {
  return function HocComponent(props) {
    const breakPoints = { sm: 480, md: 767, lg: 1280 };

    const [windowDimensions, setWindowDimensions] = useState({
      width: window.innerWidth,
      height: window.innerHeight,
    });

    useEffect(() => {
      const handleResizeOnScroll = () => {
        setWindowDimensions({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      };
      window.addEventListener("resize", handleResizeOnScroll);
      return () => window.removeEventListener("resize", handleResizeOnScroll);
    }, [windowDimensions, breakPoints.sm]);

    return (
      <Component size={breakPoints} screen={windowDimensions} {...props} />
    );
  };
};

export default GetScreenSizeHoc;

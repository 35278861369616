import React, { useRef } from "react";
import "../../../css/create.css";
import { VoisLogo } from "../../../assets/icons/Icons";
import { Outlet, useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import GetScreenSizeHoc from "../../../general/components/GetScreenSizeHoc";
import AuthNavigations from "../../../layout/components/AuthNavigations";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";

const CreateWrapper = ({ size, screen }) => {
  const { auth } = useSelector((state) => state.firebase);
  const params = useParams();
  const modalRef = useRef();
  // const navigate = useNavigate();

  const location = useLocation();
  const posts = useSelector(
    ({ firestore: { ordered } }) => ordered.createposts
  );

  // const handleNavigateBack = () => {
  //   navigate(-1, { replace: true });
  // };

  const hero = document.querySelector(".hero-desc");
  return (
    <div
      className="create-wrapper"
      style={{
        height:
          location.pathname === "/create" ||
          location.pathname === "/create/post" ||
          location.pathname === "/create/skill" ||
          location.pathname === "/create/house"
            ? "100vh"
            : "unset",
      }}
    >
      <div className="create-items">
        <header className="create-header container">
          <div className="header-content">
            <a href="/" className="logo-area">
              <VoisLogo width={40} theme={`fl-icon-p`} />
            </a>
            <ul className="header-lists">
              {/* <li className="nav-link">
                <button className="create-btn" onClick={handleNavigateBack}> Cancel</button>
              </li> */}
              {auth.uid && (
                <li className="nav-link">
                  <AuthNavigations
                    screen={screen}
                    modalRef={modalRef}
                    auth={auth}
                    size={size}
                  />
                </li>
              )}
            </ul>
          </div>
        </header>
        <main className="create-main container">
          <Outlet context={{ posts }} />
        </main>
        <footer className="create-footer">
          <div className="footer-inner">
            {(location.pathname === "/create" ||
              location.pathname === "/create/post" ||
              location.pathname === "/create/skill" ||
              location.pathname === "/create/house") && (
              <p>
                All creations are subjected to review as per our
                <a href="/"> Community Guidelines.</a>
              </p>
            )}
            {params.pid && !hero && (
              <p>
                Only you can view this draft. Finish up and submit for review as
                per our
                <a href="/"> Community Guidelines.</a>
              </p>
            )}
          </div>
        </footer>
      </div>
    </div>
  );
};

export default compose(
  GetScreenSizeHoc,
  firestoreConnect(() => {
    return [
      {
        collection: "posts",
        orderBy: ["createdAt", "desc"],
        storeAs: "createposts",
      },
    ];
  })
)(CreateWrapper);

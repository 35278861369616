import React from "react";
import { isEmpty } from "react-redux-firebase";
import { NavLink } from "react-router-dom";

const HomeHeaderFixed = ({ tags }) => {
  return (
    <div className="main-fixed-header">
      <ul className="main-lists">
        {!isEmpty(tags) && (
          <li>
            <NavLink className="main-list" to={"/"} end>
              Latest
            </NavLink>
          </li>
        )}

        {!isEmpty(tags) &&
          tags[0].data.map((tag) => (
            <li className="main-list" key={tag}>
              <NavLink to={`/forum/${tag.toLowerCase()}`} end>
                {tag}
              </NavLink>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default HomeHeaderFixed;

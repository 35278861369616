export const strip = (html) => {
  let doc = new DOMParser().parseFromString(html, "text/html");
  return doc.body.innerHTML || "";
};

export const replaceSpaces = (string) => {
  return string.replace(/ /g, "-");
};

export const replaceSpecial = (string) => {
  return string.replace(/[&\/\\#,+()$~%!.„'":*‚^_¤?<>|@ª{«»§}©®™ ]/g, "-");
};

export const replaceDashes = (string) => {
  return string.replace(/-/g, " ");
};

export const firstLetterToUpper = (string) => {
  return string.slice(0, 1).toUpperCase().concat(string.slice(1));
};

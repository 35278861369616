import React, { useEffect } from "react";
import authStyles from "../../css/auth.module.css";
import { Outlet, useLocation } from "react-router-dom";
import { VoisLogo } from "../../assets/icons/Icons";
import cuate from "../../assets/imgs/cuate.png";
import { useDispatch } from "react-redux";
import { SET__AUTH__ERROR } from "../../stores/auth/AuthSlice";

const AuthWrapper = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const isWelcome = location.pathname === "/auth/step-username" ? true : false;
  const isRecovery = location.pathname === "/auth/recovery" ? true : false;

  useEffect(() => {
    dispatch(SET__AUTH__ERROR(null));
  }, [location.pathname]);
  return (
    <div className={`${authStyles["auth-wrapper"]}`}>
      <div className={`${authStyles["auth-content"]}`}>
        <div className={`${authStyles["auth-sections"]}`}>
          <header
            className={`${authStyles["auth-hero"]} ${authStyles["bg"]}`}
            style={{
              backgroundImage: isWelcome
                ? ` url(${cuate})`
                : isRecovery
                ? "unset"
                : null,
            }}
          >
            <a href="/">
              <VoisLogo width={60} theme={`fl-icon-p`} />
            </a>
          </header>
          <Outlet />
          <footer className={`${authStyles["auth-footer"]}`}>
            <ul className={`${authStyles["auth-footer-navs"]} container`}>
              <li className={`${authStyles["auth-nav"]}`}>
                By continuing, you agree to our <a href="/">Privacy Policy</a>{" "}
                and <a href="/">Terms of Use</a>
              </li>
              {/* <li className={`${authStyles["auth-nav"]}`}>
                <a href="/">Website</a>
              </li>
              <li className={`${authStyles["auth-nav"]}`}>
                <a href="/">Community Guidelines</a>
              </li>
              <li className={`${authStyles["auth-nav"]}`}>
                <a href="/">Terms of Use</a>
              </li>
              <li className={`${authStyles["auth-nav"]}`}>
                <a href="/">Privacy Policy</a>
              </li> */}
            </ul>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default AuthWrapper;

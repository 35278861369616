import React from "react";
import { Outlet } from "react-router-dom";

const MainLayout = () => {
  return (
    <div
      className="main-layout"
      style={{ width: "100%", position: "relative" }}
    >
      <Outlet />
    </div>
  );
};

export default MainLayout;

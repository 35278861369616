import React, { useEffect } from "react";
import "../../css/community-content.css";
import { EventIcon } from "../../assets/icons/Icons";
import { Link, useLocation, useOutletContext } from "react-router-dom";
import { isEmpty, isLoaded } from "react-redux-firebase";
import moment from "moment";
import BannerCardSkeleton from "../../general/components/skeletons/BannerCardSkeleton";
import EventCard from "../../general/components/EventCard";
import queryString from "query-string";

const Events = () => {
  const { events } = useOutletContext();
  const location = useLocation();

  const upcomingEvents = React.useMemo(() => {
    const parsed = queryString.parse(location.search);
    if (isLoaded(events)) {
      return events.filter((data) => {
        const localTime = new Date();
        const dateFrom = new Date(data.display.from.toDate());
        const dateTo = new Date(data.display.to.toDate());
        if (Object.keys(parsed).length === 0) {
          return (
            dateFrom.getTime() <= dateTo.getTime() &&
            dateTo.getTime() >= localTime.getTime()
          );
        } else if (parsed.ord === "older") {
          return (
            dateFrom.getTime() <= dateTo.getTime() &&
            dateTo.getTime() <= localTime.getTime()
          );
        } else if (parsed.ord === "upcoming") {
          return (
            dateFrom.getTime() <= dateTo.getTime() &&
            dateTo.getTime() >= localTime.getTime()
          );
        } else {
          return null;
        }
      });
    }
  }, [events, location.search]);

  useEffect(() => {
    window.scrollTo(0, 0, "smooth");
  }, []);

  return (
    <div className="community-forum">
      <section className="section-container container v-forum-hero">
        <div className="hero-area">
          <div className="hero-content">
            <div className="hero-info">
              <EventIcon height={80} theme={`cl-icon-p`} />
              <h3 className="hero-title">Events</h3>
              <p className="hero-desc">
                Stay connected with activities organized by VOIS Cyprus etc.
              </p>
            </div>
          </div>
        </div>
      </section>
      <div className="container hero-actions">
        <ul className="hero-tag-lists">
          <li className="tag-list">
            <Link
              to={`/events?ord=upcoming`}
              className={
                Object.keys(queryString.parse(location.search)).length === 0 ||
                queryString.parse(location.search).ord === "upcoming"
                  ? "active"
                  : ""
              }
            >
              Upcoming
            </Link>
          </li>
          <li className="tag-list">
            <Link
              to={`/events?ord=older`}
              className={
                Object.keys(queryString.parse(location.search)).length !== 0 &&
                queryString.parse(location.search).ord === "older"
                  ? "active"
                  : ""
              }
            >
              Older
            </Link>
          </li>
        </ul>
      </div>

      <div className="v-forum-lists container">
        <section className="section-container">
          <div className="section-row row">
            {!isLoaded(upcomingEvents) ? (
              [1, 2, 3, 4, 5, 6].map((d) => (
                <div
                  className="section-col section-card col s12 m6 l4 xl3"
                  key={d}
                >
                  <BannerCardSkeleton />
                </div>
              ))
            ) : isEmpty(upcomingEvents) ? (
              <div>No events</div>
            ) : (
              upcomingEvents.map((e) => {
                return (
                  <div
                    className="section-col section-card col s12 m6 l4 xl3"
                    key={e.id}
                  >
                    <EventCard
                      cardId={e.id}
                      cardBanner={e.properties.poster}
                      cardTitle={e.properties.title}
                      cardDate={moment(e.createdAt.toDate()).fromNow()}
                    />
                  </div>
                );
              })
            )}
          </div>
        </section>
      </div>
    </div>
  );
};

export default Events;

import React, { useEffect, useState } from "react";
import "../../../css/blob-content.css";
import SliderContainer from "../../../general/components/SliderContainer";
import { useOutletContext, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, isLoaded } from "react-redux-firebase";
import Loader from "../../../general/components/Loader";
import PartialUserInfo from "../forum/PartialUserInfo";
import moment from "moment";
import SkillActions from "./SkillActions";
import AbortableToast from "../../../general/components/AbortableToast";
import { replaceSpaces, replaceSpecial } from "../../../general/functions";
import validator from "validator";
import {
  SET__CRUD__ERROR,
  createSkillReport,
  createSkillReview,
  deleteSkill,
  deleteSkillReview,
  reportSkillReview,
} from "../../../stores/crud/CrudSlice";
import SkillConnect from "./SkillConnect";
import SkillReviewWrapper from "./SkillReviewWrapper";
import {
  SET__REPORT__MODAL,
  SET__REVIEW__REPORT__MODAL,
} from "../../../stores/modals/ModalSlice";
import MoreFromAuthor from "./MoreFromAuthor";
import MoreFromSkillshop from "./MoreFromSkillshop";
import Error404 from "../../Error404";

const SkillByID = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const { skills, posts } = useOutletContext();
  const [loading, setIsLoading] = useState(false);
  const { auth } = useSelector((state) => state.firebase);
  const { userLikes } = useSelector((state) => state.firebase.profile);
  const [mainView, setMainView] = useState(false);
  const [subView, setSubView] = useState(false);

  // report state
  const [skillData, setSkillData] = useState({
    id: "",
    type: "",
    report: "",
    content: "",
    sid: "",
  });
  // pagination variables
  const maxLimit = 5;
  const [indexDisp, setIndexDisp] = useState(0);
  const [maxDispLimit, setMaxDispLimit] = useState(maxLimit);

  const handleLoadMore = () => {
    setMaxDispLimit(maxDispLimit + maxLimit);
  };

  // update post value
  const updateFormValue = (evt) => {
    setSkillData((state) => ({
      ...state,
      [evt.target.name]: evt.target.value,
    }));
  };

  const filterSkillByID = React.useMemo(() => {
    if (isLoaded(skills)) {
      return skills.filter((data) => {
        return (
          [data.id]
            .join("")
            .toLowerCase()
            .indexOf(
              params.sid
                .split("-")
                [params.sid.split("-").length - 1].toLowerCase()
            ) !== -1
        );
      });
    }
  }, [skills, params.sid]);

  useEffect(() => {
    if (isLoaded(filterSkillByID)) {
      setIsLoading(true);
      window.scrollTo(0, 0, "smooth");
      setTimeout(() => {
        setIsLoading(false);
      }, 300);
    }
  }, [params.sid]);

  const handleDelete = () => {
    setIsLoading(true);
    dispatch(deleteSkill(skillData)).then(() => {
      AbortableToast("Skill successfully deleted.");
      setTimeout(() => {
        setMainView(false);
        window.location.replace("/me/skills");
      }, 1500);
    });
  };

  const handleReviewTotal = (docs) => {
    let total = 0;
    docs.forEach((doc) => {
      total +=
        (Number(doc.rating.communication) +
          Number(doc.rating.quality) +
          Number(doc.rating.recommend)) /
        3;
    });
    if (total === 0) {
      return 0;
    }
    return total / docs.length;
  };

  const handleReviewBreakDown = (type, docs) => {
    let total = 0;

    if (type === "communication") {
      docs.forEach((doc) => {
        total += Number(doc.rating.communication);
      });
    } else if (type === "quality") {
      docs.forEach((doc) => {
        total += Number(doc.rating.quality);
      });
    } else {
      docs.forEach((doc) => {
        total += Number(doc.rating.recommend);
      });
    }
    if (total === 0) {
      return 0.0;
    }
    return (total / docs.length).toFixed(1);
  };

  const handleRatingDescription = (index, docs) => {
    let total = 0;
    let t1 = 0;
    docs.map((doc, i) => {
      t1 =
        (Number(doc.rating.communication) +
          Number(doc.rating.quality) +
          Number(doc.rating.recommend)) /
        3;
      if (Math.ceil(t1) === index) {
        total = total + 1;
      }
      return 0;
    });
    return total;
  };

  const copyToClipboard = () => {
    const link = document.querySelector(".copy__url");
    navigator.clipboard
      .writeText(`https://${window.location.host}${link.value}`)
      .then(() => {
        AbortableToast("Link copied successfully");
      });
  };

  const shareOnFacebook = () => {
    const link = document.querySelector(".copy__url");
    const navUrl =
      "https://www.facebook.com/sharer/sharer.php?u=" +
      `https://${window.location.host}${window.encodeURIComponent(link.value)}`;
    window.open(navUrl, "_blank");
  };

  const shareOnTwitter = () => {
    const link = document.querySelector(".copy__url");
    const navUrl =
      "https://twitter.com/intent/tweet?text=" +
      `https://${window.location.host}${link.value}`;
    window.open(navUrl, "_blank");
  };

  const filterUrl = (url) => {
    if (url.split("https://").length > 1) {
      return url;
    } else {
      return `https://${url}`;
    }
  };

  const reviews = useSelector(({ firestore: { ordered } }) => {
    if (isLoaded(filterSkillByID) && !isEmpty(filterSkillByID))
      return (
        ordered[`${filterSkillByID[0].id}-reviews`] &&
        ordered[`${filterSkillByID[0].id}-reviews`]
      );
  });

  // form state
  const [reviewState, setReviewState] = useState({
    communication: 1,
    quality: 1,
    recommend: 1,
    comment: "",
  });

  const [formView, setFormView] = useState(false);

  const updateReviewValue = (evt) => {
    setReviewState((state) => ({
      ...state,
      [evt.target.name]: evt.target.value,
    }));
  };

  const handleSubmitReview = () => {
    const id = filterSkillByID[0].id;
    const data = { ...reviewState, id };

    dispatch(createSkillReview(data))
      .then((data) => {
        dispatch(SET__CRUD__ERROR(null));
        setReviewState({
          comment: "",
          communication: 1,
          recommend: 1,
          quality: 1,
        });
        setFormView(false);
      })
      .catch((err) => {
        // console.log(err.code);
        dispatch(SET__CRUD__ERROR(err.code));
      });
  };

  // callback action for report
  const handleSkillReport = () => {
    dispatch(createSkillReport(skillData))
      .then((data) => {
        dispatch(SET__CRUD__ERROR("success"));
        dispatch(SET__REPORT__MODAL(false));
        setTimeout(() => {
          AbortableToast("Your report has been submitted.");
        }, 1500);
      })
      .catch((err) => {
        dispatch(SET__REPORT__MODAL(false));
        setTimeout(() => {
          AbortableToast("Your report could not be submitted.");
        }, 1500);
        dispatch(SET__CRUD__ERROR(err.code));
      });
  };

  const handleReviewReport = () => {
    dispatch(reportSkillReview(skillData))
      .then((data) => {
        dispatch(SET__CRUD__ERROR(null));
        dispatch(SET__REVIEW__REPORT__MODAL(false));
        setTimeout(() => {
          AbortableToast("Your report has been submitted.");
        }, 1500);
      })
      .catch((err) => {
        dispatch(SET__REVIEW__REPORT__MODAL(false));
        setTimeout(() => {
          AbortableToast("Your report could not be submitted.");
        }, 1500);
        dispatch(SET__CRUD__ERROR(err.code));
      });
  };

  const handleReviewDelete = () => {
    dispatch(deleteSkillReview(skillData))
      .then((data) => {
        dispatch(SET__CRUD__ERROR(null));
        setSubView(false);
        setTimeout(() => {
          AbortableToast("Your review has been deleted.");
        }, 1500);
      })
      .catch((err) => {
        setSubView(false);
        setTimeout(() => {
          AbortableToast("Your review could not be deleted.");
        }, 1500);
        dispatch(SET__CRUD__ERROR(err.code));
      });
  };

  return (
    <>
      {loading && (
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "calc(100vh - 120px)",
          }}
        >
          <Loader type={`cover`} />
        </div>
      )}
      {!isLoaded(filterSkillByID) ? (
        <div
          style={{
            width: "100%",
            height: "calc(100vh - 120px)",
          }}
        >
          <Loader type={`cover`} />
        </div>
      ) : isEmpty(filterSkillByID) ? (
        <Error404
          posts={posts}
          text={"Restricted or deleted file"}
          code={401}
          unset={params.pid !== undefined}
        />
      ) : isLoaded(filterSkillByID) && filterSkillByID[0].status !== 0 ? (
        <Error404
          posts={posts}
          text={"This skill is currently under review."}
          code={402}
          unset={params.pid !== undefined}
        />
      ) : isLoaded(filterSkillByID) &&
        filterSkillByID[0].interactions.hidden ? (
        <Error404
          posts={posts}
          text={"Skill restriction by author"}
          code={401}
          unset={params.pid !== undefined}
        />
      ) : (
        <div className="blob-wrapper">
          <div className="blob-content container">
            <div className="blob-content-items">
              <section className="blob-item ">
                <div className="blob-info-area">
                  <h2
                    className="blob-title"
                    data-header-for="ratings"
                    style={{ marginBottom: "0" }}
                  >
                    {validator.unescape(filterSkillByID[0].properties.title)}
                    <span>
                      {reviews && Number(handleReviewTotal(reviews).toFixed(1))}
                    </span>
                  </h2>
                  <p
                    className="blob-sub"
                    dangerouslySetInnerHTML={{
                      __html: filterSkillByID[0].properties.description,
                    }}
                  ></p>
                </div>
                <input
                  type="hidden"
                  name="link"
                  value={`/forum/${replaceSpaces(
                    filterSkillByID[0].properties.category
                  ).toLowerCase()}/${replaceSpaces(
                    replaceSpecial(filterSkillByID[0].properties.title)
                  ).toLowerCase()}-${filterSkillByID[0].id
                    .slice(10)
                    .toLowerCase()}`}
                  className="copy__url"
                />
              </section>
              <PartialUserInfo
                type={"skillshop"}
                userId={filterSkillByID[0].author.id}
                username={filterSkillByID[0].author.username}
                userAvatar={filterSkillByID[0].author.photoURL}
                category={filterSkillByID[0].properties.category}
                // duration={filterSkillByID[0].properties.duration}
                createdAt={moment(
                  filterSkillByID[0].createdAt.toDate()
                ).fromNow()}
              />
              <SkillActions
                skillId={filterSkillByID[0].id}
                location={filterSkillByID[0].properties.location[0]}
                auth={auth}
                disableActions={false}
                handleReviewTotal={handleReviewTotal}
                copyToClipboard={copyToClipboard}
                shareOnFacebook={shareOnFacebook}
                shareOnTwitter={shareOnTwitter}
                setSkillData={setSkillData}
                skillData={skillData}
                handleSubmit={handleSkillReport}
                updateFormValue={updateFormValue}
                userLikes={userLikes}
                likeCount={filterSkillByID[0].interactions.likeCount}
                userId={filterSkillByID[0].author.id}
                mainView={mainView}
                setMainView={setMainView}
                handleDelete={handleDelete}
              />
            </div>
          </div>
          {filterSkillByID[0].properties.banners.length > 0 && (
            <div className="blob-hero-slider">
              <div className="blob-break-view">
                <section className="blob-item blob-br">
                  <div className="blob-img-area">
                    <SliderContainer
                      imgArr={filterSkillByID[0].properties.banners}
                    />
                  </div>
                </section>
              </div>
            </div>
          )}

          <div className="blob-content container">
            <div className="blob-content-items">
              <section className="blob-item divide">
                <div className="blob-tag-area">
                  {filterSkillByID[0].properties.isJob ? (
                    <h4>Job description </h4>
                  ) : (
                    <h4>Services </h4>
                  )}

                  <div
                    className="blob-text-area"
                    dangerouslySetInnerHTML={{
                      __html: filterSkillByID[0].properties.serviceHTML,
                    }}
                  ></div>
                </div>
              </section>
              <SkillConnect
                userId={filterSkillByID[0].author.id}
                skillPrice={filterSkillByID[0].properties.price}
                filterUrl={filterUrl}
                isJob={filterSkillByID[0].properties.isJob}
                skillLocation={filterSkillByID[0].properties.location[0]}
              />
              <SkillReviewWrapper
                skillId={filterSkillByID[0].id}
                handleRatingDescription={handleRatingDescription}
                handleReviewBreakDown={handleReviewBreakDown}
                auth={auth}
                reviewState={reviewState}
                setReviewState={setReviewState}
                updateReviewValue={updateReviewValue}
                updateFormValue={updateFormValue}
                handleSubmit={handleSubmitReview}
                handleReviewReport={handleReviewReport}
                subView={subView}
                setSubView={setSubView}
                formView={formView}
                setFormView={setFormView}
                skillData={skillData}
                setSkillData={setSkillData}
                handleReviewDelete={handleReviewDelete}
                maxLimit={maxLimit}
                maxDispLimit={maxDispLimit}
                indexDisp={indexDisp}
                handleLoadMore={handleLoadMore}
              />
            </div>
          </div>
          <div className="blob-extra">
            <div className="blob-extra-content container">
              <div className="blob-extra-items">
                <MoreFromAuthor
                  authorId={filterSkillByID[0].author.id}
                  skills={skills}
                  skillId={filterSkillByID[0].id}
                />
                <MoreFromSkillshop
                  skills={skills}
                  skillId={filterSkillByID[0].id}
                  authorId={filterSkillByID[0].author.id}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SkillByID;

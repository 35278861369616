import React, { useEffect, useState } from "react";
import "../../css/community-content.css";
import { CancelIcon, SearchIcon } from "../../assets/icons/Icons";
import immigration from "../../assets/imgs/immigration.jpg";
import health from "../../assets/imgs/edu.jpg";
import resource from "../../assets/imgs/protest.jpeg";
import housing from "../../assets/imgs/housing.jpg";
import {
  Link,
  useLocation,
  useNavigate,
  useOutletContext,
} from "react-router-dom";
import ForumCardBox from "../../general/components/ForumCardBox";
import { isEmpty, isLoaded } from "react-redux-firebase";
import moment from "moment";
import BannerCardSkeleton from "../../general/components/skeletons/BannerCardSkeleton";
import queryString from "query-string";
import SkillCard from "../../general/components/SkillCard";
import HousingCard from "../../general/components/HousingCard";
import ResourceCard from "../../general/components/ResourceCard";

const Search = () => {
  const { posts, skills, houses, resources } = useOutletContext();
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const updateFormValue = (evt) => {
    setSearch(evt.target.value);
  };

  useEffect(() => {
    const data = queryString.parse(window.location.search);
    const string =
      data.w !== undefined ? queryString.parse(window.location.search).w : "";
    setSearch(string);
    if (data.nav === undefined || data.nav === undefined) {
      navigate(`${window.location.pathname}?nav=forum`);
    }
  }, []);

  const setUrl = () => {
    navigate(
      `${window.location.pathname}${
        window.location.search.split("&")[0]
      }&w=${search}`
    );
    setLoading(false);
  };

  const filteredPosts = React.useMemo(() => {
    if (isLoaded(posts)) {
      return posts.filter((data) => {
        return (
          data.status === 0 &&
          [
            data.author.username,
            data.author.fullName,
            data.properties.title,
            data.properties.subTitle,
            data.properties.contentPlain,
            data.properties.tags.map((t) => t).join(),
          ]
            .join("")
            .toLowerCase()
            .indexOf(search.toLowerCase()) !== -1
        );
      });
    }
  }, [search, posts]);

  const filteredHouses = React.useMemo(() => {
    if (isLoaded(houses)) {
      return houses.filter((data) => {
        return (
          [data.building.city, data.building.area, data.building.name]
            .join("")
            .toLowerCase()
            .indexOf(search.toLowerCase()) !== -1
        );
      });
    }
  }, [search, houses]);

  const filteredSkills = React.useMemo(() => {
    if (isLoaded(skills)) {
      return skills.filter((data) => {
        return (
          data.status === 0 &&
          [
            data.author.username,
            data.author.fullName,
            data.properties.title,
            data.properties.description,
            data.properties.category,
            data.properties.location.map((t) => t).join(""),
          ]
            .join("")
            .toLowerCase()
            .indexOf(search.toLowerCase()) !== -1
        );
      });
    }
  }, [search, skills]);

  const filteredResources = React.useMemo(() => {
    if (isLoaded(resources)) {
      return resources.filter((data) => {
        return (
          data.status === 0 &&
          [
            data.author.username,
            data.author.fullName,
            data.properties.title,
            data.properties.contentPlain,
            data.properties.category,
          ]
            .join("")
            .toLowerCase()
            .indexOf(search.toLowerCase()) !== -1
        );
      });
    }
  }, [search, resources]);

  const tags = [
    {
      expImg: immigration,
      expTitle: "Forum",
      expLink: "/forum",
      expTotal: filteredPosts && filteredPosts.length,
      expText: "Immigration stories and opinions.",
    },
    {
      expImg: health,
      expTitle: "Skillshop",
      expLink: "/skillshop",
      expTotal: filteredSkills && filteredSkills.length,
      expText: "Skills offered.",
    },
    {
      expImg: housing,
      expTitle: "Housing",
      expTotal: filteredHouses && filteredHouses.length,
      expLink: "/housing",
      expText: "Housing reviews.",
    },
    {
      expImg: resource,
      expTitle: "Resources",
      expLink: "/resources",
      expTotal: filteredResources && filteredResources.length,
      expText: "Resources for students.",
    },
  ];

  return (
    <div className="community-forum">
      <section className="section-container container v-forum-hero">
        <div className="hero-area" style={{ height: "250px" }}>
          <div className="hero-content">
            <div className="hero-info">
              <div className="search-form">
                <div className="form-container">
                  <label htmlFor="search">
                    <SearchIcon width={30} theme={`cl-icon-light`} />
                  </label>
                  <input
                    type="text"
                    placeholder="Type anything here"
                    id="search"
                    name="search"
                    autoComplete="off"
                    value={search}
                    onKeyUp={setUrl}
                    onKeyDown={() => setLoading(true)}
                    onChange={updateFormValue}
                    className="browser-default"
                  />
                  {search.length !== 0 && (
                    <button
                      className="browser-default"
                      style={{ border: "none", background: "transparent" }}
                      onClick={() => {
                        setSearch("");
                        navigate(
                          `${window.location.pathname}${
                            window.location.search.split("&")[0]
                          }&w=`
                        );
                      }}
                    >
                      <CancelIcon width={15} theme={`cl-icon-p`} />
                    </button>
                  )}
                </div>
              </div>
              <h3 className="hero-title">Search</h3>
            </div>
          </div>
        </div>
      </section>
      {search.length !== 0 && (
        <div className="container hero-actions fx-fade">
          <ul className="hero-tag-lists">
            <li className="tag-list">
              <Link
                to={`/search?nav=forum&w=${
                  queryString.parse(location.search).w
                }`}
                className={
                  queryString.parse(location.search).nav === "forum" && `active`
                }
              >
                Forum
                <span
                  style={{ fontSize: ".8rem", color: "var(--tx-main-light)" }}
                >
                  ({filteredPosts && filteredPosts.length})
                </span>
              </Link>
            </li>
            <li className="tag-list">
              <Link
                to={`/search?nav=skillshop&w=${
                  queryString.parse(location.search).w
                }`}
                className={
                  queryString.parse(location.search).nav === "skillshop" &&
                  `active`
                }
              >
                Skillshop
                <span
                  style={{ fontSize: ".8rem", color: "var(--tx-main-light)" }}
                >
                  ({filteredSkills && filteredSkills.length})
                </span>
              </Link>
            </li>
            <li className="tag-list">
              <Link
                to={`/search?nav=housing&w=${
                  queryString.parse(location.search).w
                }`}
                className={
                  queryString.parse(location.search).nav === "housing" &&
                  `active`
                }
              >
                Housing
                <span
                  style={{ fontSize: ".8rem", color: "var(--tx-main-light)" }}
                >
                  ({filteredHouses && filteredHouses.length})
                </span>
              </Link>
            </li>
            <li className="tag-list">
              <Link
                to={`/search?nav=resources&w=${
                  queryString.parse(location.search).w
                }`}
                className={
                  queryString.parse(location.search).nav === "resources" &&
                  `active`
                }
              >
                Resources
                <span
                  style={{ fontSize: ".8rem", color: "var(--tx-main-light)" }}
                >
                  ({filteredResources && filteredResources.length})
                </span>
              </Link>
            </li>
          </ul>
        </div>
      )}

      {search.length !== 0 ? (
        <>
          {queryString.parse(location.search).nav === "forum" && (
            <div className="v-forum-lists container">
              <section className="section-container">
                <div className="section-row row">
                  {loading ? (
                    [1, 2, 3, 4, 5, 6].map((d) => (
                      <div
                        className="section-col section-card col s12 m6 l4 xl3"
                        key={d}
                      >
                        <BannerCardSkeleton />
                      </div>
                    ))
                  ) : !isLoaded(filteredPosts) ? (
                    [1, 2, 3, 4, 5, 6].map((d) => (
                      <div
                        className="section-col section-card col s12 m6 l4 xl3"
                        key={d}
                      >
                        <BannerCardSkeleton />
                      </div>
                    ))
                  ) : isEmpty(filteredPosts) ? (
                    <i>
                      No results on <b>{search}</b> in forums
                    </i>
                  ) : (
                    filteredPosts
                      .filter((p) => p.status === 0)
                      .map((s) => {
                        return (
                          <div
                            className="section-col section-card col s12 m6 l4 xl3"
                            key={s.id}
                          >
                            <ForumCardBox
                              cardId={s.id}
                              cardBanner={s.properties.banner}
                              cardTag={s.properties.tags[0]}
                              cardTitle={s.properties.title}
                              cardAvatar={s.author.photoURL}
                              cardUserName={s.author.username}
                              cardDate={moment(s.createdAt.toDate()).fromNow()}
                            />
                          </div>
                        );
                      })
                  )}
                </div>
              </section>
            </div>
          )}
          {queryString.parse(location.search).nav === "skillshop" && (
            <div className="v-forum-lists container">
              <section className="section-container">
                <div className="section-row row">
                  {loading ? (
                    [1, 2, 3, 4, 5, 6].map((d) => (
                      <div
                        className="section-col section-card col s12 m6 l4 xl3"
                        key={d}
                      >
                        <BannerCardSkeleton />
                      </div>
                    ))
                  ) : !isLoaded(filteredSkills) ? (
                    [1, 2, 3, 4, 5, 6].map((d) => (
                      <div
                        className="section-col section-card col s12 m6 l4 xl3"
                        key={d}
                      >
                        <BannerCardSkeleton />
                      </div>
                    ))
                  ) : isEmpty(filteredSkills) ? (
                    <i>
                      No results on <b>{search}</b> in skillshop
                    </i>
                  ) : (
                    filteredSkills.map((skill) => (
                      <div
                        className="section-col section-card col s12 m6 l4 xl3"
                        key={skill.id}
                      >
                        <SkillCard
                          skillId={skill.id}
                          skillBanner={skill.properties.banners[0]}
                          skillTitle={skill.properties.title}
                          skillTag={skill.properties.category}
                          skillAvatar={skill.author.photoURL}
                          skillAuthor={skill.author.username}
                          skillRateScore={skill.interactions.reviewCount}
                          skillReviewCount={skill.interactions.reviewCount}
                          skillPrice={skill.properties.price}
                        />
                      </div>
                    ))
                  )}
                </div>
              </section>
            </div>
          )}
          {queryString.parse(location.search).nav === "housing" && (
            <div className="v-forum-lists container">
              <section className="section-container">
                <div className="section-row row">
                  {loading ? (
                    [1, 2, 3, 4, 5, 6].map((d) => (
                      <div
                        className="section-col section-card col s12 m6 l4 xl3"
                        key={d}
                      >
                        <BannerCardSkeleton />
                      </div>
                    ))
                  ) : !isLoaded(filteredHouses) ? (
                    [1, 2, 3, 4, 5, 6].map((d) => (
                      <div
                        className="section-col section-card col s12 m6 l4 xl3"
                        key={d}
                      >
                        <BannerCardSkeleton />
                      </div>
                    ))
                  ) : isEmpty(filteredHouses) ? (
                    <i>
                      No results on <b>{search}</b> in housing
                    </i>
                  ) : (
                    filteredHouses.map((house) => (
                      <div
                        className="section-col section-card col s12 m6 l4 xl3"
                        key={house.id}
                      >
                        <HousingCard
                          cardId={house.id}
                          cardBanner={house.building.photos[0]}
                          cardHouseName={house.building.name}
                          cardRateScore={4.0}
                          cardReviews={house.interactions.reviewCount}
                          cardHouseCity={house.building.city}
                          cardHouseRegion={house.building.area}
                        />
                      </div>
                    ))
                  )}
                </div>
              </section>
            </div>
          )}
          {queryString.parse(location.search).nav === "resources" && (
            <div className="v-forum-lists container">
              <section className="section-container">
                <div className="section-row row">
                  {loading ? (
                    [1, 2, 3, 4, 5, 6].map((d) => (
                      <div
                        className="section-col section-card col s12 m6 l4 xl3"
                        key={d}
                      >
                        <BannerCardSkeleton />
                      </div>
                    ))
                  ) : !isLoaded(filteredResources) ? (
                    [1, 2, 3, 4, 5, 6].map((d) => (
                      <div
                        className="section-col section-card col s12 m6 l4 xl3"
                        key={d}
                      >
                        <BannerCardSkeleton />
                      </div>
                    ))
                  ) : isEmpty(filteredResources) ? (
                    <i>
                      No results on <b>{search}</b> in resources
                    </i>
                  ) : (
                    filteredResources.map((s) => {
                      return (
                        <div
                          className="section-col section-card col s12 m6 l4 xl3"
                          key={s.id}
                        >
                          <ResourceCard
                            cardId={s.id}
                            cardBanner={s.properties.poster}
                            cardTag={s.properties.category}
                            cardTitle={s.properties.title}
                            cardAvatar={s.author.photoURL}
                            cardUserName={s.author.username}
                            cardDate={moment(s.createdAt.toDate()).fromNow()}
                          />
                        </div>
                      );
                    })
                  )}
                </div>
              </section>
            </div>
          )}
        </>
      ) : (
        <div className="v-forum-lists container">
          <section className="section-container">
            <h1 className="section-title" style={{ fontSize: "1.5rem", fontWeight: "600" }}>
              Browse by category
            </h1>
            <div className="section-row row">
              {tags.map((tag, index) => (
                <div key={index} className="section-col col s12 m6 l4 xl3">
                  <Link
                    to={tag.expLink}
                    style={{ padding: window.screen.width > 500 && "10px" , display: "block"}}
                  >
                    <div className="tag__wrapper">
                      <div className="tag__container">
                        <div className="tag__banner__title">
                          <img
                            loading="lazy"
                            src={tag.expImg}
                            alt={tag.expTitle}
                          />
                          <div className="over__lay"></div>
                          <div className="over__text">{tag.expTitle} </div>
                        </div>
                        <div className="tag__info__actions">
                          <p className="info">{tag.expText}</p>
                          <p className="post_count">
                            <span>{tag.expTotal}</span>
                            {tag.expTitle === "Forum"
                              ? " stories "
                              : tag.expTitle === "Skillshop"
                              ? " skills "
                              : tag.expTitle === "Housing"
                              ? " reviews "
                              : " resources "}
                            and counting
                          </p>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </section>
        </div>
      )}
    </div>
  );
};

export default Search;

import React from "react";
import { BrokenLinkIcon } from "../assets/icons/Icons";
import ForumCardBox from "../general/components/ForumCardBox";
import moment from "moment";
import BannerCardSkeleton from "../general/components/skeletons/BannerCardSkeleton";
import { isEmpty, isLoaded } from "react-redux-firebase";

const Error404 = ({ posts, code, text, unset }) => {
  return (
    <div className="community-forum">
      <section
        className={`section-container ${unset ? "" : "container"} v-forum-hero`}
      >
        <div className="hero-area" style={{ height: "350px" }}>
          <div className="hero-content">
            <div className="hero-info">
              <BrokenLinkIcon height={60} theme={`cl-icon-p`} />
              <h3 className="hero-title"> {code === undefined ? 404 : code}</h3>
              <p className="hero-desc">
                {text === undefined
                  ? "Page not found or is currently not available"
                  : text}
              </p>
            </div>
          </div>
        </div>
      </section>
      <div className={`v-forum-lists ${unset ? "" : "container"}`}>
        <section className="section-container">
          <h1 className="section-title md" style={{ marginLeft: "10px" }}>
            Keep exploring
          </h1>
          <div className="section-row row">
            {!isLoaded(posts) ? (
              [1, 2, 3, 4, 5, 6].map((d) => (
                <div
                  className="section-col section-card col s12 m6 l4 xl3"
                  key={d}
                >
                  <BannerCardSkeleton />
                </div>
              ))
            ) : isEmpty(posts) ? (
              <div>Nothing to see here!!</div>
            ) : (
              posts
                .filter((p) => p.status === 0)
                .slice(0, 6)
                .map((s) => {
                  return (
                    <div
                      className="section-col section-card col s12 m6 l4 xl3"
                      key={s.id}
                    >
                      <ForumCardBox
                        cardId={s.id}
                        cardBanner={s.properties.banner}
                        cardTag={s.properties.tags[0]}
                        cardTitle={s.properties.title}
                        cardAvatar={s.author.photoURL}
                        cardUserName={s.author.username}
                        cardDate={moment(s.createdAt.toDate()).fromNow()}
                      />
                    </div>
                  );
                })
            )}
          </div>
        </section>
      </div>
    </div>
  );
};

export default Error404;

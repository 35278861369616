import React from "react";
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import HomeLayout from "./layout/HomeLayout";
import Home from "./pages/home/Home";
import MainLayout from "./layout/MainLayout";
import ContainerLayout from "./layout/ContainerLayout";
import Forum from "./pages/communities/Forum";
import Skillshop from "./pages/communities/Skillshop";
import Housing from "./pages/communities/Housing";
import Resources from "./pages/resources/Resources";
import ForumByID from "./pages/categories/forum/ForumByID";
import SkillByID from "./pages/categories/skillshop/SkillById";
import HouseByID from "./pages/categories/housing/HouseByID";
import ContainerChildren from "./layout/ContainerChildren";
import Profile from "./pages/profiles/Profile";
import SettingsLayout from "./layout/SettingsLayout";
import AuthWrapper from "./auth/layout/AuthWrapper";
import AuthInit from "./auth/AuthInit";
import AuthLogin from "./auth/AuthLogin";
import AuthSignUp from "./auth/AuthSignUp";
import AuthUserNameSelect from "./auth/AuthUserNameSelect";
import AuthRecovery from "./auth/AuthRecovery";
import CreateWrapper from "./pages/create/layout/CreateWrapper";
import CreatePost from "./pages/create/CreatePost";
import CreateSkill from "./pages/create/CreateSkill";
import CreateComplaint from "./pages/create/CreateComplaint";
import CreateHouseRating from "./pages/create/CreateHouseRating";
import AuthProtected from "./auth/components/AuthProtected";
import AuthSignOut from "./auth/AuthSignOut";
import Error404 from "./pages/Error404";
import EditPost from "./pages/create/EditPost";
import AccountProfile from "./pages/profiles/AccountProfile";
import EditProfile from "./pages/profiles/EditProfile";
import Settings from "./pages/settings/Settings";
import { useSelector } from "react-redux";
import ResourceById from "./pages/categories/resources/ResourceById";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfUse from "./pages/TermsOfUse";
import CommunityGuidelines from "./pages/CommunityGuidelines";
import Search from "./pages/search/Search";
import Events from "./pages/events/Events";
import EventsId from "./pages/events/EventsId";
import ErrorLayout from "./layout/ErrorLayout";
import AuthSecured from "./auth/components/AuthSecured";
import { ErrorBound } from "./general/components/ErrorBound";

const App = () => {
  const { auth } = useSelector((state) => state.firebase);
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<MainLayout />}>
        {/* home */}
        <Route
          index
          element={
            <HomeLayout auth={auth}>
              <Home />
            </HomeLayout>
          }
        ></Route>
        <Route
          path=":id"
          element={
            <ContainerChildren>
              <Profile />
            </ContainerChildren>
          }
        ></Route>
        <Route
          path=":id/posts"
          element={
            <ContainerChildren>
              <Profile />
            </ContainerChildren>
          }
        ></Route>
        <Route
          path=":id/skills"
          element={
            <ContainerChildren>
              <Profile />
            </ContainerChildren>
          }
        ></Route>
        {/* init auth */}
        <Route
          path="auth"
          element={
            <ErrorBound>
              <AuthProtected>
                <AuthWrapper />
              </AuthProtected>
            </ErrorBound>
          }
        >
          <Route index element={<AuthInit />} />
          <Route path="signin" element={<AuthLogin />} />
          <Route path="signup" element={<AuthSignUp />} />
          <Route path="signout" element={<AuthSignOut />} />
          <Route path="recovery" element={<AuthRecovery />} />
          <Route
            path="step-username"
            element={
              <AuthSecured>
                <AuthUserNameSelect />
              </AuthSecured>
            }
          />
        </Route>
        {/* init auth */}
        <Route path="create" element={<CreateWrapper />}>
          <Route index element={<CreatePost />} />
          <Route path="post" element={<CreatePost />} />
          <Route path="skill" element={<CreateSkill />} />
          <Route path="house" element={<CreateHouseRating />} />
        </Route>
        <Route path="submit-a-complaint" element={<CreateWrapper />}>
          <Route index element={<CreateComplaint />} />
        </Route>
        {/* me*/}
        <Route
          path="me"
          element={
            <AuthSecured>
              <CreateWrapper />
            </AuthSecured>
          }
        >
          <Route index element={<AccountProfile />} />
          <Route path="posts" element={<AccountProfile />} />
          <Route path="saves" element={<AccountProfile />} />
          <Route path="posts/published" element={<AccountProfile />} />
          <Route path="posts/pending" element={<AccountProfile />} />
          <Route path="posts/rejected" element={<AccountProfile />} />
          <Route path="skills" element={<AccountProfile />} />
          <Route path="skills/published" element={<AccountProfile />} />
          <Route path="skills/pending" element={<AccountProfile />} />
          <Route path="skills/rejected" element={<AccountProfile />} />
          <Route path="collections" element={<AccountProfile />} />
          <Route path="collections/posts" element={<AccountProfile />} />
          <Route path="collections/resources" element={<AccountProfile />} />
          <Route path="edit" element={<EditProfile />} />
          <Route path=":pid" element={<ForumByID nmg={true} />} />
          <Route path=":pid/edit" element={<EditPost />} />
        </Route>
        {/* communites/forums */}
        <Route path="forum" element={<ContainerLayout />}>
          <Route index element={<Forum />}></Route>
          <Route path=":tag" element={<Forum />}></Route>
          <Route path=":tag/:fid" element={<ForumByID />}></Route>
        </Route>
        {/* communities/skills */}
        <Route path="skillshop" element={<ContainerLayout />}>
          <Route index element={<Skillshop />} />
          <Route path=":tag" element={<Skillshop />} />
          <Route path=":tag/:sid" element={<SkillByID />} />
        </Route>
        {/* housing */}
        <Route path="housing" element={<ContainerLayout />}>
          <Route index element={<Housing />}></Route>
          <Route path=":tag" element={<Housing />} />
          <Route path=":tag/:hid" element={<HouseByID />} />
        </Route>
        {/* Resources*/}
        <Route path="resources" element={<ContainerLayout />}>
          <Route index element={<Resources />}></Route>
          <Route path=":tag" element={<Resources />}></Route>
          <Route path=":tag/:rid" element={<ResourceById />}></Route>
        </Route>
        {/* account*/}
        <Route
          path="account"
          element={
            <AuthSecured>
              <SettingsLayout />
            </AuthSecured>
          }
        >
          <Route index element={<Settings />}></Route>
          <Route path="settings" element={<Settings />}></Route>
        </Route>
        {/* communites/forums */}
        <Route path="search" element={<ContainerLayout />}>
          <Route index element={<Search />}></Route>
        </Route>
        <Route path="events" element={<ContainerLayout />}>
          <Route index element={<Events />}></Route>
          <Route path=":id" element={<EventsId />}></Route>
        </Route>
        <Route path="privacy-policy" element={<ContainerLayout />}>
          <Route index element={<PrivacyPolicy />}></Route>
        </Route>
        <Route path="terms-of-use" element={<ContainerLayout />}>
          <Route index element={<TermsOfUse />}></Route>
        </Route>
        <Route path="community-guidelines" element={<ContainerLayout />}>
          <Route index element={<CommunityGuidelines />}></Route>
        </Route>
        {/* <Route path="*" element={<ContainerLayout />}>
          <Route index element={<Error404 />} />
        </Route> */}
        <Route
          path="*"
          element={
            <ErrorLayout>
              <Error404 />
            </ErrorLayout>
          }
        />
      </Route>
    )
  );
  return (
    <>
      <RouterProvider router={router}></RouterProvider>
    </>
  );
};

export default App;

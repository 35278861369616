import React, { useEffect, useState } from "react";
import "../../css/community-content.css";
import { HouseIcon, LocationIcon } from "../../assets/icons/Icons";
import {
  NavLink,
  useLocation,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { houseNavs } from "../../assets/dummies/Static";
import HousingCard from "../../general/components/HousingCard";
import AuthCreateButton from "../../general/protected/AuthCreateButton";
import { isEmpty, isLoaded } from "react-redux-firebase";
import BannerCardSkeleton from "../../general/components/skeletons/BannerCardSkeleton";
import Error404 from "../Error404";

const Housing = () => {
  const params = useParams();
  const { houses, posts } = useOutletContext();
  const location = useLocation();
  const [view, setView] = useState(false);

  const filteredHouses = React.useMemo(() => {
    if (isLoaded(houses) && params.tag) {
      return houses.filter((data) => {
        return (
          [data.building.city]
            .join("")
            .toLowerCase()
            .indexOf(params.tag.toLowerCase()) !== -1
        );
      });
    }
  }, [params.tag, houses]);

  useEffect(() => {
    if (location.pathname === "/housing/") {
      window.location.href = "/housing";
    }
    if (location.pathname === "/housing/famagusta-gazimağusa/") {
      window.location.href = "/housing/famagusta-gazimağusa";
    }
    if (location.pathname === "/housing/kyrenia-girne/") {
      window.location.href = "/housing/kyrenia-girne";
    }
    if (location.pathname === "/housing/nicosia-lefkoşa/") {
      window.location.href = "/housing/nicosia-lefkoşa";
    }
    if (location.pathname === "/housing/morphou-güzelyurt/") {
      window.location.href = "/housing/morphou-güzelyurt";
    }
    if (location.pathname === "/housing/trikomo-iskele/") {
      window.location.href = "/housing/trikomo-iskele";
    }
    if (location.pathname === "/housing/lefka-lefke/") {
      window.location.href = "/housing/lefka-lefke";
    }
  }, [location.pathname]);

  useEffect(() => {
    if (params.tag !== undefined) {
      const data = houseNavs.filter((d) => {
        console.log(
          "Famagusta/Gazimagusa".toLowerCase() === "famagusta-gazimagusa"
        );
        return d.text.toLowerCase() === params.tag.toLowerCase();
      });
      if (data.length === 0) {
        setView(true);
      }
    }
  }, [params]);

  if (view) {
    return (
      <Error404
        posts={posts}
        text={"Missing or broken link."}
        code={404}
        unset={params.pid !== undefined}
      />
    );
  }
  return (
    <div className="community-forum">
      <section className="section-container container v-forum-hero">
        <div className="hero-area">
          {params.tag === undefined && (
            <div className="hero-content">
              <div className="hero-info">
                <HouseIcon height={80} theme={`cl-icon-p`} />
                <h3 className="hero-title">Housing</h3>
                <p className="hero-desc">
                  Explore and discover house ratings based on the individual
                  experiences of international students in the northern part of
                  Cyprus.
                </p>
              </div>
            </div>
          )}
          {params.tag && (
            <div className="hero-content">
              <div className="hero-info">
                <LocationIcon height={40} theme={`cl-icon-p`} />
                <h3 className="hero-title">
                  {params.tag.slice(0, 1).toUpperCase()}
                  {params.tag.slice(1)}
                </h3>
                <p className="hero-desc">
                  {params.tag.slice(0, 1).toUpperCase()}
                  {params.tag.slice(1)} house ratings based on the individual
                  experiences.
                </p>
                {filteredHouses && filteredHouses.length > 0 ? (
                  <p
                    className="hero-desc"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      fontSize: "1.3rem",
                    }}
                  >
                    {filteredHouses.length}
                    <span className="cl-icon-light">
                      review(s) and counting
                    </span>
                  </p>
                ) : (
                  <p
                    className="hero-desc"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      fontSize: "1.3rem",
                    }}
                  >
                    <span className="cl-icon-light">No reviews yet</span>
                  </p>
                )}
              </div>
            </div>
          )}
        </div>
      </section>
      <div className="container hero-actions">
        <ul className="hero-tag-lists">
          {houseNavs.map((nav) => (
            <li className="tag-list" key={nav.id}>
              <NavLink to={nav.path} end>
                {nav.text}
              </NavLink>
            </li>
          ))}
        </ul>
        <div className="hero-auth-area">
          <AuthCreateButton text={`Create`} link="/create/house" />
        </div>
      </div>
      {!params.tag ? (
        <section className="section-container v-skill-lists container">
          <div className="section-row row">
            {!isLoaded(houses) ? (
              [1, 2, 3, 4, 5, 6].map((d) => (
                <div
                  className="section-col section-card col s12 m6 l4 xl3"
                  key={d}
                >
                  <BannerCardSkeleton />
                </div>
              ))
            ) : isEmpty(houses) ? (
              <div>No reviews yet</div>
            ) : (
              houses.map((house) => (
                <div
                  className="section-col section-card col s12 m6 l4 xl3"
                  key={house.id}
                >
                  <HousingCard
                    cardId={house.id}
                    cardBanner={house.building.photos[0]}
                    cardHouseName={house.building.name}
                    cardRateScore={4.0}
                    cardReviews={house.interactions.reviewCount}
                    cardHouseCity={house.building.city}
                    cardHouseRegion={house.building.area}
                  />
                </div>
              ))
            )}
          </div>
        </section>
      ) : (
        <section className="section-container v-skill-lists container">
          <div className="section-row row">
            {!isLoaded(filteredHouses) ? (
              [1, 2, 3, 4, 5, 6].map((d) => (
                <div
                  className="section-col section-card col s12 m6 l4 xl3"
                  key={d}
                >
                  <BannerCardSkeleton />
                </div>
              ))
            ) : isEmpty(filteredHouses) ? (
              <div>No reviews yet</div>
            ) : (
              filteredHouses.map((house) => (
                <div
                  className="section-col section-card col s12 m6 l4 xl3"
                  key={house.id}
                >
                  <HousingCard
                    cardId={house.id}
                    cardBanner={house.building.photos[0]}
                    cardHouseName={house.building.name}
                    cardRateScore={4.0}
                    cardReviews={house.interactions.reviewCount}
                    cardHouseCity={house.building.city}
                    cardHouseRegion={house.building.area}
                  />
                </div>
              ))
            )}
          </div>
        </section>
      )}
    </div>
  );
};

export default Housing;

import React, { useEffect } from "react";
import Quill from "quill/core";
import Block from "quill/blots/block";
import Inline from "quill/blots/inline";
import List, { ListItem } from "quill/formats/list";
import PropTypes from "prop-types";

class BoldBlot extends Inline {}

BoldBlot.blotName = "bold";
BoldBlot.tagName = "strong";

class ItalicBlot extends Inline {}

ItalicBlot.blotName = "italic";
ItalicBlot.tagName = "em";

class BlockBlot extends Block {}

BlockBlot.blotName = "blockquote";
BlockBlot.tagName = "blockquote";

class HeaderBlot extends Block {}

HeaderBlot.blotName = "header";
HeaderBlot.tagName = ["h1", "h2"];

Quill.register(BoldBlot);
Quill.register(ItalicBlot);
Quill.register(BlockBlot);
Quill.register(HeaderBlot);
Quill.register({
  "formats/list": List,
  "formats/list-item": ListItem,
});
Quill.debug(false);

const QuillEditorContainer = ({
  quillInstanceRef,
  quillEditorRef,
  setEditorState,
  getWordCount,
  getWordLength,
  postFromId
}) => {
  // quill initialize
  useEffect(() => {
    quillInstanceRef.current = new Quill(quillEditorRef.current, {
      placeholder: "Start writing here...",
      //   scrollingContainer: ".modal-content",
    });

    // quillInstanceRef.current.addContainer(quillToolbarRef.current);
    // console.log(quillInstanceRef.current);
  }, [quillInstanceRef, postFromId]);

  // ontextchange
  useEffect(() => {
    const quill = quillInstanceRef.current;
    const onTextChange = (type) => {
      const ulGrafs = document.querySelectorAll(`#editor-container ul`);
      setEditorState((state) => ({
        ...state,
        wordCount: getWordCount(),
        wordLength: getWordLength(),
        content: quillInstanceRef.current.container.textContent,
        contentinHTML: quillInstanceRef.current.root.innerHTML,
      }));

      ulGrafs.forEach((ul) => {
        if (ul.classList.length === 0) {
          ul.classList.add("browser-default");
          ul.classList.add("editor-ul");
        }
      });
    };
    quill.on("text-change", onTextChange);
    return () => quill.off("text-change", onTextChange);
  }, [quillInstanceRef, postFromId]);

  return (
    <div className="content-editor">
      <div
        className="editor-container"
        ref={quillEditorRef}
        id="editor-container"
      ></div>
    </div>
  );
};

QuillEditorContainer.propType = {
  quillInstanceRef: PropTypes.object,
  quillEditorRef: PropTypes.object,
  setEditorState: PropTypes.func,
  getWordCount: PropTypes.func,
  getWordLength: PropTypes.func,
};

export default QuillEditorContainer;

import React from 'react'
import SkeletonAnimate from './SkeletonAnimate'
import SkeletonElement from './SkeletonElement'

const BannerCardSkeleton = () => {
    return (
        <div className="skeleton__wrapper" style={{ marginBottom: "10px"}}>
            <div className="skeleton__forum">
                <div className="skeleton__fh" style={{ margin: 0}}>
                    <SkeletonElement
                        type='banner lg'
                    />
                </div>
                <div className="skeleton__bd">
                    <div>
                        <SkeletonElement
                            type='title'
                        />
                        <SkeletonElement
                            type='text'
                        />
                        <div style={{ margin: "0"}}>
                            <SkeletonElement
                                type='profile'
                            />
                            <SkeletonElement
                                type='username'
                            />
                        </div>
                    </div>
                </div>
            </div>
            <SkeletonAnimate />
        </div>
    )
}

export default BannerCardSkeleton
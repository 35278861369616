import React from "react";
import PropTypes from "prop-types";
import { PLACE__HOLDER } from "../../config/globals";
import { NavLink } from "react-router-dom";
import { replaceSpaces, replaceSpecial } from "../functions";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import { useSelector } from "react-redux";
import validator from "validator";

const SkillCard = ({
  skillId,
  skillBanner,
  skillTitle,
  skillTag,
  skillAvatar,
  skillAuthor,
  skillRateScore,
  skillReviewCount,
  skillPrice,
}) => {
  const docs = useSelector(
    ({ firestore: { ordered } }) =>
      ordered[`${skillId}-subCols`] && ordered[`${skillId}-subCols`]
  );

  const handleReviewTotal = () => {
    let total = 0;
    docs.forEach((doc) => {
      total +=
        (Number(doc.rating.communication) +
          Number(doc.rating.quality) +
          Number(doc.rating.recommend)) /
        3;
    });
    if (total === 0) {
      return 0;
    }
    return (total / docs.length).toFixed(1);
  };

  return (
    <div className="skill-card">
      <div className="skill-banner-area">
        <NavLink
          to={`/skillshop/${replaceSpaces(
            skillTag
          ).toLowerCase()}/${replaceSpaces(
            replaceSpecial(skillTitle)
          ).toLowerCase()}-${skillId.slice(10).toLowerCase()}`}
        >
          <img
            src={skillBanner ? skillBanner : PLACE__HOLDER}
            alt={skillTitle}
            draggable="false"
            loading="lazy"
          />
        </NavLink>
      </div>
      <div className="skill-info-area">
        <h3 className="skill-title">
          <NavLink
            to={`/skillshop/${replaceSpaces(
              skillTag
            ).toLowerCase()}/${replaceSpaces(
              replaceSpecial(skillTitle)
            ).toLowerCase()}-${skillId.slice(10).toLowerCase()}`}
          >
            {validator.unescape(skillTitle)}
          </NavLink>
        </h3>
        <p className="skill-tag">
          <NavLink to={`/skillshop/${replaceSpaces(skillTag).toLowerCase()}`}>
            {skillTag}
          </NavLink>
        </p>
        <div className="skill-author-area">
          <NavLink to={`/@${skillAuthor}`}>
            <img src={skillAvatar} alt={skillAuthor} className="skill-avatar" />
          </NavLink>
          <p className="author-name">
            <span className="name-area">
              by
              <span className="bd-area">
                <NavLink to={`/@${skillAuthor}`}>{skillAuthor}</NavLink>
              </span>
            </span>
            <span className="author-reviews">
              <span className="rate-stars">
                <i className="fa fa-star" aria-hidden="true"></i>
                {docs && handleReviewTotal()}
              </span>
              <span className="rate-counts">({skillReviewCount})</span>
            </span>
          </p>
          <p className="skill-price">
            <span>TRY.</span>
            {skillPrice}
          </p>
        </div>
      </div>
    </div>
  );
};

SkillCard.propTypes = {
  skillId: PropTypes.string,
  skillBanner: PropTypes.string,
  skillTitle: PropTypes.string,
  skillAvatar: PropTypes.string,
  skillTag: PropTypes.string,
  skillAuthor: PropTypes.string,
  skillRateScore: PropTypes.number,
  skillReviewCount: PropTypes.number,
  skillPrice: PropTypes.string,
};

export default compose(
  firestoreConnect((props) => {
    return [
      {
        collection: "skills",
        doc: props.skillId,
        subcollections: [{ collection: "reviews" }],
        storeAs: `${props.skillId}-subCols`,
      },
    ];
  })
)(SkillCard);

import React from "react";
import MultVerifiedInfo from "../../../../general/components/MultVerifiedInfo";

export const CommentInfo = ({ userId, index }) => {
  return (
    <span className="user-badge">
      <MultVerifiedInfo userId={userId} index={index} />
    </span>
  );
};

import React from "react";
import PropTypes from "prop-types";
import styles from "../../css/rating.module.css";

const RateStarGenerator = ({
  ratingStarCount,
  ratingNumberCount,
  ratingReviewCount,
}) => {
  const generateSpans = (num) => {
    const generatedStars = [];
    const totalStars = 5;
    const remainingStars = totalStars - ratingStarCount;

    const initStars = () => {
      for (let i = 0; i < Number(String(ratingStarCount).split(".")[0]); i++) {
        generatedStars.push(1);
        if (
          i === Number(String(ratingStarCount).split(".")[0]) - 1 &&
          Number(String(ratingStarCount).split(".")[1]) > 0
        ) {
          generatedStars.push(
            Number(`0.${String(ratingStarCount).split(".")[1]}`)
          );
        }
      }
      for (let i = 0; i < Number(String(remainingStars).split(".")[0]); i++) {
        generatedStars.push(0);
      }
    };

    initStars();

    return generatedStars;
  };
  const innerStars = generateSpans(ratingStarCount);
  return (
    <div className={`${styles["custom-rating-wrapper"]}`}>
      {innerStars.map((star, index) => (
        <div
          className={`${styles["custom-rating-mask"]}`}
          key={index}
          data-star={star}
        >
          <div className={`${styles["custom-rating-img"]}`}></div>
        </div>
      ))}
      <div className={`${styles["rating__desc"]} hide-on-small-only`}>
        {ratingNumberCount === 0 ||
        ratingNumberCount === null ||
        ratingNumberCount === undefined ? (
          <p className={`${styles["rating__count"]}`}> no reviews yet</p>
        ) : (
          <>
            <p className={`${styles["rating__count"]}`}> {ratingNumberCount}</p>
            <p className={`${styles["rating__review"]}`}>
              {ratingReviewCount &&
                `(${ratingReviewCount} ${
                  ratingReviewCount > 1 ? "reviews" : "review"
                })`}
            </p>
          </>
        )}
      </div>
    </div>
  );
};

RateStarGenerator.propTypes = {
  ratingNumberCount: PropTypes.number,
  ratingReviewCount: PropTypes.number,
  ratingStarCount: PropTypes.number,
};
export default RateStarGenerator;

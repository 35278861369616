import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { store } from "./stores/store";
import { createFirestoreInstance } from "redux-firestore";
import fbConfig from "./config/fbConfig";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import { Provider } from "react-redux";
import AuthIsLoaded from "./auth/components/AuthIsLoaded";
import { ErrorBoundary } from "react-error-boundary";

//enabled for direct access to user profile //firestore
const rrfConfig = {
  userProfile: "users",
  useFirestoreForProfile: true,
  enableClaims: true,
};

//react redux provide props
const rrfProps = {
  firebase: fbConfig,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance,
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <AuthIsLoaded>
          <ErrorBoundary
            fallback={
              <p>
                Something went wrong. Try again later.{" "}
                <a href="/">Refresh page</a>
              </p>
            }
          >
            <App />
          </ErrorBoundary>
        </AuthIsLoaded>
      </ReactReduxFirebaseProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

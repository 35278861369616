import React from "react";
// import ForumCardBox from "../../../general/components/ForumCardBox";
// import { storyLists } from "../../../assets/dummies/Static";
import { isLoaded } from "react-redux-firebase";
import moment from "moment";
import ResourceCard from "../../../general/components/ResourceCard";

const MoreResources = ({ resources, resourceId }) => {
  const otherResources = React.useMemo(() => {
    if (isLoaded(resources)) {
      return resources.filter((data) => {
        return [data.status].join("").toLowerCase().indexOf(0) !== -1;
      });
    }
  }, [resources]);

  return (
    <section className="blob-item divide" id="general__lists">
      <div className="blob-tag-area">
        <h4>Continue browsing resources</h4>
        <div className="section-row row">
          {otherResources
            .filter((p) => p.id !== resourceId)
            .slice(0, 4)
            .map((s) => {
              return (
                <div
                  className="section-col section-card col s12 m6 l6 xl6"
                  key={s.id}
                >
                  <ResourceCard
                    cardId={s.id}
                    cardBanner={s.properties.poster}
                    cardTag={s.properties.category}
                    cardTitle={s.properties.title}
                    cardAvatar={s.author.photoURL}
                    cardUserName={s.author.username}
                    cardDate={moment(s.createdAt.toDate()).fromNow()}
                  />
                </div>
              );
            })}
        </div>
      </div>
    </section>
  );
};

export default MoreResources;

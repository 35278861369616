import React from "react";
import PropTypes from "prop-types";
import VerifiedInfo from "../../../general/components/VerifiedInfo";
import { replaceSpaces } from "../../../general/functions";

const PartialUserInfo = ({
  userId,
  userAvatar,
  username,
  category,
  duration,
  createdAt,
  type,
}) => {
  return (
    <section className="blob-item ">
      <div className="blob-desc-area">
        <a href={`/@${username}`}>
          <img
            src={userAvatar}
            alt={`${username}-${userAvatar}`}
            loading="lazy"
            draggable="false"
            className="user-avatar"
          />
        </a>
        <div className="user-info">
          <div className="user-name-info">
            <a href={`/@${username}`} className="user-name">
              {username}
            </a>
            <span className="user-badge">
              <VerifiedInfo userId={userId} />
            </span>
          </div>
          <p className="blob-properties">
            <span
              className="blob-lt"
              style={{ display: "flex", alignItems: "center", gap: "3px" }}
            >
              {category !== "" ? "Published in" : "Drafts"}
              <a
                href={`/${type}/${replaceSpaces(category).toLowerCase()}`}
                className="blob-tag"
              >
                {category}
              </a>
            </span>
            {duration && <span className="blob-lt">{duration} min read</span>}
            <span className="blob-lt">{createdAt}</span>
          </p>
        </div>
      </div>
    </section>
  );
};

PartialUserInfo.propTypes = {
  userId: PropTypes.string,
  username: PropTypes.string,
  category: PropTypes.string,
  duration: PropTypes.number,
  createdAt: PropTypes.string,
};

export default PartialUserInfo;

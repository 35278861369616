import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { replaceSpaces, replaceSpecial } from "../functions";
import validator from "validator";

const MiniCard = ({
  cardId,
  cardTitle,
  cardAvatar,
  cardUserName,
  cardCategory,
  cardLocation,
  cardType,
  cardAmount,
}) => {
  return (
    <div className="mini-card" key={cardId}>
      <div className="mini-img-area">
        <NavLink
          to={`/skillshop/${replaceSpaces(
            cardCategory
          ).toLowerCase()}/${replaceSpaces(
            replaceSpecial(cardTitle)
          ).toLowerCase()}-${cardId.slice(10).toLowerCase()}`}
        >
          <img
            src={cardAvatar}
            alt={`${cardUserName}-${cardType}`}
            draggable="false"
            loading="lazy"
          />
        </NavLink>
      </div>
      <div className="mini-info-area">
        <p className="info-name">
          <NavLink
            to={`/skillshop/${replaceSpaces(
              cardCategory
            ).toLowerCase()}/${replaceSpaces(
              replaceSpecial(cardTitle)
            ).toLowerCase()}-${cardId.slice(10).toLowerCase()}`}
          >
            {validator.unescape(cardTitle)}
          </NavLink>
        </p>
        <p className="info-type pin-tr">
          <NavLink
            to={`/skillshop/${replaceSpaces(
              cardCategory
            ).toLowerCase()}/${replaceSpaces(
              replaceSpecial(cardTitle)
            ).toLowerCase()}-${cardId.slice(10).toLowerCase()}`}
          >
            {cardCategory}
          </NavLink>
        </p>
        <div className="fx-mini mult-info">
          <p className="fx-mini">
            <i className="fa fa-map-marker" aria-hidden="true"></i>
            {cardLocation}
          </p>
          <p className="fx-mini">
            <i className="fa fa-clock-o" aria-hidden="true"></i>
            {cardType}
          </p>
        </div>

        <p className="fx-mini info-price">
          <i className="fa fa-money" aria-hidden="true"></i>
          TRY. {cardAmount}
        </p>
      </div>
    </div>
  );
};

MiniCard.propTypes = {
  cardId: PropTypes.string,
  cardAvatar: PropTypes.string,
  cardUserName: PropTypes.string,
  cardTitle: PropTypes.string,
  cardCategory: PropTypes.string,
  cardLocation: PropTypes.string,
  cardType: PropTypes.string,
  cardAmount: PropTypes.string,
};

export default MiniCard;

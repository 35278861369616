import { compose, configureStore } from "@reduxjs/toolkit";
import { firebaseReducer } from "react-redux-firebase";
import AuthReducer from "./auth/AuthSlice";
import CrudReducer from "./crud/CrudSlice";
import ModalReducer from "./modals/ModalSlice";
import { getFirebase } from "react-redux-firebase";
import {
  firestoreReducer,
  getFirestore,
  reduxFirestore,
} from "redux-firestore";
import fbConfig from "../config/fbConfig";

export const store = configureStore({
  reducer: {
    auth: AuthReducer,
    crud: CrudReducer,
    modal: ModalReducer,
    firebase: firebaseReducer,
    firestore: firestoreReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: {
        extraArgument: { getFirebase, getFirestore },
      },
      serializableCheck: false,
    }),
  enhancers: (getDefaultEnhancers) => [
    ...getDefaultEnhancers(),
    compose(reduxFirestore(fbConfig)),
  ],
});

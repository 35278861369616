import React from "react";

const SearchBar = ({ updateFormValue, submit, search }) => {
  return (
    <div className="nav-search hide-on-small-only">
      <label htmlFor="nav-search"></label>
      <input
        type="text"
        id="nav-search"
        name="nav-search"
        value={search}
        onChange={updateFormValue}
        onKeyDown={submit}
        placeholder="Search for news, resources etc."
        className="nav-input browser-default"
      />
    </div>
  );
};

export default SearchBar;

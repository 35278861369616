import React, { useEffect, useRef } from "react";
import {
  DeleteIcon,
  ReportIcon,
  ThreeDots,
} from "../../../../assets/icons/Icons";
import { CommentInfo } from "./CommentInfo";
import SubComments from "./SubComments";
import { compose } from "redux";
import withRouter from "../../../../general/components/WithRouter";
import { firestoreConnect } from "react-redux-firebase";
import { useDispatch, useSelector } from "react-redux";
import M from "materialize-css";
import { SET__COMMENT__REPORT__MODAL } from "../../../../stores/modals/ModalSlice";
import DeleteModal from "../../../../modals/DeleteModal";

const Comments = ({
  id,
  avatar,
  username,
  createdAt,
  comment,
  userId,
  index,
  auth,
  setCommentData,
  updateSubCommentValue,
  subTextCount,
  setSubTextCount,
  subComment,
  handleSubmitSubComment,
  postId,
  view,
  setView,
  subView,
  setSubView,
  handleCommentDelete,
  handleSubCommentDelete,
  setDelData,
}) => {
  const dotRef = useRef();
  const authRef = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    const nav = document.querySelectorAll("#main__main");
    dotRef.current = M.Dropdown.init(nav, {
      coverTrigger: false,
      alignment: "right",
      constrainWidth: false,
      onOpenStart: (el) => {
        setCommentData((state) => ({
          ...state,
          pid: postId,
          id: el.dataset.value,
          type: "comment",
          content: el.parentNode.nextSibling.textContent,
        }));
      },
    });

    const reCalculate = () => {
      dotRef.current.forEach((d) => d.recalculateDimensions());
    };

    if (nav) {
      window.addEventListener("resize", reCalculate);
      return () => window.removeEventListener("resize", reCalculate);
    }
  }, [window.screen.width, index]);

  useEffect(() => {
    const nav = document.querySelectorAll("#main__auth");
    authRef.current = M.Dropdown.init(nav, {
      coverTrigger: false,
      alignment: "right",
      constrainWidth: false,
      onOpenStart: (el) => {
        setDelData((state) => ({
          ...state,
          pid: postId,
          cid: el.dataset.value,
        }));
      },
    });

    const reCalculate = () => {
      authRef.current.forEach((d) => d.recalculateDimensions());
    };

    if (nav) {
      window.addEventListener("resize", reCalculate);
      return () => window.removeEventListener("resize", reCalculate);
    }
  }, [index, auth]);

  const subComments = useSelector(
    ({ firestore: { ordered } }) =>
      ordered[`${id}-subs`] && ordered[`${id}-subs`]
  );

  return (
    <>
      <li className="comment-list" key={id}>
        <div className="blob-desc-area">
          <img
            src={avatar}
            draggable="false"
            alt={`${username}-${avatar}`}
            className="user-avatar"
          />
          <div className="wrapper-fx-col">
            <div className="wrapper-fx-row">
              <div className="user-info">
                <div className="user-name-info">
                  <a href={`/@${username}`} className="user-name">
                    {username}
                  </a>
                  <CommentInfo userId={userId} index={index} />
                </div>
                <p className="blob-properties">
                  <span className="blob-lt">{createdAt}</span>
                </p>
              </div>
              {auth && auth.uid === userId ? (
                <>
                  <button
                    className="comment-action"
                    id="main__auth"
                    data-value={id}
                    data-target={`a__t${index}`}
                  >
                    <ThreeDots width={20} />
                  </button>
                  <ul id={`a__t${index}`} className="dropdown-content badge">
                    <li className="custom__list">
                      <button
                        className="comment-action"
                        style={{
                          justifyContent: "flex-start",
                          gap: "10px",
                          padding: "7px 10px",
                        }}
                        onClick={() => setView(true)}
                      >
                        <DeleteIcon width={15} theme={`cl-icon-light`} />
                        Delete comment
                      </button>
                    </li>
                  </ul>
                </>
              ) : (
                <>
                  <button
                    className="comment-action"
                    id="main__main"
                    data-value={id}
                    data-target={`main__t${index}`}
                  >
                    <ThreeDots width={20} />
                  </button>
                  <ul id={`main__t${index}`} className="dropdown-content badge">
                    <li className="custom__list">
                      <button
                        className="comment-action"
                        style={{
                          justifyContent: "flex-start",
                          gap: "10px",
                          padding: "7px 10px",
                        }}
                        onClick={() =>
                          dispatch(SET__COMMENT__REPORT__MODAL(true))
                        }
                      >
                        <ReportIcon width={15} theme={`cl-icon-light`} />
                        Report comment
                      </button>
                    </li>
                  </ul>
                </>
              )}
            </div>
            <div className="comment-textbox">{comment}</div>
            <SubComments
              postId={postId}
              parent={id}
              index={index}
              subComments={subComments}
              auth={auth}
              setCommentData={setCommentData}
              subTextCount={subTextCount}
              setSubTextCount={setSubTextCount}
              subComment={subComment}
              updateSubCommentValue={updateSubCommentValue}
              handleSubmitSubComment={handleSubmitSubComment}
              view={view}
              setView={setView}
              handleSubCommentDelete={handleSubCommentDelete}
              setDelData={setDelData}
              subView={subView}
              setSubView={setSubView}
            />
          </div>
        </div>
      </li>

      {view && (
        <DeleteModal
          view={view}
          setView={setView}
          text={"Comment"}
          callback={handleCommentDelete}
        />
      )}
    </>
  );
};

export default compose(
  withRouter,
  firestoreConnect((props) => {
    if (props.id || props.postId) {
      return [
        {
          collection: "posts",
          doc: props.postId,
          subcollections: [
            { collection: "comments", doc: props.id },
            { collection: "commentSubComments" },
          ],
          where: ["body.parentId", "==", props.id],
          orderBy: ["createdAt", "desc"],
          storeAs: `${props.id}-subs`,
        },
      ];
    }
  })
)(Comments);

import React, { useEffect, useState } from "react";
import "../../css/blob-content.css";
import PropTypes from "prop-types";
import { FbIcon, LinkIcon, XIcon } from "../../assets/icons/Icons";
import { useOutletContext, useParams } from "react-router-dom";
import { isEmpty, isLoaded } from "react-redux-firebase";
import Loader from "../../general/components/Loader";
import AbortableToast from "../../general/components/AbortableToast";
import validator from "validator";
import { EventActions } from "./EventActions";
import { atcb_action } from "add-to-calendar-button";
import { useSelector } from "react-redux";
import Error404 from "../Error404";

const EventsId = ({ nmg }) => {
  const params = useParams();
  const { user } = useSelector((state) => state.firebase.profile);
  const { auth } = useSelector((state) => state.firebase);
  const { events, posts } = useOutletContext();
  const [loading, setIsLoading] = useState(false);

  const filterEventsByID = React.useMemo(() => {
    if (isLoaded(events)) {
      return events.filter((data) => {
        return (
          [data.id].join("").toLowerCase().indexOf(params.id.toLowerCase()) !==
          -1
        );
      });
    }
  }, [events, params]);

  useEffect(() => {
    setIsLoading(true);
    window.scrollTo(0, 0, "smooth");
    setTimeout(() => {
      setIsLoading(false);
    }, 300);
  }, []);

  const copyToClipboard = () => {
    const link = document.querySelector(".copy__url");
    navigator.clipboard
      .writeText(`https://${window.location.host}${link.value}`)
      .then(() => {
        AbortableToast("Link copied successfully");
      });
  };

  const shareOnFacebook = () => {
    const link = document.querySelector(".copy__url");
    const navUrl =
      "https://www.facebook.com/sharer/sharer.php?u=" +
      `https://${window.location.host}${window.encodeURIComponent(link.value)}`;
    window.open(navUrl, "_blank");
  };

  const shareOnTwitter = () => {
    const link = document.querySelector(".copy__url");
    const navUrl =
      "https://twitter.com/intent/tweet?text=" +
      `https://${window.location.host}${link.value}`;
    window.open(navUrl, "_blank");
  };

  const getMobileOS = () => {
    const ua = window.navigator.userAgent;
    if (/android/i.test(ua)) {
      return "Android";
    } else if (
      /iPad|iPhone|iPod|/.test(ua) ||
      (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
    ) {
      return "iOS";
    }
    return "Other";
  };

  const handleEventAction = (evt) => {
    const dateStart = new Date(
      filterEventsByID[0].properties.dateStart.toDate()
    );
    const dateEnd = new Date(filterEventsByID[0].properties.dateEnd.toDate());

    // icsFile: `https://voisapp-5deb7.firebaseapp.com/download/?dateStart=${filterEventsByID[0].properties.dateStart.toDate()}&dateEnd=${filterEventsByID[0].properties.dateEnd.toDate()}&title=${filterEventsByID[0].properties.title}`
    const config = {
      name: filterEventsByID[0].properties.title,
      label: filterEventsByID[0].properties.title,
      options: [`${getMobileOS() === "iOS" ? "Apple" : "Google"}`],
      attendee: auth.uid
        ? `${user.username}|${user.email}`
        : "You|No-auth-user@noauth.com",
      organizer: "Vois Cyprus|vois@gmail.com",
      location: `${filterEventsByID[0].location.venue}, ${filterEventsByID[0].location.city}`,
      startDate: `${dateStart.getFullYear()}-${
        String(dateStart.getMonth() + 1).length > 1
          ? dateStart.getMonth() + 1
          : `0${dateStart.getMonth() + 1}`
      }-${
        String(dateStart.getDate()).length > 1
          ? dateStart.getDate()
          : `0${dateStart.getDate()}`
      }`,
      endDate: `${dateEnd.getFullYear()}-${
        String(dateEnd.getMonth() + 1).length > 1
          ? dateEnd.getMonth() + 1
          : `0${dateEnd.getMonth() + 1}`
      }-${
        String(dateEnd.getDate()).length > 1
          ? dateEnd.getDate()
          : `0${dateEnd.getDate()}`
      }`,
      startTime: `${
        dateStart.getHours().toString().length === 1
          ? `0${dateStart.getHours()}`
          : dateStart.getHours()
      }:${
        dateStart.getMinutes().toString().length === 1
          ? `0${dateStart.getMinutes()}`
          : dateStart.getMinutes()
      }`,
      endTime: `${
        dateEnd.getHours().toString().length === 1
          ? `0${dateEnd.getHours()}`
          : dateEnd.getHours()
      }:${
        dateEnd.getMinutes().toString().length === 1
          ? `0${dateEnd.getMinutes()}`
          : dateEnd.getMinutes()
      }`,
      trigger: "click",
      hideIconButton: true,
      hideCheckmark: true,
      hideBranding: true,
      bypassWebViewCheck: true,
      debug: false,
      icsFile: filterEventsByID[0].properties.icsFile,
    };

    // console.log(config)
    atcb_action(config, evt.currentTarget);
  };

  return (
    <>
      {!isLoaded(filterEventsByID) ? (
        <div
          style={{
            width: "100%",
            height: "calc(100vh - 120px)",
          }}
        >
          <Loader type={`cover`} />
        </div>
      ) : isEmpty(filterEventsByID) ? (
        <Error404
          posts={posts}
          text={"Event not found"}
          code={401}
          unset={params.pid !== undefined}
        />
      ) : isLoaded(filterEventsByID) && filterEventsByID[0].status !== 0 ? (
        <Error404
          posts={posts}
          text={"Event information not available"}
          code={401}
          unset={params.pid !== undefined}
        />
      ) : (
        <div className="blob-wrapper" style={{ marginTop: nmg && "0" }}>
          <div className="blob-content container">
            <div className="blob-content-items">
              <section className="blob-item ">
                <div className="blob-info-area">
                  <h2 className="blob-title" style={{ marginBottom: 0 }}>
                    {validator.unescape(filterEventsByID[0].properties.title)}
                  </h2>
                </div>
                <input
                  type="hidden"
                  name="link"
                  value={`/events/${filterEventsByID[0].id
                    .slice(10)
                    .toLowerCase()}`}
                  className="copy__url"
                />
              </section>
              <EventActions
                disableActions={false}
                dateFrom={filterEventsByID[0].display.from}
                dateTo={filterEventsByID[0].display.to}
                handleEventAction={handleEventAction}
                copyToClipboard={copyToClipboard}
                shareOnFacebook={shareOnFacebook}
                shareOnTwitter={shareOnTwitter}
              />
              <section className="blob-item ">
                <div className="blob-desc-area"></div>
              </section>
            </div>
            {filterEventsByID[0].properties.poster && (
              <div className="blob-break-view">
                <section className="blob-item blob-br">
                  <div className="blob-img-area">
                    <img
                      src={filterEventsByID[0].properties.poster}
                      alt={`post-poster-${filterEventsByID[0].properties.poster}`}
                      role="presentation"
                    />
                  </div>
                </section>
              </div>
            )}

            <div className="blob-content-items">
              <section className="blob-item ">
                <div
                  className="blob-text-area"
                  dangerouslySetInnerHTML={{
                    __html: filterEventsByID[0].properties.contentHTML,
                  }}
                ></div>
              </section>
              <section className="blob-item divide">
                <div className="blob-tag-area">
                  {/* <h4>Your audience would be interested?</h4> */}
                  <div className="blob-connect">
                    <h5>Interact by sharing</h5>
                    <ul className="blob-tag-lists">
                      <li className="blob-list">
                        <button onClick={shareOnTwitter}>
                          <XIcon width={20} theme={`cl-icon-light`} />
                        </button>
                      </li>
                      <li className="blob-list">
                        <button onClick={shareOnFacebook}>
                          <FbIcon width={20} theme={`cl-icon-light`} />
                        </button>
                      </li>
                      <li className="blob-list">
                        <button onClick={copyToClipboard}>
                          <LinkIcon width={20} theme={`cl-icon-light`} />
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </section>
            </div>
          </div>
          <div className="blob-extra">
            <div className="blob-extra-content container">
              <div className="blob-extra-items"></div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

EventsId.propTypes = {
  nmg: PropTypes.bool,
};

export default EventsId;

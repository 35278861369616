import React from "react";
import { LoadIcon } from "../../../assets/icons/Icons";
import Review from "./Review";
import moment from "moment";

const SkillReview = ({
  reviews,
  auth,
  setSkillData,
  skillId,
  handleReviewDelete,
  subView,
  setSubView,
  maxLimit,
  maxDispLimit,
  indexDisp,
  handleLoadMore,
}) => {
  return (
    <ul className="blob-comment-lists">
      {reviews.slice(indexDisp, maxDispLimit).map((review, index) => (
        <Review
          key={review.id}
          cardUserId={review.author.id}
          cardAvatar={review.author.photoURL}
          cardUsername={review.author.username}
          index={index}
          cardId={review.id}
          ratingStarCount={(
            (Number(review.rating.communication) +
              Number(review.rating.quality) +
              Number(review.rating.recommend)) /
            3
          ).toFixed(1)}
          ratingNumberCount={(
            (Number(review.rating.communication) +
              Number(review.rating.quality) +
              Number(review.rating.recommend)) /
            3
          ).toFixed(1)}
          ratingComment={review.rating.comment}
          auth={auth}
          createdAt={moment(review.createdAt.toDate()).fromNow()}
          setSkillData={setSkillData}
          skillId={skillId}
          handleReviewDelete={handleReviewDelete}
          subView={subView}
          setSubView={setSubView}
        />
      ))}

      {reviews.length > maxLimit && maxDispLimit < reviews.length && (
        <li className="comment-list" id="load__er">
          <button
            className="comment-action wrapper-fx-row gap upper-tx"
            onClick={handleLoadMore}
          >
            <LoadIcon width={20} theme={`cl-icon-light`} />
            load more
          </button>
        </li>
      )}
    </ul>
  );
};

export default SkillReview;

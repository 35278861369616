import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../../general/components/Loader";
import { useSelector } from "react-redux";

const AuthSecured = ({ children }) => {
  const navigate = useNavigate();
  const { auth } = useSelector((state) => state.firebase);
  const [view, setView] = useState(true);

  useEffect(() => {
    if (!auth.uid) {
      navigate(`/auth/signin`, { replace: true });
      setView(false);
    } else {
      setView(false);
    }
  }, [auth, auth.uid]);

  return (
    <>
      {view && <Loader type="cover" />}
      {children}
    </>
  );
};

export default AuthSecured;

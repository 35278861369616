import React from "react";
import PropTypes from "prop-types";
import { PLACE__HOLDER, VOLUNTEER__BADGE } from "../../config/globals";
import { NavLink } from "react-router-dom";
import { replaceSpaces, replaceSpecial } from "../functions";
import validator from "validator";

const ForumCardBox = ({
  cardId,
  cardBanner,
  cardAvatar,
  cardTitle,
  cardDate,
  cardUserName,
  cardIsDraft,
  cardTag,
}) => {
  // const navigate = useNavigate();
  return (
    <div className="card-box" key={cardId}>
      <div className="card-img-area">
        {cardIsDraft ? (
          <NavLink to={`/me/${cardId}`}>
            <img
              src={cardBanner ? cardBanner : PLACE__HOLDER}
              className="card-img"
              draggable="false"
              loading="lazy"
              alt={cardTitle}
            />
          </NavLink>
        ) : (
          <NavLink
            to={`/forum/${replaceSpaces(cardTag).toLowerCase()}/${replaceSpaces(
              replaceSpecial(cardTitle)
            ).toLowerCase()}-${cardId.slice(10).toLowerCase()}`}
          >
            <img
              src={cardBanner ? cardBanner : PLACE__HOLDER}
              className="card-img"
              draggable="false"
              loading="lazy"
              alt={cardTitle}
            />
          </NavLink>
        )}
      </div>
      <div className="card-desc-area">
        <div className="card-tag">
          {cardIsDraft ? (
            <NavLink to={`/me/${cardId}/edit`}> {cardTag}</NavLink>
          ) : (
            <NavLink to={`/forum/${replaceSpaces(cardTag).toLowerCase()}`}>
              {cardTag}
            </NavLink>
          )}
        </div>
        <div className="card-title">
          {cardIsDraft ? (
            <NavLink to={`/me/${cardId}/edit`}>
              {validator.unescape(cardTitle)}
            </NavLink>
          ) : (
            <NavLink
              to={`/forum/${replaceSpaces(
                cardTag
              ).toLowerCase()}/${replaceSpaces(
                replaceSpecial(cardTitle)
              ).toLowerCase()}-${cardId.slice(10).toLowerCase()}`}
            >
              {validator.unescape(cardTitle)}
            </NavLink>
          )}
        </div>
        <div className="card-user">
          <NavLink to={`/@${cardUserName}`}>
            <img src={cardAvatar} alt="profile" />{" "}
          </NavLink>

          <div className="card-user-info">
            <div className="card-name" style={{ display: "flex", alignItems: "center"}}>
              <NavLink to={`/@${cardUserName}`}>{cardUserName}</NavLink>
              <img src={VOLUNTEER__BADGE} alt="volunteer badge" className="card-badge" title="volunteer's badge"/>
            </div>
            <div className="card-date" style={{ marginTop: ".2rem"}}>{cardDate}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

ForumCardBox.propTypes = {
  cardId: PropTypes.string,
  cardAvatar: PropTypes.string,
  cardIsDraft: PropTypes.bool,
  cardBanner: PropTypes.string,
  cardTitle: PropTypes.string,
  cardDate: PropTypes.string,
  cardUserName: PropTypes.string,
  cardTag: PropTypes.string,
};

export default ForumCardBox;

import React, { useEffect, useRef } from "react";
import btnStyles from "../../css/auth-btn.module.css";
import {
  ClickIcon,
  FbIcon,
  LinkIcon,
  ShareIcon,
  XIcon,
} from "../../assets/icons/Icons";
import M from "materialize-css";

export const EventActions = ({
  copyToClipboard,
  handleEventAction,
  shareOnFacebook,
  shareOnTwitter,
  disableActions,
  dateFrom,
  dateTo,
}) => {
  const shareRef = useRef();
  useEffect(() => {
    const share = document.querySelector(".btn__share");
    shareRef.current = M.Dropdown.init(share, {
      coverTrigger: false,
      alignment: "right",
      constrainWidth: false,
    });

    const reCalculate = () => {
      shareRef.current.recalculateDimensions();
    };

    if (share) {
      window.addEventListener("resize", reCalculate);
      return () => window.removeEventListener("resize", reCalculate);
    }
  }, []);
  // console.log(dispatch(SET__REPORT__MODAL(true)));

  const isExpired = () => {
    const localTime = new Date();
    const from = new Date(dateFrom.toDate());
    const to = new Date(dateTo.toDate());

    return (
      from.getTime() <= to.getTime() && to.getTime() >= localTime.getTime()
    );
  };
  return (
    <>
      <section className="blob-item ">
        <div className="blob-action-area">
          <div className="action-lr">
            <div className="l" title="Save to calendar">
              <button
                className={`${btnStyles["auth-blob-btn"]}`}
                onClick={handleEventAction}
                disabled={!isExpired()}
                title={isExpired() ? "Save to calendar" : "Event has passed"}
              >
                <ClickIcon width={20} theme={`cl-icon-light`} />
                Save to calendar
              </button>
            </div>
          </div>
          <div className="action-rl">
            <div className="r">
              <button
                className={`${btnStyles["auth-blob-btn"]} btn__share`}
                title="Share resource"
                data-target={`btn-share`}
                disabled={disableActions}
              >
                <ShareIcon width={22} theme={`cl-icon-light`} />
              </button>
              <ul id="btn-share" className="dropdown-content">
                <li
                  className="custom__list"
                  style={{ width: "100%", gap: "10px", padding: "7px 14px" }}
                >
                  <button
                    className={`${btnStyles["auth-blob-btn"]}`}
                    style={{ width: "100%", justifyContent: "flex-start" }}
                    title="Copy link"
                    onClick={copyToClipboard}
                  >
                    <LinkIcon width={20} />
                    Copy link
                  </button>
                </li>
                <li
                  className="custom__list"
                  style={{ width: "100%", gap: "10px", padding: "7px 14px" }}
                >
                  <button
                    className={`${btnStyles["auth-blob-btn"]}`}
                    style={{ width: "100%", justifyContent: "flex-start" }}
                    title="Share to facebook"
                    onClick={shareOnFacebook}
                  >
                    <FbIcon width={20} />
                    Share on Facebook
                  </button>
                </li>
                <li
                  className="custom__list"
                  style={{ width: "100%", gap: "10px", padding: "7px 14px" }}
                >
                  <button
                    className={`${btnStyles["auth-blob-btn"]}`}
                    style={{ width: "100%", justifyContent: "flex-start" }}
                    title="Share to twitter"
                    onClick={shareOnTwitter}
                  >
                    <XIcon width={20} />
                    Share on X (Twitter)
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

import React from "react";
import PropTypes from "prop-types";

const Loader = ({ type }) => {
  return (
    <div className={`loader-wrapper ${type === "cover" && "cover"}`}>
      <div className="loader"></div>
    </div>
  );
};

Loader.propTypes = {
  type: PropTypes.string,
};
export default Loader;

// import edu from "../imgs/edu.jpg";
// import immigration from "../imgs/immigration.jpg";
// import police from "../imgs/police.jpg";
// import housing from "../imgs/housing.jpg";
// import gender from "../imgs/gender.jpg";
// import disc from "../imgs/disc.jpg";
// import avatar from "../imgs/avatar.jpeg";

export const navs = [
  {
    id: 1,
    text: "Latest",
    path: "/forum",
  },
  {
    id: 2,
    text: "Healthcare",
    path: "/",
  },
  {
    id: 3,
    text: "Immigration",
    path: "/",
  },
  {
    id: 4,
    text: "Working",
    path: "/",
  },
  {
    id: 5,
    text: "Gender",
    path: "/",
  },
  {
    id: 6,
    text: "Discrimination",
    path: "/",
  },
  {
    id: 7,
    text: "Education",
    path: "/",
  },
  {
    id: 8,
    text: "General",
    path: "/",
  },
  {
    id: 9,
    text: "Housing",
    path: "/",
  },
];

export const houseNavs = [
  {
    id: 1,
    text: "All",
    path: "/housing",
  },
  {
    id: 2,
    text: "Famagusta-Gazimağusa",
    path: "/housing/famagusta-gazimağusa",
  },
  {
    id: 3,
    text: "Kyrenia-Girne",
    path: "/housing/kyrenia-girne",
  },
  {
    id: 4,
    text: "Nicosia-Lefkoşa",
    path: "/housing/nicosia-lefkoşa",
  },
  {
    id: 5,
    text: "Morphou-Güzelyurt",
    path: "/housing/morphou-güzelyurt",
  },
  {
    id: 6,
    text: "Trikomo-Iskele",
    path: "/housing/trikomo-iskele",
  },
  {
    id: 7,
    text: "Lefka-Lefke",
    path: "/housing/lefka-lefke",
  },
];

export const communities = [
  {
    id: 1,
    text: "Forum",
    path: "/",
  },
  {
    id: 2,
    text: "Skillshop",
    path: "/",
  },
  {
    id: 3,
    text: "Housing",
    path: "/",
  },
];

// export const storyLists = [
//   {
//     id: "199jdjsjdssdfsdfsasjcnjsadcnajshncx",
//     title: `Lorem ipsum, dolor sit amet consectetur adipisicing elit. Odio sequi
//         ullam nisi, quo quam earum atque qui suscipit adipisci. Blanditiis.`,
//     name: "Apariosha",
//     tag: "Healthcare",
//     banner: edu,
//     userImg: avatar,
//     date: "2 hours ago",
//   },
//   {
//     id: "299jdjsjdssdfsdfsasjcnjsadcnajshncx",
//     title: `Lorem ipsum, dolor sit amet consectetur adipisicing elit.`,
//     name: "Apariwerey",
//     tag: "Immigration",
//     banner: immigration,
//     userImg: avatar,
//     date: "3 hours ago",
//   },
//   {
//     id: "399jdjsjdssdfsdfsasjcnjsadcnajshncx",
//     title: `Lorem ipsum, dolor sit amet consectetur adipisicing elit. Odio sequi
//         ullam nisi, quo quam earum atque qui suscipit adipisci. Blanditiis.`,
//     name: "Apariosha",
//     tag: "General",
//     banner: housing,
//     userImg: police,
//     date: "3 hours ago",
//   },
//   {
//     id: "499jdjsjdssdfsdfsasjcnjsadcnajshncx",
//     title: `Lorem ipsum, dolor sit amet consectetur adipisicing elit. Odio sequi
//         ullam nisi`,
//     name: "Apari332",
//     tag: "Gender",
//     banner: police,
//     userImg: avatar,
//     date: "3 hours ago",
//   },
//   {
//     id: "599jdjsjdssdfsdfsasjcnjsadcnajshncx",
//     title: `Lorem ipsum, dolor sit amet consectetur adipisicing elit. Odio sequi
//         ullam nisi, quo quam earum atque qui suscipit adipisci. Blanditiis.`,
//     name: "leparusie",
//     tag: "Housing",
//     banner: disc,
//     userImg: edu,
//     date: "13 hours ago",
//   },
//   {
//     id: "699jdjsjdssdfsdfsasjcnjsadcnajshncx",
//     title: `Lorem ipsum, dolor sit amet consectetur adipisicing elit. Odio sequi
//         ullam nisi, quo quam earum atque qui suscipit adipisci. Blanditiis.`,
//     name: "Komotelpe",
//     tag: "Healthcare",
//     banner: gender,
//     userImg: avatar,
//     date: "22 hours ago",
//   },
//   {
//     id: "799jdjsjdssdfsdfsasjcnjsadcnajshncx",
//     title: `Lorem ipsum, dolor sit amet consectetur adipisicing elit. Odio sequi
//         ullam nisi, quo quam earum atque qui suscipit adipisci. Blanditiis.`,
//     name: "Apariosha",
//     tag: "Healthcare",
//     banner: edu,
//     userImg: avatar,
//     date: "2 hours ago",
//   },
//   {
//     id: "899jdjsjdssdfsdfsasjcnjsadcnajshncx",
//     title: `Lorem ipsum, dolor sit amet consectetur adipisicing elit.`,
//     name: "Apariwerey",
//     tag: "Immigration",
//     banner: immigration,
//     userImg: avatar,
//     date: "3 hours ago",
//   },
// ];

// export const jobLists = [
//   {
//     id: 100,
//     category: `Design`,
//     name: "Apariosha",
//     type: "Full Time",
//     location: "Nicosia",
//     avatar: avatar,
//     price: 1000,
//   },
//   {
//     id: 200,
//     category: `Education`,
//     name: "Byjheri",
//     type: "Remote",
//     location: "Famagusta",
//     avatar: edu,
//     price: 5000,
//   },
//   {
//     id: 300,
//     category: `Technology`,
//     name: "Apariosha",
//     type: "Part Time",
//     location: "Nicosia",
//     avatar: gender,
//     price: 2000,
//   },
//   {
//     id: 400,
//     category: `Home care`,
//     name: "Apa99sha",
//     type: "Full Time",
//     location: "Nicosia",
//     avatar: police,
//     price: 4000,
//   },
//   {
//     id: 522,
//     category: `Design`,
//     name: "Apariosha",
//     type: "Full Time",
//     location: "Nicosia",
//     avatar: avatar,
//     price: 1000,
//   },
//   {
//     id: 622,
//     category: `Technology`,
//     name: "Apariosha",
//     type: "Part Time",
//     location: "Nicosia",
//     avatar: avatar,
//     price: 1000,
//   },
//   {
//     id: 822,
//     category: `Design`,
//     name: "Apariosha",
//     type: "Full Time",
//     location: "Nicosia",
//     avatar: avatar,
//     price: 1000,
//   },
//   {
//     id: 922,
//     category: `Design`,
//     name: "Apariosha",
//     type: "Full Time",
//     location: "Nicosia",
//     avatar: avatar,
//     price: 1000,
//   },
//   {
//     id: 1022,
//     category: `Design`,
//     name: "Apariosha",
//     type: "Full Time",
//     location: "Nicosia",
//     avatar: avatar,
//     price: 1000,
//   },
//   {
//     id: 112,
//     category: `Design`,
//     name: "Apariosha",
//     type: "Full Time",
//     location: "Nicosia",
//     avatar: avatar,
//     price: 1000,
//   },
//   {
//     id: 1222,
//     category: `Design`,
//     name: "Apariosha",
//     type: "Full Time",
//     location: "Nicosia",
//     avatar: avatar,
//     price: 1000,
//   },
//   {
//     id: 132,
//     category: `Design`,
//     name: "Apariosha",
//     type: "Full Time",
//     location: "Nicosia",
//     avatar: avatar,
//     price: 1000,
//   },
// ];

// export const skillLists = [
//   {
//     id: `100dnjdsnfkdnsknfdsknkdsndsnfkdsnfkjdsdsds`,
//     tag: `Design`,
//     name: "Apariosha",
//     banner: gender,
//     title: `Lorem ipsum, dolor sit amet consectetur adipisicing elit. Odio sequi
//         ullam nisi, quo quam earum atque qui suscipit adipisci. Blanditiis.`,
//     avatar: avatar,
//     rate: 4.5,
//     reviews: 302,
//     price: 1000,
//   },
//   {
//     id: "200djsadsjdasddnejkdf93ondedfnjdnks",
//     tag: `Entertainment`,
//     name: "Apa00osha",
//     banner: police,
//     title: `Lorem ipsum, dolor sit amet consectetur adipisicing elit. Odio sequi
//         ullam nisi, quo quam earum atque qui suscipit adipisci. Blanditiis.`,
//     avatar: edu,
//     rate: 4.0,
//     reviews: 39,
//     price: 1000,
//   },
//   {
//     id: "30ewndkefkdsnfi39fnejfsdfsdfdsfsdkf",
//     tag: `Education`,
//     name: "vitalii",
//     banner: housing,
//     title: `Lorem ipsum, dolor sit amet consectetur adipisicing elit. Odio sequi
//         ullam nisi, quo quam earum atque qui suscipit adipisci. Blanditiis.`,
//     avatar: immigration,
//     rate: 4.0,
//     reviews: 302,
//     price: 1000,
//   },
//   {
//     id: "393jendsdjfndsisfnsdfndsfddsfsdf",
//     tag: `Design`,
//     name: "Apariosha",
//     banner: gender,
//     title: `Lorem ipsum, dolor sit amet consectetur adipisicing elit. Odio sequi
//         ullam nisi, quo quam earum atque qui suscipit adipisci. Blanditiis.`,
//     avatar: avatar,
//     rate: 4.0,
//     reviews: 302,
//     price: 1000,
//   },
//   {
//     id: "dfjdsjnfdsf93jn3jn3nefedefdf",
//     tag: `Technology`,
//     name: "Apa00osha",
//     banner: police,
//     title: `Lorem ipsum, dolor sit amet consectetur adipisicing elit. Odio sequi
//         ullam nisi, quo quam earum atque qui suscipit adipisci. Blanditiis.`,
//     avatar: edu,
//     rate: 4.0,
//     reviews: 39,
//     price: 1000,
//   },
//   {
//     id: "80039nejwfnjsednfunsdjnfsdnfdsf",
//     tag: `Education`,
//     name: "vitalii",
//     banner: housing,
//     title: `Lorem ipsum, dolor sit amet consectetur adipisicing elit. Odio sequi
//         ullam nisi, quo quam earum atque qui suscipit adipisci. Blanditiis.`,
//     avatar: immigration,
//     rate: 4.0,
//     reviews: 302,
//     price: 1000,
//   },
//   {
//     id: "109039nfenfdjfnd9f3nfenddfdjfdnfe",
//     tag: `Home Care`,
//     name: "Apariosha",
//     banner: gender,
//     title: `Lorem ipsum, dolor sit amet consectetur adipisicing elit. Odio sequi
//         ullam nisi, quo quam earum atque qui suscipit adipisci. Blanditiis.`,
//     avatar: avatar,
//     rate: 4.0,
//     reviews: 302,
//     price: 1000,
//   },
//   {
//     id: "200030enfefjn3fjdnfdjfndjnfdfn4fd",
//     tag: `Design`,
//     name: "Apa00osha",
//     banner: police,
//     title: `Lorem ipsum, dolor sit amet consectetur adipisicing elit. Odio sequi
//         ullam nisi, quo quam earum atque qui suscipit adipisci. Blanditiis.`,
//     avatar: edu,
//     rate: 4.0,
//     reviews: 39,
//     price: 1000,
//   },
//   {
//     id: "dfdijfidjifdifjdf333jmfejejedj",
//     tag: `Design`,
//     name: "vitalii",
//     banner: housing,
//     title: `Lorem ipsum, dolor sit amet consectetur adipisicing elit. Odio sequi
//         ullam nisi, quo quam earum atque qui suscipit adipisci. Blanditiis.`,
//     avatar: immigration,
//     rate: 4.0,
//     reviews: 302,
//     price: 1000,
//   },
// ];

// export const houseLists = [
//   {
//     id: "240030enfefjn3fjdnfdjfndjnfdfn4fd",
//     name: "Northernland Center",
//     banner: gender,
//     rate: 4.5,
//     reviews: 302,
//     city: "Famagusta",
//     region: "Dumlupinar",
//   },
//   {
//     id: "330030enfefjn3fjdnfdjfndjnfdfn4fd",
//     name: "Northernland Premier",
//     banner: police,
//     rate: 4.5,
//     reviews: 302,
//     city: "Iskele",
//     region: "Dumlupi",
//   },
//   {
//     id: "2sdfds0enfefjn3fjdnfdjfndjnfdfn4fd",
//     name: "Northernland Center",
//     banner: housing,
//     rate: 4.5,
//     reviews: 302,
//     city: "Famagusta",
//     region: "Dumlupinar",
//   },
//   {
//     id: "2asdas030enfefjn3fjdnfdjfndjnfdfn4fd",
//     name: "Northernland Premier",
//     banner: immigration,
//     rate: 4.5,
//     reviews: 302,
//     city: "Iskele",
//     region: "Dumlupi",
//   },
//   {
//     id: "2asdas30enfefjn3fjdnfdjfndjnfdfn4fd",
//     name: "Northernland Center",
//     banner: disc,
//     rate: 4.5,
//     reviews: 302,
//     city: "Famagusta",
//     region: "Dumlupinar",
//   },
//   {
//     id: "asdasd0enfefjn3fjdnfdjfndjnfdfn4fd",
//     name: "Northernland Premier",
//     banner: gender,
//     rate: 4.5,
//     reviews: 302,
//     city: "Iskele",
//     region: "Dumlupi",
//   },
//   {
//     id: "2sadsade30enfefjn3fjdnfdjfndjnfdfn4fd",
//     name: "Northernland Center",
//     banner: housing,
//     rate: 4.5,
//     reviews: 302,
//     city: "Famagusta",
//     region: "Dumlupinar",
//   },
//   {
//     id: "23330enfefjn3fjdnfdjfndjnfdfn4fd",
//     name: "Northernland Premier",
//     banner: immigration,
//     rate: 4.5,
//     reviews: 302,
//     city: "Iskele",
//     region: "Dumlupi",
//   },
// ];

// review opions
export const reviewOptions = [
  { name: "Communication", id: "communication" },
  { name: "Quality", id: "quality" },
  { name: "Recommend to a friend", id: "recommend" },
];

export const houseOptions = [
  { name: "Overall Building (Exterior)", id: "exterior" },
  { name: "Communication with landlord", id: "communication" },
  { name: "Interior Decoration (e.g. Furnitures)", id: "interior" },
  { name: "Home Appliances", id: "hApp" },
  { name: "Facilities", id: "facility" },
  { name: "Services (e.g Cleaning, Internet etc)", id: "services" },
  { name: "Security", id: "security" },
  { name: "Value for money", id: "vfm" },
];

// img arr
// export const imgArr = [edu, gender, immigration];

export const reportCategories = [
  "Harassment",
  "Offensive remarks",
  "Intellectual theft",
  "False advertisement",
  "Spam",
  "Rules violation",
];

export const magusaArea = [
  "Akdogan",
  "Akova",
  "Alanici",
  "Aslankoy",
  "Baykal",
  "Beyarmudu",
  "Canakkale",
  "Dortyol",
  "Dumlupinar",
  "Gecitkale",
  "Gonendere",
  "Guvercinlik",
  "Inonu",
  "Kaleici",
  "Karakol",
  "Korkuteli",
  "Kurudere",
  "Magusa Merkez",
  "Maras",
  "Mormenekse",
  "Mutluyaka",
  "Pasakoy",
  "Pinarli",
  "Sakarya",
  "Serdarli",
  "Tatlisu",
  "Turunclu",
  "Tuzla",
  "Turkmenkoy",
  "Ulukisla",
  "Vadili",
  "Yeni Bogazici",
  "Yildirim",
];

export const lefkosaArea = [
  "Alaykoy",
  "Balikesir",
  "Beykoy",
  "Cihangir",
  "Caglayan",
  "Cukurova",
  "Degirmenlik",
  "Demirhan",
  "Dilekkaya",
  "Duzova",
  "Gazikoy",
  "Gelibolu",
  "Gocmenkoy",
  "Gonyeli",
  "Hamitkoy",
  "Haspolat",
  "Kalavac",
  "Kanlikoy",
  "Koskluciftlik",
  "Kumsal",
  "Kucuk Kaymakli",
  "Lefkosa Surlarici",
  "Marmara",
  "Meric",
  "Minarelikoy",
  "Ortakoy",
  "Taskinkoy",
  "Yenicekoy",
  "Yenikent",
  "Yenisehir",
  "Yilmazkoy",
];

export const girneArea = [
  "Agirdag",
  "Akcicek",
  "Akdeniz",
  "Alsancak",
  "Arapkoy",
  "Asagi Girne",
  "Bahceli",
  "Bellapais",
  "Besparmak",
  "Bogaz",
  "camlibel",
  "catalkoy",
  "Dagyolu",
  "Dikmen",
  "Dogankoy",
  "Edremit",
  "Esentepe",
  "Goceri",
  "Hisarkoy",
  "Ilgaz",
  "Incesu",
  "Karaagac",
  "Karakum",
  "Karaoglanoglu",
  "Karsiyaka",
  "Kayalar",
  "Kozan",
  "Komurcu",
  "Lapta",
  "Malatya",
  "Ozankoy",
  "Pinarbasi",
  "Sadrazamkoy",
  "Sirinevler",
  "Taskent",
  "Tepebasi",
  "Turk Mahallesi",
  "Yesiltepe",
  "Yukari Girne",
  "Zeytinlik",
];

export const iskeleArea = [
  "Agillar",
  "Altinova",
  "Ardahan",
  "Avtepe",
  "Aygun",
  "Bafra",
  "Balalan",
  "Bogazici",
  "Bogaz",
  "Bogaztepe - Monarga",
  "Boltasli",
  "Buyukkonuk",
  "cayirova",
  "Derince",
  "Dipkarpaz",
  "Ergazi",
  "Esenkoy",
  "Iskele Merkez",
  "Kaleburnu",
  "Kalecik",
  "Kaplica",
  "Kumyali",
  "Kurtulus",
  "Kuruova",
  "Kuzucuk",
  "Mehmetcik",
  "Mersinlik",
  "Ötuken",
  "Pamuklu",
  "Sinirustu",
  "Sipahi",
  "Taslica",
  "Topcukoy",
  "Turnalar",
  "Tuzluca",
  "Yarkoy",
  "Yedikonuk",
  "Yeni Erenkoy",
  "Yesilkoy",
  "Ziyamet",
];

// report categories
export const complaintCategories = [
  "General",
  "Skillshop",
  "Immigration",
  "Housing",
  "About App",
];

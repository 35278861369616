import React, { useEffect, useState } from "react";
import "../../css/community-content.css";
import { SkillIcon, TagIcon } from "../../assets/icons/Icons";
import {
  NavLink,
  useLocation,
  useOutletContext,
  useParams,
} from "react-router-dom";
import SkillCard from "../../general/components/SkillCard";
import AuthCreateButton from "../../general/protected/AuthCreateButton";
import { isEmpty, isLoaded } from "react-redux-firebase";
import { replaceDashes, replaceSpaces } from "../../general/functions";
import BannerCardSkeleton from "../../general/components/skeletons/BannerCardSkeleton";
import Error404 from "../Error404";

const Skillshop = () => {
  const params = useParams();
  const location = useLocation();
  const { skills, categorys, posts } = useOutletContext();
  const [view, setView] = useState(false);

  const filteredSkills = React.useMemo(() => {
    if (isLoaded(skills) && params.tag) {
      return skills.filter((data) => {
        return (
          data.status === 0 &&
          [data.properties.category]
            .join("")
            .toLowerCase()
            .indexOf(replaceDashes(params.tag).toLowerCase()) !== -1
        );
      });
    }
  }, [params.tag, skills]);

  useEffect(() => {
    if (location.pathname === "/skillshop/") {
      window.location.href = "/skillshop";
    }
    if (location.pathname === "/skillshop/technology/") {
      window.location.href = "/skillshop/technology";
    }
    if (location.pathname === "/skillshop/home-care/") {
      window.location.href = "/skillshop/home-care";
    }
    if (location.pathname === "/skillshop/personal-care/") {
      window.location.href = "/skillshop/personal-care";
    }
    if (location.pathname === "/skillshop/art/") {
      window.location.href = "/skillshop/art";
    }
    if (location.pathname === "/skillshop/food/") {
      window.location.href = "/skillshop/food";
    }
    if (location.pathname === "/skillshop/entertainment/") {
      window.location.href = "/skillshop/entertainment";
    }
    if (location.pathname === "/skillshop/education/") {
      window.location.href = "/skillshop/education";
    }
    if (location.pathname === "/skillshop/others/") {
      window.location.href = "/skillshop/others";
    }
  }, [location.pathname]);

  useEffect(() => {
    if (isLoaded(categorys) && params.tag !== undefined) {
      const data = categorys[0].data.filter(
        (d) => d.toLowerCase() === replaceDashes(params.tag.toLowerCase())
      );
      if (data.length === 0) {
        setView(true);
      }
    }
  }, [params, categorys]);

  if (view) {
    return (
      <Error404
        posts={posts}
        text={"Missing or broken link."}
        code={404}
        unset={params.pid !== undefined}
      />
    );
  }
  return (
    <div className="community-forum">
      <section className="section-container container v-forum-hero">
        <div className="hero-area">
          {params.tag === undefined && (
            <div className="hero-content">
              <div className="hero-info">
                <SkillIcon height={80} theme={`cl-icon-p`} />
                <h3 className="hero-title">Skillshop</h3>
                <p className="hero-desc">
                  Share individual skills or hire skilled individuals from the
                  international students community in the northern part of
                  Cyprus.
                </p>
              </div>
            </div>
          )}
          {params.tag && (
            <div className="hero-content">
              <div className="hero-info">
                <TagIcon height={40} theme={`cl-icon-p`} />
                <h3 className="hero-title">
                  {params.tag.slice(0, 1).toUpperCase()}
                  {params.tag.slice(1)}
                </h3>
                <p className="hero-desc">
                  Browse individual skills or hire skilled individuals that
                  deals with{" "}
                  {replaceDashes(params.tag.slice(0, 1).toLowerCase())}
                  {replaceDashes(params.tag.slice(1))}.
                </p>
                {filteredSkills && filteredSkills.length > 0 ? (
                  <p
                    className="hero-desc"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      fontSize: '1.3rem'
                    }}
                  >
                    {filteredSkills.length}
                    <span className="cl-icon-light">skill(s) and counting</span>
                  </p>
                ) : (
                  <p
                    className="hero-desc"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      fontSize: '1.3rem'
                    }}
                  >
                    <span className="cl-icon-light">No skills yet</span>
                  </p>
                )}
              </div>
            </div>
          )}
        </div>
      </section>
      <div className="container hero-actions">
        <ul className="hero-tag-lists">
          {!isEmpty(categorys) && (
            <li className="tag-list">
              <NavLink to={`/skillshop`} end>
                All
              </NavLink>
            </li>
          )}
          {!isEmpty(categorys) &&
            categorys[0].data.map((tag) => (
              <li className="tag-list" key={tag}>
                <NavLink
                  to={`/skillshop/${replaceSpaces(tag).toLowerCase()}`}
                  end
                >
                  {tag}
                </NavLink>
              </li>
            ))}
        </ul>
        <div className="hero-auth-area">
          <AuthCreateButton text={`Create`} link="/create/skill" />
        </div>
      </div>
      {!params.tag ? (
        <section className="section-container v-skill-lists container">
          <div className="section-row row">
            {!isLoaded(skills) ? (
              [1, 2, 3, 4, 5, 6].map((d) => (
                <div
                  className="section-col section-card col s12 m6 l4 xl3"
                  key={d}
                >
                  <BannerCardSkeleton />
                </div>
              ))
            ) : isEmpty(skills) ? (
              <div>No skills yet</div>
            ) : (
              skills
                .filter((p) => p.status === 0)
                .map((skill) => (
                  <div
                    className="section-col section-card col s12 m6 l4 xl3"
                    key={skill.id}
                  >
                    <SkillCard
                      skillId={skill.id}
                      skillBanner={skill.properties.banners[0]}
                      skillTitle={skill.properties.title}
                      skillTag={skill.properties.category}
                      skillAvatar={skill.author.photoURL}
                      skillAuthor={skill.author.username}
                      skillRateScore={skill.interactions.reviewCount}
                      skillReviewCount={skill.interactions.reviewCount}
                      skillPrice={skill.properties.price}
                    />
                  </div>
                ))
            )}
          </div>
        </section>
      ) : (
        <section className="section-container v-skill-lists container">
          <div className="section-row row">
            {!isLoaded(filteredSkills) ? (
              [1, 2, 3, 4, 5, 6].map((d) => (
                <div
                  className="section-col section-card col s12 m6 l4 xl3"
                  key={d}
                >
                  <BannerCardSkeleton />
                </div>
              ))
            ) : isEmpty(filteredSkills) ? (
              <div>No skills yet</div>
            ) : (
              filteredSkills.map((skill) => (
                <div
                  className="section-col section-card col s12 m6 l4 xl3"
                  key={skill.id}
                >
                  <SkillCard
                    skillId={skill.id}
                    skillBanner={skill.properties.banners[0]}
                    skillTitle={skill.properties.title}
                    skillTag={skill.properties.category}
                    skillAvatar={skill.author.photoURL}
                    skillAuthor={skill.author.username}
                    skillRateScore={skill.interactions.reviewCount}
                    skillReviewCount={skill.interactions.reviewCount}
                    skillPrice={skill.properties.price}
                  />
                </div>
              ))
            )}
          </div>
        </section>
      )}
    </div>
  );
};

export default Skillshop;

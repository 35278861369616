import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { compose } from "redux";
import { firestoreConnect, isEmpty, isLoaded } from "react-redux-firebase";
import withRouter from "./WithRouter";
import {
  DEV__BADGE,
  ROYALTY__BADGE,
  VOLUNTEER__BADGE,
} from "../../config/globals";

const MultVerifiedInfo = ({ userId, index }) => {
  const user = useSelector(
    ({ firestore: { ordered } }) => ordered[userId] && ordered[userId]
  );
  if (isLoaded(user) && !isEmpty(user)) {
    return (
      <>
        {user[0].user.badgeType !== "" && (
          <>
            <img
              src={
                user[0].user.badgeType === "volunteer"
                  ? VOLUNTEER__BADGE
                  : user[0].user.badgeType === "member"
                  ? ROYALTY__BADGE
                  : user[0].user.badgeType === "royalty"
                  ? DEV__BADGE
                  : ""
              }
              alt={
                user[0].user.badgeType === "volunteer"
                  ? "Volunteer badge"
                  : user[0].user.badgeType === "member"
                  ? "Member badge"
                  : user[0].user.badgeType === "royalty"
                  ? "Developer "
                  : ""
              }
              className="badge__mult"
              id="badge__mult"
              style={{ cursor: "pointer" }}
              //   onClick={() => badgeRef.current.open()}
              data-target={`user-${index}`}
            />
          </>
        )}
      </>
    );
  }
};

MultVerifiedInfo.propTypes = {
  userId: PropTypes.string,
};

export default compose(
  withRouter,
  firestoreConnect((props) => {
    if (props.userId) {
      return [
        {
          collection: "users",
          doc: props.userId,
          storeAs: props.userId,
        },
      ];
    }
  })
)(MultVerifiedInfo);

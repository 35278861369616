import React, { useEffect, useRef } from "react";
import "../../css/community-content.css";
import { NavLink, useLocation, useParams } from "react-router-dom";
import VerifiedInfo from "../../general/components/VerifiedInfo";
import withRouter from "../../general/components/WithRouter";
import { firestoreConnect, isEmpty, isLoaded } from "react-redux-firebase";
import Loader from "../../general/components/Loader";
import { useSelector } from "react-redux";
import { compose } from "redux";
import { FilterIcon, UserEditIcon } from "../../assets/icons/Icons";
import M from "materialize-css";
import PostProfile from "./sections/PostProfile";
import SkillProfile from "./sections/SkillProfile";
import DraftsProfile from "./sections/DraftsProfile";
import CollectionsProfile from "./sections/CollectionsProfile";
import Error404 from "../Error404";

const AccountProfile = () => {
  const params = useParams();
  const filterRef = useRef();
  const location = useLocation();

  // const [loading, setIsLoading] = useState(false);
  const user = useSelector(({ firebase: { profile } }) => profile);
  const posts = useSelector(
    ({ firestore: { ordered } }) => ordered[`${params.id}-posts`]
  );
  const skills = useSelector(
    ({ firestore: { ordered } }) => ordered[`${params.id}-skills`]
  );
  const resources = useSelector(
    ({ firestore: { ordered } }) => ordered[`${params.id}-resources`]
  );

  const userPosts = React.useMemo(() => {
    if (isLoaded(posts, user)) {
      return posts.filter((data) => {
        const url = location.pathname
          .split("/")
          [location.pathname.split("/").length - 1].toLowerCase();
        if (url === "published") {
          return data.status === 0 && data.author.id === user.user.id;
        } else if (url === "pending") {
          return data.status === 2 && data.author.id === user.user.id;
        } else if (url === "rejected") {
          return data.status === 1 && data.author.id === user.user.id;
        } else {
          return data.status === 0 && data.author.id === user.user.id;
        }
      });
    }
  }, [posts, user, location]);

  const userDrafts = React.useMemo(() => {
    if (isLoaded(posts, user)) {
      return posts.filter((data) => {
        return data.status === 3 && data.author.id === user.user.id;
      });
    }
  }, [posts, user]);

  const userCollections = React.useMemo(() => {
    if (isLoaded(posts, resources, user)) {
      const url = location.pathname
        .split("/")
        [location.pathname.split("/").length - 1].toLowerCase();
      if (url === "posts") {
        return posts.filter((data) => {
          return (
            [...user.userSaves.posts]
              .map((s) => s)
              .join("")
              .indexOf(data.id) !== -1
          );
        });
      } else if (url === "resources") {
        return resources.filter((data) => {
          return (
            [...user.userSaves.resources]
              .map((s) => s)
              .join("")
              .indexOf(data.id) !== -1
          );
        });
      } else {
        return posts.filter((data) => {
          return (
            [...user.userSaves.posts]
              .map((s) => s)
              .join("")
              .indexOf(data.id) !== -1
          );
        });
      }
    }
  }, [posts, resources, user, location]);

  // const userResourceCollections = React.useMemo(() => {
  //   if (isLoaded(resources, user)) {
  //     return resources.filter((data) => {
  //       return (
  //         [...user.userSaves.resources]
  //           .map((s) => s)
  //           .join("")
  //           .indexOf(data.id) !== -1
  //       );
  //     });
  //   }
  // }, [posts, user]);

  const userSkills = React.useMemo(() => {
    if (isLoaded(skills, user)) {
      return skills.filter((data) => {
        const url = location.pathname
          .split("/")
          [location.pathname.split("/").length - 1].toLowerCase();
        if (url === "published") {
          return data.status === 0 && data.author.id === user.user.id;
        } else if (url === "pending") {
          return data.status === 2 && data.author.id === user.user.id;
        } else if (url === "rejected") {
          return data.status === 1 && data.author.id === user.user.id;
        } else {
          return data.status === 0 && data.author.id === user.user.id;
        }
      });
    }
  }, [skills, user, location]);

  const getCustomMonth = (date) => {
    const month = new Date(date.toDate());
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return months[month.getMonth()];
  };

  useEffect(() => {
    if (isLoaded(user)) {
      window.scrollTo(0, 0, "smooth");
    }
  }, [params.id, user]);

  useEffect(() => {
    const nav = document.querySelector(".post__filter");
    filterRef.current = M.Dropdown.init(nav, {
      coverTrigger: false,
      alignment: "right",
      constrainWidth: false,
    });

    const reCalculate = () => {
      filterRef.current.recalculateDimensions();
    };

    if (nav) {
      window.addEventListener("resize", reCalculate);
      return () => window.removeEventListener("resize", reCalculate);
    }
  });

  return (
    <>
      {!isLoaded(user) ? (
        <div
          style={{
            width: "100%",
            height: "calc(100vh - 120px)",
          }}
        >
          <Loader type={`cover`} />
        </div>
      ) : isEmpty(user) ? (
        <Error404
          posts={posts}
          text={"User not found"}
          code={401}
          unset={params.pid !== undefined}
        />
      ) : (
        <div className="community-forum" style={{ marginTop: "0" }}>
          <section
            className="section-container  -forum-hero"
            data-area="creator"
          >
            <div className="hero-area">
              <div className="hero-content">
                <div
                  className="hero-info"
                  data-area="creator-banner"
                  style={
                    user.user.banner !== undefined && user.user.banner !== ""
                      ? {
                          backgroundImage: `url(${user.user.banner})`,
                        }
                      : {}
                  }
                ></div>
                <div className="hero-info" data-area="creator-info">
                  <div className="hero-creator-title">
                    {user.user.username}
                    <span
                      className="creator-badge"
                      title="VOIS member badge"
                      style={{ zIndex: "1000" }}
                    >
                      <VerifiedInfo userId={user.user.id} />
                    </span>
                  </div>
                  {user.user.bio && (
                    <div className="hero-creator-bio">{user.user.bio}</div>
                  )}

                  <div className="hero-creator-created">
                    <i className="fa fa-calendar" aria-hidden="true"></i>
                    Joined {getCustomMonth(user.account.createdAt)}{" "}
                    {new Date(user.account.createdAt.toDate()).getFullYear()}
                    <NavLink
                      to={"/me/edit"}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                        zIndex: "1",
                        color: "var(--tx-main-light)",
                      }}
                    >
                      <UserEditIcon width={20} theme={`cl-icon-light`} />
                      Edit
                    </NavLink>
                  </div>
                </div>
                <div
                  className="hero-float"
                  // style={{
                  //   alignItems:
                  //     (user.user.banner === undefined ||
                  //       user.user.banner === "") &&
                  //     "flex-start",
                  //   top:
                  //     (user.user.banner === undefined ||
                  //       user.user.banner === "") &&
                  //     "50px",
                  // }}
                >
                  <div className="hero-float-img">
                    <img
                      src={user.user.photoURL}
                      alt="creator avatar"
                      loading="lazy"
                      draggable={false}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="hero-actions">
            <ul className="hero-tag-lists">
              <li className="tag-list">
                <NavLink
                  to={`/me`}
                  className={
                    (location.pathname === `/me` ||
                      location.pathname === `/me/` ||
                      location.pathname === `/me/posts` ||
                      location.pathname === `/me/posts/published` ||
                      location.pathname === `/me/posts/pending` ||
                      location.pathname === `/me/posts/rejected`) &&
                    `active`
                  }
                  end
                >
                  Posts
                </NavLink>
              </li>
              <li className="tag-list">
                <NavLink to={`/me/saves`} end>
                  Drafts
                </NavLink>
              </li>
              <li className="tag-list">
                <NavLink
                  to={`/me/skills`}
                  className={
                    (location.pathname === `/me/skills` ||
                      location.pathname === `/me/skills/` ||
                      location.pathname === `/me/skills/published` ||
                      location.pathname === `/me/skills/pending` ||
                      location.pathname === `/me/skills/rejected`) &&
                    `active`
                  }
                  end
                >
                  Skills
                </NavLink>
              </li>
              <li className="tag-list">
                <NavLink
                  to={`/me/collections`}
                  className={
                    (location.pathname === `/me/collections` ||
                      location.pathname === `/me/collections/` ||
                      location.pathname === `/me/collections/posts` ||
                      location.pathname === `/me/collections/resources`) &&
                    `active`
                  }
                  end
                >
                  Collections
                </NavLink>
              </li>
            </ul>
            <div className="hero-auth-area">
              {location.pathname === `/me` ||
              location.pathname === `/me/posts` ||
              location.pathname === `/me/posts/` ||
              location.pathname === `/me/posts/published` ||
              location.pathname === `/me/posts/pending` ||
              location.pathname === `/me/posts/rejected` ? (
                <>
                  <a
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      color: "var(--tx-main-light)",
                    }}
                    href="/me"
                    className="post__filter"
                    data-target="post-filter"
                  >
                    <FilterIcon width={15} theme={`cl-icon-light`} />
                    Filter
                  </a>
                  <ul
                    id="post-filter"
                    className="dropdown-content custom__dropdown"
                  >
                    <li className="custom__list">
                      <NavLink to="/me/posts/published">Published</NavLink>
                    </li>
                    <li className="custom__list">
                      <NavLink to="/me/posts/pending">Pending</NavLink>
                    </li>
                    <li className="custom__list">
                      <NavLink to="/me/posts/rejected">Rejected</NavLink>
                    </li>
                  </ul>
                </>
              ) : location.pathname === `/me/skills` ||
                location.pathname === `/me/skills/` ||
                location.pathname === `/me/skills/published` ||
                location.pathname === `/me/skills/pending` ||
                location.pathname === `/me/skills/rejected` ? (
                <>
                  <a
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      color: "var(--tx-main-light)",
                    }}
                    href="/me"
                    className="post__filter"
                    data-target="post-filter"
                  >
                    <FilterIcon width={15} theme={`cl-icon-light`} />
                    Filter
                  </a>
                  <ul
                    id="post-filter"
                    className="dropdown-content custom__dropdown"
                  >
                    <li className="custom__list">
                      <NavLink to="/me/skills/published">Published</NavLink>
                    </li>
                    <li className="custom__list">
                      <NavLink to="/me/skills/pending">Pending</NavLink>
                    </li>
                    <li className="custom__list">
                      <NavLink to="/me/skills/rejected">Rejected</NavLink>
                    </li>
                  </ul>
                </>
              ) : location.pathname === `/me/collections` ||
                location.pathname === `/me/collections/` ||
                location.pathname === `/me/collections/posts` ||
                location.pathname === `/me/collections/resources` ? (
                <>
                  <a
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      color: "var(--tx-main-light)",
                    }}
                    href="/me"
                    className="post__filter"
                    data-target="post-filter"
                  >
                    <FilterIcon width={15} theme={`cl-icon-light`} />
                    Filter
                  </a>
                  <ul
                    id="post-filter"
                    className="dropdown-content custom__dropdown"
                  >
                    <li className="custom__list">
                      <NavLink to="/me/collections/posts">Posts</NavLink>
                    </li>
                    <li className="custom__list">
                      <NavLink to="/me/collections/resources">
                        Resources
                      </NavLink>
                    </li>
                  </ul>
                </>
              ) : null}
            </div>
          </div>

          {(location.pathname === `/me` ||
            location.pathname === `/me/posts` ||
            location.pathname === `/me/posts/` ||
            location.pathname === `/me/posts/published` ||
            location.pathname === `/me/posts/pending` ||
            location.pathname === `/me/posts/rejected`) && (
            <PostProfile userPosts={userPosts} />
          )}

          {(location.pathname === `/me/collections` ||
            location.pathname === `/me/collections/` ||
            location.pathname === `/me/collections/posts` ||
            location.pathname === `/me/collections/resources`) && (
            <CollectionsProfile userPosts={userCollections} />
          )}

          {(location.pathname === `/me/saves` ||
            location.pathname === `/me/saves/`) && (
            <DraftsProfile userPosts={userDrafts} />
          )}

          {(location.pathname === `/me/skills` ||
            location.pathname === `/me/skills/` ||
            location.pathname === `/me/skills/published` ||
            location.pathname === `/me/skills/pending` ||
            location.pathname === `/me/skills/rejected`) && (
            <SkillProfile userSkills={userSkills} />
          )}
        </div>
      )}
    </>
  );
};

export default compose(
  withRouter,
  firestoreConnect((props) => {
    return [
      {
        collection: "posts",
        orderBy: ["createdAt", "desc"],
        storeAs: `${props.id}-posts`,
      },
      {
        collection: "skills",
        orderBy: ["createdAt", "desc"],
        storeAs: `${props.id}-skills`,
      },
      {
        collection: "resources",
        orderBy: ["createdAt", "desc"],
        storeAs: `${props.id}-resources`,
      },
    ];
  })
)(AccountProfile);

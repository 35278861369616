import React from "react";
import "../css/root.css";
import Header from "./components/Header";
import {
  AndroidIcon,
  AppleIcon,
  FBIcon,
  InstaIcon,
  LinkedInIcon,
  TubeIcon,
  XIcon,
} from "../assets/icons/Icons";
import CookieProtected from "../auth/components/CookieProtected";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import { useSelector } from "react-redux";

const HomeLayout = ({ children, auth }) => {
  const navAttr = { width: 25, theme: "cl-black" };

  const posts = useSelector(({ firestore: { ordered } }) => ordered.homeposts);
  const skills = useSelector(
    ({ firestore: { ordered } }) => ordered.homeskills
  );
  const events = useSelector(
    ({ firestore: { ordered } }) => ordered.homeevents
  );
  const resources = useSelector(
    ({ firestore: { ordered } }) => ordered.homeresources
  );

  const surveys = useSelector(
    ({ firestore: { ordered } }) => ordered.homesurveys
  );
  const tags = useSelector(({ firestore: { ordered } }) => ordered.postTags);

  const propChildren = React.Children.map(children, (c) =>
    React.cloneElement(c, {
      posts,
      skills,
      resources,
      tags,
      events,
      surveys,
    })
  );

  return (
    <>
      <Header />
      <div className="root-layout">
        <div className="main-layout">{propChildren}</div>
        <footer className="main-footer">
          <div className="footer-content">
            <div className="footer-row row">
              <div className="footer-col col s12 m6 l6 xl6">
                <div className="footer-copy">© 2023 VOIS Cyprus.</div>
                <ul className="foot-links">
                  <li>
                    <a href="/privacy-policy">Privacy Policy</a>
                  </li>
                  <li>
                    <a href="/terms-of-use">Terms of Use</a>
                  </li>
                  <li>
                    <a href="/community-guidelines">Community Guidelines</a>
                  </li>
                </ul>
              </div>
              <div className="footer-col col s12 m6 l6 xl6">
                <div className="footer-links">
                  <ul className="nav-socials">
                    <li>
                      <a
                        href="https://twitter.com/voiscyprus"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <XIcon {...navAttr} />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/company/voiscyprus/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <LinkedInIcon {...navAttr} />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.facebook.com/VOISCyprus/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FBIcon {...navAttr} />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/voiscyprus/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <InstaIcon {...navAttr} />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.youtube.com/VOIS%20Cyprus"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <TubeIcon {...navAttr} />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.youtube.com/VOIS%20Cyprus"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <AndroidIcon {...navAttr} />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.youtube.com/VOIS%20Cyprus"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <AppleIcon {...navAttr} />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default compose(
  CookieProtected,
  firestoreConnect(() => {
    return [
      {
        collection: "posts",
        orderBy: ["createdAt", "desc"],
        storeAs: "homeposts",
      },
      {
        collection: "skills",
        orderBy: ["createdAt", "desc"],
        storeAs: "homeskills",
      },
      {
        collection: "events",
        where: ["status", "==", 0],
        storeAs: "homeevents",
      },
      {
        collection: "surveys",
        where: ["status", "==", 0],
        storeAs: "homesurveys",
      },
      {
        collection: "resources",
        orderBy: ["createdAt", "desc"],
        storeAs: "homeresources",
      },
      { collection: "inits", doc: "tags", storeAs: "postTags" },
    ];
  })
)(HomeLayout);

import React, { useEffect } from "react";
import {
  CaretIcon,
  ComplaintIcon,
  ForumIcon,
  HouseIcon,
  LogOffIcon,
  ResourceIcon,
  ReviewHouseIcon,
  SettingsIcon,
  SkillIcon,
  SkillShareIcon,
  WriteIcon,
} from "../../assets/icons/Icons";
import M from "materialize-css";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { isLoaded } from "react-redux-firebase";

const AuthNavigations = ({ modalRef, view, auth, screen, size }) => {
  const user = useSelector((state) => state.firebase.profile.user);
  useEffect(() => {
    const nav = document.querySelector("#nav__avatar");
    modalRef.current = M.Dropdown.init(nav, {
      coverTrigger: false,
      alignment: "left",
      constrainWidth: false,
    });

    const reCalculate = () => {
      modalRef.current.recalculateDimensions();
    };

    if (nav) {
      window.addEventListener("resize", reCalculate);
      return () => {
        window.removeEventListener("resize", reCalculate);
        modalRef.current.destroy();
      };
    }
  }, [view, auth, screen.width, user]);

  if (isLoaded(user))
    return (
      <>
        <div
          id="nav__avatar"
          className="nav__avatar"
          data-target="nav-avatar"
          style={{
            gap: "5px",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <img
            src={user.photoURL}
            alt="nav profile"
            className="nav-avatar"
            draggable="false"
          />{" "}
          <CaretIcon width={20} />
        </div>
        <ul
          id="nav-avatar"
          className="dropdown-content custom__dropdown nav-drop"
        >
          <li className="custom__list">
            <div className="nav-profile">
              {user.userFullName !== "" && (
                <p className="nav-user">{user.username}</p>
              )}
              <p className="nav-mail">{user.email}</p>
              <a href="/me" className="nav-btn plain">
                View Profile
              </a>
            </div>
          </li>
          <li className="divider"></li>
          {/* <li className="custom__list">
          <NavLink to="/me/posts">My Posts</NavLink>
        </li>
        <li className="custom__list">
          <NavLink to="/me/skills">My Skills</NavLink>
        </li>
        <li className="custom__list">
          <NavLink to="/me/collections">Collections</NavLink>
        </li> */}
          {screen.width < size.md && (
            <>
              <li className="divider"></li>
              {/* actions */}
              <li className="custom__list">
                <NavLink to="/create">
                  <WriteIcon width={15} theme={`cl-icon-light`} />
                  Create Post
                </NavLink>
              </li>
              <li className="custom__list">
                <NavLink to="/create/skill">
                  <SkillShareIcon width={20} theme={`cl-icon-light`} />
                  Create Skill
                </NavLink>
              </li>
              <li className="custom__list">
                <NavLink to="/create/house">
                  <ReviewHouseIcon width={20} theme={`cl-icon-light`} />
                  Rate House
                </NavLink>
              </li>
              <li className="nav-link">
                <NavLink to="/submit-a-complaint" style={{ gap: "5px" }}>
                  <ComplaintIcon width={20} theme={`cl-icon-light`} />
                  Submit a complaint
                </NavLink>
              </li>
            </>
          )}

          <li className="divider"></li>
          {/* links */}
          <li className="custom__list">
            <NavLink to="/forum">
              <ForumIcon width={20} theme={`cl-icon-light`} />
              Forum
            </NavLink>
          </li>
          <li className="custom__list">
            <NavLink to="/skillshop">
              <SkillIcon width={20} theme={`cl-icon-light`} />
              Skillshop
            </NavLink>
          </li>
          <li className="custom__list">
            <NavLink to="/housing">
              <HouseIcon width={20} theme={`cl-icon-light`} />
              Housing
            </NavLink>
          </li>
          <li className="custom__list">
            <NavLink to="/resources">
              <ResourceIcon width={20} theme={`cl-icon-light`} />
              Resources
            </NavLink>
          </li>
          <li className="divider"></li>
          <>
            {/* <li className="custom__list">
            <NavLink to="/resources">
              <DisplayIcon width={20} theme={`cl-icon-light`} />
              Appearance
            </NavLink>
          </li> */}
            {/* <li className="divider"></li> */}
            <li className="custom__list">
              <NavLink to="/account/settings">
                <SettingsIcon width={20} theme={`cl-icon-light`} />
                Settings
              </NavLink>
            </li>
          </>
          <li className="custom__list">
            <NavLink to="/auth/signout">
              <LogOffIcon width={20} theme={`cl-icon-light`} />
              Sign out
            </NavLink>
          </li>
        </ul>
      </>
    );
};

export default AuthNavigations;

import React, { useState } from "react";
import { VoisLogo } from "../../assets/icons/Icons";
import Nav from "./Nav";
import PropTypes from "prop-types";
import GetScreenSizeHoc from "../../general/components/GetScreenSizeHoc";
import { NavLink, useNavigate } from "react-router-dom";
import SearchBar from "./SearchBar";

const Header = ({ type, view, size, screen }) => {
  const isNull = type !== undefined ? true : false;
  const isView = view !== "settings" ? true : false;
  const navigate = useNavigate();

  const [search, setSearch] = useState();
  const updateFormValue = (evt) => {
    setSearch(evt.target.value);
  };

  const submit = (evt) => {
    if (evt.key === "Enter") navigate(`/search?nav=forum&w=${search}`);
  };
  return (
    <header className={`main-header pd-lr ${isNull && type}`}>
      <div className={`header-area ${isNull && "container"}`}>
        <nav className="header-nav z-depth-0">
          <div className="logo-search">
            <div className="nav-logo">
              <NavLink to="/">
                <VoisLogo width={40} theme={`fl-icon-p`} />
              </NavLink>
            </div>
            {isView && (
              <SearchBar
                updateFormValue={updateFormValue}
                submit={submit}
                search={search}
              />
            )}
          </div>
          <Nav view={isView} size={size} screen={screen} />
        </nav>
      </div>
    </header>
  );
};

Header.propTypes = {
  type: PropTypes.string,
  view: PropTypes.string,
  size: PropTypes.object,
  screen: PropTypes.object,
};

export default GetScreenSizeHoc(Header);

import React from "react";
import ForumCardBox from "../../../general/components/ForumCardBox";
// import { storyLists } from "../../../assets/dummies/Static";
import { isLoaded } from "react-redux-firebase";
import moment from "moment";

const MoreFromAuthor = ({ authorId, posts, postId }) => {
  const authorPosts = React.useMemo(() => {
    if (isLoaded(posts)) {
      return posts.filter((data) => {
        return (
          [data.author.id, data.status]
            .join("")
            .toLowerCase()
            .indexOf(authorId.toLowerCase() || 0) !== -1
        );
      });
    }
  }, [posts, authorId]);

  return (
    <section className="blob-item divide" id="general__lists">
      <div className="blob-tag-area">
        <h4>More from Author</h4>
        <div className="section-row row">
          {authorPosts
            .filter((p) => p.status === 0 && p.id !== postId)
            .slice(0, 4)
            .map((s) => {
              return (
                <div
                  className="section-col section-card col s12 m6 l6 xl6"
                  key={s.id}
                >
                  <ForumCardBox
                    cardId={s.id}
                    cardBanner={s.properties.banner}
                    cardTag={s.properties.tags[0]}
                    cardTitle={s.properties.title}
                    cardAvatar={s.author.photoURL}
                    cardUserName={s.author.username}
                    cardDate={moment(s.createdAt.toDate()).fromNow()}
                  />
                </div>
              );
            })}
        </div>
      </div>
    </section>
  );
};

export default MoreFromAuthor;

import React from "react";
import "../../../css/blob-content.css";
import { CancelIcon, EventIcon } from "../../../assets/icons/Icons";
import { useNavigate } from "react-router-dom";

const HomeEventFixed = ({ id, title, handleEventCancel }) => {
  const navigate = useNavigate();
  return (
    <div className="blob-fixed bottom">
      <div className="blob-inner">
        <div className="blob-text">
          <EventIcon width={30} />
          <h4>{title}</h4>
        </div>
        <div className="blob-action">
          <button
            onClick={() => navigate(`/events/${id.slice(10).toLowerCase()}`)}
          >
            View
          </button>
        </div>
        <button className="r-float" onClick={handleEventCancel}>
          <CancelIcon width={15} theme={`cl-icon-p`} />
        </button>
      </div>
    </div>
  );
};

export default HomeEventFixed;

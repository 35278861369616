import React, { useEffect } from "react";
import {
  BarIcon,
  CaretIcon,
  ComplaintIcon,
  ForumIcon,
  HouseIcon,
  ResourceIcon,
  SignInIcon,
  SkillIcon,
} from "../../assets/icons/Icons";
import M from "materialize-css";
import { NavLink } from "react-router-dom";

const NoAuthNavigations = ({ modalRef, view, auth, screen }) => {
  useEffect(() => {
    const nav = document.querySelector("#nav__bar");
    modalRef.current = M.Dropdown.init(nav, {
      coverTrigger: false,
      alignment: "left",
      constrainWidth: false,
      closeOnClick: true,
    });

    const reCalculate = () => {
      modalRef.current.recalculateDimensions();
    };

    if (nav) {
      window.addEventListener("resize", reCalculate);
      return () => {
        window.removeEventListener("resize", reCalculate);
        modalRef.current.destroy();
      };
    }
  }, [view, auth, screen.width]);

  return (
    <>
      <div
        id="nav__bar"
        className="nav__bar"
        data-target="nav-bar"
        style={{
          gap: "5px",
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <BarIcon width={20} theme={`cl-icon-light`} />
        <CaretIcon width={20} />
      </div>
      <ul id="nav-bar" className="dropdown-content custom__dropdown nav-drop">
        <li className="custom__list">
          <div className="nav-profile">
            <a
              href="/auth/signup"
              className="nav-btn plain"
              style={{
                marginTop: "0",
                backgroundColor: "var(--cl-primary)",
                color: "var(--tx-white)",
              }}
            >
              Create account
            </a>
          </div>
        </li>
        <li className="divider"></li>
        {/* links */}
        <li className="custom__list">
          <NavLink to="/forum">
            <ForumIcon width={20} theme={`cl-icon-light`} />
            Forum
          </NavLink>
        </li>
        <li className="custom__list">
          <NavLink to="/skillshop">
            <SkillIcon width={20} theme={`cl-icon-light`} />
            Skillshop
          </NavLink>
        </li>
        <li className="custom__list">
          <NavLink to="/housing">
            <HouseIcon width={20} theme={`cl-icon-light`} />
            Housing
          </NavLink>
        </li>
        <li className="custom__list">
          <NavLink to="/resources">
            <ResourceIcon width={20} theme={`cl-icon-light`} />
            Resources
          </NavLink>
        </li>
        <li className="divider"></li>
        <li className="custom__list">
          <NavLink to="/submit-a-complaint">
            <ComplaintIcon width={20} theme={`cl-icon-light`} />
            Submit a complaint
          </NavLink>
        </li>
       <li className="divider"></li>
        <li className="custom__list">
          <NavLink to="/auth/signin">
            <SignInIcon width={20} theme={`cl-icon-light`} />
            Sign in
          </NavLink>
        </li>
      </ul>
    </>
  );
};

export default NoAuthNavigations;

import React from "react";
import { DeactivateIcon, ForwardIcon } from "../../../assets/icons/Icons";
import formStyles from "../../../css/forms.module.css";
import authStyles from "../../../css/auth.module.css";

const DeactivateSection = ({
  locUser,
  updateFormValue,
  handleDisableAccount,
  isCrudLoading,
}) => {
  return (
    <li className="collapsible-item">
      <div className="collapsible-header">
        <DeactivateIcon width={20} theme={`cl-icon-light`} />
        <p className="tx-warning">
          Deactivate account
          <span>
            Deactivating will suspend your account until you sign back in.
          </span>
        </p>
        <div className="caret-area">
          <ForwardIcon width={20} theme={`cl-icon-light`} />
        </div>
      </div>
      <div className="collapsible-body">
        <div
          className={`${authStyles["auth-main-items"]}`}
          style={{ maxWidth: "unset" }}
        >
          <div
            className={`${authStyles["auth-main-content"]} ${formStyles["form-pd"]}`}
          >
            <div className={`${formStyles["form-control"]}`}>
              <div className={`${formStyles["text-area"]}`}>
                Deactivating your account will remove it from VOIS within a few
                minutes. Deactivation will also immediately cancel any
                subscription for VOIS newsletters. You can sign back in anytime
                to reactivate your account and restore its content and
                privileges.
              </div>
              <div className={`${formStyles["form-group"]}`}>
                <label htmlFor="disable" style={{ textTransform: "lowercase" }}>
                  To confirm, type “disable” below:
                </label>
                <input
                  type="text"
                  id="disable"
                  name="disable"
                  defaultValue={locUser.disable}
                  autoComplete="off"
                  className="browser-default"
                  onChange={updateFormValue}
                />
              </div>
              <div
                className={`${formStyles["form-group"]}`}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <button
                  className={`${formStyles["form-btn"]} browser-default`}
                  id={`${formStyles["submit__main"]}`}
                  type="submit"
                  style={{ width: "fit-content" }}
                  disabled={locUser.disable !== "disable" || isCrudLoading}
                  onClick={handleDisableAccount}
                >
                  Deactivate
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};

export default DeactivateSection;

import React, { useEffect, useRef } from "react";
import PortalWrapper from "./portal/PortalWrapper";
import M from "materialize-css";
import { CancelIcon } from "../assets/icons/Icons";

const DeleteModal = ({ view, setView, text, callback }) => {
  const modalRef = useRef(null);
  useEffect(() => {
    if (view) {
      const elem = document.querySelector("#report__modal");
      modalRef.current = M.Modal.init(elem, {
        dismissible: false,
      });

      modalRef.current.open();
      return () => modalRef.current.close();
    }
  }, [view]);
  return (
    <PortalWrapper>
      <div className="modal modal__box" id="report__modal">
        <div className="modal-header">
          <button className="btn-modal plain" onClick={() => setView(false)}>
            <CancelIcon width={25} theme={`cl-icon-light`} />
          </button>
        </div>
        <div className="modal-content">
          <h4>Delete {text}</h4>
          <p>
            Deletion is irreversible, hence this {text.toLowerCase()} will be
            completely deleted. Are you sure you want to proceed?
          </p>
        </div>
        <div className="modal-footer">
          <div className="modal-actions">
            <button
              className="a-btn-modal b-only"
              onClick={() => setView(false)}
            >
              Cancel
            </button>
            <button onClick={callback} className="a-btn-modal bg-only danger">
              Delete
            </button>
          </div>
        </div>
      </div>
    </PortalWrapper>
  );
};

export default DeleteModal;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BLANK__IMAGE } from "../../config/globals";
import { deleteUserById } from "../crud/CrudSlice";

const initialState = {
  authError: null,
  resetStatus: null,
  isAuthLoading: false,
};

export const signInWithCredentials = createAsyncThunk(
  "auth/signInWithCredentials",
  async (data, { dispatch, extra, rejectWithValue }) => {
    const { getFirebase } = extra;
    const firebase = getFirebase();
    try {
      return firebase
        .auth()
        .signInWithEmailAndPassword(data.email, data.password)
        .then((data) => {
          dispatch(SET__AUTH__ERROR(null));
        })
        .catch((err) => {
          let errMessage = "";
          // console.log(err.code);
          switch (err.code) {
            case "auth/wrong-password":
              errMessage = { password: "You have entered an invalid password" };
              dispatch(SET__AUTH__ERROR(errMessage));
              break;
            case "auth/user-not-found":
              errMessage = { email: "User record not found" };
              dispatch(SET__AUTH__ERROR(errMessage));
              break;
            default:
              errMessage = "Something went wrong. Try again later";
              dispatch(SET__AUTH__ERROR(errMessage));
          }
        });
    } catch (error) {
      dispatch(SET__AUTH__ERROR(error.message));
      rejectWithValue(error.message);
    }
  }
);

export const signUpWithCredentials = createAsyncThunk(
  "auth/signUpWithCredentials",
  async (data, { dispatch, extra, rejectWithValue }) => {
    const { getFirebase } = extra;
    const firebase = getFirebase();
    // console.log(data.email, data.password);
    try {
      return firebase
        .auth()
        .createUserWithEmailAndPassword(data.email, data.password)
        .then((resp) => {
          dispatch(createUserWithCredentials(resp));
        })
        .catch((err) => {
          let errMessage = "";
          // console.log(err.code);
          switch (err.code) {
            case "auth/email-already-in-use":
              errMessage = {
                email: "The email address is already in use by another account",
              };
              dispatch(SET__AUTH__ERROR(errMessage));
              break;
            default:
              errMessage = "Something went wrong. Try again later";
              dispatch(SET__AUTH__ERROR(errMessage));
          }
        });
    } catch (err) {
      // console.log(err);
      dispatch(SET__AUTH__ERROR(err));
      rejectWithValue(err);
    }
  }
);

export const createUserWithCredentials = createAsyncThunk(
  "auth/createUserWithCredentials",
  async (data, { dispatch, extra, rejectWithValue }) => {
    const { getFirebase, getFirestore } = extra;
    const firebase = getFirebase();
    const firestore = getFirestore();
    // console.log(data);
    try {
      return firestore
        .collection("users")
        .doc(data.user.uid)
        .set({
          user: {
            id: data.user.uid,
            email: data.user.email,
            badgeType: "",
            photoURL: BLANK__IMAGE,
            banner: "",
            fullName: "",
            username: "",
            bio: "",
            isAdmin: 0,
          },
          account: {
            isRegCompleted: false,
            isSuspended: false,
            isAutoApprove: false,
            isDisabled: false,
            notifications: false,
            isMailSubscription: true,
            createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
          },
          socials: {
            portfolio: "",
            whatsapp: "",
            facebook: "",
            twitter: "",
            linkedIn: "",
            instagram: "",
          },
          userSaves: {
            posts: [],
            skills: [],
            resources: [],
          },
          userLikes: {
            posts: [],
            skills: [],
            resources: [],
          },
        });
    } catch (err) {
      let errMessage = "";
      errMessage = "Something went wrong. Try again later";
      dispatch(SET__AUTH__ERROR(errMessage));
      rejectWithValue(errMessage);
    }
  }
);

export const isUsernameAvailable = createAsyncThunk(
  "auth/isUsernameAvailable",
  async (data, { dispatch, extra }) => {
    const { getFirestore } = extra;
    const firestore = getFirestore();
    try {
      return firestore
        .collection("users")
        .where("user.username", "==", data.username)
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.docs.length === 0) {
            dispatch(completeRegistration(data));
          } else {
            let errMessage = { username: "Username is not available" };
            dispatch(SET__AUTH__ERROR(errMessage));
          }
        })
        .catch((err) => {
          dispatch(SET__AUTH__ERROR({ err }));
        });
    } catch (err) {
      dispatch(SET__AUTH__ERROR(err));
    }
  }
);

export const completeRegistration = createAsyncThunk(
  "auth/completeRegistration",
  async (data, { dispatch, extra, getState }) => {
    const { getFirestore } = extra;
    const firestore = getFirestore();
    const { user } = getState().firebase.profile;

    try {
      const updateRef = firestore.collection("users").doc(user.id);
      return updateRef
        .update({
          "user.username": data.username,
          "user.fullName": data.fullName,
          "account.isRegCompleted": true,
        })
        .then(() => {
          dispatch(SET__AUTH__ERROR(null));
        })
        .catch((err) => {
          dispatch(SET__AUTH__ERROR(err));
        });
    } catch (error) {
      dispatch(SET__AUTH__ERROR(error));
    }
  }
);

export const signOut = createAsyncThunk(
  "auth/signOut",
  async (data, { dispatch, extra }) => {
    const { getFirebase } = extra;
    const firebase = getFirebase();

    return firebase.auth().signOut();
  }
);

export const signOutAndDelete = createAsyncThunk(
  "auth/signOut",
  async (id, { dispatch, extra }) => {
    const { getFirebase } = extra;
    const firebase = getFirebase();

    return firebase
      .auth()
      .signOut()
      .then(() => {
        return dispatch(deleteUserById(id));
      });
  }
);

export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async (data, { dispatch, extra }) => {
    const { getFirebase } = extra;
    const firebase = getFirebase();
    // console.log(data)
    try {
      return firebase
        .auth()
        .sendPasswordResetEmail(data.email)
        .then(() => {
          dispatch(SET__RESET__STATUS("fulfilled"));
        })
        .catch((err) => {
          let errMessage = "";
          // console.log(err.code);
          switch (err.code) {
            case "auth/user-not-found":
              errMessage = {
                email: "Sorry, we didn't recognize that email.",
              };
              dispatch(SET__RESET__STATUS(errMessage));
              break;
            default:
              errMessage = "Something went wrong. Try again later";
              dispatch(SET__RESET__STATUS(errMessage));
          }
        });
    } catch (error) {
      dispatch(SET__RESET__STATUS(error));
    }
  }
);

export const verifyResetCode = createAsyncThunk(
  "auth/verifyResetCode",
  async (actionCode, { dispatch, extra }) => {
    const { getFirebase } = extra;
    const firebase = getFirebase();
    // console.log(actionCode)

    try {
      return firebase
        .auth()
        .verifyPasswordResetCode(actionCode)
        .then((email) => {
          // console.log(email)
          dispatch(SET__RESET__STATUS("code__confirmed"));
        })
        .catch((err) => {
          switch (err.code) {
            case "auth/invalid-action-code":
              dispatch(SET__RESET__STATUS("code__invalid"));
              break;
            default:
              dispatch(SET__RESET__STATUS(err.code));
          }
        });
    } catch (error) {
      dispatch(SET__RESET__STATUS(error));
    }
  }
);

export const resetPasswordComplete = createAsyncThunk(
  "auth/resetPasswordComplete",
  async (data, { dispatch, extra }) => {
    const { getFirebase } = extra;
    const firebase = getFirebase();
    // console.log(data);
    try {
      return firebase
        .auth()
        .confirmPasswordReset(data.oobCode, data.password)
        .then((resp) => {
          dispatch(SET__RESET__STATUS("reset__confirmed"));
        })
        .catch((err) => {
          dispatch(SET__RESET__STATUS("Something went wrong. Try again later"));
        });
    } catch (error) {
      dispatch(SET__RESET__STATUS("Something went wrong. Try again later"));
    }
  }
);

export const handleAuthWithGoogle = createAsyncThunk(
  "auth/handleAuthWithGoogle",
  async (response, { dispatch, extra }) => {
    const { getFirebase, getFirestore } = extra;
    const firebase = getFirebase();
    const firestore = getFirestore();

    const idToken = response.credential;
    const credential = firebase.auth.GoogleAuthProvider.credential(idToken);

    firebase
      .auth()
      .signInWithCredential(credential)
      .then((result) => {
        // console.log(result)
        const docRef = firestore.collection("users").doc(result.user.uid);
        docRef.get().then((doc) => {
          if (!doc.exists) {
            return firestore
              .collection("users")
              .doc(result.user.uid)
              .set({
                user: {
                  id: result.user.uid,
                  email: result.user.email,
                  badgeType: "",
                  banner: "",
                  photoURL: result.user.photoURL,
                  fullName: result.user.displayName,
                  username: "",
                  bio: "",
                  isAdmin: 0,
                },
                account: {
                  isRegCompleted: false,
                  isSuspended: false,
                  isAutoApprove: false,
                  isDisabled: false,
                  notifications: false,
                  isMailSubscription: true,
                  createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
                },
                socials: {
                  portfolio: "",
                  whatsapp: "",
                  facebook: "",
                  twitter: "",
                  linkedIn: "",
                  instagram: "",
                },
                userSaves: {
                  posts: [],
                  skills: [],
                  resources: [],
                },
                userLikes: {
                  posts: [],
                  skills: [],
                  resources: [],
                },
              });
          }
        });
      })
      .then(() => {
        dispatch(SET__AUTH__ERROR(null));
      })
      .catch((error) => {
        // Handle Errors here.
        // console.log(error.code);
        const errorCode = error.code;
        const email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        // const credential = error.credential;
        if (errorCode === "auth/account-exists-with-different-credential") {
          firebase
            .auth()
            .fetchSignInMethodsForEmail(email)
            .then((providers) => {
              // console.log(providers)
              // console.log(credential)
              if (providers[0] === "facebook.com") {
                const credential =
                  firebase.auth.FacebookAuthProvider.credential(
                    firestore.profile.token.token
                  );
                firebase
                  .auth()
                  .signInWithCredential(credential)
                  .then((result) => {
                    result.user.linkWithCredential(error.credential);
                  });
              }
            })
            .catch((error) => console.log(error));
        } else {
          console.error(error);
        }
      });
  }
);

export const fbAuthHandlerSignIn = createAsyncThunk(
  "auth/fbAuthHandlerSignIn",
  async (response, { dispatch, extra }) => {
    const { getFirebase, getFirestore } = extra;
    const firebase = getFirebase();
    const firestore = getFirestore();

    const isUserEqual = (facebookAuthResponse, firebaseUser) => {
      if (firebaseUser) {
        var providerData = firebaseUser.providerData;
        for (var i = 0; i < providerData.length; i++) {
          if (
            providerData[i].providerId ===
              firebase.auth.FacebookAuthProvider.PROVIDER_ID &&
            providerData[i].uid === facebookAuthResponse.userID
          ) {
            // We don't need to re-auth the Firebase connection.
            return true;
          }
        }
      }
      return false;
    };

    if (response.authResponse) {
      // User is signed-in Facebook.
      var unsubscribe = firebase.auth().onAuthStateChanged((firebaseUser) => {
        unsubscribe();
        // Check if we are already signed-in Firebase with the correct user.
        if (!isUserEqual(response.authResponse, firebaseUser)) {
          // Build Firebase credential with the Facebook auth token.
          const credential = firebase.auth.FacebookAuthProvider.credential(
            response.authResponse.accessToken
          );

          // Sign in with the credential from the Facebook user.
          firebase
            .auth()
            .signInWithCredential(credential)
            .then((result) => {
              console.log(result);
              const docRef = firestore.collection("users").doc(result.user.uid);
              docRef.get().then((doc) => {
                if (!doc.exists) {
                  return firestore
                    .collection("users")
                    .doc(result.user.uid)
                    .set({
                      user: {
                        id: result.user.uid,
                        email: result.user.email,
                        badgeType: "",
                        banner: "",
                        photoURL: result.user.photoURL,
                        fullName: result.user.displayName,
                        username: "",
                        bio: "",
                        isAdmin: 0,
                      },
                      account: {
                        isRegCompleted: false,
                        isSuspended: false,
                        isAutoApprove: false,
                        isDisabled: false,
                        notifications: false,
                        isMailSubscription: true,
                        createdAt: firebase.firestore.Timestamp.fromDate(
                          new Date()
                        ),
                      },
                      socials: {
                        portfolio: "",
                        whatsapp: "",
                        facebook: "",
                        twitter: "",
                        linkedIn: "",
                        instagram: "",
                      },
                      userSaves: {
                        posts: [],
                        skills: [],
                        resources: [],
                      },
                      userLikes: {
                        posts: [],
                        skills: [],
                        resources: [],
                      },
                    });
                }
              });
            })
            .then(() => {
              dispatch(SET__AUTH__ERROR(null));
            })
            .catch((error) => {
              // Handle Errors here.
              const errorCode = error.code;
              const email = error.email;
              if (
                errorCode === "auth/account-exists-with-different-credential"
              ) {
                firebase
                  .auth()
                  .fetchSignInMethodsForEmail(email)
                  .then((providers) => {
                    if (providers[0] === "google.com") {
                      const credential =
                        firebase.auth.GoogleAuthProvider.credential(
                          firestore.profile.token.token
                        );
                      firebase
                        .auth()
                        .signInWithCredential(credential)
                        .then((result) => {
                          result.user.linkWithCredential(error.credential);
                        });
                    }
                  })
                  .catch((error) => console.log(error));
              } else {
                console.error(error);
              }
            });
        } else {
          // User is already signed-in Firebase with the correct user.
        }
      });
    }
    //   else {
    //     // User is signed-out of Facebook.
    //     firebase.auth().signOut();
    //   }
  }
);

const AuthSlice = createSlice({
  name: "Auth",
  initialState,
  reducers: {
    SET__AUTH__ERROR: (state, { payload }) => {
      state.authError = payload;
    },
    RESET__AUTH__ERROR: (state) => {
      state.authError = null;
    },
    SET__RESET__STATUS: (state, { payload }) => {
      state.resetStatus = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signInWithCredentials.pending, (state) => {
        state.isAuthLoading = true;
      })
      .addCase(signInWithCredentials.fulfilled, (state) => {
        state.isAuthLoading = false;
      })
      .addCase(signUpWithCredentials.pending, (state) => {
        state.isAuthLoading = true;
      })
      .addCase(signUpWithCredentials.fulfilled, (state) => {
        state.isAuthLoading = false;
      })
      .addCase(resetPassword.pending, (state) => {
        state.isAuthLoading = true;
      })
      .addCase(resetPassword.fulfilled, (state) => {
        state.isAuthLoading = false;
      })
      .addCase(resetPasswordComplete.pending, (state) => {
        state.isAuthLoading = true;
      })
      .addCase(resetPasswordComplete.fulfilled, (state) => {
        state.isAuthLoading = false;
      });
  },
});
export const { SET__AUTH__ERROR, RESET__AUTH__ERROR, SET__RESET__STATUS } =
  AuthSlice.actions;
export default AuthSlice.reducer;

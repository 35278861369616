import React from "react";
import ReactDOM from "react-dom";

const PortalWrapper = ({ children }) => {
  const modalRef = document.getElementById("modal__area");
  return ReactDOM.createPortal(
    <div className="modal__wrapper">{children}</div>,
    modalRef
  );
};

export default PortalWrapper;

import React, { useEffect, useState } from "react";
import HouseReviews from "./HouseReviews";
import {
  ShieldIcon,
  SortDownIcon,
  SortUpIcon,
} from "../../../assets/icons/Icons";
import { houseOptions } from "../../../assets/dummies/Static";
import { isLoaded } from "react-redux-firebase";
import { useSelector } from "react-redux";

const HouseReviewWrapper = ({
  subView,
  setSubView,
  auth,
  navigate,
  reviews,
  reviewState,
  handleReviewSubmit,
  updateReviewValue,
  houseId,
  houseData,
  setHouseData,
  mainView,
  setMainView,
  updateFormValue,
  maxLimit,
  maxDispLimit,
  indexDisp,
  handleLoadMore,
  handleReviewDelete,
  handleReviewReport,
}) => {
  const { isCrudLoading } = useSelector((state) => state.crud);
  const [locReviews, setLocReviews] = useState([]);
  useEffect(() => {
    if (isLoaded(reviews)) {
      const sortFilter = (a, b) =>
        new Date(b.createdAt.toDate()) - new Date(a.createdAt.toDate());
      setLocReviews([...reviews].sort(sortFilter));
    }
  }, [reviews]);

  const handleFilter = (e, type) => {
    const sort = document.querySelectorAll(".sort");
    sort.forEach((el) => {
      el.classList.remove("active");
    });

    switch (type) {
      case "up":
        setLocReviews(reviews);
        break;
      case "down":
        const sortFilter = (a, b) =>
          new Date(a.createdAt.toDate()) - new Date(b.createdAt.toDate());
        setLocReviews([...locReviews].sort(sortFilter));
        break;
      default:
        setLocReviews(reviews);
        break;
    }
    e.currentTarget.classList.add("active");
  };
  if (isLoaded(reviews))
    return (
      <section className="blob-item divide">
        <div className="blob-tag-area">
          <div className="blog-mult">
            <h4>
              Reviews
              <span className="count">({reviews.length})</span>
            </h4>
            <a href="/">
              <ShieldIcon width={20} theme={`cl-icon-light`} />
            </a>
          </div>
          <div className="blob-comment-area">
            {!auth.uid ? (
              <button
                className="blob-button"
                onClick={() => {
                  navigate(`/auth/signin?nav=${window.location.pathname}`);
                }}
              >
                Sign in to review
              </button>
            ) : (
              <button className="blob-button" onClick={() => setSubView(true)}>
                Rate this apartment
              </button>
            )}
            {subView && (
              <div className="form-area fx-fade">
                <h3 className="form-title sm">Rating categories</h3>
                {houseOptions.map((option, ind) => (
                  <div
                    className="col s12 m6 l7"
                    style={{ marginBottom: "1rem" }}
                    key={ind}
                  >
                    <div className="blob-rate__type">{option.name}</div>
                    <div className="blob-rate">
                      {[5, 4, 3, 2, 1].map((n, index) => (
                        <React.Fragment key={`${option.id}${index}`}>
                          <input
                            type="radio"
                            id={`${option.id}${n}`}
                            name={option.id}
                            value={n}
                            onChange={updateReviewValue}
                          />
                          <label
                            htmlFor={`${option.id}${n}`}
                            title={`${n} star`}
                          >
                            {n} stars
                          </label>
                        </React.Fragment>
                      ))}
                    </div>
                  </div>
                ))}
                <div className="form-group">
                  <label htmlFor="comment"></label>
                  <textarea
                    name="comment"
                    placeholder="Additional comments"
                    id="comment"
                    value={reviewState.comment}
                    onChange={updateReviewValue}
                    style={{ resize: "vertical" }}
                    maxLength={300}
                  ></textarea>
                </div>

                <div className="form-btn">
                  <button
                    className="blob-button"
                    style={{
                      backgroundColor: "transparent",
                      color: "var(--tx-main-light)",
                    }}
                    onClick={() => setSubView(false)}
                  >
                    cancel
                  </button>
                  <button
                    className="blob-button"
                    disabled={isCrudLoading}
                    onClick={handleReviewSubmit}
                  >
                    Review
                  </button>
                </div>
              </div>
            )}

            {reviews.length > 0 && (
              <div className="blob-sort">
                <p>sort by</p>
                <button
                  className="comment-action sort"
                  onClick={(e) => handleFilter(e, "up")}
                >
                  <SortUpIcon width={20} theme={`cl-icon-light`} />
                  Most recent
                </button>
                <button
                  className="comment-action sort"
                  onClick={(e) => handleFilter(e, "down")}
                >
                  <SortDownIcon width={20} theme={`cl-icon-light`} />
                  Older
                </button>
              </div>
            )}
            <HouseReviews
              reviews={locReviews}
              auth={auth}
              handleReviewDelete={handleReviewDelete}
              handleReviewReport={handleReviewReport}
              houseId={houseId}
              houseData={houseData}
              setHouseData={setHouseData}
              mainView={mainView}
              setMainView={setMainView}
              updateFormValue={updateFormValue}
              maxLimit={maxLimit}
              maxDispLimit={maxDispLimit}
              indexDisp={indexDisp}
              handleLoadMore={handleLoadMore}
            />
          </div>
        </div>
      </section>
    );
};

export default HouseReviewWrapper;

export const BLANK__IMAGE =
  "https://firebasestorage.googleapis.com/v0/b/voisapp-5deb7.appspot.com/o/misc%2Fblank_avatar.png?alt=media&token=9de5e1ae-b715-4f58-aef9-77db882fc8cd";
export const ILLUSTRATION__1 =
  "https://firebasestorage.googleapis.com/v0/b/voisapp-5deb7.appspot.com/o/misc%2Fillustration1.png?alt=media&token=7f8ed78a-deed-45c7-894c-87659173507f";
export const ILLUSTRATION__2 =
  "https://firebasestorage.googleapis.com/v0/b/voisapp-5deb7.appspot.com/o/misc%2Fillustration2.png?alt=media&token=a986b855-a04d-4b7b-a5d9-e514824304e3";
export const ILLUSTRATION__3 =
  "https://firebasestorage.googleapis.com/v0/b/voisapp-5deb7.appspot.com/o/misc%2Fvc1.png?alt=media&token=772a00ae-3206-43d6-ad3c-456eebca78a8";
export const VOLUNTEER__BADGE =
  "https://firebasestorage.googleapis.com/v0/b/voisapp-5deb7.appspot.com/o/misc%2Fblue.png?alt=media&token=0809199d-5d76-46ad-9d46-52952962df88";
export const ROYALTY__BADGE =
  "https://firebasestorage.googleapis.com/v0/b/voisapp-5deb7.appspot.com/o/misc%2Froyal.png?alt=media&token=0853cb66-a01b-43c6-bbe7-67f9917953d7";
export const DEV__BADGE =
  "https://firebasestorage.googleapis.com/v0/b/voisapp-5deb7.appspot.com/o/misc%2Ftick.png?alt=media&token=7e6863c7-0eb3-43c5-bd30-0f47d6cdb20f";
export const PLACE__HOLDER =
  "https://firebasestorage.googleapis.com/v0/b/voisapp-5deb7.appspot.com/o/misc%2Fplaceholder.jpg?alt=media&token=a1d6a29a-7f54-4cff-8303-29c6aec175fd";

export const POST__COUNT = 500;
export const POST__LENGTH = 3160;
export const SERVICES__COUNT = 200;
export const SERVICES__LENGTH = 1100;
export const GOOGLE__CLIENT__ID =
  "379924387036-d00deh2nb4e07knu174anlo291h4vghj.apps.googleusercontent.com";

import React, { useEffect, useState } from "react";
import "../../css/community-content.css";
import formStyles from "../../css/forms.module.css";
import authStyles from "../../css/auth.module.css";
import { isEmpty, isLoaded } from "react-redux-firebase";
import Loader from "../../general/components/Loader";
import { useDispatch, useSelector } from "react-redux";
import { DeleteIcon } from "../../assets/icons/Icons";
import imageCompression from "browser-image-compression";
import { BLANK__IMAGE } from "../../config/globals";
import {
  SET__CRUD__ERROR,
  updateUserName,
  updateUserProfile,
} from "../../stores/crud/CrudSlice";
import DisplayValidationMessages from "../../auth/components/DisplayValidationMessages";
import AbortableToast from "../../general/components/AbortableToast";
import Error404 from "../Error404";
import { useOutletContext, useParams } from "react-router";

const EditProfile = () => {
  const user = useSelector(({ firebase: { profile } }) => profile);
  const { posts } = useOutletContext();
  const params = useParams();
  const { crudError, isCrudLoading } = useSelector((state) => state.crud);
  const dispatch = useDispatch();
  const [userInfo, setUserInfo] = useState({
    banner: "",
    avatar: "",
    name: "",
    username: "",
    bio: "",
  });

  useEffect(() => {
    if (isLoaded(user)) {
      setUserInfo((state) => ({
        ...state,
        name: user.user.fullName,
        username: user.user.username,
        bio: user.user.bio,
        avatar: user.user.photoURL,
        banner: user.user.banner,
      }));
    }
  }, [user]);

  const isFormSubmittable = () => {
    return (
      userInfo.name === user.user.fullName &&
      userInfo.username === user.user.username &&
      userInfo.bio === user.user.bio &&
      userInfo.avatar === user.user.photoURL &&
      userInfo.banner === ""
    );
  };

  const updateFormValue = (evt) => {
    setUserInfo((state) => ({ ...state, [evt.target.name]: evt.target.value }));
  };

  // compressed image
  const compressImageFile = async (file) => {
    const options = {
      maxWidthOrHeight: 800,
      useWebWorker: true,
      fileType: "image/webp",
    };

    const compressedFile = await imageCompression(file, options);
    return compressedFile;
  };

  //image preview for upload
  const handleCoverPreview = (evt) => {
    const [file] = document.getElementById("user-img").files;
    const compressedFile = compressImageFile(file);
    compressedFile.then((cf) => {
      if (cf) {
        setUserInfo((state) => ({ ...state, avatar: cf }));
      }
    });

    if (file) {
      document.getElementById("avatar-img").src = URL.createObjectURL(file);
    }
  };

  const handleCoverRemove = () => {
    setUserInfo((state) => ({ ...state, avatar: BLANK__IMAGE }));
    document.getElementById("avatar-img").src = BLANK__IMAGE;
  };

  //image preview for upload
  const handleBannerPreview = (evt) => {
    const [file] = document.getElementById("banner-img").files;
    const compressedFile = compressImageFile(file);
    compressedFile.then((cf) => {
      if (cf) {
        setUserInfo((state) => ({ ...state, banner: cf }));
      }
    });

    if (file) {
      document.getElementById(
        "hero-info"
      ).style.backgroundImage = `url(${URL.createObjectURL(file)})`;
    }
  };

  const handleBannerRemove = () => {
    setUserInfo((state) => ({ ...state, banner: "" }));
    document.getElementById("hero-info").style.backgroundImage = "unset";
  };

  const handleUpdate = () => {
    const data = {
      name: userInfo.name,
      username: userInfo.username,
      bio: userInfo.bio,
      avatar: userInfo.avatar,
      banner: userInfo.banner,
    };

    // console.log(data);

    if (userInfo.username === user.user.username) {
      dispatch(updateUserProfile(data))
        .then((user) => {
          dispatch(SET__CRUD__ERROR(null));
          setTimeout(() => {
            window.location.href = "/me";
          }, 500);
        })
        .catch((err) => {
          AbortableToast("Error updating profile. Try again later");
        });
    } else {
      dispatch(updateUserName(data)).then((res) => {
        if (res.payload === undefined) {
          dispatch(updateUserProfile(data))
            .then((user) => {
              dispatch(SET__CRUD__ERROR(null));
              setTimeout(() => {
                window.location.href = "/me";
              }, 500);
            })
            .catch((err) => {
              AbortableToast("Error updating profile. Try again later");
            });
        }
      });
    }
  };
  return (
    <>
      {!isLoaded(user) ? (
        <div
          style={{
            width: "100%",
            height: "calc(100vh - 120px)",
          }}
        >
          <Loader type={`cover`} />
        </div>
      ) : isEmpty(user) ? (
        <Error404
          posts={posts}
          text={"User not found"}
          code={401}
          unset={params.pid !== undefined}
        />
      ) : (
        <div className="community-forum" style={{ marginTop: "1rem" }}>
          <section
            className="section-container container v-forum-hero"
            data-area="creator"
          >
            <div className="hero-area" style={{ height: "300px" }}>
              <div className="hero-content">
                <div
                  className="hero-info"
                  id="hero-info"
                  data-area="creator-banner"
                  style={{
                    backgroundImage:
                      user.user.isAdmin === 3 &&
                      user.user.banner !== undefined &&
                      user.user.banner !== "" &&
                      `url(${user.user.banner})`,
                  }}
                >
                  {user.user.isAdmin === 3 && (
                    <div
                      className="hero-info"
                      data-area="creator-info"
                      style={{
                        position: "relative",
                        width: "100%",
                        background: "transparent",
                      }}
                    >
                      <div
                        className="editor-featured-upload"
                        style={{
                          justifyContent: "flex-end",
                          background: "rgb(0,0,0,.4)",
                        }}
                      >
                        <div
                          style={{
                            width: "fit-content",
                            height: "100%",
                            display: "flex",
                            alignItems: "flex-end",
                            justifyContent: "flex-end",
                            zIndex: "999",
                          }}
                        >
                          <div>
                            <label
                              htmlFor="banner-img"
                              className="featured-label"
                            >
                              <i
                                className="fa fa-camera"
                                aria-hidden="true"
                                style={{ color: "#fff" }}
                              ></i>
                            </label>
                            <input
                              accept="image/*"
                              type="file"
                              onChange={handleBannerPreview}
                              id="banner-img"
                            />
                          </div>
                          <div style={{ padding: "10px 5px" }}>
                            <button
                              style={{
                                padding: "0",
                                lineHeight: "0",
                                background: "transparent",
                                border: "0",
                                display: "flex",
                              }}
                              onClick={handleBannerRemove}
                            >
                              <DeleteIcon width={15} theme={`cl-icon-white`} />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="hero-float">
                  <div
                    className="hero-float-img"
                    style={{ position: "relative" }}
                  >
                    <img
                      src={user.user.photoURL}
                      alt="creator avatar"
                      id="avatar-img"
                    />
                    <div
                      className="editor-featured-upload"
                      style={{
                        borderRadius: "50%",
                        background: "rgb(0,0,0,.4)",
                      }}
                    >
                      <div
                        style={{
                          width: "fit-content",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                          zIndex: "999",
                        }}
                      >
                        <div>
                          <label htmlFor="user-img" className="featured-label">
                            <i
                              className="fa fa-camera"
                              aria-hidden="true"
                              style={{ color: "#fff" }}
                            ></i>
                          </label>
                          <input
                            accept="image/*"
                            onChange={handleCoverPreview}
                            type="file"
                            id="user-img"
                          />
                        </div>
                        <div style={{ padding: "10px 5px" }}>
                          <button
                            style={{
                              padding: "0",
                              lineHeight: "0",
                              background: "transparent",
                              border: "0",
                              display: "flex",
                            }}
                            onClick={handleCoverRemove}
                          >
                            <DeleteIcon width={15} theme={`cl-icon-white`} />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="v-forum-lists container">
            <section className="section-container">
              <div className="section-row row">
                <div className="section-col section-card col s12 m6 l12 xl12">
                  <div
                    className={`${authStyles["auth-main-items"]}`}
                    style={{ maxWidth: "unset" }}
                  >
                    <h4 className={`${authStyles["auth-main-title"]}`}>
                      Tell us about yourself
                    </h4>
                    <div
                      className={`${authStyles["auth-main-content"]} ${formStyles["form-pd"]}`}
                    >
                      <div className={`${formStyles["form-control"]}`}>
                        <div className={`${formStyles["form-group"]}`}>
                          <label htmlFor="name">Name: (Optional)</label>
                          <input
                            type="text"
                            id="name"
                            name="name"
                            defaultValue={user.user.fullName}
                            autoComplete="off"
                            onChange={updateFormValue}
                            className="browser-default"
                            maxLength={60}
                          />
                        </div>
                        <div className={`${formStyles["form-group"]}`}>
                          <label htmlFor="username">Username</label>
                          <input
                            type="text"
                            id="username"
                            name="username"
                            defaultValue={user.user.username}
                            autoComplete="off"
                            onChange={updateFormValue}
                            className="browser-default"
                            maxLength={30}
                          />
                          <DisplayValidationMessages
                            el={`username`}
                            authError={crudError}
                          />
                        </div>
                        <div className={`${formStyles["form-group"]}`}>
                          <label htmlFor="bio">Bio: (Optional)</label>
                          <textarea
                            type="text"
                            id="bio"
                            name="bio"
                            defaultValue={user.user.bio}
                            autoComplete="off"
                            onChange={updateFormValue}
                            className="browser-default"
                            maxLength={150}
                            style={{
                              minHeight: "120px",
                              resize: "vertical",
                              padding: "10px",
                            }}
                          />
                        </div>
                        <div className={`${formStyles["form-group"]}`}>
                          <button
                            className={`${formStyles["form-btn"]} browser-default`}
                            id={`${formStyles["submit__main"]}`}
                            type="submit"
                            disabled={isFormSubmittable() || isCrudLoading}
                            onClick={handleUpdate}
                          >
                            Save changes
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      )}
    </>
  );
};

export default EditProfile;

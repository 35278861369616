import React, { useEffect } from "react";
import PortalWrapper from "./portal/PortalWrapper";
import M from "materialize-css";
import { CancelIcon } from "../assets/icons/Icons";
import { reportCategories } from "../assets/dummies/Static";
import { useDispatch, useSelector } from "react-redux";
import { SET__COMMENT__REPORT__MODAL } from "../stores/modals/ModalSlice";

const ReportComment = ({
  modalRef,
  commentReportModal,
  handleSubmit,
  updateFormValue,
  setCommentData,
  commentData,
}) => {
  const dispatch = useDispatch();
  const { isCrudLoading } = useSelector((state) => state.crud);
  useEffect(() => {
    if (commentReportModal) {
      const elem = document.querySelector("#comment__modal");
      modalRef.current = M.Modal.init(elem, {
        dismissible: false,
      });

      modalRef.current.open();
      return () => modalRef.current.close();
    }
  }, [commentReportModal]);

  const handleCancel = () => {
    dispatch(SET__COMMENT__REPORT__MODAL(false));
    setCommentData((state) => ({ ...state, report: "" }));
  };
  
  return (
    <PortalWrapper>
      <div className="modal modal__box" id="comment__modal">
        <div className="modal-header">
          <button className="btn-modal plain" onClick={handleCancel}>
            <CancelIcon width={25} theme={`cl-icon-light`} />
          </button>
        </div>
        <div className="modal-content">
          <h4>Report Comment</h4>
          <div className="inner-content">
            {reportCategories.map((report, index) => (
              <div key={index} className="col s12 m12 l2">
                <label className="text-theme">
                  <input
                    className="with-gap"
                    name="report"
                    defaultValue={report}
                    onChange={updateFormValue}
                    type="radio"
                  />
                  <span>{report}</span>
                </label>
              </div>
            ))}
          </div>
        </div>
        <div className="modal-footer">
          <div className="modal-actions">
            <button className="a-btn-modal b-only" onClick={handleCancel}>
              Cancel
            </button>
            <button
              className="a-btn-modal bg-only danger"
              disabled={isCrudLoading || commentData.report === ""}
              onClick={handleSubmit}
            >
              Report
            </button>
          </div>
        </div>
      </div>
    </PortalWrapper>
  );
};

export default ReportComment;

import React from "react";
import { LoadIcon } from "../../../assets/icons/Icons";
import Comments from "./components/Comments";
import moment from "moment";

const ForumComment = ({
  comments,
  postId,
  auth,
  setCommentData,
  updateSubCommentValue,
  subTextCount,
  setSubTextCount,
  subComment,
  handleSubmitSubComment,
  view,
  setView,
  subView,
  setSubView,
  handleCommentDelete,
  handleSubCommentDelete,
  delData,
  setDelData,
  maxLimit,
  maxDispLimit,
  indexDisp,
  handleLoadMore,
}) => {
  return (
    <ul className="blob-comment-lists">
      {comments.slice(indexDisp, maxDispLimit).map((comment, index) => (
        <Comments
          key={comment.id}
          index={index}
          id={comment.id}
          avatar={comment.author.photoURL}
          username={comment.author.username}
          userId={comment.author.id}
          createdAt={moment(comment.createdAt.toDate()).fromNow()}
          comment={comment.body.comment}
          postId={postId}
          auth={auth}
          setCommentData={setCommentData}
          subTextCount={subTextCount}
          setSubTextCount={setSubTextCount}
          subComment={subComment}
          updateSubCommentValue={updateSubCommentValue}
          handleSubmitSubComment={handleSubmitSubComment}
          view={view}
          setView={setView}
          subView={subView}
          setSubView={setSubView}
          handleCommentDelete={handleCommentDelete}
          handleSubCommentDelete={handleSubCommentDelete}
          setDelData={setDelData}
        />
      ))}
      {comments.length > maxLimit && maxDispLimit < comments.length && (
        <li className="comment-list">
          <button
            className="comment-action wrapper-fx-row gap upper-tx"
            onClick={handleLoadMore}
          >
            <LoadIcon width={20} theme={`cl-icon-light`}/>
            load more
          </button>
        </li>
      )}
    </ul>
  );
};

export default ForumComment;

import React, { useEffect, useRef, useState } from "react";
import {
  ShieldIcon,
  SortDownIcon,
  SortUpIcon,
} from "../../../assets/icons/Icons";
import { compose } from "redux";
import withRouter from "../../../general/components/WithRouter";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import { useSelector } from "react-redux";
import ForumComment from "./ForumComment";
import ReportComment from "../../../modals/ReportComment";

const ForumCommentWrapper = ({
  auth,
  postId,
  navigate,
  textCount,
  comment,
  updateCommentValue,
  setTextCount,
  handleSubmitComment,
  handleCommentReport,
  commentData,
  setCommentData,
  updateCommentDataValue,
  updateSubCommentValue,
  subTextCount,
  setSubTextCount,
  subComment,
  handleSubmitSubComment,
  handleCommentDelete,
  setDelData,
  handleSubCommentDelete,
  view,
  setView,
  subView,
  setSubView,
  maxLimit,
  maxDispLimit,
  indexDisp,
  handleLoadMore,
}) => {
  const [comments, setComments] = useState([]);
  const postComments = useSelector(
    ({ firestore: { ordered } }) =>
      ordered[`${postId}-comments`] && ordered[`${postId}-comments`]
  );
  useEffect(() => {
    if (isLoaded(postComments)) {
      const sortFilter = (a, b) =>
        new Date(b.createdAt.toDate()) - new Date(a.createdAt.toDate());
      setComments([...postComments].sort(sortFilter));
    }
  }, [postComments]);

  const { commentReportModal } = useSelector((state) => state.modal);
  const commentRef = useRef();

  const handleFilter = (e, type) => {
    const sort = document.querySelectorAll(".sort");
    sort.forEach((el) => {
      el.classList.remove("active");
    });

    switch (type) {
      case "up":
        setComments(postComments);
        break;
      case "down":
        const sortFilter = (a, b) =>
          new Date(a.createdAt.toDate()) - new Date(b.createdAt.toDate());
        setComments([...comments].sort(sortFilter));
        break;
      default:
        setComments(postComments);
        break;
    }
    e.currentTarget.classList.add("active");
  };
  // console.log(comments);
  if (isLoaded(postComments)) {
    return (
      <>
        <section className="blob-item divide">
          <div className="blob-tag-area">
            <div className="blog-mult">
              <h4>
                Comments
                <span className="count">
                  (
                  {postComments.length < 1
                    ? "No comments yet"
                    : postComments.length}
                  )
                </span>
              </h4>
              <a href="/">
                <ShieldIcon width={20} theme={`cl-icon-light`}/>
              </a>
            </div>
            <div className="blob-comment-area">
              {!auth.uid && (
                <button
                  className="blob-button"
                  onClick={() =>
                    navigate(`/auth/signin?nav=${window.location.pathname}`)
                  }
                >
                  Sign in to comment
                </button>
              )}
              <div className="form-area">
                <label htmlFor="main__comment"></label>
                <textarea
                  name="blob-textarea"
                  placeholder={
                    postComments.length < 1
                      ? "Be the first to comment"
                      : "Share your reaction"
                  }
                  id="main__comment"
                  value={comment}
                  onChange={updateCommentValue}
                  onKeyUp={() => setTextCount(comment.length)}
                  maxLength={400}
                ></textarea>
                <div
                  className="form-btn"
                  style={{
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "flex-end",
                  }}
                >
                  <p
                    style={{
                      marginBottom: ".5rem",
                      fontFamily: "Lato, sans-serif",
                      color: `${
                        400 - textCount === 0
                          ? "var(--tx-warning)"
                          : "var(--tx-main-light)"
                      }`,
                    }}
                  >
                    {400 - textCount} characters left
                  </p>
                  <button
                    onClick={handleSubmitComment}
                    className="blob-button"
                    style={{ marginTop: "10px" }}
                    disabled={textCount === null || textCount === 0}
                  >
                    Comment
                  </button>
                </div>
              </div>
              {comments.length > 0 && (
                <div className="blob-sort">
                  <p>sort by</p>
                  <button
                    className="comment-action sort"
                    onClick={(e) => handleFilter(e, "up")}
                  >
                    <SortUpIcon width={20} theme={`cl-icon-light`} />
                    Most recent
                  </button>
                  <button
                    className="comment-action sort"
                    onClick={(e) => handleFilter(e, "down")}
                  >
                    <SortDownIcon width={20} theme={`cl-icon-light`} />
                    Older
                  </button>
                </div>
              )}

              <ForumComment
                comments={comments}
                postId={postId}
                auth={auth}
                setCommentData={setCommentData}
                subTextCount={subTextCount}
                setSubTextCount={setSubTextCount}
                subComment={subComment}
                updateSubCommentValue={updateSubCommentValue}
                handleSubmitSubComment={handleSubmitSubComment}
                view={view}
                setView={setView}
                subView={subView}
                setSubView={setSubView}
                handleCommentDelete={handleCommentDelete}
                handleSubCommentDelete={handleSubCommentDelete}
                setDelData={setDelData}
                maxLimit={maxLimit}
                maxDispLimit={maxDispLimit}
                indexDisp={indexDisp}
                handleLoadMore={handleLoadMore}
              />
            </div>
          </div>
        </section>
        {commentReportModal && (
          <ReportComment
            modalRef={commentRef}
            setCommentData={setCommentData}
            commentData={commentData}
            commentReportModal={commentReportModal}
            handleSubmit={handleCommentReport}
            updateFormValue={updateCommentDataValue}
          />
        )}
      </>
    );
  }
};

export default compose(
  withRouter,
  firestoreConnect((props) => {
    if (props.postId) {
      return [
        {
          collection: "posts",
          doc: props.postId,
          subcollections: [{ collection: "comments" }],
          orderBy: ["createdAt", "desc"],
          storeAs: `${props.postId}-comments`,
        },
      ];
    }
  })
)(ForumCommentWrapper);

import React, { useEffect, useState } from "react";
import "../../css/community-content.css";
import { ForumIcon, TagIcon } from "../../assets/icons/Icons";
import {
  NavLink,
  useLocation,
  useOutletContext,
  useParams,
} from "react-router-dom";
import ForumCardBox from "../../general/components/ForumCardBox";
import AuthCreateButton from "../../general/protected/AuthCreateButton";
import { isEmpty, isLoaded } from "react-redux-firebase";
import moment from "moment";
import BannerCardSkeleton from "../../general/components/skeletons/BannerCardSkeleton";
import Error404 from "../Error404";

const Forum = () => {
  const params = useParams();
  const location = useLocation();
  const { posts, tags } = useOutletContext();
  const [view, setView] = useState(false);

  const filteredPosts = React.useMemo(() => {
    if (isLoaded(posts) && params.tag) {
      return posts.filter((data) => {
        return (
          data.status === 0 &&
          [data.properties.tags.map((t) => t).join("")]
            .join("")
            .toLowerCase()
            .indexOf(params.tag.toLowerCase()) !== -1
        );
      });
    }
  }, [params.tag, posts]);

  useEffect(() => {
    if (location.pathname === "/forum/") {
      window.location.href = "/forum";
    }
    if (location.pathname === "/forum/general/") {
      window.location.href = "/forum/general";
    }
    if (location.pathname === "/forum/immigration/") {
      window.location.href = "/forum/immigration";
    }
    if (location.pathname === "/forum/housing/") {
      window.location.href = "/forum/housing";
    }
    if (location.pathname === "/forum/working/") {
      window.location.href = "/forum/working";
    }
    if (location.pathname === "/forum/education/") {
      window.location.href = "/forum/education";
    }
    if (location.pathname === "/forum/discrimination/") {
      window.location.href = "/forum/discrimination";
    }
    if (location.pathname === "/forum/gender/") {
      window.location.href = "/forum/gender";
    }
    if (location.pathname === "/forum/healthcare/") {
      window.location.href = "/forum/healthcare";
    }
    if (location.pathname === "/forum/technology/") {
      window.location.href = "/forum/technology";
    }
  }, [location.pathname]);

  useEffect(() => {
    if (isLoaded(tags) && params.tag !== undefined) {
      const data = tags[0].data.filter(
        (d) => d.toLowerCase() === params.tag.toLowerCase()
      );
      if (data.length === 0) {
        setView(true);
      }
    }
  }, [params, tags]);

  if (view) {
    return (
      <Error404
        posts={posts}
        text={"Missing or broken link."}
        code={404}
        unset={params.pid !== undefined}
      />
    );
  }

  return (
    <div className="community-forum">
      <section className="section-container container v-forum-hero">
        <div className="hero-area">
          {params.tag === undefined && (
            <div className="hero-content">
              <div className="hero-info">
                <ForumIcon height={80} theme={`cl-icon-p`} />
                <h3 className="hero-title">Forum</h3>
                <p className="hero-desc">
                  Share stories, ask questions, debate, interact with other
                  international students in the northern part of Cyprus.
                </p>
              </div>
            </div>
          )}
          {params.tag && (
            <div className="hero-content">
              <div className="hero-info">
                <TagIcon height={40} theme={`cl-icon-p`} />
                <h3 className="hero-title">
                  {params.tag.slice(0, 1).toUpperCase()}
                  {params.tag.slice(1)}
                </h3>
                <p className="hero-desc">
                  {params.tag.slice(0, 1).toUpperCase()}
                  {params.tag.slice(1)} stories, questions, debate in the
                  northern part of Cyprus.
                </p>
                {filteredPosts && filteredPosts.length > 0 ? (
                  <p
                    className="hero-desc"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      fontSize: '1.3rem'
                    }}
                  >
                    {filteredPosts.length}
                    <span className="cl-icon-light">
                      story(s) and counting
                    </span>
                  </p>
                ) : (
                  <p
                    className="hero-desc"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      fontSize: '1.3rem'
                    }}
                  >
                    <span className="cl-icon-light">No story yet</span>
                  </p>
                )}
              </div>
            </div>
          )}
        </div>
      </section>
      <div className="container hero-actions">
        <ul className="hero-tag-lists">
          {!isEmpty(tags) && (
            <li className="tag-list">
              <NavLink to={`/forum`} end>
                All
              </NavLink>
            </li>
          )}
          {!isEmpty(tags) &&
            tags[0].data.map((tag) => (
              <li className="tag-list" key={tag}>
                <NavLink to={`/forum/${tag.toLowerCase()}`} end>
                  {tag}
                </NavLink>
              </li>
            ))}
        </ul>
        <div className="hero-auth-area">
          <AuthCreateButton text={`Create`} link="/create/post" />
        </div>
      </div>
      {!params.tag ? (
        <div className="v-forum-lists container">
          <section className="section-container">
            <div className="section-row row">
              {!isLoaded(posts) ? (
                [1, 2, 3, 4, 5, 6].map((d) => (
                  <div
                    className="section-col section-card col s12 m6 l4 xl3"
                    key={d}
                  >
                    <BannerCardSkeleton />
                  </div>
                ))
              ) : isEmpty(posts) ? (
                <div>No posts yet</div>
              ) : (
                posts
                  .filter((p) => p.status === 0)
                  .map((s) => {
                    return (
                      <div
                        className="section-col section-card col s12 m6 l4 xl3"
                        key={s.id}
                      >
                        <ForumCardBox
                          cardId={s.id}
                          cardBanner={s.properties.banner}
                          cardTag={s.properties.tags[0]}
                          cardTitle={s.properties.title}
                          cardAvatar={s.author.photoURL}
                          cardUserName={s.author.username}
                          cardDate={moment(s.createdAt.toDate()).fromNow()}
                        />
                      </div>
                    );
                  })
              )}
            </div>
          </section>
        </div>
      ) : (
        <div className="v-forum-lists container">
          <section className="section-container">
            <div className="section-row row">
              {!isLoaded(filteredPosts) ? (
                [1, 2, 3, 4, 5, 6].map((d) => (
                  <div
                    className="section-col section-card col s12 m6 l4 xl3"
                    key={d}
                  >
                    <BannerCardSkeleton />
                  </div>
                ))
              ) : isEmpty(filteredPosts) ? (
                <div>No posts yet</div>
              ) : (
                filteredPosts.map((s) => {
                  return (
                    <div
                      className="section-col section-card col s12 m6 l4 xl3"
                      key={s.id}
                    >
                      <ForumCardBox
                        cardId={s.id}
                        cardBanner={s.properties.banner}
                        cardTag={s.properties.tags[0]}
                        cardTitle={s.properties.title}
                        cardAvatar={s.author.photoURL}
                        cardUserName={s.author.username}
                        cardDate={moment(s.createdAt.toDate()).fromNow()}
                      />
                    </div>
                  );
                })
              )}
            </div>
          </section>
        </div>
      )}
    </div>
  );
};

export default Forum;

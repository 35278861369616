import React, { useState } from "react";
import authStyles from "../css/auth.module.css";
import formStyles from "../css/forms.module.css";
import FormValidate from "./components/FormValidate";
import DisplayValidationMessages from "./components/DisplayValidationMessages";
import { isUsernameAvailable } from "../stores/auth/AuthSlice";
import { useSelector } from "react-redux";

const AuthUserNameSelect = () => {
  const { authError } = useSelector((state) => state.auth);

  const [elements, setElements] = useState({
    username: "",
    fullName: "",
  });

  const rules = {
    username: {
      required: true,
      minLength: 6,
      maxLength: 20,
      alphanumeric: true,
    },
    fullName: { maxLength: 30, alpha: true },
  };
  const updateFormElements = (e) => {
    setElements((els) => ({ ...els, [e.target.name]: e.target.value }));
  };

  return (
    <main className={`${authStyles["auth-main"]}`}>
      <div className={`${authStyles["auth-main-items"]}`}>
        <h4 className={`${authStyles["auth-main-title"]}`}>
          You're almost there.
        </h4>
        <div
          className={`${authStyles["auth-main-content"]} ${formStyles["form-pd"]}`}
        >
          <div className={`${formStyles["form-control"]}`}>
            <FormValidate
              btnText={`Complete`}
              data={elements}
              rules={rules}
              callBack={isUsernameAvailable}
            >
              <div className={`${formStyles["form-group"]}`}>
                <label htmlFor="fullName">Name: (Optional)</label>
                <input
                  type="text"
                  id="fullName"
                  name="fullName"
                  autoComplete="off"
                  onChange={updateFormElements}
                  className="browser-default"
                />
                <DisplayValidationMessages el="fullName" />
              </div>
              <div className={`${formStyles["form-group"]}`}>
                <label htmlFor="username">Username</label>
                <input
                  type="text"
                  id="username"
                  name="username"
                  autoComplete="off"
                  onChange={updateFormElements}
                  className="browser-default"
                />
                <DisplayValidationMessages
                  el="username"
                  authError={authError}
                />
              </div>
            </FormValidate>
          </div>
        </div>
      </div>
    </main>
  );
};

export default AuthUserNameSelect;

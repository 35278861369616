import React from "react";
import { AppEditIcon, ForwardIcon } from "../../../assets/icons/Icons";
import formStyles from "../../../css/forms.module.css";
import authStyles from "../../../css/auth.module.css";

const ChangeSocials = ({
  updateFormValue,
  handleSocialUpdate,
  userSocials,
  isCrudLoading,
  locUser,
}) => {
  const canFormBeSubmitted = () => {
    return (
      locUser.facebook === userSocials.facebook &&
      locUser.twitter === userSocials.twitter &&
      locUser.linkedIn === userSocials.linkedIn &&
      locUser.instagram === userSocials.instagram &&
      locUser.whatsapp === userSocials.whatsapp &&
      locUser.portfolio === userSocials.portfolio
    );
  };
  return (
    <li className="collapsible-item" id="set__socials">
      <div className="collapsible-header">
        <AppEditIcon width={20} theme={`cl-icon-light`} />
        <p>
          Social links
          <span>Add social links to connect with others in the community.</span>
        </p>
        <div className="caret-area">
          <ForwardIcon width={20} theme={`cl-icon-light`} />
        </div>
      </div>
      <div className="collapsible-body">
        <div
          className={`${authStyles["auth-main-items"]}`}
          style={{ maxWidth: "unset" }}
        >
          <div
            className={`${authStyles["auth-main-content"]} ${formStyles["form-pd"]}`}
          >
            <div className={`${formStyles["form-control"]}`}>
              <div className={`${formStyles["form-group"]}`}>
                <label htmlFor="facebook">Facebook</label>
                <input
                  type="text"
                  id="facebook"
                  name="facebook"
                  autoComplete="off"
                  className="browser-default"
                  defaultValue={userSocials.facebook}
                  onChange={updateFormValue}
                />
              </div>
              <div className={`${formStyles["form-group"]}`}>
                <label htmlFor="instagram">Instagram</label>
                <input
                  type="text"
                  id="instagram"
                  name="instagram"
                  autoComplete="off"
                  className="browser-default"
                  defaultValue={userSocials.instagram}
                  onChange={updateFormValue}
                />
              </div>
              <div className={`${formStyles["form-group"]}`}>
                <label htmlFor="twitter">Twitter(X)</label>
                <input
                  type="text"
                  id="twitter"
                  name="twitter"
                  autoComplete="off"
                  className="browser-default"
                  defaultValue={userSocials.twitter}
                  onChange={updateFormValue}
                />
              </div>
              <div className={`${formStyles["form-group"]}`}>
                <label htmlFor="linkedIn">LinkedIn</label>
                <input
                  type="text"
                  id="linkedIn"
                  name="linkedIn"
                  autoComplete="off"
                  className="browser-default"
                  defaultValue={userSocials.linkedIn}
                  onChange={updateFormValue}
                />
              </div>
              <div className={`${formStyles["form-group"]}`}>
                <label htmlFor="portfolio">Portfolio</label>
                <input
                  type="text"
                  id="portfolio"
                  name="portfolio"
                  autoComplete="off"
                  className="browser-default"
                  defaultValue={userSocials.portfolio}
                  onChange={updateFormValue}
                />
              </div>
              <div className={`${formStyles["form-group"]}`}>
                <label htmlFor="whatsapp">WhatsApp</label>
                <input
                  type="text"
                  id="whatsapp"
                  name="whatsapp"
                  autoComplete="off"
                  className="browser-default"
                  defaultValue={userSocials.whatsapp}
                  onChange={updateFormValue}
                />
              </div>
              <div
                className={`${formStyles["form-group"]}`}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <button
                  className={`${formStyles["form-btn"]} browser-default`}
                  id={`${formStyles["submit__main"]}`}
                  type="submit"
                  style={{ width: "fit-content" }}
                  disabled={canFormBeSubmitted() || isCrudLoading}
                  onClick={handleSocialUpdate}
                >
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};

export default ChangeSocials;

import React, { useEffect, useRef } from "react";
import RateStarGenerator from "../../../general/components/RateStarGenerator";
import {
  FbIcon,
  LinkIcon,
  ShareIcon,
  XIcon,
} from "../../../assets/icons/Icons";
import { compose } from "redux";
import withRouter from "../../../general/components/WithRouter";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import { useSelector } from "react-redux";
import btnStyles from "../../../css/auth-btn.module.css";
import M from "materialize-css";
// import { useNavigate } from "react-router-dom";

const HouseActions = ({
  location,
  houseId,
  handleReviewTotal,
  copyToClipboard,
  shareOnFacebook,
  shareOnTwitter,
  disableActions,
}) => {

  const shareRef = useRef();

  const reviews = useSelector(
    ({ firestore: { ordered } }) =>
      ordered[`${houseId}-reviews`] && ordered[`${houseId}-reviews`]
  );

  useEffect(() => {
    const share = document.querySelector(".btn__share");
    shareRef.current = M.Dropdown.init(share, {
      coverTrigger: false,
      alignment: "right",
      constrainWidth: false,
    });

    const reCalculate = () => {
      shareRef.current.recalculateDimensions();
    };

    if (share) {
      window.addEventListener("resize", reCalculate);
      return () => window.removeEventListener("resize", reCalculate);
    }
  }, [reviews]);

  if (isLoaded(reviews))
    return (
      <>
        <section className="blob-item fx-fade">
          <div className="blob-action-area">
            <div className="action-lr">
              <div className="l">
                <span>
                  <i className="fa fa-map-marker" aria-hidden="true"></i>
                  {location}
                </span>
              </div>
              <div className="l">
                <RateStarGenerator
                  ratingNumberCount={Number(
                    handleReviewTotal(reviews).toFixed(1)
                  )}
                  ratingStarCount={
                    reviews.length === 0
                      ? 0
                      : Number(handleReviewTotal(reviews).toFixed(1))
                  }
                  ratingReviewCount={Number(reviews.length)}
                />
              </div>
            </div>
            <div className="action-rl">
              <div className="r">
                <button
                  className={`${btnStyles["auth-blob-btn"]} btn__share`}
                  title="Share house"
                  data-target={`btn-share`}
                  disabled={disableActions}
                >
                  <ShareIcon width={22} theme={`cl-icon-light`} />
                </button>
                <ul id="btn-share" className="dropdown-content">
                  <li
                    className="custom__list"
                    style={{ width: "100%", gap: "10px", padding: "7px 14px" }}
                  >
                    <button
                      className={`${btnStyles["auth-blob-btn"]}`}
                      style={{ width: "100%", justifyContent: "flex-start" }}
                      onClick={copyToClipboard}
                    >
                      <LinkIcon width={20} theme={`cl-icon-light`} />
                      Copy link
                    </button>
                  </li>
                  <li
                    className="custom__list"
                    style={{ width: "100%", gap: "10px", padding: "7px 14px" }}
                  >
                    <button
                      className={`${btnStyles["auth-blob-btn"]}`}
                      style={{ width: "100%", justifyContent: "flex-start" }}
                      onClick={shareOnFacebook}
                    >
                      <FbIcon width={20} />
                      Share on Facebook
                    </button>
                  </li>
                  <li
                    className="custom__list"
                    style={{ width: "100%", gap: "10px", padding: "7px 14px" }}
                  >
                    <button
                      className={`${btnStyles["auth-blob-btn"]}`}
                      style={{ width: "100%", justifyContent: "flex-start" }}
                      onClick={shareOnTwitter}
                    >
                      <XIcon width={20} />
                      Share on X (Twitter)
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </>
    );
};

export default compose(
  withRouter,
  firestoreConnect((props) => {
    if (props.houseId) {
      return [
        {
          collection: "ratings",
          doc: props.houseId,
          subcollections: [{ collection: "reviews" }],
          orderBy: ["createdAt", "desc"],
          storeAs: `${props.houseId}-reviews`,
        },
      ];
    } else {
      return [];
    }
  })
)(HouseActions);

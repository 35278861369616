import React, { useEffect, useRef, useState } from "react";
import {
  DeleteIcon,
  ReportIcon,
  ThreeDots,
} from "../../../../assets/icons/Icons";
import { isLoaded } from "react-redux-firebase";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import SubInfo from "./SubInfo";
import M from "materialize-css";
import { SET__COMMENT__REPORT__MODAL } from "../../../../stores/modals/ModalSlice";
import DeleteModal from "../../../../modals/DeleteModal";

const SubComments = ({
  subComments,
  postId,
  index,
  auth,
  setCommentData,
  updateSubCommentValue,
  subTextCount,
  setSubTextCount,
  subComment,
  handleSubmitSubComment,
  parent,
  subView,
  setSubView,
  handleSubCommentDelete,
  setDelData,
}) => {
  const { user } = useSelector((state) => state.firebase.profile);
  const formRef = useRef();
  const [viewComments, setViewComments] = useState(false);

  const dotRef = useRef();
  const authRef = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    const nav = document.querySelectorAll("#sub__sub");
    dotRef.current = M.Dropdown.init(nav, {
      coverTrigger: false,
      alignment: "right",
      constrainWidth: false,
      onOpenStart: (el) => {
        setCommentData((state) => ({
          ...state,
          id: el.dataset.value,
          postId: postId,
          pid: parent,
          type: "subcomment",
          content: el.parentNode.nextSibling.textContent,
        }));
      },
    });
    // el.parentElement.parentElement.parentElement.parentElement
    // .parentElement.parentElement.firstChild.childNodes[1].dataset.value
    const reCalculate = () => {
      dotRef.current.forEach((d) => d.recalculateDimensions());
    };

    if (nav) {
      window.addEventListener("resize", reCalculate);
      return () => window.removeEventListener("resize", reCalculate);
    }
  }, [window.screen.width, index, viewComments, subComments]);

  useEffect(() => {
    const nav = document.querySelectorAll("#sub__auth");
    authRef.current = M.Dropdown.init(nav, {
      coverTrigger: false,
      alignment: "right",
      constrainWidth: false,
      onOpenStart: (el) => {
        setDelData((state) => ({
          ...state,
          pid: postId,
          cid: parent,
          scid: el.dataset.value,
        }));
      },
    });

    const reCalculate = () => {
      authRef.current.forEach((d) => d.recalculateDimensions());
    };

    if (nav) {
      window.addEventListener("resize", reCalculate);
      return () => window.removeEventListener("resize", reCalculate);
    }
  }, [window.screen.width, index, viewComments, subComments, auth]);

  if (isLoaded(subComments)) {
    return (
      <>
        <div className="wrapper-fx-row j-sb m-x">
          <button
            className="fx-row comment-action"
            onClick={() => {
              setViewComments(!viewComments);
            }}
          >
            {!viewComments
              ? `Replies (${subComments.length})`
              : `Hide (${subComments.length})`}
          </button>
          <button
            className="fx-row comment-action"
            onClick={() => {
              setViewComments(true);
              formRef.current.focus();
            }}
          >
            Reply
          </button>
        </div>
        {viewComments && (
          <ul className="blob-comment-lists sub">
            {subComments.map((sub) => (
              <li className="comment-list" key={sub.id}>
                <div className="blob-desc-area">
                  <img
                    src={sub.author.photoURL}
                    alt=""
                    className="user-avatar"
                  />
                  <div className="wrapper-fx-col">
                    <div className="wrapper-fx-row">
                      <div className="user-info">
                        <div className="user-name-info">
                          <a href={`/@${sub.author.username}`} className="user-name">
                            {sub.author.username}
                          </a>
                          <SubInfo userId={sub.author.id} index={index} />
                        </div>
                        <p className="blob-properties">
                          <span className="blob-lt">
                            {moment(sub.createdAt.toDate()).fromNow()}
                          </span>
                        </p>
                      </div>
                      {auth && auth.uid === sub.author.id ? (
                        <>
                          <button
                            className="comment-action"
                            id="sub__auth"
                            data-value={sub.id}
                            data-target={`auh__t${index}`}
                          >
                            <ThreeDots width={20} />
                          </button>
                          <ul
                            id={`auh__t${index}`}
                            className="dropdown-content badge"
                          >
                            <li className="custom__list">
                              <button
                                className="comment-action"
                                style={{
                                  justifyContent: "flex-start",
                                  gap: "10px",
                                  padding: "7px 10px",
                                }}
                                onClick={() => setSubView(true)}
                              >
                                <DeleteIcon
                                  width={15}
                                  theme={`cl-icon-light`}
                                />
                                Delete comment
                              </button>
                            </li>
                          </ul>
                        </>
                      ) : (
                        <>
                          <button
                            className="comment-action"
                            id="sub__sub"
                            data-value={sub.id}
                            data-target={`sub__t${index}`}
                          >
                            <ThreeDots width={20} />
                          </button>
                          <ul
                            id={`sub__t${index}`}
                            className="dropdown-content badge"
                          >
                            <li className="custom__list">
                              <button
                                className="comment-action"
                                style={{
                                  justifyContent: "flex-start",
                                  gap: "10px",
                                  padding: "7px 10px",
                                }}
                                onClick={() =>
                                  dispatch(SET__COMMENT__REPORT__MODAL(true))
                                }
                              >
                                <ReportIcon
                                  width={15}
                                  theme={`cl-icon-light`}
                                />
                                Report comment
                              </button>
                            </li>
                          </ul>
                        </>
                      )}
                    </div>
                    <div className="comment-textbox">{sub.body.comment}</div>
                  </div>
                </div>
              </li>
            ))}
            <li className="comment-list">
              <div className="blob-desc-area">
                <img
                  src={user && user.photoURL}
                  alt={user && `${user.username}-${user.photoURL}`}
                  className="user-avatar"
                />
                <div className="wrapper-fx-col">
                  <div className="wrapper-fx-col">
                    <div
                      className="form-area"
                      style={{ marginTop: "0", marginLeft: "10px" }}
                    >
                      <label htmlFor={`sub-${index}`}></label>
                      <textarea
                        name="blob-textarea"
                        placeholder="Share your reaction"
                        id={`sub-${index}`}
                        ref={formRef}
                        autoFocus={true}
                        value={subComment}
                        onChange={updateSubCommentValue}
                        onKeyUp={() => setSubTextCount(subComment.length)}
                        maxLength={400}
                      ></textarea>
                      <div
                        className="form-btn"
                        style={{
                          flexDirection: "column",
                          justifyContent: "space-between",
                          alignItems: "flex-end",
                        }}
                      >
                        <p
                          style={{
                            marginBottom: ".5rem",
                            fontFamily: "Lato, sans-serif",
                            color: `${
                              400 - subTextCount === 0
                                ? "var(--tx-warning)"
                                : "var(--tx-main-light)"
                            }`,
                          }}
                        >
                          {400 - subTextCount} characters left
                        </p>
                        <button
                          onClick={() =>
                            handleSubmitSubComment(`${postId}?${parent}`)
                          }
                          className="blob-button"
                          style={{ marginTop: "10px" }}
                          disabled={subTextCount === null || subTextCount === 0}
                        >
                          Comment
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        )}
        {subView && (
          <DeleteModal
            view={subView}
            setView={setSubView}
            text={"Comment"}
            callback={handleSubCommentDelete}
          />
        )}
      </>
    );
  }
};

export default SubComments;

import React, { useEffect } from "react";
import "../../css/community-content.css";
import { NavLink, useLocation, useParams } from "react-router-dom";
import ForumCardBox from "../../general/components/ForumCardBox";
import VerifiedInfo from "../../general/components/VerifiedInfo";
import withRouter from "../../general/components/WithRouter";
import { firestoreConnect, isEmpty, isLoaded } from "react-redux-firebase";
import Loader from "../../general/components/Loader";
import { useSelector } from "react-redux";
import { compose } from "redux";
import { UserEditIcon } from "../../assets/icons/Icons";
import moment from "moment";
import SkillCard from "../../general/components/SkillCard";
import Error404 from "../Error404";

const Profile = () => {
  const params = useParams();
  // const [loading, setIsLoading] = useState(false);
  const auth = useSelector(({ firebase: { auth } }) => auth);
  const user = useSelector(
    ({ firestore: { ordered } }) => ordered[params.id] && ordered[params.id]
  );
  const posts = useSelector(
    ({ firestore: { ordered } }) => ordered[`${params.id}-posts`]
  );
  const skills = useSelector(
    ({ firestore: { ordered } }) => ordered[`${params.id}-skills`]
  );
  const location = useLocation();
  const getCustomMonth = (date) => {
    const month = new Date(date.toDate());
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return months[month.getMonth()];
  };

  useEffect(() => {
    if (isLoaded(user)) {
      window.scrollTo(0, 0, "smooth");
    }
  }, [params.id, user]);

  return (
    <>
      {!isLoaded(user) ? (
        <div
          style={{
            width: "100%",
            height: "calc(100vh - 120px)",
          }}
        >
          <Loader type={`cover`} />
        </div>
      ) : isEmpty(user) ? (
        <Error404
          posts={posts}
          text={
            params.id.split("@").length > 1
              ? "User not found"
              : "Page not found"
          }
          code={401}
          unset={params.pid !== undefined}
        />
      ) : (
        <div className="community-forum">
          <section
            className="section-container container v-forum-hero"
            data-area="creator"
          >
            <div className="hero-area">
              <div className="hero-content">
                <div
                  className="hero-info"
                  data-area="creator-banner"
                  style={
                    user[0].user.banner !== undefined &&
                    user[0].user.banner !== ""
                      ? {
                          backgroundImage: `url(${user[0].user.banner})`,
                        }
                      : {}
                  }
                ></div>
                <div className="hero-info" data-area="creator-info">
                  <div className="hero-creator-title">
                    {user[0].user.username}
                    <span className="creator-badge" title="VOIS member badge">
                      <VerifiedInfo userId={user[0].user.id} />
                    </span>
                  </div>
                  {user[0].user.bio && (
                    <div className="hero-creator-bio">{user[0].user.bio}</div>
                  )}

                  <div className="hero-creator-created">
                    <i className="fa fa-calendar" aria-hidden="true"></i>
                    Joined {getCustomMonth(user[0].account.createdAt)}{" "}
                    {new Date(user[0].account.createdAt.toDate()).getFullYear()}
                  </div>
                </div>
                <div
                  className="hero-float"
                  // style={{
                  //   alignItems:
                  //     (user[0].user.banner === undefined ||
                  //       user[0].user.banner === "") &&
                  //     "flex-start",
                  //   top:
                  //     (user[0].user.banner === undefined ||
                  //       user[0].user.banner === "") &&
                  //     "50px",
                  // }}
                >
                  <div className="hero-float-img">
                    <img
                      src={user[0].user.photoURL}
                      alt="creator avatar"
                      loading="lazy"
                      draggable={false}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="container hero-actions">
            <ul className="hero-tag-lists">
              <li className="tag-list">
                <NavLink
                  to={
                    location.pathname === `/${params.id}`
                      ? `/${params.id}`
                      : `/${params.id}/posts`
                  }
                  end
                >
                  Posts
                </NavLink>
              </li>
              <li className="tag-list">
                <NavLink to={`/${params.id}/skills`} end>
                  Skills
                </NavLink>
              </li>
            </ul>
            {auth.uid === user[0].user.id && (
              <div className="hero-auth-area">
                <NavLink
                  to={"/me"}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    color: "var(--tx-main-light)",
                  }}
                >
                  <UserEditIcon width={20} theme={`cl-icon-light`} />
                  Edit
                </NavLink>
              </div>
            )}
          </div>

          {(location.pathname === `/${params.id}` ||
            location.pathname === `/${params.id}/posts`) && (
            <div className="v-forum-lists container">
              <section className="section-container">
                <div className="section-row row">
                  {!isLoaded(posts) ? (
                    <Loader type={`cover`} />
                  ) : isEmpty(posts) ||
                    posts.filter((p) => p.status === 0).length < 1 ? (
                    <div>No posts yet</div>
                  ) : (
                    posts
                      .filter((p) => p.status === 0)
                      .map((s) => {
                        return (
                          <div
                            className="section-col section-card col s12 m6 l4 xl3"
                            key={s.id}
                          >
                            <ForumCardBox
                              cardId={s.id}
                              cardBanner={s.properties.banner}
                              cardTag={s.properties.tags[0]}
                              cardTitle={s.properties.title}
                              cardAvatar={s.author.photoURL}
                              cardUserName={s.author.username}
                              cardDate={moment(s.createdAt.toDate()).fromNow()}
                            />
                          </div>
                        );
                      })
                  )}
                </div>
              </section>
            </div>
          )}

          {location.pathname === `/${params.id}/skills` && (
            <div className="v-forum-lists container">
              <section className="section-container">
                <div className="section-row row">
                  {!isLoaded(skills) ? (
                    <Loader type={`cover`} />
                  ) : isEmpty(skills) ||
                    skills.filter((s) => s.status === 0).length < 1 ? (
                    <div>No skills yet</div>
                  ) : (
                    skills
                      .filter((s) => s.status === 0)
                      .map((skill) => (
                        <div
                          className="section-col section-card col s12 m6 l4 xl3"
                          key={skill.id}
                        >
                          <SkillCard
                            skillId={skill.id}
                            skillBanner={skill.properties.banners[0]}
                            skillTitle={skill.properties.title}
                            skillTag={skill.properties.category}
                            skillAvatar={skill.author.photoURL}
                            skillAuthor={skill.author.username}
                            skillRateScore={skill.interactions.reviewCount}
                            skillReviewCount={skill.interactions.reviewCount}
                            skillPrice={skill.properties.price}
                          />
                        </div>
                      ))
                  )}
                </div>
              </section>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default compose(
  withRouter,
  firestoreConnect((props) => {
    if (props.id.split("@")[1] !== undefined) {
      return [
        {
          collection: "users",
          where: [["user.username", "==", props.id.split("@")[1]]],
          storeAs: props.id,
        },
        {
          collection: "posts",
          where: [["author.username", "==", props.id.split("@")[1]]],
          orderBy: ["createdAt", "desc"],
          storeAs: `${props.id}-posts`,
        },
        {
          collection: "skills",
          where: [["author.username", "==", props.id.split("@")[1]]],
          orderBy: ["createdAt", "desc"],
          storeAs: `${props.id}-skills`,
        },
      ];
    } else {
      return [
        {
          collection: "users",
          where: [["user.username", "==", window.btoa(props.id)]],
          storeAs: props.id,
        },
        {
          collection: "posts",
          orderBy: ["createdAt", "desc"],
          storeAs: `${props.id}-posts`,
        },
      ];
    }
  })
)(Profile);

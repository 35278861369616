import React, { useEffect, useState } from "react";
import authStyles from "../css/auth.module.css";
import formStyles from "../css/forms.module.css";
import { FacebookIcon, GoogleIcon } from "../assets/icons/Icons";
import { NavLink } from "react-router-dom";
import FormValidate from "./components/FormValidate";
import DisplayValidationMessages from "./components/DisplayValidationMessages";
import {
  fbAuthHandlerSignIn,
  handleAuthWithGoogle,
  signInWithCredentials,
} from "../stores/auth/AuthSlice";
import { useDispatch, useSelector } from "react-redux";
import { GOOGLE__CLIENT__ID } from "../config/globals";
import Loader from "../general/components/Loader";

const AuthLogin = () => {
  const dispatch = useDispatch();
  const { authError } = useSelector((state) => state.auth);
  const [showLoader, setShowLoader] = useState(false);

  const [elements, setElements] = useState({
    email: "",
    password: "",
  });
  const [handleCustomGoogleBtnClick, setHandleCustomGoogleBtnClick] =
    useState(null);

  const rules = {
    email: { required: true, email: true },
    password: { required: true, minLength: 8 },
  };

  const updateFormElements = (e) => {
    setElements((els) => ({ ...els, [e.target.name]: e.target.value }));
  };

  useEffect(() => {
    const handleCredentialResponse = (response) => {
      dispatch(handleAuthWithGoogle(response));
    };

    if (window.google !== null && window.google !== undefined) {
      window.google.accounts.id.initialize({
        client_id: GOOGLE__CLIENT__ID,
        callback: handleCredentialResponse,
      });
      window.google.accounts.id.renderButton(
        document.getElementById("custom-hidden"),
        { theme: "outline", size: "large", text: "signin_with" } // customization attributes
      );
      setHandleCustomGoogleBtnClick(returnGoogleButtonForClick());
    }

    // window.google.accounts.id.prompt(); // also display the One Tap dialog
  }, [dispatch]);

  const returnGoogleButtonForClick = () => {
    const googleLoginWrapperButton = document.querySelector(
      "#custom-hidden div[role=button]"
    );
    return {
      click: () => {
        googleLoginWrapperButton.click();
      },
    };
  };

  const handleGoogleButton = () => {
    setShowLoader(true);
    handleCustomGoogleBtnClick.click();
  };

  const handleFacebookButton = () => {
    setShowLoader(true);
    window.FB.login(
      (response) => {
        console.log(response);
        window.FB.Event.subscribe(
          "auth.authResponseChange",
          dispatch(fbAuthHandlerSignIn(response))
        );
      },
      { scope: "public_profile,email" }
    );
  };
  return (
    <>
      {showLoader && <Loader type={"cover"} />}
      <main className={`${authStyles["auth-main"]}`}>
        <div className={`${authStyles["auth-main-items"]}`}>
          <p className={`${authStyles["auth-routes"]} auth-active`}>
            <NavLink to="/auth" end>
              Start
            </NavLink>
            <NavLink to="/auth/signin">Sign in</NavLink>
            <NavLink to="/auth/signup">Sign up</NavLink>
          </p>
          <h4 className={`${authStyles["auth-main-title"]}`}>
            Sign in via email
          </h4>
          <div
            className={`${authStyles["auth-main-content"]} ${formStyles["form-pd"]}`}
          >
            <div className={`${formStyles["form-control"]}`}>
              <FormValidate
                btnText={`Sign in`}
                data={elements}
                rules={rules}
                callBack={signInWithCredentials}
              >
                {authError && (
                  <p className={`${formStyles["form-error"]}`}>
                    {typeof authError !== "object" && authError}
                  </p>
                )}
                <div className={`${formStyles["form-group"]}`}>
                  <label htmlFor="email">Email</label>
                  <input
                    type="text"
                    id="email"
                    name="email"
                    autoComplete="off"
                    onChange={updateFormElements}
                    className="browser-default"
                  />
                  <DisplayValidationMessages el="email" authError={authError} />
                </div>
                <div className={`${formStyles["form-group"]}`}>
                  <label htmlFor="password">Password</label>
                  <input
                    type="password"
                    id="password"
                    name="password"
                    onChange={updateFormElements}
                    className="browser-default"
                  />
                  <DisplayValidationMessages
                    el="password"
                    authError={authError}
                  />
                </div>
              </FormValidate>

              <div className={`${formStyles["form-options"]}`}>
                <NavLink to="/auth/recovery">Forgot password</NavLink>
                <p>
                  Don't have an account?
                  <NavLink to="/auth/signup">Sign up</NavLink>
                </p>
              </div>
            </div>
            <div className={`${formStyles["form-divided"]}`}>
              <span className={`${formStyles["b-top"]}`}></span>
              <span>or</span>
              <span className={`${formStyles["b-top"]}`}></span>
            </div>
            <div className={`${formStyles["form-others"]}`}>
              <button
                className={`${formStyles["form-other"]}`}
                onClick={handleGoogleButton}
                id="goo__gle"
              >
                <div id="custom-hidden"></div>
                <div>
                  <GoogleIcon width={20} />
                  <p>Sign in with google</p>
                </div>
              </button>
              <button
                className={`${formStyles["form-other"]}`}
                onClick={handleFacebookButton}
                id="face__book"
              >
                <div>
                  <FacebookIcon width={20} />
                  <p>Sign in with Facebook</p>
                </div>
              </button>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default AuthLogin;

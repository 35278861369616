import React, { useEffect, useRef } from "react";
import btnStyles from "../../../css/auth-btn.module.css";
import {
  ActiveSavedIcon,
  CommentIcon,
  FbIcon,
  FilledHeartIcon,
  HeartIcon,
  LinkIcon,
  RemoveIcon,
  ReportIcon,
  SavedIcon,
  ShareIcon,
  ThreeDots,
  WriteIcon,
  XIcon,
} from "../../../assets/icons/Icons";
import { isEmpty } from "react-redux-firebase";
import {
  likePost,
  savePost,
  unLikePost,
  unSavePost,
} from "../../../stores/crud/CrudSlice";
import { useDispatch, useSelector } from "react-redux";
import ReportModal from "../../../modals/ReportModal";
import { SET__REPORT__MODAL } from "../../../stores/modals/ModalSlice";
import M from "materialize-css";
import DeleteModal from "../../../modals/DeleteModal";

export const ForumActions = ({
  likeCount,
  postId,
  userId,
  commentCount,
  auth,
  navigate,
  userLikes,
  userSaves,
  isCommentDisabled,
  copyToClipboard,
  shareOnFacebook,
  shareOnTwitter,
  setPostData,
  handleSubmit,
  updateFormValue,
  postStatus,
  handleDelete,
  handleEnable,
  handleDisable,
  postData,
  disableActions,
  mainView,
  setMainView,
}) => {
  const dispatch = useDispatch();
  const { reportModal } = useSelector((state) => state.modal);
  const reportRef = useRef();
  const shareRef = useRef();
  const editRef = useRef();

  const handleCommentFocus = () => {
    const commentBox = document.querySelector("#main__comment");
    commentBox.focus();
  };

  useEffect(() => {
    const share = document.querySelector(".btn__share");
    shareRef.current = M.Dropdown.init(share, {
      coverTrigger: false,
      alignment: "right",
      constrainWidth: false,
    });

    const reCalculate = () => {
      shareRef.current.recalculateDimensions();
    };

    if (share) {
      window.addEventListener("resize", reCalculate);
      return () => window.removeEventListener("resize", reCalculate);
    }
  }, []);
  useEffect(() => {
    const edit = document.querySelector(".btn__edit");
    editRef.current = M.Dropdown.init(edit, {
      coverTrigger: false,
      alignment: "right",
      constrainWidth: false,
      onOpenStart: () => {
        setPostData((state) => ({
          ...state,
          id: postId,
        }));
      },
    });

    const reCalculate = () => {
      editRef.current.recalculateDimensions();
    };

    if (edit) {
      window.addEventListener("resize", reCalculate);
      return () => window.removeEventListener("resize", reCalculate);
    }
  }, [ auth.uid]);

  const handleReportClick = () => {
    setPostData((state) => ({
      ...state,
      id: postId,
      type: "post",
    }));
    dispatch(SET__REPORT__MODAL(true));
  };

  // console.log(dispatch(SET__REPORT__MODAL(true)));
  return (
    <>
      <section className="blob-item ">
        <div className="blob-action-area">
          <div className="action-lr">
            <div className="l">
              {isEmpty(userLikes) ? (
                <button
                  className={`${btnStyles["auth-blob-btn"]}`}
                  onClick={() => {
                    auth.uid
                      ? dispatch(likePost(postId))
                      : navigate(
                          `/auth/signin?nav=${window.location.pathname}`
                        );
                  }}
                  title={"Like post"}
                  disabled={disableActions}
                >
                  <HeartIcon width={20} theme={`cl-icon-light`} />
                  {likeCount}
                </button>
              ) : userLikes && !userLikes.posts.includes(postId) ? (
                <button
                  onClick={() => {
                    auth.uid
                      ? dispatch(likePost(postId))
                      : navigate(
                          `/auth/signin?nav=${window.location.pathname}`
                        );
                  }}
                  className={`${btnStyles["auth-blob-btn"]}`}
                  title={"Like post"}
                  disabled={disableActions}
                >
                  <HeartIcon width={20} theme={`cl-icon-light`} />
                  {likeCount}
                </button>
              ) : (
                <button
                  onClick={() => {
                    auth.uid
                      ? dispatch(unLikePost(postId))
                      : navigate(
                          `/auth/signin?nav=${window.location.pathname}`
                        );
                  }}
                  className={`${btnStyles["auth-blob-btn"]}`}
                  title={"Unlike post"}
                  disabled={disableActions}
                >
                  <FilledHeartIcon width={20} theme={`cl-icon-light`} />
                  {likeCount}
                </button>
              )}
            </div>
            <div className="l">
              <button
                className={`${btnStyles["auth-blob-btn"]}`}
                title={
                  isCommentDisabled ? "Comment" : "Comment has been disabled."
                }
                onClick={() => {
                  auth.uid
                    ? handleCommentFocus()
                    : navigate(`/auth/signin?nav=${window.location.pathname}`);
                }}
                disabled={!isCommentDisabled || disableActions}
              >
                <CommentIcon width={20} theme={`cl-icon-light`} />
                {commentCount}
              </button>
            </div>
          </div>
          <div className="action-rl">
            <div className="r">
              {isEmpty(userSaves) ? (
                <button
                  onClick={() => {
                    auth.uid
                      ? dispatch(savePost(postId))
                      : navigate(
                          `/auth/signin?nav=${window.location.pathname}`
                        );
                  }}
                  className={`${btnStyles["auth-blob-btn"]}`}
                  title="Save post"
                  disabled={disableActions}
                >
                  <SavedIcon width={22} theme={`cl-icon-light`} />
                </button>
              ) : userSaves && !userSaves.posts.includes(postId) ? (
                <button
                  onClick={() => {
                    auth.uid
                      ? dispatch(savePost(postId))
                      : navigate(
                          `/auth/signin?nav=${window.location.pathname}`
                        );
                  }}
                  className={`${btnStyles["auth-blob-btn"]}`}
                  title="Save post"
                  disabled={disableActions}
                >
                  <SavedIcon width={22} theme={`cl-icon-light`} />
                </button>
              ) : (
                <button
                  onClick={() => {
                    auth.uid
                      ? dispatch(unSavePost(postId))
                      : navigate(
                          `/auth/signin?nav=${window.location.pathname}`
                        );
                  }}
                  className={`${btnStyles["auth-blob-btn"]}`}
                  title="Unsave post"
                  disabled={disableActions}
                >
                  <ActiveSavedIcon width={22} theme={`cl-icon-light`} />
                </button>
              )}
            </div>
            <div className="r">
              <button
                className={`${btnStyles["auth-blob-btn"]}`}
                title="Report post"
                onClick={() => {
                  auth.uid
                    ? handleReportClick()
                    : navigate(`/auth/signin?nav=${window.location.pathname}`);
                }}
                disabled={disableActions}
              >
                <ReportIcon width={22} theme={`cl-icon-light`} />
              </button>
            </div>
            <div className="r">
              <button
                className={`${btnStyles["auth-blob-btn"]} btn__share`}
                title="Share post"
                data-target={`btn-share`}
                disabled={disableActions}
              >
                <ShareIcon width={22} theme={`cl-icon-light`} />
              </button>
              <ul id="btn-share" className="dropdown-content">
                <li
                  className="custom__list"
                  style={{ width: "100%", gap: "10px", padding: "7px 14px" }}
                >
                  <button
                    className={`${btnStyles["auth-blob-btn"]}`}
                    style={{ width: "100%", justifyContent: "flex-start" }}
                    title="Report post"
                    onClick={copyToClipboard}
                  >
                    <LinkIcon width={20} />
                    Copy link
                  </button>
                </li>
                <li
                  className="custom__list"
                  style={{ width: "100%", gap: "10px", padding: "7px 14px" }}
                >
                  <button
                    className={`${btnStyles["auth-blob-btn"]}`}
                    style={{ width: "100%", justifyContent: "flex-start" }}
                    title="Report post"
                    onClick={shareOnFacebook}
                  >
                    <FbIcon width={20} />
                    Share on Facebook
                  </button>
                </li>
                <li
                  className="custom__list"
                  style={{ width: "100%", gap: "10px", padding: "7px 14px" }}
                >
                  <button
                    className={`${btnStyles["auth-blob-btn"]}`}
                    style={{ width: "100%", justifyContent: "flex-start" }}
                    title="Report post"
                    onClick={shareOnTwitter}
                  >
                    <XIcon width={20} />
                    Share on X (Twitter)
                  </button>
                </li>
              </ul>
            </div>
            {auth.uid && auth.uid === userId && (
              <div className="r">
                <button
                  className={`${btnStyles["auth-blob-btn"]} btn__edit`}
                  data-target={`btn-edit`}
                >
                  <ThreeDots width={22} theme={`cl-icon-light`} />
                </button>
                <ul id="btn-edit" className="dropdown-content">
                  <li
                    className="custom__list"
                    style={{ width: "100%", gap: "10px", padding: "7px 14px" }}
                  >
                    <button
                      className={`${btnStyles["auth-blob-btn"]}`}
                      style={{ width: "100%", justifyContent: "flex-start" }}
                      title="Delete post"
                      onClick={() => setMainView(true)}
                    >
                      <RemoveIcon width={20} />
                      Delete {disableActions ? "draft" : "post"}
                    </button>
                  </li>
                  <li
                    className="custom__list"
                    style={{ width: "100%", gap: "10px", padding: "7px 14px" }}
                  >
                    <button
                      className={`${btnStyles["auth-blob-btn"]}`}
                      style={{ width: "100%", justifyContent: "flex-start" }}
                      title={
                        !isCommentDisabled
                          ? "Enable comment"
                          : "Disable comment"
                      }
                      onClick={
                        !isCommentDisabled ? handleDisable : handleEnable
                      }
                      disabled={disableActions}
                    >
                      <CommentIcon width={20} />
                      {!isCommentDisabled
                        ? "Enable comment"
                        : "Disable comment"}
                    </button>
                  </li>
                  {postStatus === 3 && (
                    <li
                      className="custom__list"
                      style={{
                        width: "100%",
                        gap: "10px",
                        padding: "7px 14px",
                      }}
                    >
                      <button
                        className={`${btnStyles["auth-blob-btn"]}`}
                        style={{ width: "100%", justifyContent: "flex-start" }}
                        title="Edit draft"
                        onClick={() => {
                          window.location.href = `/me/${postId}/edit`;
                        }}
                      >
                        <WriteIcon width={20} />
                        Edit draft
                      </button>
                    </li>
                  )}
                </ul>
              </div>
            )}
          </div>
        </div>
      </section>
      {reportModal && (
        <ReportModal
          modalRef={reportRef}
          reportModal={reportModal}
          postData={postData}
          setPostData={setPostData}
          handleSubmit={handleSubmit}
          updateFormValue={updateFormValue}
          text={`Post`}
        />
      )}
      {mainView && (
        <DeleteModal
          view={mainView}
          setView={setMainView}
          text={"Post"}
          callback={handleDelete}
        />
      )}
    </>
  );
};

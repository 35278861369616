import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  reportModal: false,
  commentReportModal: false,
  reviewReportModal: false,
  deleteModal: false,
};

const ModalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    SET__REPORT__MODAL: (state, { payload }) => {
      state.reportModal = payload;
    },
    SET__COMMENT__REPORT__MODAL: (state, { payload }) => {
      state.commentReportModal = payload;
    },
    SET__REVIEW__REPORT__MODAL: (state, { payload }) => {
      state.reviewReportModal = payload;
    },
    SET__DELETE__MODAL: (state, { payload }) => {
      state.deleteModal = payload;
    },
  },
});

export const {
  SET__REPORT__MODAL,
  SET__DELETE__MODAL,
  SET__COMMENT__REPORT__MODAL,
  SET__REVIEW__REPORT__MODAL,
} = ModalSlice.actions;
export default ModalSlice.reducer;

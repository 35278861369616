import React, { useEffect, useRef, useState } from "react";
import { NotificationIcon } from "../../assets/icons/Icons";
import M from "materialize-css";
import { compose } from "redux";
import { firestoreConnect, isEmpty, isLoaded } from "react-redux-firebase";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../general/components/Loader";
import moment from "moment";
import { updateNotifications } from "../../stores/crud/CrudSlice";

const Notification = ({ auth, screen }) => {
  const modalRef = useRef();
  const dispatch = useDispatch();
  const [status, setStatus] = useState(false);
  const notifications = useSelector(
    ({ firestore: { ordered } }) =>
      ordered[`notifications`] && ordered[`notifications`]
  );

  useEffect(() => {
    const nav = document.querySelector(".nav__notification");
    modalRef.current = M.Dropdown.init(nav, {
      coverTrigger: true,
      alignment: "left",
      constrainWidth: false,
      closeOnClick: true,
      onOpenEnd: () => {
        setStatus(true);
      },
      onCloseEnd: () => {
        setStatus(false);
      },
    });

    const reCalculate = () => {
      modalRef.current.recalculateDimensions();
    };

    if (nav) {
      window.addEventListener("resize", reCalculate);
      return () => {
        window.removeEventListener("resize", reCalculate);
        modalRef.current.destroy();
      };
    }
  }, [auth, screen.width]);

  const alerts = useSelector(
    ({ firestore: { ordered } }) => ordered[`alerts`] && ordered[`alerts`]
  );

  const { account } = useSelector((state) => state.firebase.profile);

  useEffect(() => {
    if (modalRef.current.isOpen) {
      dispatch(updateNotifications("id"));
    }
  }, [status]);

  return (
    <li className="nav-link">
      <a
        href="/"
        className="nav-link nav__notification"
        id="nav__notification"
        data-target="nav-notification"
        style={{ position: "relative" }}
      >
        <NotificationIcon width={22} theme={`cl-icon-light`} />
        {isLoaded(alerts) && !isEmpty(alerts) && (
          <span
            style={{
              width: "5px",
              height: "5px",
              borderRadius: "50%",
              background: "rgb(201, 74, 74)",
              position: "absolute",
              top: "40%",
              left: "45%",
            }}
          ></span>
        )}
      </a>
      <div
        id="nav-notification"
        className="dropdown-content custom__dropdown nav-notification l20"
      >
        <div className="notification-wrapper">
          <h4 className="notification-title">Notifications</h4>
          <div className="notification-body">
            {!isLoaded(notifications) ? (
              <Loader type={`cover`} />
            ) : isEmpty(notifications) &&
              isLoaded(account) &&
              account.notifications ? (
              <>
                <span
                  className="icon-area"
                  style={{ transform: "translateX(140px)" }}
                >
                  <NotificationIcon width={20} theme={`cl-icon-light`} />
                </span>
                <p className="icon-info" style={{ textAlign: "center" }}>
                  No new notification
                </p>
              </>
            ) : isEmpty(notifications) &&
              isLoaded(account) &&
              account.notifications ? (
              <>
                <span
                  className="icon-area"
                  style={{ transform: "translateX(140px)" }}
                >
                  <NotificationIcon width={20} theme={`cl-icon-light`} />
                </span>
                <p className="icon-info" style={{ textAlign: "center" }}>
                  Notifications have been disabled in{" "}
                  <span
                    onClick={() => (window.location.href = "/account/settings")}
                    className="cl-icon-light"
                    style={{ padding: "0", cursor: "pointer" }}
                  >
                    settings
                  </span>
                </p>
              </>
            ) : isLoaded(account) && account.notifications ? (
              <ul className="notification-lists">
                {notifications.slice(0, 5).map((n, index) => {
                  if (n.type === 1) {
                    return (
                      <li className="n-list" key={index}>
                        <p className="info">
                          {n.message}
                          <span>
                            {" "}
                            for{" "}
                            <span
                              onClick={() => (window.location.href = n.payload)}
                              className="cl-icon-light"
                              style={{ padding: "0", cursor: "pointer" }}
                            >
                              {" "}
                              {n.reason.toLowerCase()}
                            </span>
                          </span>
                        </p>
                        <p className="created">
                          {" "}
                          {moment(n.createdAt.toDate()).fromNow()}
                        </p>
                      </li>
                    );
                  }

                  if (n.type === 2) {
                    return (
                      <li className="n-list" key={index}>
                        <p className="info">{n.message}</p>
                        <p className="created">
                          {" "}
                          {moment(n.createdAt.toDate()).fromNow()}
                        </p>
                      </li>
                    );
                  }

                  return;
                })}
              </ul>
            ) : (
              <>
                <span
                  className="icon-area"
                  style={{ transform: "translateX(140px)" }}
                >
                  <NotificationIcon width={20} theme={`cl-icon-light`} />
                </span>
                <p className="icon-info" style={{ textAlign: "center" }}>
                  Notifications have been disabled in{" "}
                  <span
                    onClick={() => (window.location.href = "/account/settings")}
                    className="cl-icon-light"
                    style={{ padding: "0", cursor: "pointer" }}
                  >
                    settings.
                  </span>
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    </li>
  );
};

export default compose(
  firestoreConnect((props) => {
    if (props.auth) {
      return [
        {
          collection: "notifications",
          where: ["to.id", "==", props.auth.uid],
          orderBy: ["createdAt", "desc"],
          storeAs: `notifications`,
        },
        {
          collection: "notifications",
          where: [
            ["to.id", "==", props.auth.uid],
            ["status", "==", 1],
          ],
          orderBy: ["createdAt", "desc"],
          storeAs: `alerts`,
        },
      ];
    }
  })
)(Notification);

import Cookies from "js-cookie";

export function CookieControl() {
  this.setCookie = (val) => {
    Cookies.set("ua_pref", val, { secure: true });
  };

  this.getCookie = (val) => {
    return Cookies.get(val);
  };
}

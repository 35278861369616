import React from "react";
import PropTypes from "prop-types";

const QuillToolBar = ({ quillToolBarRef, handleFormatStyle }) => {
  return (
    <ul className="editor-style" ref={quillToolBarRef}>
      <li>
        <button
          className="btn-plain"
          onClick={handleFormatStyle}
          id="bold"
          title="bold"
        >
          <i className="fa fa-bold"></i>
        </button>
      </li>
      <li>
        <button
          className="btn-plain"
          onClick={handleFormatStyle}
          id="italic"
          title="italic"
        >
          <i className="fa fa-italic"></i>
        </button>
      </li>
      <li>
        <button
          className="btn-plain"
          onClick={handleFormatStyle}
          id="blockquote"
          title="blockquote"
        >
          <i className="fa fa-quote-right"></i>
        </button>
      </li>
      <li>
        <button
          className="btn-plain"
          onClick={handleFormatStyle}
          id="header-1"
          title="header"
        >
          <i className="fa fa-header"></i>
        </button>
      </li>
      <li>
        <button
          className="btn-plain"
          onClick={handleFormatStyle}
          id="list"
          title="list"
        >
          <i className="fa fa-list"></i>
        </button>
      </li>
    </ul>
  );
};

QuillToolBar.propType = {
  quillToolBarRef: PropTypes.object,
  handleFormatStyle: PropTypes.func,
};

export default QuillToolBar;

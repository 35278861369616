import React, { Component } from "react";
import Header from "../../layout/components/Header";
import {
  AndroidIcon,
  AppleIcon,
  BrokenLinkIcon,
  FBIcon,
  InstaIcon,
  LinkedInIcon,
  TubeIcon,
  XIcon,
} from "../../assets/icons/Icons";

const navAttr = { width: 25, theme: "cl-black" };
export class ErrorBound extends Component {
  state = { hasError: false };

  static getDerivedStateFromError(err) {
    return { hasError: true };
  }

  componentDidCatch(err, info) {
    // console.log(err, info);
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          <Header type={`contain-layout`} />
          <div
            className="root-layout"
            style={{
              display: "flex",
              flexDirection: "column",
              minHeight: "100vh",
            }}
          >
            <div className="main-layout" style={{ flex: "1" }}>
              <div className="community-forum">
                <section className={`section-container container v-forum-hero`}>
                  <div className="hero-area" style={{ height: "350px" }}>
                    <div className="hero-content">
                      <div className="hero-info">
                        <BrokenLinkIcon height={60} theme={`cl-icon-p`} />
                        <h3 className="hero-title">404</h3>
                        <p className="hero-desc">
                          Error h4300. This service is currently not available.
                          Try again later
                        </p>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
            <footer className={`main-footer`}>
              <div className="footer-content container">
                <div className="footer-row row">
                  <div className="footer-col col s12 m6 l6 xl6">
                    <div className="footer-copy">© 2023 VOIS Cyprus.</div>
                    <ul className="foot-links">
                      <li>
                        <a href="/privacy-policy">Privacy Policy</a>
                      </li>
                      <li>
                        <a href="/terms-of-use">Terms of Use</a>
                      </li>
                      <li>
                        <a href="/community-guidelines">Community Guidelines</a>
                      </li>
                    </ul>
                  </div>
                  <div className="footer-col col s12 m6 l6 xl6">
                    <div className="footer-links">
                      <ul className="nav-socials">
                        <li>
                          <a
                            href="https://twitter.com/voiscyprus"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <XIcon {...navAttr} />
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.linkedin.com/company/voiscyprus/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <LinkedInIcon {...navAttr} />
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.facebook.com/VOISCyprus/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <FBIcon {...navAttr} />
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.instagram.com/voiscyprus/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <InstaIcon {...navAttr} />
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.youtube.com/VOIS%20Cyprus"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <TubeIcon {...navAttr} />
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.youtube.com/VOIS%20Cyprus"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <AndroidIcon {...navAttr} />
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.youtube.com/VOIS%20Cyprus"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <AppleIcon {...navAttr} />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </footer>
          </div>
        </>
      );
    }

    return this.props.children;
  }
}

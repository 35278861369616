import React, { useEffect, useState } from "react";
import "../../css/main-content.css";
import HomeHeaderFixed from "./components/HomeHeaderFixed";
import ForumCardBox from "../../general/components/ForumCardBox";
import MiniCard from "../../general/components/MiniCard";
import SkillCard from "../../general/components/SkillCard";
import ResourceCard from "../../general/components/ResourceCard";
import moment from "moment";
import Loader from "../../general/components/Loader";
import { isEmpty, isLoaded } from "react-redux-firebase";
import BannerCardSkeleton from "../../general/components/skeletons/BannerCardSkeleton";
import HomeEventFixed from "./components/HomeEventFixed";
import Cookies from "js-cookie";
import SurveyPopUp from "./components/SurveyPopUp";
import HomeBanner from "./components/HomeBanner";

const Home = ({ posts, skills, resources, tags, events, surveys }) => {
  const [eventView, setEventView] = useState(false);
  const [surveyView, setSurveyView] = useState(false);

  const filteredPosts = React.useMemo(() => {
    if (isLoaded(posts)) {
      return posts.filter((data) => {
        return data.status === 0;
      });
    }
  }, [posts]);
  const filteredSkills = React.useMemo(() => {
    if (isLoaded(skills)) {
      return skills.filter((data) => {
        return data.status === 0 && !data.properties.isJob;
      });
    }
  }, [skills]);
  const filteredJobs = React.useMemo(() => {
    if (isLoaded(skills)) {
      return skills.filter((data) => {
        return data.status === 0 && data.properties.isJob;
      });
    }
  }, [skills]);
  const filteredResources = React.useMemo(() => {
    if (isLoaded(resources)) {
      return resources.filter((data) => {
        return data.status === 0;
      });
    }
  }, [resources]);
  const validEvents = React.useMemo(() => {
    if (isLoaded(events)) {
      return events.filter((data) => {
        const localTime = new Date();
        const dateFrom = new Date(data.display.from.toDate());
        const dateTo = new Date(data.display.to.toDate());
        return (
          dateFrom.getTime() <= dateTo.getTime() &&
          dateTo.getTime() >= localTime.getTime()
        );
      });
    }
  }, [events]);

  const validSurveys = React.useMemo(() => {
    if (isLoaded(surveys)) {
      return surveys.filter((data) => {
        const localTime = new Date();
        const dateFrom = new Date(data.display.from.toDate());
        const dateTo = new Date(data.display.to.toDate());
        return (
          dateFrom.getTime() <= dateTo.getTime() &&
          dateTo.getTime() >= localTime.getTime()
        );
      });
    }
  }, [surveys]);

  useEffect(() => {
    if (isLoaded(validEvents) && !isEmpty(validEvents)) {
      // console.log(validEvents)
      const localTime = new Date();
      const dateFrom = new Date(validEvents[0].display.from.toDate());
      const dateTo = new Date(validEvents[0].display.to.toDate());

      // console.log(localTime)
      // console.log(dateTo.getHours(), dateTo.getMinutes())
      if (
        dateFrom.getTime() <= dateTo.getTime() &&
        dateTo.getTime() >= localTime.getTime()
      ) {
        if (Cookies.get("e_stats") === undefined) {
          Cookies.set("e_stats", validEvents[0].id.slice(10).toLowerCase());
          setEventView(true);
        } else if (
          Cookies.get("e_stats") === "null" ||
          Cookies.get("e_stats") === ""
        ) {
          setEventView(false);
        } else if (
          Cookies.get("e_stats") !== undefined &&
          Cookies.get("e_stats") !== validEvents[0].id.slice(10).toLowerCase()
        ) {
          setEventView(true);
        } else {
          setEventView(true);
        }
      } else {
        Cookies.set("e_stats", null);
        setEventView(false);
      }
    }
  }, [validEvents]);

  useEffect(() => {
    if (isLoaded(validSurveys) && !isEmpty(validSurveys)) {
      // console.log(validSurveys)
      const localTime = new Date();
      const dateFrom = new Date(validSurveys[0].display.from.toDate());
      const dateTo = new Date(validSurveys[0].display.to.toDate());

      // console.log(localTime)
      // console.log(dateTo.getHours(), dateTo.getMinutes())
      if (
        dateFrom.getTime() <= dateTo.getTime() &&
        dateTo.getTime() >= localTime.getTime()
      ) {
        if (Cookies.get("s_stats") === undefined) {
          Cookies.set("s_stats", validSurveys[0].id.slice(10).toLowerCase());
          setSurveyView(true);
        } else if (
          Cookies.get("s_stats") === "null" ||
          Cookies.get("s_stats") === ""
        ) {
          setSurveyView(false);
        } else if (
          Cookies.get("s_stats") !== undefined &&
          Cookies.get("s_stats") !== validSurveys[0].id.slice(10).toLowerCase()
        ) {
          setSurveyView(true);
        } else {
          setSurveyView(true);
        }
      } else {
        Cookies.set("s_stats", null);
        setSurveyView(false);
      }
    }
  }, [validSurveys]);

  const handleEventCancel = () => {
    Cookies.set("e_stats", null);
    setEventView(false);
  };

  const handleSurveyCancel = () => {
    Cookies.set("s_stats", null);
    setSurveyView(false);
  };

  const handleRedirect = (link) => {
    window.open(link, "_blank");
    handleSurveyCancel();
  };

  return (
    <>
      <HomeHeaderFixed tags={tags} />
      {eventView && isLoaded(validEvents) && !isEmpty(validEvents) && (
        <HomeEventFixed
          id={validEvents[0].id}
          title={validEvents[0].properties.title}
          handleEventCancel={handleEventCancel}
        />
      )}

      <div className="main-content">
        <HomeBanner />
        {surveyView && isLoaded(validSurveys) && !isEmpty(validSurveys) && (
          <SurveyPopUp
            handleCancel={handleSurveyCancel}
            handleRedirect={handleRedirect}
            link={validSurveys[0].properties.link}
          />
        )}
        <section className="section-container v-stories">
          <h1 className="section-title pd-lr">Top stories</h1>
          <div className="section-row row">
            {!isLoaded(filteredPosts) ? (
              [1, 2, 3, 4, 5, 6].map((d) => (
                <div
                  className="section-col section-card col s12 m6 l4 xl2"
                  key={d}
                >
                  <BannerCardSkeleton />
                </div>
              ))
            ) : isEmpty(filteredPosts) ? (
              <div>No posts yet</div>
            ) : (
              filteredPosts.slice(0, 6).map((s) => {
                return (
                  <div
                    className="section-col section-card col s12 m6 l4 xl2"
                    key={s.id}
                  >
                    <ForumCardBox
                      cardId={s.id}
                      cardBanner={s.properties.banner}
                      cardTag={s.properties.tags[0]}
                      cardTitle={s.properties.title}
                      cardAvatar={s.author.photoURL}
                      cardUserName={s.author.username}
                      cardDate={moment(s.createdAt.toDate()).fromNow()}
                    />
                  </div>
                );
              })
            )}
          </div>
        </section>
        {!isLoaded(filteredSkills) ? (
          <div style={{ position: "relative", height: "100px", width: "100%" }}>
            <Loader type={`cover`} />
          </div>
        ) : isEmpty(filteredSkills) ? null : (
          <div className="section-container v-inject">
            <h1 className="section-title md">Job listings</h1>
            <div className="section-listings">
              {filteredJobs.map((job) => (
                <MiniCard
                  key={job.id}
                  cardId={job.id}
                  cardTitle={job.properties.title}
                  cardAvatar={job.author.photoURL}
                  cardUserName={job.author.username}
                  cardCategory={job.properties.category}
                  cardLocation={job.properties.location[0]}
                  cardType={job.properties.jobStatus}
                  cardAmount={job.properties.price}
                />
              ))}
            </div>
          </div>
        )}

        <section className="section-container v-stories">
          <div className="section-row row">
            {!isLoaded(filteredPosts) ? (
              [1, 2, 3, 4, 5, 6].map((d) => (
                <div
                  className="section-col section-card col s12 m6 l4 xl2"
                  key={d}
                >
                  <BannerCardSkeleton />
                </div>
              ))
            ) : isEmpty(filteredPosts) ? (
              <div>No posts yet</div>
            ) : (
              filteredPosts.slice(6).map((s) => {
                return (
                  <div
                    className="section-col section-card col s12 m6 l4 xl2"
                    key={s.id}
                  >
                    <ForumCardBox
                      cardId={s.id}
                      cardBanner={s.properties.banner}
                      cardTag={s.properties.tags[0]}
                      cardTitle={s.properties.title}
                      cardAvatar={s.author.photoURL}
                      cardUserName={s.author.username}
                      cardDate={moment(s.createdAt.toDate()).fromNow()}
                    />
                  </div>
                );
              })
            )}
          </div>
        </section>
        <section className="section-container v-skills v-bg-break">
          <h1 className="section-title">Recent skills</h1>
          <div className="section-row row" style={{ marginLeft: 0 }}>
            {!isLoaded(filteredSkills) ? (
              <div style={{ position: "relative" }}>
                <Loader type={`cover`} />
              </div>
            ) : isEmpty(filteredSkills) ? (
              <div>No skills yet</div>
            ) : (
              filteredSkills.map((skill) => (
                <div
                  className="section-col dark section-card col s8 m6 l4 xl2"
                  key={skill.id}
                >
                  <SkillCard
                    skillId={skill.id}
                    skillBanner={skill.properties.banners[0]}
                    skillTitle={skill.properties.title}
                    skillTag={skill.properties.category}
                    skillAvatar={skill.author.photoURL}
                    skillAuthor={skill.author.username}
                    skillRateScore={skill.interactions.reviewCount}
                    skillReviewCount={skill.interactions.reviewCount}
                    skillPrice={skill.properties.price}
                  />
                </div>
              ))
            )}
          </div>
        </section>

        <section className="section-container v-resources">
          <h1 className="section-title pd-lr">Resources</h1>
          <div className="section-row row">
            {!isLoaded(filteredResources) ? (
              [1, 2, 3, 4, 5, 6].map((d) => (
                <div
                  className="section-col section-card col s12 m6 l4 xl2"
                  key={d}
                >
                  <BannerCardSkeleton />
                </div>
              ))
            ) : isEmpty(filteredResources) ? (
              <div>No resources yet</div>
            ) : (
              filteredResources.map((s) => {
                return (
                  <div
                    className="section-col section-card col s12 m6 l4 xl2"
                    key={s.id}
                  >
                    <ResourceCard
                      cardId={s.id}
                      cardBanner={s.properties.poster}
                      cardTag={s.properties.category}
                      cardTitle={s.properties.title}
                      cardAvatar={s.author.photoURL}
                      cardUserName={s.author.username}
                      cardDate={moment(s.createdAt.toDate()).fromNow()}
                    />
                  </div>
                );
              })
            )}
          </div>
        </section>
      </div>
    </>
  );
};

export default Home;

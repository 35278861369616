import React from "react";
import SkillCard from "../../../general/components/SkillCard";
import { isEmpty, isLoaded } from "react-redux-firebase";

const MoreFromSkillshop = ({ skills, skillId, authorId }) => {
  const forumSkills = React.useMemo(() => {
    if (isLoaded(skills)) {
      return skills.filter((data) => {
        return [data.status].join("").toLowerCase().indexOf(0) !== -1;
      });
    }
  }, [skills]);

  if (
    !isEmpty(skills) &&
    forumSkills.filter((s) => s.id !== skillId).length > 0
  )
    return (
      <section className="blob-item divide" id="general__lists">
        <div className="blob-tag-area">
          <h4>More from Skillshop</h4>
          <div className="section-row row">
            {forumSkills
              .filter((p) => p.author.id !== authorId)
              .slice(0, 4)
              .map((skill) => (
                <div
                  className="section-col section-card col s12 m6 l6 xl6"
                  key={skill.id}
                >
                  <SkillCard
                    skillId={skill.id}
                    skillBanner={skill.properties.banners[0]}
                    skillTitle={skill.properties.title}
                    skillTag={skill.properties.category}
                    skillAvatar={skill.author.photoURL}
                    skillAuthor={skill.author.username}
                    skillRateScore={skill.interactions.reviewCount}
                    skillReviewCount={skill.interactions.reviewCount}
                    skillPrice={skill.properties.price}
                  />
                </div>
              ))}
          </div>
        </div>
      </section>
    );
};

export default MoreFromSkillshop;

import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { replaceSpaces } from "../functions";

const HousingCard = ({
  cardId,
  cardBanner,
  cardHouseName,
  cardRateScore,
  cardReviews,
  cardHouseCity,
  cardHouseRegion,
}) => {
  const house = useSelector(
    ({ firestore: { ordered } }) =>
      ordered[`house-${cardId}`] && ordered[`house-${cardId}`]
  );

  const handleReviewTotal = () => {
    let total = 0;

    house &&
      house.forEach((doc) => {
        total +=
          Number(doc.rating.exterior) +
          Number(doc.rating.interior) +
          Number(doc.rating.communication) +
          Number(doc.rating.services) +
          Number(doc.rating.security) +
          Number(doc.rating.vfm) +
          Number(doc.rating.hApp) +
          Number(doc.rating.facility);
      });

    if (total === 0) {
      return 0;
    }
    return (total / (8 * house.length)).toFixed(1);
  };

  return (
    <div className="house-card-box" key={cardId}>
      <div className="house-card-img-area">
        <NavLink
          to={`/housing/${replaceSpaces(cardHouseCity).toLowerCase()}/${cardId
            .slice(10)
            .toLowerCase()}`}
        >
          <img
            src={cardBanner}
            className="house-card-img"
            alt={cardHouseName}
          />
        </NavLink>
      </div>
      <div className="house-card-desc-area">
        <div className="house-card-info">
          <div className="house-card-title">
            <NavLink
              to={`/housing/${replaceSpaces(
                cardHouseCity
              ).toLowerCase()}/${cardId.slice(10).toLowerCase()}`}
            >
              {cardHouseName}
            </NavLink>
          </div>
          <div className="house-card-review">
            <span>
              <i className="fa fa-star" aria-hidden="true"></i>
              {house && handleReviewTotal()}
            </span>
            <span>
              ({cardReviews} {cardReviews > 1 ? "reviews" : "review"})
            </span>
          </div>
          <div className="house-card-location">
            <span>
              <i className="fa fa-map-marker" aria-hidden="true"></i>
              <NavLink
                to={`/housing/${replaceSpaces(cardHouseCity).toLowerCase()}`}
              >
                {cardHouseCity}
              </NavLink>
            </span>
            <span>{cardHouseRegion}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

HousingCard.propTypes = {
  cardId: PropTypes.string,
  cardBanner: PropTypes.string,
  cardHouseName: PropTypes.string,
  cardRateScore: PropTypes.number,
  cardReviews: PropTypes.number,
  cardHouseCity: PropTypes.string,
  cardHouseRegion: PropTypes.string,
};

export default compose(
  firestoreConnect((props) => {
    return [
      {
        collection: "ratings",
        doc: props.cardId,
        subcollections: [{ collection: "reviews" }],
        storeAs: `house-${props.cardId}`,
      },
    ];
  })
)(HousingCard);

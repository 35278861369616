import React from "react";
import btnStyles from "../../css/auth-btn.module.css";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const AuthCreateButton = ({ text, link }) => {
  const navigate = useNavigate();
  const { auth } = useSelector((state) => state.firebase);

  return (
    <button
      className={`${btnStyles["auth-btn"]} browser-default`}
      onClick={() => {
        auth.uid
          ? navigate(link)
          : navigate(`/auth/signin?nav=${window.location.pathname}`);
      }}
    >
      <span className="auth-btn-icon">
        <i className="fa fa-plus-circle" aria-hidden="true"></i>
      </span>
      <span className={`${btnStyles["auth-btn-text"]}`}>{text}</span>
    </button>
  );
};

AuthCreateButton.propTypes = {
  text: PropTypes.string,
};

export default AuthCreateButton;

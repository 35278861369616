import React, { useContext } from "react";
import { ValidationContext } from "./ValidationContext";
import PropTypes from "prop-types";
import formStyles from "../../css/forms.module.css";

const DisplayValidationMessages = ({ authError, el }) => {
  const { getFieldMessages } = useContext(ValidationContext);
  return (
    <>
      {authError && (
        <p className={`${formStyles["form-error"]}`}>
          {typeof authError === "object" && authError[el]}
        </p>
      )}
    
      {getFieldMessages(el)
        .slice(0, 1)
        .map((err, index) => (
          <p className={`${formStyles["form-error"]}`} key={index}>
            {err}
          </p>
        ))}
    </>
  );
};
DisplayValidationMessages.propTypes = {
  authError: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  el: PropTypes.string,
};
export default DisplayValidationMessages;

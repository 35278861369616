import React, { useState } from "react";
import {
  girneArea,
  houseOptions,
  iskeleArea,
  lefkosaArea,
  magusaArea,
} from "../../assets/dummies/Static";
import {
  BackIcon,
  CancelIcon,
  HouseCreateIcon,
} from "../../assets/icons/Icons";
// import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import { compose } from "redux";
import AbortableToast from "../../general/components/AbortableToast";
import imageCompression from "browser-image-compression";
import {
  createHouseRecord,
  createHouseReviewDb,
} from "../../stores/crud/CrudSlice";
import { replaceSpaces } from "../../general/functions";
import Loader from "../../general/components/Loader";

const CreateHouseRating = () => {
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const [next, setNext] = useState(true);
  const [back, setBack] = useState(false);
  const { isCrudLoading } = useSelector((state) => state.crud);
  const { account } = useSelector((state) => state.firebase.profile);

  const [ratingState, setRatingState] = useState({
    city: "",
    area: "",
    building: "unavailable",
    files: [],
    compFiles: [],
    exterior: 0,
    communication: 0,
    interior: 0,
    hApp: 0,
    facility: 0,
    services: 0,
    security: 0,
    vfm: 0,
    comment: "",
    apartment: 0,
    floor: 0,
  });
  const ratings = useSelector(
    ({ firestore: { ordered } }) => ordered["house-ratings"]
  );
  const handleOnChange = (evt) => {
    if (evt.target.type === "radio") {
      setRatingState((state) => ({
        ...state,
        [evt.target.name]: Number(evt.target.value),
      }));
    } else {
      if (ratingState[evt.target.name].length + 1 === evt.target.maxLength) {
        AbortableToast(
          "You have exceeded the total length for your description!"
        );
      }
      setRatingState((state) => ({
        ...state,
        [evt.target.name]: evt.target.value,
      }));
    }
  };

  const isBuildingAvailable = React.useMemo(() => {
    if (isLoaded(ratings)) {
      return ratings.filter((data) => {
        return (
          [data.building.name]
            .join("")
            .toLowerCase()
            .indexOf(ratingState.building.toLowerCase()) !== -1
        );
      });
    }
  }, [ratings, ratingState.building]);

  const updateFormValue = (evt) => {
    setRatingState((state) => ({
      ...state,
      [evt.target.name]: evt.target.value,
    }));
  };
  const selectHouseCity = (evt) => {
    if (ratingState.city === evt.target.innerText) {
      setRatingState((state) => ({
        ...state,
        city: "",
      }));
      return;
    }
    setRatingState((state) => ({
      ...state,
      city: evt.target.innerText,
    }));
  };
  const selectHouseArea = (evt) => {
    if (ratingState.area === evt.target.innerText) {
      setRatingState((state) => ({
        ...state,
        area: "",
      }));
      return;
    }
    setRatingState((state) => ({
      ...state,
      area: evt.target.innerText,
    }));
  };

  const selectApartment = (evt) => {
    if (ratingState.building === evt.target.innerText) {
      setRatingState((state) => ({
        ...state,
        building: "",
      }));
      return;
    }
    setRatingState((state) => ({
      ...state,
      building: evt.target.innerText,
    }));
  };

  // compress images
  const compressImageFile = async (file) => {
    const options = {
      maxWidthOrHeight: 700,
      useWebWorker: true,
      fileType: "image/webp",
    };

    const compFile = await imageCompression(file, options);
    await mapToState(compFile);
  };

  // mapto state
  const mapToState = async (blob) => {
    const temp = await blob;
    setRatingState((state) => ({
      ...state,
      compFiles: [...state.compFiles, temp],
    }));
  };

  const handleImageChange = () => {
    const input = document.querySelector("#file");
    const imgArr = [];
    const files = input.files;

    if (files.length > 6) {
      AbortableToast("Maximum upload is 6. Limit Exceeded!");
      return;
    }

    setRatingState((state) => ({ ...state, compFiles: [] }));
    // push files to array
    for (let i = 0; i < files.length; i++) {
      imgArr.push(files[i]);
      compressImageFile(files[i]);
    }
    setRatingState((state) => ({ ...state, files: [...files] }));
  };

  const removeImageFromLists = (index) => {
    setRatingState((state) => ({
      ...state,
      files: state.files.filter((b) => b !== state.files[index]),
      compFiles: state.compFiles.filter((b) => b !== state.compFiles[index]),
    }));
  };

  const handleRatingSubmit = () => {
    if (ratingState.city === "" || ratingState.city === null) {
      AbortableToast("Provide the city for this building!");
      return;
    }

    if (ratingState.area === "" || ratingState.area === null) {
      AbortableToast("Provide the area for this building!");
      return;
    }

    if (ratingState.building === "" || ratingState.building === null) {
      AbortableToast("Provide the building name of this building!");
      return;
    }

    if (ratingState.apartment === "" || ratingState.apartment === null) {
      AbortableToast("Provide the apartment number!");
      return;
    }

    if (ratingState.floor === "" || ratingState.floor === null) {
      AbortableToast("Provide the floor number!");
      return;
    }

    if (ratingState.files.length === 0) {
      AbortableToast("Provide an image as proof for this rating!");
      return;
    }

    const data = {
      city: ratingState.city,
      area: ratingState.area,
      building: ratingState.building,
      files: ratingState.files,
      compFiles: ratingState.compFiles,
      exterior: ratingState.exterior,
      communication: ratingState.communication,
      interior: ratingState.interior,
      hApp: ratingState.hApp,
      facility: ratingState.facility,
      services: ratingState.services,
      security: ratingState.security,
      vfm: ratingState.vfm,
      comment: ratingState.comment,
      apartment: ratingState.apartment,
      floor: ratingState.floor,
    };
    // console.log(ratingState)
    // upload to firebase
    dispatch(createHouseRecord(data))
      .then((doc) => {
        const id = doc.payload.id;
        const rdata = { ...data, id };
        dispatch(createHouseReviewDb(rdata))
          .then((res) => {
            // console.log(res);
            setTimeout(() => {
              window.location.replace(
                `/housing/${replaceSpaces(
                  ratingState.city
                ).toLowerCase()}/${doc.payload.id.slice(10).toLowerCase()}`,
                {
                  replace: true,
                }
              );
            }, 500);
          })
          .catch((err) => {
            AbortableToast("Could not create review. Try again later");
          });
      })
      .catch((err) => {
        AbortableToast("Could not create review. Try again later");
      });
  };

  return (
    <>
      <div className="create-content">
        <h3 className="content-title">Rate a house</h3>
        {!isLoaded(account) ? (
          <Loader type={`cover`} />
        ) : isLoaded(account) && account.isSuspended ? (
          <div className="content-area down__slide__fx">
            <div className="content-error">
              <p>
                You are currently suspended from using this feature.{" "}
                <a href="/submit-a-complaint"> Submit a complaint</a>
              </p>
            </div>
          </div>
        ) : (
          <div className="content-area">
            <div
              className="content-box down__slide__fx"
              style={{ display: next ? "flex" : "none" }}
              id="step__uno"
            >
              <div className="content-input" id="city">
                <p className="label">Location: Select a city</p>
                <ul className="forum-tags">
                  <li>
                    <button
                      className={`list-btn ${
                        ratingState.city === "Famagusta-Gazimağusa"
                          ? "selected"
                          : ""
                      }`}
                      onClick={selectHouseCity}
                    >
                      Famagusta-Gazimağusa
                    </button>
                  </li>
                  <li>
                    <button
                      className={`list-btn ${
                        ratingState.city === "Kyrenia-Girne" ? "selected" : ""
                      }`}
                      onClick={selectHouseCity}
                    >
                      Kyrenia-Girne
                    </button>
                  </li>
                  <li>
                    <button
                      className={`list-btn ${
                        ratingState.city === "Trikomo-Iskele" ? "selected" : ""
                      }`}
                      onClick={selectHouseCity}
                    >
                      Trikomo-Iskele
                    </button>
                  </li>
                  <li>
                    <button
                      className={`list-btn ${
                        ratingState.city === "Nicosia-Lefkoşa" ? "selected" : ""
                      }`}
                      onClick={selectHouseCity}
                    >
                      Nicosia-Lefkoşa
                    </button>
                  </li>
                </ul>
              </div>
              {ratingState.city.length > 0 && (
                <>
                  {ratingState.city === "Famagusta-Gazimağusa" ? (
                    <div className="content-input" id="area">
                      <p className="label">Location: Select an area</p>
                      <ul className="forum-tags">
                        {magusaArea.map((area) => (
                          <li key={area}>
                            <button
                              className={`list-btn ${
                                ratingState.area === area ? "selected" : ""
                              }`}
                              onClick={selectHouseArea}
                            >
                              {area}
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ) : ratingState.city === "Kyrenia-Girne" ? (
                    <div className="content-input" id="area">
                      <p className="label">Location: Select an area</p>
                      <ul className="forum-tags">
                        {girneArea.map((area) => (
                          <li key={area}>
                            <button
                              className={`list-btn ${
                                ratingState.area === area ? "selected" : ""
                              }`}
                              onClick={selectHouseArea}
                            >
                              {area}
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ) : ratingState.city === "Nicosia-Lefkoşa" ? (
                    <div className="content-input" id="area">
                      <p className="label">Location: Select an area</p>
                      <ul className="forum-tags">
                        {lefkosaArea.map((area) => (
                          <li key={area}>
                            <button
                              className={`list-btn ${
                                ratingState.area === area ? "selected" : ""
                              }`}
                              onClick={selectHouseArea}
                            >
                              {area}
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ) : ratingState.city === "Trikomo-Iskele" ? (
                    <div className="content-input" id="area">
                      <p className="label">Location: Select an area</p>
                      <ul className="forum-tags">
                        {iskeleArea.map((area) => (
                          <li key={area}>
                            <button
                              className={`list-btn ${
                                ratingState.area === area ? "selected" : ""
                              }`}
                              onClick={selectHouseArea}
                            >
                              {area}
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ) : null}
                </>
              )}

              <div className="content-input" id="house__name">
                <div className="content-input" id="db__houses">
                  <p className="label">
                    Start typing your apartment name. Select from option if
                    available
                  </p>
                  <ul className="forum-tags fx-fade">
                    {isBuildingAvailable &&
                      isBuildingAvailable.splice(0, 1).map((item) => (
                        <li key={item.id} onClickCapture={selectApartment}>
                          <button
                            className={`list-btn ${
                              ratingState.building === item.building.name
                                ? "selected"
                                : ""
                            }`}
                          >
                            {item.building.name}
                          </button>
                        </li>
                      ))}
                  </ul>
                </div>
                <label htmlFor="building"></label>
                <input
                  type="text"
                  autoComplete="off"
                  placeholder="Apartment name"
                  id="building"
                  name="building"
                  onChange={updateFormValue}
                />
              </div>
              <div className="content-input">
                <div className="content-row row" style={{ display: "block" }}>
                  <div className="content-col col s12 m12 l6 xl6">
                    <label htmlFor="apartment"></label>
                    <input
                      type="number"
                      placeholder="Apartment number"
                      id="apartment"
                      name="apartment"
                      autoComplete="off"
                      onKeyDown={(evt) =>
                        ["e", "E", "+", "-"].includes(evt.key) &&
                        evt.preventDefault()
                      }
                      onChange={updateFormValue}
                    />
                  </div>
                  <div className="content-col col s12 m12 l6 xl6">
                    <label htmlFor="floor"></label>
                    <input
                      type="number"
                      placeholder="Apartment floor "
                      id="floor"
                      name="floor"
                      autoComplete="off"
                      onKeyDown={(evt) =>
                        ["e", "E", "+", "-"].includes(evt.key) &&
                        evt.preventDefault()
                      }
                      onChange={updateFormValue}
                    />
                  </div>
                </div>
              </div>
              <div className="content-input">
                <p className="label">Any images related to this Apartment?</p>
                <div className="content-row row">
                  <div className="content-col col s12 m12 l12 x12">
                    <div className="poster-area">
                      <div className="editor-featured-upload">
                        <label htmlFor="file" className="featured-label">
                          <i
                            className="fa fa-cloud-upload"
                            aria-hidden="true"
                          ></i>
                          Add a cover image
                        </label>
                        <input
                          accept="image/*"
                          type="file"
                          id="file"
                          name="file"
                          onChange={handleImageChange}
                          multiple="multiple"
                        />
                      </div>
                    </div>
                    {ratingState.files.length > 0 && (
                      <div className="poster-display">
                        <ul className="forum-tags">
                          {ratingState.files.map((b, i) => (
                            <li key={i}>
                              <p className={`list-btn`}>
                                <span>{b.name}</span>
                                <button
                                  onClick={() => {
                                    removeImageFromLists(i);
                                  }}
                                >
                                  <CancelIcon width={15} />
                                </button>
                              </p>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="content-editor-actions">
                <button
                  onClick={() => {
                    setNext(false);
                    setBack(true);
                  }}
                  className="btn-next"
                  id="next"
                >
                  Next
                </button>
              </div>
            </div>
            <div
              className="content-box up__slide__fx"
              id="step__deux"
              style={{ display: back ? "flex" : "none", paddingTop: "0" }}
            >
              <div className="poster-action" style={{ marginBottom: "1rem" }}>
                <button
                  onClick={() => {
                    setBack(false);
                    setNext(true);
                  }}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  <BackIcon width={20} theme={`cl-icon-light`} /> Back
                </button>
              </div>
              <div className="content-input">
                <div className="form-area" style={{ marginTop: "0" }}>
                  <h3 className="form-title sm" style={{ marginTop: "0" }}>
                    Rating categories
                  </h3>
                  {houseOptions.map((option, ind) => (
                    <div
                      className="col s12 m6 l7"
                      style={{ marginBottom: "1rem" }}
                      key={ind}
                    >
                      <div className="blob-rate__type">{option.name}</div>
                      <div className="blob-rate">
                        {[5, 4, 3, 2, 1].map((n, index) => (
                          <React.Fragment key={`${option.id}${index}`}>
                            <input
                              type="radio"
                              id={`${option.id}${n}`}
                              name={option.id}
                              value={n}
                              onChange={handleOnChange}
                            />
                            <label
                              htmlFor={`${option.id}${n}`}
                              title={`${n} star`}
                            >
                              {n} stars
                            </label>
                          </React.Fragment>
                        ))}
                      </div>
                    </div>
                  ))}
                  <div className="form-group">
                    <label htmlFor="comment"></label>
                    <textarea
                      name="comment"
                      placeholder="Additional comments"
                      id="comment"
                      maxLength={400}
                      value={ratingState.comment}
                      onChange={handleOnChange}
                    ></textarea>
                  </div>
                </div>
              </div>
              <div
                className="content-editor-actions"
                style={{ borderTop: "0", marginTop: "0" }}
              >
                <button
                  className="btn-next"
                  id="next"
                  disabled={isCrudLoading}
                  onClick={handleRatingSubmit}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="create-float hide-on-med-and-down">
        <div className="action-illustrator">
          <HouseCreateIcon />
        </div>
      </div>
    </>
  );
};

export default compose(
  firestoreConnect(() => [{ collection: "ratings", storeAs: "house-ratings" }])
)(CreateHouseRating);

import React from "react";
import SkillCard from "../../../general/components/SkillCard";
import { isEmpty, isLoaded } from "react-redux-firebase";

const MoreFromAuthor = ({ skills, authorId, skillId }) => {
  const authorSkills = React.useMemo(() => {
    if (isLoaded(skills)) {
      return skills.filter((data) => {
        return (
          [data.author.id, data.status]
            .join("")
            .toLowerCase()
            .indexOf(authorId.toLowerCase() || 0) !== -1
        );
      });
    }
  }, [skills, authorId]);

  if (
    !isEmpty(authorSkills) &&
    authorSkills.filter((s) => s.status === 0 && s.id !== skillId).length > 0
  )
    return (
      <section className="blob-item divide" id="general__lists">
        <div className="blob-tag-area">
          <h4>More from Owner</h4>
          <div className="section-row row">
            {authorSkills
              .filter((s) => s.status === 0 && s.id !== skillId)
              .slice(0, 4)
              .map((skill) => (
                <div
                  className="section-col section-card col s12 m6 l6 xl6"
                  key={skill.id}
                >
                  <SkillCard
                    skillId={skill.id}
                    skillBanner={skill.properties.banners[0]}
                    skillTitle={skill.properties.title}
                    skillTag={skill.properties.category}
                    skillAvatar={skill.author.photoURL}
                    skillAuthor={skill.author.username}
                    skillRateScore={skill.interactions.reviewCount}
                    skillReviewCount={skill.interactions.reviewCount}
                    skillPrice={skill.properties.price}
                  />
                </div>
              ))}
          </div>
        </div>
      </section>
    );
};

export default MoreFromAuthor;

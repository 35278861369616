import React from "react";
import ForumCardBox from "../../../general/components/ForumCardBox";
// import { storyLists } from "../../../assets/dummies/Static";
import { isLoaded } from "react-redux-firebase";
import moment from "moment";

const MoreFromForum = ({ posts, authorId }) => {
  const forumPosts = React.useMemo(() => {
    if (isLoaded(posts)) {
      return posts.filter((data) => {
        return [data.status].join("").toLowerCase().indexOf(0) !== -1;
      });
    }
  }, [posts]);

  return (
    <section className="blob-item divide" id="general__lists">
      <div className="blob-tag-area">
        <h4>More from Forum</h4>
        <div className="section-row row">
          {forumPosts
            .filter((p) => p.author.id !== authorId)
            .slice(0, 4)
            .map((s) => {
              return (
                <div
                  className="section-col section-card col s12 m6 l6 xl6"
                  key={s.id}
                >
                  <ForumCardBox
                    cardId={s.id}
                    cardBanner={s.properties.banner}
                    cardTag={s.properties.tags[0]}
                    cardTitle={s.properties.title}
                    cardAvatar={s.author.photoURL}
                    cardUserName={s.author.username}
                    cardDate={moment(s.createdAt.toDate()).fromNow()}
                  />
                </div>
              );
            })}
        </div>
      </div>
    </section>
  );
};

export default MoreFromForum;

import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import validator from "validator";

const EventCard = ({
  cardId,
  cardBanner,
  cardAvatar,
  cardTitle,
  cardDate,
  cardUserName,
  cardTag,
}) => {
  return (
    <div className="card-box resource" key={cardId}>
      <div className="card-img-area">
        <NavLink to={`/events/${cardId.slice(10).toLowerCase()}`}>
          <img
            src={cardBanner}
            className="card-img"
            alt={cardTitle}
            draggable="false"
            loading="lazy"
          />
        </NavLink>
      </div>
      <div className="card-desc-area">
        <div className="card-tag"></div>
        <div className="card-title">
          <NavLink to={`/events/${cardId.slice(10).toLowerCase()}`}>
            {validator.unescape(cardTitle)}
          </NavLink>
        </div>
        <div className="card-user">
          <div
            className="card-user-info hide-on-med-and-up"
            style={{ marginLeft: "0px" }}
          >
            <div className="card-date">{cardDate}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

EventCard.propTypes = {
  cardId: PropTypes.string,
  cardAvatar: PropTypes.string,
  cardBanner: PropTypes.string,
  cardTitle: PropTypes.string,
  cardDate: PropTypes.string,
  cardUserName: PropTypes.string,
  cardTag: PropTypes.string,
};

export default EventCard;

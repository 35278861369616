import React, { useEffect, useRef, useState } from "react";
import { BackIcon } from "../../assets/icons/Icons";
import QuillEditorContainer from "../../general/components/QuillEditorContainer";
import QuillToolBar from "../../general/components/QuillToolBar";
import { POST__COUNT, POST__LENGTH } from "../../config/globals";
import imageCompression from "browser-image-compression";
import { firestoreConnect, isEmpty, isLoaded } from "react-redux-firebase";
import { useDispatch, useSelector } from "react-redux";
import { compose } from "redux";
import AbortableToast from "../../general/components/AbortableToast";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import validator from "validator";
import { replaceSpaces, replaceSpecial, strip } from "../../general/functions";
import { createPostInDB, updatePostInDB } from "../../stores/crud/CrudSlice";
import WithRouter from "../../general/components/WithRouter";
import Loader from "../../general/components/Loader";
import Error404 from "../Error404";

const $ = window.jQuery;
const EditPost = () => {
  const dispatch = useDispatch();
  const { posts } = useOutletContext();
  // ------refs-------------
  //quill ref to store quill instance
  const quillInstanceRef = useRef();
  //toolbar ref to access toolbar div
  const quillToolbarRef = useRef();
  //quill editor div
  const quillEditorRef = useRef();
  const params = useParams();
  const navigate = useNavigate();

  // states
  const [next, setNext] = useState(true);
  const [back, setBack] = useState(false);
  const { account } = useSelector((state) => state.firebase.profile);
  const { isCrudLoading } = useSelector((state) => state.crud);
  const postTags = useSelector(
    ({ firestore: { ordered } }) => ordered.postTags && ordered.postTags
  );
  const postFromId = useSelector(
    ({ firestore: { ordered } }) => ordered[`${params.pid}-post`]
  );

  const getWordCount = () => {
    const editorContainer = $("#editor-container").get(0);
    const regex = /\s+/gi;
    const count =
      $(editorContainer).find(".ql-editor").text().length > 0
        ? $(editorContainer)
            .find(".ql-editor")
            .text()
            .trim()
            .replace(regex, " ")
            .split(" ").length
        : 0;
    return count;
  };

  const getWordLength = () => {
    const editorContainer = $("#editor-container").get(0);
    const count =
      $(editorContainer).find(".ql-editor").text().length > 0
        ? $(editorContainer).find(".ql-editor").text().trim().length
        : 0;
    return count;
  };

  const [editorState, setEditorState] = useState({
    wordCount: getWordCount() || 0,
    wordLength: getWordLength() || 0,
    title: "",
    subtitle: "",
    content: "",
    contentinHTML: "",
    banner: "",
    tags: ["General"],
    savedId: "",
    isCommentEnabled: true,
  });

  // edit mode
  useEffect(() => {
    if (isLoaded(postFromId) && quillInstanceRef.current !== undefined) {
      console.log(postFromId, quillInstanceRef);
      quillInstanceRef.current.root.innerHTML =
        postFromId[0].properties.contentHTML;
      quillInstanceRef.current.setSelection(
        quillInstanceRef.current.getLength(),
        0
      );
      setEditorState((state) => ({
        ...state,
        wordCount: getWordCount() || 0,
        wordLength: getWordLength() || 0,
        title: postFromId[0].properties.title,
        subtitle: postFromId[0].properties.subTitle,
        content: postFromId[0].properties.contentPlain,
        contentinHTML: postFromId[0].properties.contentHTML,
        banner: postFromId[0].properties.banner,
        tags: postFromId[0].properties.tags,
        savedId: params.pid,
        isCommentEnabled: !postFromId[0].interactions.isCommentDisabled,
      }));
    }
    setTimeout(() => {
      $("#save").text("Save draft").fadeIn();
    }, 3000);
  }, [params.pid, postFromId, quillInstanceRef]);

  // toolbar formatting
  const handleFormatStyle = (evt) => {
    const quill = quillInstanceRef.current;
    const target = evt.currentTarget.id;
    const selection = quill.getSelection(() => false);
    const selectedFormat = quill.getFormat();

    const isSelectedFormatted = selectedFormat.hasOwnProperty(target)
      ? true
      : false;
    switch (target) {
      case "bold":
        isSelectedFormatted
          ? quill.formatText(selection.index, selection.length, "bold", false)
          : quill.formatText(selection.index, selection.length, "bold", true);
        break;
      case "italic":
        isSelectedFormatted
          ? quill.formatText(selection.index, selection.length, "italic", false)
          : quill.formatText(selection.index, selection.length, "italic", true);
        break;
      case "blockquote":
        isSelectedFormatted
          ? quill.format("blockquote", false)
          : quill.format("blockquote", true);
        break;
      case "header-1":
        quill
          .getFormat(selection.index, selection.length)
          .hasOwnProperty("header")
          ? quill.removeFormat(selection.index, selection.length, "header")
          : quill.format("header", 1);
        break;
      case "list":
        isSelectedFormatted
          ? quill.format("list", false)
          : quill.format("list", true);
        break;
      case "list-ul":
        isSelectedFormatted
          ? quill.format("ulist", false)
          : quill.format("ulist", true);
        break;
      default:
    }
  };

  // enable publish button
  const disableButton = () => {
    return editorState.wordCount > 5 ? true : false;
  };

  useEffect(() => {
    if (isLoaded(postFromId) && quillInstanceRef.current !== undefined) {
      quillInstanceRef.current.root.innerHTML =
        postFromId[0].properties.contentHTML;
      quillInstanceRef.current.setSelection(
        quillInstanceRef.current.getLength(),
        0
      );
      setEditorState((state) => ({
        ...state,
        wordCount: getWordCount() || 0,
      }));
      const editorFeatured = document.querySelector(".editor-featured-upload");
      const editorFeaturedImg = document.querySelector(
        ".editor-featured-image"
      );
      const coverImg = document.querySelector("#cover-img");
      if (back) {
        editorFeatured.style.backgroundColor = "transparent";
        if (editorState.banner !== "") {
          editorFeaturedImg.style.zIndex = "10";
          coverImg.src =
            typeof editorState.banner === "string"
              ? editorState.banner
              : URL.createObjectURL(editorState.banner);
          coverImg.style.display = "block";
        }
      }
    }
  }, [next, back, postFromId, quillInstanceRef]);

  // compressed image
  const compressImageFile = async (file) => {
    const options = {
      maxWidthOrHeight: 800,
      useWebWorker: true,
      fileType: "image/webp",
    };

    const compressedFile = await imageCompression(file, options);
    return compressedFile;
  };

  //image preview for upload
  const handleCoverPreview = (evt) => {
    const [file] = document.getElementById("featured-img").files;
    const compressedFile = compressImageFile(file);
    compressedFile.then((cf) => {
      if (cf) {
        setEditorState((state) => ({ ...state, banner: cf }));
      }
    });

    if (file) {
      document.querySelector(".editor-featured-upload").style.backgroundColor =
        "transparent";
      document.querySelector(".editor-featured-image").style.zIndex = "10";

      document.getElementById("cover-img").src = URL.createObjectURL(file);
      document.getElementById("cover-img").style.display = "block";
    }
  };

  const handleCoverAdd = () => {
    document.querySelector("#featured-img").click();
  };

  const handleCoverRemove = () => {
    setEditorState((state) => ({ ...state, banner: "" }));
    document.querySelector(".editor-featured-upload").style.backgroundColor =
      "transparent";
    document.querySelector(".editor-featured-image").style.zIndex = "0";
    document.getElementById("cover-img").src = "";
    document.getElementById("cover-img").style.display = "none";
  };

  const updateFormValue = (evt) => {
    if (evt.target.checked) {
      setEditorState((state) => ({
        ...state,
        [evt.target.name]: evt.target.checked,
      }));
      return;
    }
    setEditorState((state) => ({
      ...state,
      [evt.target.name]: evt.target.value,
    }));
  };

  const selectCategory = (evt) => {
    const removeSelected = (arr, index) => {
      return arr.slice(0, index).concat(arr.slice(index + 1));
    };

    if (editorState.tags.length !== 3) {
      if (editorState.tags.includes(evt.target.innerText)) {
        setEditorState((state) => ({
          ...state,
          tags: removeSelected(
            editorState.tags,
            editorState.tags.indexOf(evt.target.innerText)
          ),
        }));
        return;
      }
      setEditorState((state) => ({
        ...state,
        tags: [...state.tags, evt.target.innerText],
      }));
    } else {
      AbortableToast("Maximum category selection is 3");
    }
  };

  // calculate post duration
  const calculatePostDuration = (wordCount) => {
    const titleCount = editorState.title.length;
    const dividedBy = 200;
    const getInteger = (wordCount + titleCount) / dividedBy;
    const getDecimal = getInteger % 1;
    const roundInteger = Math.floor(getInteger) + Math.ceil(getDecimal * 0.6);

    return roundInteger;
  };

  const isSaveAll = (id) => {
    //TODO make you ajax calls here
    const data = {
      isCommentDisabled: editorState.isCommentEnabled,
      postTitle: validator.escape(editorState.title.trim()),
      postSubTitle: validator.escape(editorState.subtitle.trim()),
      postContentPlain: validator.escape(
        quillInstanceRef.current.container.textContent
      ),
      postContentHtml: strip(quillInstanceRef.current.root.innerHTML),
      postBanner: editorState.banner,
      savedId: id,
      postDuration: calculatePostDuration(editorState.wordCount),
      postTags: [...editorState.tags],
      postStatus: 3,
    };

    if (id === "") {
      // console.log("i saved all");
      dispatch(createPostInDB(data)).then((res) => {
        if (res.payload) {
          navigate(`/me/${res.payload.id}/edit`, { replace: true });
        }
      });
    } else {
      dispatch(updatePostInDB(data)).then((res) => {
        if (res.payload) {
          navigate(`/me/${res.payload.id}/edit`, { replace: true });
        }
      });
    }

    //Return 1 if the save operations is successful and 0 if failed.
    return 1;
  };

  const isSave = () => {
    const inputContainer = $(".input-container").get(0);
    let isSaveReturn = false;
    if (inputContainer) {
      isSaving(inputContainer);

      //Save the text here
      if (isSaveAll(editorState.savedId)) isSaveReturn = true;

      if (isSaveReturn) {
        isSaved();
      } else {
        isError();
      }
    }
    return isSaveReturn;
  };

  const isSaving = () => {
    $("#save").text("Saving...");
  };

  const isSaved = () => {
    $("#save").text("Post saved");
  };

  const isError = () => {
    $("#save").text("Error saving post");
  };

  // handle post submit
  const handlePostSubmit = () => {
    // check for errors
    if (editorState.wordLength > POST__LENGTH) {
      AbortableToast("You have exceeded the total length for your post!");
      return;
    }

    if (editorState.tags === null || editorState.tags.length === 0) {
      AbortableToast("Select one or more post category!");
      return;
    }

    if (editorState.title === null || editorState.title === "") {
      AbortableToast("Add a suitable title for your post!");
      return;
    }

    if (account.isSuspended) {
      AbortableToast("Account suspended. Request post access in settings!");
      return;
    }

    const data = {
      isCommentDisabled: editorState.isCommentEnabled,
      postTitle: validator.escape(editorState.title.trim()),
      postSubTitle: validator.escape(editorState.subtitle.trim()),
      postContentPlain: validator.escape(editorState.content),
      postContentHtml: strip(editorState.contentinHTML),
      postBanner: editorState.banner,
      savedId: editorState.savedId,
      postDuration: calculatePostDuration(editorState.wordCount),
      postTags: [...editorState.tags],
      postStatus: account.isAutoApprove ? 0 : 2,
    };
    if (editorState.savedId === "") {
      // console.log("i saved all");
      dispatch(createPostInDB(data)).then((res) => {
        setTimeout(() => {
          window.location.replace(
            `/forum/${editorState.tags[0].toLowerCase()}/${replaceSpaces(
              replaceSpecial(editorState.title)
            ).toLowerCase()}-${res.payload.id.slice(10).toLowerCase()}`
          );
        }, 200);
      });
    } else {
      dispatch(updatePostInDB(data)).then((res) => {
        // console.log(res);
        setTimeout(() => {
          window.location.replace(
            `/forum/${editorState.tags[0].toLowerCase()}/${replaceSpaces(
              replaceSpecial(editorState.title)
            ).toLowerCase()}-${editorState.savedId.slice(10).toLowerCase()}`
          );
        }, 500);
      });
    }
  };

  return (
    <>
      {!isLoaded(postFromId) ? (
        <Loader type={`cover`} />
      ) : isEmpty(postFromId) ? (
        <Error404
          posts={posts}
          text={"File not found"}
          code={401}
          unset={params.pid !== undefined}
        />
      ) : (
        <div className="create-content">
          <h3 className="content-title">Create a post</h3>
          <div className="content-area input-container">
            <div
              className="content-box down__slide__fx"
              style={{ display: next ? "flex" : "none" }}
              id="step__uno"
            >
              <QuillEditorContainer
                quillInstanceRef={quillInstanceRef}
                quillEditorRef={quillEditorRef}
                setEditorState={setEditorState}
                getWordCount={getWordCount}
                getWordLength={getWordLength}
                postFromId={postFromId}
              />
              <div className="content-editor-props">
                <QuillToolBar
                  quillToolBarRef={quillToolbarRef}
                  handleFormatStyle={handleFormatStyle}
                />
                <p className="editor-count">
                  {editorState.wordCount}/{POST__COUNT}
                </p>
              </div>
              <div className="content-editor-actions" style={{ gap: "10px" }}>
                <button
                  className="btn-next"
                  id="save"
                  disabled={!disableButton()}
                  style={{
                    background: "transparent",
                    color: "var(--tx-main-light)",
                    paddingLeft: "0",
                    fontSize: ".85rem",
                    textDecoration: "underline",
                  }}
                  onClick={isSave}
                >
                  Save draft
                </button>
                <button
                  onClick={() => {
                    setNext(false);
                    setBack(true);
                  }}
                  className="btn-next"
                  id="next"
                  disabled={!disableButton()}
                >
                  Next
                </button>
              </div>
            </div>
            <div
              className="content-box up__slide__fx"
              style={{ display: back ? "flex" : "none" }}
              id="step__deux"
            >
              <div className="poster-action" style={{ marginBottom: "1rem" }}>
                <button
                  onClick={() => {
                    setBack(false);
                    setNext(true);
                  }}
                  style={{ display: "flex", alignItems: "center", gap: "8px" }}
                >
                  <BackIcon width={20} theme={`cl-icon-light`} /> Back
                </button>
              </div>
              <div className="content-input">
                <p className="label">Add a poster</p>
                <div className="content-row row">
                  <div className="content-col col s12 m12 l6 xl6">
                    <div className="poster-area" style={{ height: "100px" }}>
                      <div className="editor-featured-image">
                        <img id="cover-img" src="#" alt="add a cover" />
                      </div>
                      <div className="editor-featured-upload">
                        <label
                          htmlFor="featured-img"
                          className="featured-label"
                        >
                          <i
                            className="fa fa-cloud-upload"
                            aria-hidden="true"
                          ></i>
                          Add a cover image
                        </label>
                        <input
                          onChange={handleCoverPreview}
                          accept="image/*"
                          type="file"
                          id="featured-img"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="content-col col s12 m12 l6 xl6">
                    <div className="poster-action">
                      <button onClick={handleCoverAdd}>
                        {editorState.banner === "" ? "Add" : "Change"}
                      </button>
                      <button
                        onClick={handleCoverRemove}
                        disabled={editorState.banner === ""}
                      >
                        remove
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="content-input">
                <label htmlFor="title"></label>
                <input
                  type="text"
                  placeholder="Title"
                  id="title"
                  name="title"
                  defaultValue={editorState.title}
                  onChange={updateFormValue}
                />
              </div>
              <div className="content-input">
                <label htmlFor="subtitle"></label>
                <input
                  type="text"
                  placeholder="Subtitle"
                  id="subtitle"
                  name="subtitle"
                  defaultValue={editorState.subtitle}
                  onChange={updateFormValue}
                />
              </div>
              <div className="content-input">
                <p className="label">
                  Select one or more category (Maximum: 3)
                </p>
                <ul className="forum-tags">
                  {isLoaded(postTags) &&
                    postTags[0].data.map((tag) => (
                      <li key={tag}>
                        <button
                          className={`list-btn ${
                            editorState.tags.includes(tag) ? "selected" : ""
                          }`}
                          onClick={selectCategory}
                          id={tag}
                        >
                          {tag}
                        </button>
                      </li>
                    ))}
                </ul>
              </div>
              <div className="content-input">
                <p className="label">Enable commenting</p>
                <div className="switch page-switch">
                  <label>
                    <input
                      type="checkbox"
                      id="isCommentEnabled"
                      name="isCommentEnabled"
                      defaultChecked={editorState.isCommentEnabled}
                      onChange={updateFormValue}
                    />
                    <span className="lever"></span>
                  </label>
                </div>
              </div>
              <div className="content-editor-actions" style={{ gap: "10px" }}>
                <button
                  className="btn-next"
                  id="save"
                  disabled={!disableButton()}
                  style={{
                    background: "transparent",
                    color: "var(--tx-main-light)",
                    paddingLeft: "0",
                    fontSize: ".85rem",
                    textDecoration: "underline",
                  }}
                  onClick={isSave}
                >
                  Save draft
                </button>
                <button
                  className="btn-next"
                  id="next"
                  style={{ position: "relative" }}
                  onClick={handlePostSubmit}
                >
                  Submit
                  {isCrudLoading && <Loader />}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default compose(
  WithRouter,
  firestoreConnect((props) => {
    if (props.pid) {
      return [
        {
          collection: "posts",
          doc: props.pid,
          storeAs: `${props.pid}-post`,
        },
        {
          collection: "inits",
          doc: "tags",
          storeAs: "postTags",
        },
      ];
    } else {
      return [
        {
          collection: "inits",
          doc: "tags",
          storeAs: "postTags",
        },
      ];
    }
  })
)(EditPost);

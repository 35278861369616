import React from "react";
import { EmailEditIcon, ForwardIcon } from "../../../assets/icons/Icons";
import formStyles from "../../../css/forms.module.css";
import authStyles from "../../../css/auth.module.css";
import DisplayValidationMessages from "../../../auth/components/DisplayValidationMessages";

const ChangeEmail = ({
  email,
  updateFormValue,
  handleChangeEmail,
  authMail,
  isCrudLoading,
  crudError,
}) => {
  return (
    <li className="collapsible-item" id="set__email">
      <div className="collapsible-header">
        <EmailEditIcon width={20} theme={`cl-icon-light`} />
        <p>
          Email<span>Change your email {email}.</span>
        </p>
        <div className="caret-area">
          <ForwardIcon width={20} theme={`cl-icon-light`} />
        </div>
      </div>
      <div className="collapsible-body">
        <div
          className={`${authStyles["auth-main-items"]}`}
          style={{ maxWidth: "unset" }}
        >
          <div
            className={`${authStyles["auth-main-content"]} ${formStyles["form-pd"]}`}
          >
            <div className={`${formStyles["form-control"]}`}>
              <div className={`${formStyles["form-group"]}`}>
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  autoComplete="off"
                  className="browser-default"
                  defaultValue={email}
                  onChange={updateFormValue}
                />
                <DisplayValidationMessages el={`email`} authError={crudError} />
              </div>
              <div
                className={`${formStyles["form-group"]}`}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <button
                  className={`${formStyles["form-btn"]} browser-default`}
                  id={`${formStyles["submit__main"]}`}
                  type="submit"
                  style={{ width: "fit-content" }}
                  onClick={handleChangeEmail}
                  disabled={authMail === email || isCrudLoading}
                >
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};

export default ChangeEmail;

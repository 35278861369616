import React, { useRef, useState } from "react";
import {
  BackIcon,
  CancelIcon,
  SkillCreateIcon,
} from "../../assets/icons/Icons";
import { compose } from "redux";
import withRouter from "../../general/components/WithRouter";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
import QuillEditorContainer from "../../general/components/QuillEditorContainer";
import QuillToolBar from "../../general/components/QuillToolBar";
import { SERVICES__COUNT, SERVICES__LENGTH } from "../../config/globals";
import imageCompression from "browser-image-compression";
import AbortableToast from "../../general/components/AbortableToast";
import validator from "validator";
import { replaceSpaces, strip, replaceSpecial } from "../../general/functions";
import { createSkillDB } from "../../stores/crud/CrudSlice";
import Loader from "../../general/components/Loader";

const $ = window.jQuery;
const CreateSkill = () => {
  const dispatch = useDispatch();
  // ------refs-------------
  //quill ref to store quill instance
  const quillInstanceRef = useRef();
  //toolbar ref to access toolbar div
  const quillToolbarRef = useRef();
  //quill editor div
  const quillEditorRef = useRef();
  // const navigate = useNavigate();
  const { account, user, socials } = useSelector(
    (state) => state.firebase.profile
  );

  const getWordCount = () => {
    const editorContainer = $("#editor-container").get(0);
    const regex = /\s+/gi;
    const count =
      $(editorContainer).find(".ql-editor").text().length > 0
        ? $(editorContainer)
            .find(".ql-editor")
            .text()
            .trim()
            .replace(regex, " ")
            .split(" ").length
        : 0;
    return count;
  };

  const getWordLength = () => {
    const editorContainer = $("#editor-container").get(0);
    const count =
      $(editorContainer).find(".ql-editor").text().length > 0
        ? $(editorContainer).find(".ql-editor").text().trim().length
        : 0;
    return count;
  };

  const skillTags = useSelector(
    ({ firestore: { ordered } }) => ordered.skillTags && ordered.skillTags
  );

  const [skillState, setSkillState] = useState({
    title: "",
    category: "",
    description: "",
    price: 0,
    isJob: false,
    jobStatus: "",
    wordCount: getWordCount() || 0,
    wordLength: getWordLength() || 0,
    skillBanners: [],
    compFiles: [],
    delFiles: [],
    content: "",
    contentinHTML: "",
    skillLocation: [],
  });

  const [next, setNext] = useState(true);
  const [back, setBack] = useState(false);
  const { isCrudLoading } = useSelector((state) => state.crud);

  const updateFormValue = (evt) => {
    if (evt.target.name === "isJob") {
      setSkillState((state) => ({
        ...state,
        [evt.target.name]: evt.target.checked,
      }));
      return;
    }
    setSkillState((state) => ({
      ...state,
      [evt.target.name]: evt.target.value,
    }));
  };

  const selectCategory = (evt) => {
    if (skillState.category === evt.target.innerText) {
      setSkillState((state) => ({
        ...state,
        category: "",
      }));
      return;
    }
    setSkillState((state) => ({
      ...state,
      category: evt.target.innerText,
    }));
  };

  const selectJobStatus = (evt) => {
    if (skillState.jobStatus === evt.target.innerText) {
      setSkillState((state) => ({
        ...state,
        jobStatus: "",
      }));
      return;
    }
    setSkillState((state) => ({
      ...state,
      jobStatus: evt.target.innerText,
    }));
  };

  const selectLocation = (evt) => {
    const removeSelected = (arr, index) => {
      return arr.slice(0, index).concat(arr.slice(index + 1));
    };

    if (skillState.skillLocation.includes(evt.target.innerText)) {
      setSkillState((state) => ({
        ...state,
        skillLocation: removeSelected(
          skillState.skillLocation,
          skillState.skillLocation.indexOf(evt.target.innerText)
        ),
      }));
      return;
    }
    setSkillState((state) => ({
      ...state,
      skillLocation: [...state.skillLocation, evt.target.innerText],
    }));
  };

  const selectJobLocation = (evt) => {
    setSkillState((state) => ({
      ...state,
      skillLocation: [evt.target.innerText],
    }));
  };

  // toolbar formatting
  const handleFormatStyle = (evt) => {
    const quill = quillInstanceRef.current;
    const target = evt.currentTarget.id;
    const selection = quill.getSelection(() => false);
    const selectedFormat = quill.getFormat();

    const isSelectedFormatted = selectedFormat.hasOwnProperty(target)
      ? true
      : false;
    switch (target) {
      case "bold":
        isSelectedFormatted
          ? quill.formatText(selection.index, selection.length, "bold", false)
          : quill.formatText(selection.index, selection.length, "bold", true);
        break;
      case "italic":
        isSelectedFormatted
          ? quill.formatText(selection.index, selection.length, "italic", false)
          : quill.formatText(selection.index, selection.length, "italic", true);
        break;
      case "blockquote":
        isSelectedFormatted
          ? quill.format("blockquote", false)
          : quill.format("blockquote", true);
        break;
      case "header-1":
        quill
          .getFormat(selection.index, selection.length)
          .hasOwnProperty("header")
          ? quill.removeFormat(selection.index, selection.length, "header")
          : quill.format("header", 1);
        break;
      case "list":
        isSelectedFormatted
          ? quill.format("list", false)
          : quill.format("list", true);
        break;
      case "list-ul":
        isSelectedFormatted
          ? quill.format("ulist", false)
          : quill.format("ulist", true);
        break;
      default:
    }
  };

  // compress images
  const compressImageFile = async (file) => {
    const options = {
      maxWidthOrHeight: 700,
      useWebWorker: true,
      fileType: "image/webp",
    };

    const compFile = await imageCompression(file, options);
    await mapToState(compFile);
  };

  // mapto state
  const mapToState = async (blob) => {
    const temp = await blob;
    setSkillState((state) => ({
      ...state,
      compFiles: [...state.compFiles, temp],
    }));
  };

  const handleImageChange = () => {
    const input = document.querySelector("#file");
    const imgArr = [];
    const files = input.files;

    if (files.length > 6) {
      AbortableToast("Maximum upload is 6. Limit Exceeded!");
      return;
    }

    setSkillState((state) => ({ ...state, compFiles: [] }));
    // push files to array
    for (let i = 0; i < files.length; i++) {
      imgArr.push(files[i]);
      compressImageFile(files[i]);
    }
    setSkillState((state) => ({ ...state, skillBanners: [...files] }));
  };

  const removeImageFromLists = (index) => {
    setSkillState((state) => ({
      ...state,
      skillBanners: state.skillBanners.filter(
        (b) => b !== state.skillBanners[index]
      ),
      compFiles: state.compFiles.filter((b) => b !== state.compFiles[index]),
    }));
  };

  const handleSkillCreate = () => {
    if (skillState.title === "" || skillState.title === null) {
      AbortableToast("Provide a suitable title for your skill.");
      return;
    }

    if (skillState.description === "" || skillState.description === null) {
      AbortableToast("Provide a suitable description for your skill.");
      return;
    }

    // check for errors
    if (skillState.wordLength > SERVICES__LENGTH) {
      AbortableToast("You have exceeded the total length for your services!");
      return;
    }

    if (skillState.contentinHTML === "" || skillState.contentinHTML === null) {
      AbortableToast("Provide description for services to be rendered.");
      return;
    }

    if (skillState.skillLocation.length === 0) {
      AbortableToast("Select one or more location for this skill.");
      return;
    }

    if (skillState.isJob === false && skillState.compFiles.length === 0) {
      AbortableToast("Upload one or more image cover for this skill.");
      return;
    }

    if (skillState.isJob && skillState.jobStatus === "") {
      AbortableToast("Select a job positon/status is required.");
      return;
    }

    let data = {
      skillTitle: validator.escape(skillState.title.trim()),
      compFiles: skillState.compFiles,
      skillCategory: skillState.category,
      isSkillAJob: skillState.isJob,
      jobStatus: skillState.isJob ? skillState.jobStatus : "",
      skillBanners: skillState.skillBanners,
      skillLocation: skillState.skillLocation,
      skillDescription: validator.escape(skillState.description),
      skillServicePlain: validator.escape(skillState.content),
      skillServiceHTML: strip(skillState.contentinHTML),
      status: account.isAutoApprove ? 0 : 2,
      skillPrice: skillState.price,
    };
    // console.log(data);

    // upload to firebase
    dispatch(createSkillDB(data))
      .then((res) => {
        // console.log(res);
        setTimeout(() => {
          window.location.replace(
            `/skillshop/${replaceSpaces(
              skillState.category
            ).toLowerCase()}/${replaceSpaces(
              replaceSpecial(skillState.title)
            ).toLowerCase()}-${res.payload.id.slice(10).toLowerCase()}`
          );
        }, 500);
      })
      .catch((err) => {
        AbortableToast("Could not create skill. Try again later");
      });
  };
  return (
    <>
      <div className="create-content">
        <h3 className="content-title">Create a skill</h3>
        {!isLoaded(user, account, socials) ? (
          <Loader type={`cover`} />
        ) : isLoaded(user, account) && account.isSuspended ? (
          <div className="content-area down__slide__fx">
            <div className="content-error">
              <p>
                You are currently suspended from using this feature.{" "}
                <a href="/submit-a-complaint"> Submit a complaint</a>
              </p>
            </div>
          </div>
        ) : isLoaded(user, account, socials) &&
          socials.facebook === "" &&
          socials.instagram === "" &&
          socials.linkedIn === "" &&
          socials.portfolio === "" &&
          socials.twitter === "" &&
          socials.whatsapp === "" ? (
          <div className="content-area down__slide__fx">
            <div className="content-error">
              <p>
                You have to have atleast one social profile active.{" "}
                <a href="/account/settings"> Please update your social links</a>
              </p>
            </div>
          </div>
        ) : (
          <div className="content-area">
            <div
              className="content-box down__slide__fx"
              style={{ display: next ? "flex" : "none" }}
              id="step__uno"
            >
              <div className="content-input">
                <label htmlFor="title"></label>
                <input
                  type="text"
                  placeholder="Title"
                  id="title"
                  name="title"
                  value={skillState.title}
                  onChange={updateFormValue}
                  maxLength={50}
                />
              </div>
              <div className="content-input">
                <label htmlFor="description"></label>
                <textarea
                  placeholder="Enter a short description for this skill"
                  id="description"
                  name="description"
                  value={skillState.description}
                  onChange={updateFormValue}
                  maxLength={70}
                />
              </div>
              <div className="content-input">
                <p className="label">Select one or more category</p>
                <ul className="forum-tags">
                  {isLoaded(skillTags) &&
                    skillTags[0].data.map((tag) => (
                      <li key={tag}>
                        <button
                          className={`list-btn ${
                            skillState.category === tag ? "selected" : ""
                          }`}
                          onClick={selectCategory}
                          id={tag}
                        >
                          {tag}
                        </button>
                      </li>
                    ))}
                </ul>
              </div>
              <div className="content-input">
                <p className="label">Is this a job offer? (Default: NO)</p>
                <div className="switch page-switch">
                  <label>
                    <input
                      type="checkbox"
                      name="isJob"
                      id="isJob"
                      defaultValue={skillState.isJob}
                      onChange={updateFormValue}
                    />
                    <span className="lever"></span>
                  </label>
                </div>
              </div>
              {skillState.isJob && (
                <div className="content-input">
                  <p className="label">Job position</p>
                  <ul className="forum-tags">
                    <li>
                      <button
                        className={`list-btn ${
                          skillState.jobStatus === "Full-time" ? "selected" : ""
                        }`}
                        onClick={selectJobStatus}
                      >
                        Full-time
                      </button>
                    </li>
                    <li>
                      <button
                        className={`list-btn ${
                          skillState.jobStatus === "Part-time" ? "selected" : ""
                        }`}
                        onClick={selectJobStatus}
                      >
                        Part-time
                      </button>
                    </li>
                  </ul>
                </div>
              )}
              <div className="content-input">
                <label htmlFor="price">
                  Enter a price for this skill offer.
                </label>
                <input
                  type="number"
                  placeholder="Prices are in TRY(Turkish Lira) only"
                  id="price"
                  name="price"
                  autoComplete="off"
                  onKeyDown={(evt) =>
                    ["e", "E", "+", "-"].includes(evt.key) &&
                    evt.preventDefault()
                  }
                  onChange={updateFormValue}
                />
              </div>
              <div className="content-input">
                <p className="label">
                  Add cover images. (Up to 6 selections available)
                </p>
                <div className="content-row row">
                  <div className="content-col col s12 m12 l12 x12">
                    <div className="poster-area">
                      <div className="editor-featured-upload">
                        <label htmlFor="file" className="featured-label">
                          <i
                            className="fa fa-cloud-upload"
                            aria-hidden="true"
                          ></i>
                          Add a cover image
                        </label>
                        <input
                          accept="image/*"
                          type="file"
                          id="file"
                          name="file"
                          onChange={handleImageChange}
                          multiple="multiple"
                        />
                      </div>
                    </div>
                    {skillState.skillBanners.length > 0 && (
                      <div className="poster-display">
                        <ul className="forum-tags">
                          {skillState.skillBanners.map((b, i) => (
                            <li key={i}>
                              <p className={`list-btn`}>
                                <span>{b.name}</span>
                                <button
                                  onClick={() => {
                                    removeImageFromLists(i);
                                  }}
                                >
                                  <CancelIcon width={15} />
                                </button>
                              </p>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {skillState.isJob ? (
                <div className="content-input">
                  <p className="label">
                    Location: This skill(job) is available in?
                  </p>
                  <ul className="forum-tags">
                    <li>
                      <button
                        className={`list-btn ${
                          skillState.skillLocation.includes("Famagusta")
                            ? "selected"
                            : ""
                        }`}
                        onClick={selectJobLocation}
                      >
                        Famagusta
                      </button>
                    </li>
                    <li>
                      <button
                        className={`list-btn ${
                          skillState.skillLocation.includes("Kyrenia")
                            ? "selected"
                            : ""
                        }`}
                        onClick={selectJobLocation}
                      >
                        Kyrenia
                      </button>
                    </li>
                    <li>
                      <button
                        className={`list-btn ${
                          skillState.skillLocation.includes("Iskele")
                            ? "selected"
                            : ""
                        }`}
                        onClick={selectJobLocation}
                      >
                        Iskele
                      </button>
                    </li>
                    <li>
                      <button
                        className={`list-btn ${
                          skillState.skillLocation.includes("Nicosia")
                            ? "selected"
                            : ""
                        }`}
                        onClick={selectJobLocation}
                      >
                        Nicosia
                      </button>
                    </li>
                    <li>
                      <button
                        className={`list-btn ${
                          skillState.skillLocation.includes("Remote")
                            ? "selected"
                            : ""
                        }`}
                        onClick={selectJobLocation}
                      >
                        Remote
                      </button>
                    </li>
                  </ul>
                </div>
              ) : (
                <div className="content-input">
                  <p className="label">
                    Location: This skill is available in? Multiple selections
                    available.
                  </p>
                  <ul className="forum-tags">
                    <li>
                      <button
                        className={`list-btn ${
                          skillState.skillLocation.includes("Famagusta")
                            ? "selected"
                            : ""
                        }`}
                        onClick={selectLocation}
                      >
                        Famagusta
                      </button>
                    </li>
                    <li>
                      <button
                        className={`list-btn ${
                          skillState.skillLocation.includes("Kyrenia")
                            ? "selected"
                            : ""
                        }`}
                        onClick={selectLocation}
                      >
                        Kyrenia
                      </button>
                    </li>
                    <li>
                      <button
                        className={`list-btn ${
                          skillState.skillLocation.includes("Iskele")
                            ? "selected"
                            : ""
                        }`}
                        onClick={selectLocation}
                      >
                        Iskele
                      </button>
                    </li>
                    <li>
                      <button
                        className={`list-btn ${
                          skillState.skillLocation.includes("Nicosia")
                            ? "selected"
                            : ""
                        }`}
                        onClick={selectLocation}
                      >
                        Nicosia
                      </button>
                    </li>
                    <li>
                      <button
                        className={`list-btn ${
                          skillState.skillLocation.includes("Remote")
                            ? "selected"
                            : ""
                        }`}
                        onClick={selectLocation}
                      >
                        Remote
                      </button>
                    </li>
                  </ul>
                </div>
              )}

              <div className="content-editor-actions">
                <button
                  onClick={() => {
                    setNext(false);
                    setBack(true);
                  }}
                  className="btn-next"
                  id="next"
                >
                  Next
                </button>
              </div>
            </div>
            <div
              className="content-box up__slide__fx"
              style={{ display: back ? "flex" : "none" }}
              id="step__deux"
            >
              <div className="poster-action" style={{ marginBottom: "1rem" }}>
                <button
                  onClick={() => {
                    setBack(false);
                    setNext(true);
                  }}
                  style={{ display: "flex", alignItems: "center", gap: "8px" }}
                >
                  <BackIcon width={20} theme={`cl-icon-light`} /> Back
                </button>
              </div>
              <QuillEditorContainer
                quillInstanceRef={quillInstanceRef}
                quillEditorRef={quillEditorRef}
                setEditorState={setSkillState}
                getWordCount={getWordCount}
                getWordLength={getWordLength}
              />
              <div className="content-editor-props">
                <QuillToolBar
                  quillToolBarRef={quillToolbarRef}
                  handleFormatStyle={handleFormatStyle}
                />
                <p className="editor-count">
                  {skillState.wordCount}/{SERVICES__COUNT}
                </p>
              </div>
              <div className="content-editor-actions">
                <button
                  className="btn-next"
                  id="next"
                  disabled={isCrudLoading}
                  onClick={handleSkillCreate}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="create-float hide-on-med-and-down">
        <div className="action-illustrator">
          <SkillCreateIcon />
        </div>
      </div>
    </>
  );
};

export default compose(
  withRouter,
  firestoreConnect(() => {
    return [
      {
        collection: "inits",
        doc: "categorys",
        storeAs: "skillTags",
      },
    ];
  })
)(CreateSkill);

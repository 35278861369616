import React, { useState } from "react";
import { complaintCategories } from "../../assets/dummies/Static";
import authStyles from "../../css/auth.module.css";
import formStyles from "../../css/forms.module.css";
import AbortableToast from "../../general/components/AbortableToast";
import imageCompression from "browser-image-compression";
import { CancelIcon } from "../../assets/icons/Icons";
import { useDispatch, useSelector } from "react-redux";
import { createComplaintDB } from "../../stores/crud/CrudSlice";
import { useNavigate } from "react-router-dom";

const CreateComplaint = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // anonymous user
  const generateAnonymous = () => {
    const date = new Date();
    const userNumber = date.getSeconds() * date.getMilliseconds();
    return `anon${userNumber}`;
  };
  const { auth } = useSelector((state) => state.firebase);
  const { isCrudLoading } = useSelector((state) => state.crud);
  const [next, setNext] = useState(true);
  const [success, setSuccess] = useState(false);
  // form state
  const [complaintState, setComplaintState] = useState({
    title: "",
    category: "",
    isAnonymous: auth.uid ? false : true,
    description: "",
    banners: [],
    isAnonymousName: generateAnonymous(),
    compFiles: [],
  });

  const updateFormValue = (evt) => {
    if (evt.target.name === "isAnonymous") {
      setComplaintState((state) => ({
        ...state,
        [evt.target.name]: evt.target.checked,
      }));
      return;
    }
    setComplaintState((state) => ({
      ...state,
      [evt.target.name]: evt.target.value,
    }));
  };

  const selectCategory = (evt) => {
    if (complaintState.category === evt.target.innerText) {
      setComplaintState((state) => ({
        ...state,
        category: "",
      }));
      return;
    }
    setComplaintState((state) => ({
      ...state,
      category: evt.target.innerText,
    }));
  };

  // compress images
  const compressImageFile = async (file) => {
    const options = {
      maxWidthOrHeight: 700,
      useWebWorker: true,
      fileType: "image/webp",
    };

    const compFile = await imageCompression(file, options);
    await mapToState(compFile);
  };

  // mapto state
  const mapToState = async (blob) => {
    const temp = await blob;
    setComplaintState((state) => ({
      ...state,
      compFiles: [...state.compFiles, temp],
    }));
  };

  const handleImageChange = () => {
    const input = document.querySelector("#file");
    const imgArr = [];
    const files = input.files;

    if (files.length > 6) {
      AbortableToast("Maximum upload is 6. Limit Exceeded!");
      return;
    }

    setComplaintState((state) => ({ ...state, compFiles: [] }));
    // push files to array
    for (let i = 0; i < files.length; i++) {
      imgArr.push(files[i]);
      compressImageFile(files[i]);
    }
    setComplaintState((state) => ({ ...state, banners: [...files] }));
  };

  const removeImageFromLists = (index) => {
    setComplaintState((state) => ({
      ...state,
      banners: state.banners.filter((b) => b !== state.banners[index]),
      compFiles: state.compFiles.filter((b) => b !== state.compFiles[index]),
    }));
  };

  const handleSubmitComplaint = () => {
    if (complaintState.title === null || complaintState.title === "") {
      AbortableToast("Provide a title for your complaint!");
      return;
    }

    if (complaintState.category === null || complaintState.category === "") {
      AbortableToast("Select a category for your compliant!");
      return;
    }

    if (
      complaintState.description === null ||
      complaintState.description === ""
    ) {
      AbortableToast("Provide detailed description for your complaint");
      return;
    }

    const data = {
      title: complaintState.title,
      category: complaintState.category,
      isAnonymous: complaintState.isAnonymous,
      description: complaintState.description,
      banners: complaintState.banners,
      isAnonymousName: complaintState.isAnonymousName,
      compFiles: complaintState.compFiles,
    };

    // console.log(complaintState)

    // upload to firebase
    dispatch(createComplaintDB(data))
      .then((dat) => {
        if (dat.payload.id !== undefined) {
          setTimeout(() => {
            setNext(false);
            setSuccess(true);
          }, 1500);
          setTimeout(() => {
            navigate("/", { replace: true });
          }, 3000);
        }
      })
      .catch((err) => {
        AbortableToast("Could not submit your complaint. Try again later");
      });
  };

  return (
    <>
      {next && (
        <div className="create-content">
          <h3 className="content-title">Submit a complaint</h3>
          <div className="content-area">
            <div className="content-box down__slide__fx" id="step__uno">
              <div className="content-input">
                <label htmlFor="title"></label>
                <input
                  type="text"
                  placeholder="Title"
                  id="title"
                  name="title"
                  maxLength={50}
                  onChange={updateFormValue}
                />
              </div>
              <div className="content-input">
                <p className="label">Select a category</p>
                <ul className="forum-tags">
                  {complaintCategories.map((tag) => (
                    <li key={tag}>
                      <button
                        className={`list-btn ${
                          complaintState.category === tag ? "selected" : ""
                        }`}
                        onClick={selectCategory}
                        id={tag}
                      >
                        {tag}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="content-input">
                <label htmlFor="description"></label>
                <textarea
                  placeholder="Further describe your complaint"
                  id="description"
                  name="description"
                  onChange={updateFormValue}
                  maxLength={400}
                  style={{ resize: "vertical", minHeight: "150px" }}
                />
              </div>
              <div className="content-input">
                <p className="label">Any files related to this complaint?</p>
                <div className="content-row row">
                  <div className="content-col col s12 m12 l12 x12">
                    <div className="poster-area">
                      <div className="editor-featured-upload">
                        <label htmlFor="file" className="featured-label">
                          <i
                            className="fa fa-cloud-upload"
                            aria-hidden="true"
                          ></i>
                          Add a cover image
                        </label>
                        <input
                          accept="image/*"
                          type="file"
                          id="file"
                          name="file"
                          onChange={handleImageChange}
                          multiple="multiple"
                        />
                      </div>
                    </div>
                    {complaintState.banners.length > 0 && (
                      <div className="poster-display">
                        <ul className="forum-tags">
                          {complaintState.banners.map((b, i) => (
                            <li key={i}>
                              <p className={`list-btn`}>
                                <span>{b.name}</span>
                                <button
                                  onClick={() => {
                                    removeImageFromLists(i);
                                  }}
                                >
                                  <CancelIcon width={15} />
                                </button>
                              </p>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {auth.uid && (
                <div className="content-input">
                  <p className="label">Submit this complaint anonymously? </p>
                  <div className="switch page-switch">
                    <label>
                      <input
                        type="checkbox"
                        id="isAnonymous"
                        name="isAnonymous"
                        defaultChecked={complaintState.isAnonymous}
                        onChange={updateFormValue}
                      />
                      <span className="lever"></span>
                    </label>
                  </div>
                </div>
              )}

              <div className="content-editor-actions">
                <button
                  className="btn-next"
                  id="submit"
                  disabled={isCrudLoading}
                  onClick={handleSubmitComplaint}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {success && (
        <div className="create-content">
          <div className="content-area">
            <div className="content-box down__slide__fx" id="step__deux">
              <h4 className={`${authStyles["auth-main-title"]}`}>
                Your complaint has been received!
              </h4>
              <p className={`${authStyles["auth-main-sub"]}`}>
                click the link below to continue or you will be automatically
                redirected.
              </p>
              <div
                className={`${authStyles["auth-main-content"]} ${formStyles["form-pd"]}`}
              >
                <div className={`${formStyles["form-control"]}`}>
                  <div className={`${formStyles["form-group"]}`}></div>

                  <div className={`${formStyles["form-group"]}`}>
                    <button
                      className={`${formStyles["form-btn"]} browser-default`}
                      id={`${formStyles["submit__main"]}`}
                      style={{
                        background: "transparent",
                        color: "var(--tx-black)",
                      }}
                      type="submit"
                      onClick={() => window.location.replace("/")}
                    >
                      Go back home
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CreateComplaint;

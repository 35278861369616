import React, { useEffect, useState } from "react";
import { ShieldIcon } from "../../../assets/icons/Icons";
import RatingDescription from "../../../general/components/RatingDescription";
import { isLoaded } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SkillReviews from "./SkillReviews";

const SkillReviewWrapper = ({
  skillId,
  handleRatingDescription,
  handleReviewBreakDown,
  auth,
  reviewState,
  // setReviewState,
  updateReviewValue,
  updateFormValue,
  handleSubmit,
  subView,
  setSubView,
  skillData,
  setSkillData,
  handleReviewReport,
  handleReviewDelete,
  maxLimit,
  maxDispLimit,
  indexDisp,
  handleLoadMore,
  formView,
  setFormView,
}) => {
  const navigate = useNavigate();

  const reviews = useSelector(
    ({ firestore: { ordered } }) =>
      ordered[`${skillId}-reviews`] && ordered[`${skillId}-reviews`]
  );

  const [locReviews, setLocReviews] = useState([]);
  useEffect(() => {
    if (isLoaded(reviews)) {
      const sortFilter = (a, b) =>
        new Date(b.createdAt.toDate()) - new Date(a.createdAt.toDate());
      setLocReviews([...reviews].sort(sortFilter));
    }
  }, [reviews]);

  const handleFilter = (e, type) => {
    const sort = document.querySelectorAll(".sort");
    sort.forEach((el) => {
      el.classList.remove("active");
    });

    switch (type) {
      case "up":
        setLocReviews(reviews);
        break;
      case "down":
        const sortFilter = (a, b) =>
          new Date(a.createdAt.toDate()) - new Date(b.createdAt.toDate());
        setLocReviews([...locReviews].sort(sortFilter));
        break;
      default:
        setLocReviews(reviews);
        break;
    }
    e.currentTarget.classList.add("active");
  };
  if (isLoaded(reviews))
    return (
      <section className="blob-item divide">
        <div className="blob-tag-area">
          <div className="blog-mult">
            <h4>
              Reviews
              <span className="count">({reviews.length})</span>
            </h4>
            <a href="/">
              <ShieldIcon width={20} />
            </a>
          </div>
          <div className="blob-connect">
            <h5>Overall breakdown</h5>
            <div className="blob-row row">
              <div className="blob-col col s12 m6 l6 xl 6">
                <div className="blob-progress-bar">
                  {[5, 4, 3, 2, 1].map((index) =>
                    handleRatingDescription(index, reviews) > 0 ? (
                      <RatingDescription
                        key={index}
                        ratingNumber={index}
                        ratingWidth={
                          (handleRatingDescription(index, reviews) /
                            reviews.length) *
                          100
                        }
                        ratingReviews={handleRatingDescription(index, reviews)}
                      />
                    ) : (
                      <RatingDescription
                        key={index}
                        ratingNumber={index}
                        ratingWidth={0}
                        ratingReviews={0}
                      />
                    )
                  )}
                </div>
              </div>
              <div className="blob-col col s12 m6 l6 xl 6">
                <div className="blob-progress-desc">
                  <div className="blob-cr__desc">
                    <div className="blob-cr">
                      <div className="blob-cr__info">
                        <p className="blob-info__title">Communication</p>
                        <p className="blob-info__rating">
                          <span className="fa fa-star"></span>
                          <span>
                            {handleReviewBreakDown("communication", reviews)}
                          </span>
                        </p>
                      </div>
                      <div className="blob-cr__info">
                        <p className="blob-info__title">Quality</p>
                        <p className="blob-info__rating">
                          <span className="fa fa-star"></span>
                          {handleReviewBreakDown("quality", reviews)}
                        </p>
                      </div>
                      <div className="blob-cr__info">
                        <p className="blob-info__title">
                          Recommend to a friend
                        </p>
                        <p className="blob-info__rating">
                          <span className="fa fa-star"></span>
                          {handleReviewBreakDown("recommend", reviews)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <SkillReviews
            auth={auth}
            reviewState={reviewState}
            updateReviewValue={updateReviewValue}
            handleSubmit={handleSubmit}
            navigate={navigate}
            reviews={locReviews}
            subView={subView}
            setSubView={setSubView}
            skillData={skillData}
            setSkillData={setSkillData}
            updateFormValue={updateFormValue}
            handleReviewReport={handleReviewReport}
            handleFilter={handleFilter}
            skillId={skillId}
            handleReviewDelete={handleReviewDelete}
            maxLimit={maxLimit}
            maxDispLimit={maxDispLimit}
            indexDisp={indexDisp}
            handleLoadMore={handleLoadMore}
            formView={formView}
            setFormView={setFormView}
          />
        </div>
      </section>
    );
};

export default SkillReviewWrapper;

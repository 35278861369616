import React from "react";
import { LoadIcon } from "../../../assets/icons/Icons";
import Review from "./HouseReview";
import moment from "moment";

const HouseReviews = ({
  reviews,
  houseId,
  houseData,
  setHouseData,
  mainView,
  setMainView,
  updateFormValue,
  maxLimit,
  maxDispLimit,
  indexDisp,
  handleLoadMore,
  auth,
  handleReviewDelete,
  handleReviewReport,
}) => {
  return (
    <ul className="blob-comment-lists">
      {reviews.slice(indexDisp, maxDispLimit).map((review, index) => (
        <Review
          key={review.id}
          cardUserId={review.author.id}
          cardAvatar={review.author.photoURL}
          cardUsername={review.author.username}
          index={index}
          cardId={review.id}
          ratingStarCount={(
            (Number(review.rating.communication) +
              Number(review.rating.vfm) +
              Number(review.rating.hApp) +
              Number(review.rating.services) +
              Number(review.rating.security) +
              Number(review.rating.interior) +
              Number(review.rating.exterior) +
              Number(review.rating.facility)) /
            8
          ).toFixed(1)}
          ratingNumberCount={(
            (Number(review.rating.communication) +
              Number(review.rating.vfm) +
              Number(review.rating.hApp) +
              Number(review.rating.services) +
              Number(review.rating.security) +
              Number(review.rating.interior) +
              Number(review.rating.exterior) +
              Number(review.rating.facility)) /
            8
          ).toFixed(1)}
          ratingComment={review.rating.comment}
          auth={auth}
          createdAt={moment(review.createdAt.toDate()).fromNow()}
          houseId={houseId}
          setHouseData={setHouseData}
          handleReviewDelete={handleReviewDelete}
          mainView={mainView}
          setMainView={setMainView}
          updateFormValue={updateFormValue}
          handleReviewReport={handleReviewReport}
          houseData={houseData}
        />
      ))}

      {reviews.length > maxLimit && maxDispLimit < reviews.length && (
        <li className="comment-list" id="load__er">
          <button
            className="comment-action wrapper-fx-row gap upper-tx"
            onClick={handleLoadMore}
          >
            <LoadIcon width={20} theme={`cl-icon-light`} />
            load more
          </button>
        </li>
      )}
    </ul>
  );
};

export default HouseReviews;

import React from "react";
import { ForwardIcon, RemoveIcon } from "../../../assets/icons/Icons";
import formStyles from "../../../css/forms.module.css";
import authStyles from "../../../css/auth.module.css";
import DeleteModal from "../../../modals/DeleteModal";

const DeleteSection = ({
  locUser,
  updateFormValue,
  handleDeleteAccount,
  view,
  setView,
  isCrudLoading,
}) => {
  return (
    <>
      <li className="collapsible-item">
        <div className="collapsible-header">
          <RemoveIcon width={20} theme={`cl-icon-light`} />
          <p className="tx-warning">
            Delete account
            <span>
              Permanently delete your account and all of your content.
            </span>
          </p>
          <div className="caret-area">
            <ForwardIcon width={20} theme={`cl-icon-light`} />
          </div>
        </div>
        <div className="collapsible-body">
          <div
            className={`${authStyles["auth-main-items"]}`}
            style={{ maxWidth: "unset" }}
          >
            <div
              className={`${authStyles["auth-main-content"]} ${formStyles["form-pd"]}`}
            >
              <div className={`${formStyles["form-control"]}`}>
                <div className={`${formStyles["text-area"]}`}>
                  We’re sorry to see you go. Once your account is deleted, all
                  of your content will be permanently gone, including your
                  profile, posts, skills, and responses. If you’re not sure
                  about this process, we suggest you deactivate or contact via
                  <a href="mailto:community@vois.com"> community@vois.com </a>
                  instead.
                </div>
                <div className={`${formStyles["form-group"]}`}>
                  <label
                    htmlFor="delete"
                    style={{ textTransform: "lowercase" }}
                  >
                    To confirm, type “delete” below:
                  </label>
                  <input
                    type="text"
                    id="delete"
                    name="delete"
                    autoComplete="off"
                    className="browser-default"
                    defaultValue={locUser.delete}
                    onChange={updateFormValue}
                  />
                </div>
                <div
                  className={`${formStyles["form-group"]}`}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <button
                    className={`${formStyles["form-btn"]} browser-default`}
                    id={`${formStyles["submit__main"]}`}
                    type="submit"
                    style={{ width: "fit-content" }}
                    disabled={locUser.delete !== "delete" || isCrudLoading}
                    onClick={() => setView(true)}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
      {view && (
        <DeleteModal
          text={`User`}
          view={view}
          setView={setView}
          callback={handleDeleteAccount}
        />
      )}
    </>
  );
};

export default DeleteSection;

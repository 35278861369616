import React, { useEffect, useState } from "react";
import { ValidationContext } from "./ValidationContext";
import formStyles from "../../css/forms.module.css";
import PropTypes from "prop-types";
import { ValidateData } from "./ValidateData";
import Loader from "../../general/components/Loader";
import { useDispatch, useSelector } from "react-redux";

const FormValidate = ({
  children,
  btnText,
  data,
  oobCode,
  rules,
  callBack,
}) => {
  // dispatcher
  const dispatch = useDispatch();

  // states
  const [formProps, setFormProps] = useState({
    errors: {},
    dirty: {},
    isSubmitted: false,
    getFieldMessages: () => [],
  });
  const { isAuthLoading } = useSelector((state) => state.auth);

  // derviced state
  useEffect(() => {
    setFormProps((state) => ({
      ...state,
      errors: ValidateData(data, rules),
    }));
  }, [data, rules]);

  //fire callback after isSumitted state is updated
  useEffect(() => {
    if (formProps.isSubmitted) {
      const rData = oobCode !== undefined ? { ...data, oobCode } : data;
      dispatch(callBack(rData));
    }
    return () => setFormProps((state) => ({ ...state, isSubmitted: false }));
  }, [formProps.isSubmitted, callBack]);

  const handleFormChange = (evt) => {
    setFormProps((state) => ({
      ...state,
      dirty: { ...state.dirty, [evt.target.name]: evt.target.value },
    }));
  };

  // is form Valid
  const canFormBeSubmitted = () => {
    return Object.keys(formProps.dirty).length === Object.keys(data).length;
  };

  const handleSubmit = (e) => {
    const getFieldMessages = (element) => {
      return formProps.errors[element] || [];
    };

    e.preventDefault();
    if (Object.keys(formProps.errors).length === 0) {
      setFormProps((state) => ({
        ...state,
        isSubmitted: true,
        getFieldMessages: () => [],
      }));
    } else {
      setFormProps((state) => ({
        ...state,
        getFieldMessages: getFieldMessages,
      }));
    }
  };

  return (
    <ValidationContext.Provider value={formProps}>
      <form
        className={`${formStyles["form-control"]}`}
        onChange={handleFormChange}
        onSubmit={handleSubmit}
      >
        {children}
        <div className={`${formStyles["form-group"]}`}>
          <button
            className={`${formStyles["form-btn"]} browser-default`}
            id={`${formStyles["submit__main"]}`}
            type="submit"
            disabled={!canFormBeSubmitted()}
          >
            {btnText}
          </button>
          {isAuthLoading && <Loader />}
        </div>
      </form>
    </ValidationContext.Provider>
  );
};

FormValidate.propTypes = {
  btnText: PropTypes.string,
  oobCode: PropTypes.string,
  data: PropTypes.object,
  rules: PropTypes.object,
  callBack: PropTypes.func,
};

export default FormValidate;

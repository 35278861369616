import React from "react";
import { ForwardIcon, ResetIcon } from "../../../assets/icons/Icons";
import formStyles from "../../../css/forms.module.css";
import authStyles from "../../../css/auth.module.css";
import DisplayValidationMessages from "../../../auth/components/DisplayValidationMessages";

const ResetSection = ({
  locUser,
  updateFormValue,
  handleChangePassword,
  isCrudLoading,
  errors,
}) => {
  return (
    <li className="collapsible-item">
      <div className="collapsible-header">
        <ResetIcon width={20} theme={`cl-icon-light`} />
        <p className="tx-warning">
          Reset password
          <span>
            8 characters or longer. Combine upper and lowercase letters and
            numbers.
          </span>
        </p>
        <div className="caret-area">
          <ForwardIcon width={20} theme={`cl-icon-light`} />
        </div>
      </div>
      <div className="collapsible-body">
        <div
          className={`${authStyles["auth-main-items"]}`}
          style={{ maxWidth: "unset" }}
        >
          <div
            className={`${authStyles["auth-main-content"]} ${formStyles["form-pd"]}`}
          >
            <div className={`${formStyles["form-control"]}`}>
              <div className={`${formStyles["form-group"]}`}>
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  autoComplete="off"
                  className="browser-default"
                  onChange={updateFormValue}
                />
                <DisplayValidationMessages el={`password`} authError={errors} />
              </div>
              <div className={`${formStyles["form-group"]}`}>
                <label htmlFor="repassword">RE-TYPE Password</label>
                <input
                  type="password"
                  id="repassword"
                  name="repassword"
                  autoComplete="off"
                  className="browser-default"
                  onChange={updateFormValue}
                />
                <DisplayValidationMessages
                  el={`repassword`}
                  authError={errors}
                />
              </div>
              <div
                className={`${formStyles["form-group"]}`}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <button
                  className={`${formStyles["form-btn"]} browser-default`}
                  id={`${formStyles["submit__main"]}`}
                  type="submit"
                  style={{ width: "fit-content" }}
                  disabled={
                    (locUser.password === "" && locUser.repassword === "") ||
                    isCrudLoading
                  }
                  onClick={handleChangePassword}
                >
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};

export default ResetSection;

import React, { useEffect, useRef } from "react";
import M from "materialize-css";
import {
  ComplaintIcon,
  ForumIcon,
  HouseIcon,
  PlusIcon,
  ReviewHouseIcon,
  SearchIcon,
  SkillIcon,
  SkillShareIcon,
  WriteIcon,
} from "../../assets/icons/Icons";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import NoAuthNavigations from "./NoAuthNavigations";
import AuthNavigations from "./AuthNavigations";
import Notification from "./Notification";

const Nav = ({ view, size, screen }) => {
  const communityNavRef = useRef();
  const createNavRef = useRef();
  const avatarNavRef = useRef();
  const barNavRef = useRef();
  const { auth } = useSelector((state) => state.firebase);

  useEffect(() => {
    const nav = document.querySelector(".nav__community");
    communityNavRef.current = M.Dropdown.init(nav, {
      coverTrigger: false,
      alignment: "right",
      constrainWidth: false,
    });

    const reCalculate = () => {
      communityNavRef.current.recalculateDimensions();
    };

    if (nav) {
      window.addEventListener("resize", reCalculate);
      return () => window.removeEventListener("resize", reCalculate);
    }
  }, [view, screen.width]);

  useEffect(() => {
    const nav = document.querySelector(".nav__create");
    createNavRef.current = M.Dropdown.init(nav, {
      coverTrigger: false,
      alignment: "right",
      constrainWidth: false,
    });

    const reCalculate = () => {
      createNavRef.current.recalculateDimensions();
    };

    if (nav) {
      window.addEventListener("resize", reCalculate);
      return () => window.removeEventListener("resize", reCalculate);
    }
  }, [view, auth.uid, screen.width]);

  return (
    <ul className="nav-links">
      <li className="nav-link hide-on-med-and-up">
        <NavLink to="/search" style={{ padding: auth.uid && "0" }}>
          <SearchIcon width={22} theme={`cl-icon-light`} />
        </NavLink>
      </li>
      {view && (
        <>
          {screen.width > size.lg && (
            <>
              <li className="nav-link">
                <NavLink to="/">Home</NavLink>
              </li>
              <li className="nav-link">
                <a
                  href="/forum"
                  className="nav__community"
                  data-target="nav-community"
                >
                  Community
                </a>
                <ul
                  id="nav-community"
                  className="dropdown-content custom__dropdown"
                >
                  <li className="custom__list">
                    <NavLink to="/forum">
                      <ForumIcon width={20} theme={`cl-icon-light`} />
                      Forum
                    </NavLink>
                  </li>
                  <li className="custom__list">
                    <NavLink to="/skillshop">
                      <SkillIcon width={20} theme={`cl-icon-light`} />
                      Skillshop
                    </NavLink>
                  </li>
                  <li className="custom__list">
                    <NavLink to="/housing">
                      <HouseIcon width={20} theme={`cl-icon-light`} />
                      Housing
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li className="nav-link">
                <NavLink to="/resources">Resources</NavLink>
              </li>
              {!auth.uid && (
                <li className="nav-link">
                  <NavLink to="/submit-a-complaint" style={{ gap: "5px" }}>
                    <PlusIcon width={20} theme={`cl-icon-light`} />
                    Submit a complaint
                  </NavLink>
                </li>
              )}
            </>
          )}

          {auth.uid && (
            <>
              {screen.width > size.md && (
                <>
                  <li className="nav-link">
                    <a
                      href="/create"
                      className="nav__create"
                      data-target="nav-create"
                      style={{ gap: "5px" }}
                    >
                      <PlusIcon width={20} />
                      Create
                    </a>
                    <ul
                      id="nav-create"
                      className="dropdown-content custom__dropdown"
                    >
                      <li className="custom__list">
                        <NavLink to="/create">
                          <WriteIcon width={15} theme={`cl-icon-light`} />
                          Create Post
                        </NavLink>
                      </li>
                      <li className="custom__list">
                        <NavLink to="/create/skill">
                          <SkillShareIcon width={20} theme={`cl-icon-light`} />
                          Create Skill
                        </NavLink>
                      </li>
                      <li className="custom__list">
                        <NavLink to="/create/house">
                          <ReviewHouseIcon width={20} theme={`cl-icon-light`} />
                          Rate House
                        </NavLink>
                      </li>
                      {auth.uid && (
                        <li className="nav-link">
                          <NavLink
                            to="/submit-a-complaint"
                            style={{ gap: "5px" }}
                          >
                            <ComplaintIcon width={20} theme={`cl-icon-light`} />
                            Submit a complaint
                          </NavLink>
                        </li>
                      )}
                    </ul>
                  </li>
                </>
              )}
            </>
          )}

          {!auth.uid && (
            <>
              {screen.width > size.lg && (
                <>
                  <li className="nav-link">
                    <a href="/auth">Sign in</a>
                  </li>
                  <li className="nav-link">
                    <button
                      className="nav-link-btn browser-default"
                      onClick={() => {
                        window.location.href = "/auth/signup";
                      }}
                    >
                      <i
                        className="fa fa-check-circle-o"
                        aria-hidden="true"
                      ></i>
                      Join community
                    </button>
                  </li>
                </>
              )}
            </>
          )}
          {auth.uid && (
            <>
              <Notification auth={auth} screen={screen} />
              <li className="nav-link">
                <AuthNavigations
                  screen={screen}
                  modalRef={avatarNavRef}
                  view={view}
                  auth={auth}
                  size={size}
                />
              </li>
            </>
          )}
          {screen.width < size.lg && !auth.uid && (
            <li className="nav-link">
              <NoAuthNavigations
                screen={screen}
                modalRef={barNavRef}
                view={view}
                auth={auth}
              />
            </li>
          )}
        </>
      )}
    </ul>
  );
};

Nav.propTypes = {
  view: PropTypes.bool,
  size: PropTypes.object,
  screen: PropTypes.object,
};

export default Nav;

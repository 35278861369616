import React from "react";
import { ExternalLinkIcon, VolunteerIcon } from "../../../assets/icons/Icons";

const BecomeVolunteer = () => {
  return (
    <a
      href="https://docs.google.com/forms/d/e/1FAIpQLSd8KrCF-WLiTd4wrnWKePJ5ZVt2jBZDtmZVx935UkFdXzq3yg/viewform"
      target="blank"
      referrerPolicy="no-referrer"
    >
      <li className="collapsible-item">
        <div className="collapsible-header">
          <VolunteerIcon width={20} theme={`cl-icon-light`} />
          <p>
            Volunter for VOIS Cyprus
            <span>
              This is an external application. You will be redirected.
            </span>
          </p>
          <div className="caret-area link">
            <ExternalLinkIcon width={20} theme={`cl-icon-light`} />
          </div>
        </div>
      </li>
    </a>
  );
};

export default BecomeVolunteer;

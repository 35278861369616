import React from "react";
import { CookieControl } from "../../config/cookieConfig";

const CookieProtected = (Component) => {
  return (props) => {
    const cookieObj = new CookieControl();
    if (cookieObj.getCookie("ua_pref") || props.auth.uid) {
      return <Component {...props} />;
    } else {
      window.location.replace("/auth");
    }
  };
};

export default CookieProtected;

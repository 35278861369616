import React, { useEffect, useState } from "react";
import "../../../css/blob-content.css";
import PropTypes from "prop-types";
import { FbIcon, LinkIcon, XIcon } from "../../../assets/icons/Icons";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { isEmpty, isLoaded } from "react-redux-firebase";
import Loader from "../../../general/components/Loader";
import moment from "moment";
import { useSelector } from "react-redux";
import { replaceSpaces, replaceSpecial } from "../../../general/functions";
import AbortableToast from "../../../general/components/AbortableToast";
import { ResourceActions } from "./ResourceActions";
import PartialUserInfo from "../forum/PartialUserInfo";
import MoreResources from "./MoreResources";
import validator from "validator";
import Error404 from "../../Error404";

const ResourceById = ({ nmg }) => {
  const params = useParams();
  const navigate = useNavigate();
  const { resources, posts } = useOutletContext();
  const [loading, setIsLoading] = useState(false);
  const { auth } = useSelector((state) => state.firebase);
  const { userSaves } = useSelector((state) => state.firebase.profile);

  const filterResourceByID = React.useMemo(() => {
    if (isLoaded(resources)) {
      return resources.filter((data) => {
        return (
          [data.properties.category, data.id.slice(10)]
            .join("")
            .toLowerCase()
            .indexOf(
              [
                params.tag,
                params.rid.split("-")[params.rid.split("-").length - 1],
              ]
                .join("")
                .toLowerCase()
            ) !== -1
        );
      });
    }
  }, [resources, params]);

  useEffect(() => {
    if (isLoaded(filterResourceByID)) {
      setIsLoading(true);
      window.scrollTo(0, 0, "smooth");
      setTimeout(() => {
        setIsLoading(false);
      }, 300);
    }
  }, [params.rid]);

  const copyToClipboard = () => {
    const link = document.querySelector(".copy__url");
    navigator.clipboard
      .writeText(`https://${window.location.host}${link.value}`)
      .then(() => {
        AbortableToast("Link copied successfully");
      });
  };

  const shareOnFacebook = () => {
    const link = document.querySelector(".copy__url");
    const navUrl =
      "https://www.facebook.com/sharer/sharer.php?u=" +
      `https://${window.location.host}${window.encodeURIComponent(link.value)}`;
    window.open(navUrl, "_blank");
  };

  const shareOnTwitter = () => {
    const link = document.querySelector(".copy__url");
    const navUrl =
      "https://twitter.com/intent/tweet?text=" +
      `https://${window.location.host}${link.value}`;
    window.open(navUrl, "_blank");
  };

  return (
    <>
      {loading && (
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "calc(100vh - 120px)",
          }}
        >
          <Loader type={`cover`} />
        </div>
      )}
      {!isLoaded(filterResourceByID) ? (
        <div
          style={{
            width: "100%",
            height: "calc(100vh - 120px)",
          }}
        >
          <Loader type={`cover`} />
        </div>
      ) : isEmpty(filterResourceByID) ? (
        <Error404
          posts={posts}
          text={"Restricted or deleted file"}
          code={401}
          unset={params.pid !== undefined}
        />
      ) : isLoaded(filterResourceByID) && filterResourceByID[0].status !== 0 ? (
        <Error404
          posts={posts}
          text={"Restricted or deleted file"}
          code={401}
          unset={params.pid !== undefined}
        />
      ) : (
        <div className="blob-wrapper" style={{ marginTop: nmg && "0" }}>
          <div className="blob-content container">
            <div className="blob-content-items">
              <section className="blob-item ">
                <div className="blob-info-area">
                  <h2 className="blob-title" style={{ marginBottom: 0 }}>
                    {validator.unescape(filterResourceByID[0].properties.title)}
                  </h2>
                  {filterResourceByID[0].properties.subTitle && (
                    <p className="blob-sub">
                      {validator.unescape(
                        filterResourceByID[0].properties.subTitle
                      )}
                    </p>
                  )}
                </div>
                <input
                  type="hidden"
                  name="link"
                  value={`/resources/${replaceSpaces(
                    filterResourceByID[0].properties.category
                  ).toLowerCase()}/${replaceSpaces(
                    replaceSpecial(filterResourceByID[0].properties.title)
                  ).toLowerCase()}-${filterResourceByID[0].id
                    .slice(10)
                    .toLowerCase()}`}
                  className="copy__url"
                />
              </section>
              <PartialUserInfo
                type={"resources"}
                userId={filterResourceByID[0].author.id}
                username={filterResourceByID[0].author.username}
                userAvatar={filterResourceByID[0].author.photoURL}
                category={filterResourceByID[0].properties.category}
                duration={filterResourceByID[0].properties.duration}
                createdAt={moment(
                  filterResourceByID[0].createdAt.toDate()
                ).fromNow()}
              />
              <ResourceActions
                disableActions={false}
                userSaves={userSaves}
                resourceId={filterResourceByID[0].id}
                auth={auth}
                navigate={navigate}
                copyToClipboard={copyToClipboard}
                shareOnFacebook={shareOnFacebook}
                shareOnTwitter={shareOnTwitter}
                isAttachment={filterResourceByID[0].properties.isAttachment}
                attachment={filterResourceByID[0].properties.attachment}
              />
              <section className="blob-item ">
                <div className="blob-desc-area"></div>
              </section>
            </div>
            {filterResourceByID[0].properties.poster && (
              <div className="blob-break-view">
                <section className="blob-item blob-br">
                  <div className="blob-img-area">
                    <img
                      src={filterResourceByID[0].properties.poster}
                      alt={`post-poster-${filterResourceByID[0].properties.poster}`}
                      role="presentation"
                    />
                  </div>
                </section>
              </div>
            )}

            <div className="blob-content-items">
              <section className="blob-item ">
                <div
                  className="blob-text-area"
                  dangerouslySetInnerHTML={{
                    __html: filterResourceByID[0].properties.contentHTML,
                  }}
                ></div>
              </section>
              <section className="blob-item divide">
                <div className="blob-tag-area">
                  <h4>Tags</h4>
                  <ul className="blob-tag-lists">
                    <li>
                      <a
                        className="blob-list"
                        href={`/resources/${replaceSpaces(
                          filterResourceByID[0].properties.category
                        ).toLowerCase()}`}
                      >
                        {filterResourceByID[0].properties.category}
                      </a>
                    </li>
                  </ul>
                </div>
              </section>
              <section className="blob-item divide">
                <div className="blob-tag-area">
                  <h4>Like this resource?</h4>
                  <div className="blob-connect">
                    <h5>Interact by sharing</h5>
                    <ul className="blob-tag-lists">
                      <li className="blob-list">
                        <button onClick={shareOnTwitter}>
                          <XIcon width={20} theme={`cl-icon-light`} />
                        </button>
                      </li>
                      <li className="blob-list">
                        <button onClick={shareOnFacebook}>
                          <FbIcon width={20} theme={`cl-icon-light`} />
                        </button>
                      </li>
                      <li className="blob-list">
                        <button onClick={copyToClipboard}>
                          <LinkIcon width={20} theme={`cl-icon-light`} />
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </section>
            </div>
          </div>
          <div className="blob-extra">
            <div className="blob-extra-content container">
              <div className="blob-extra-items">
                <MoreResources
                  resources={resources}
                  resourceId={filterResourceByID[0].id}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

ResourceById.propTypes = {
  nmg: PropTypes.bool,
};

export default ResourceById;

import M from "materialize-css";

const AbortableToast = (text) => {
  M.Toast.dismissAll();
  var toastHTML = `<span>${text}</span><button onclick='M.Toast.getInstance(this.parentElement).dismiss();' class="btn-flat toast-action">
    <i class="fa fa-times" aria-hidden="true"></i>
    </button>`;
  M.toast({ html: toastHTML, inDuration: 0, outDuration: 100 });
};

export default AbortableToast;

import React from "react";
import styles from "../../css/rating.module.css";
import PropTypes from "prop-types";

const RatingDescription = ({ ratingNumber, ratingWidth, ratingReviews }) => {
  const rWidth = {
    width: ratingWidth ? `${ratingWidth}%` : "0",
  };

  return (
    <div className={`${styles["blob-rating"]}`}>
      <div className={`${styles["rating__stats"]}`}>
        <div className={`${styles["rating__order"]}`}>
          {ratingNumber} {ratingNumber <= 1 ? "star" : "stars "}
        </div>
        <div className={`${styles["rating__bar"]}`}>
          <span className={`${styles["rating__width"]}`} style={rWidth}></span>
        </div>
        <div className={`${styles["rating__reviews"]}`}>({ratingReviews})</div>
      </div>
    </div>
  );
};

RatingDescription.propTypes = {
  ratingNumber: PropTypes.number,
  ratingWidth: PropTypes.number,
  ratingReviews: PropTypes.number,
};

export default RatingDescription;

import React from "react";
import { ShieldIcon } from "../assets/icons/Icons";
import { compose } from "redux";
import { firestoreConnect, isEmpty, isLoaded } from "react-redux-firebase";
import { useSelector } from "react-redux";
import Loader from "../general/components/Loader";
import Error404 from "./Error404";
import { useParams } from "react-router";

const CommunityGuidelines = ({ posts }) => {
  const misc = useSelector(
    ({ firestore: { ordered } }) => ordered[`guidelines`]
  );
  const params = useParams();

  return (
    <>
      {!isLoaded(misc) ? (
        <div style={{ marginTop: "55px" }}>
          <Loader type={`cover`} />
        </div>
      ) : isEmpty(misc) ? (
        <Error404
          posts={posts}
          text={"Community guideline update in progress"}
          code={401}
          unset={params.pid !== undefined}
        />
      ) : (
        <div className="community-forum">
          <section className="section-container container v-forum-hero">
            <div className="hero-area">
              <div className="hero-content">
                <div className="hero-info">
                  <ShieldIcon height={80} theme={`cl-icon-p`} />
                  <h3
                    className="hero-title"
                    dangerouslySetInnerHTML={{
                      __html: misc[0].properties.title,
                    }}
                  ></h3>
                  <p className="hero-desc">
                    VOIS Cyprus community guideline is a thorough explanation of
                    how we plan to use any personal information that you collect
                    through our mobile app or website.
                  </p>
                </div>
              </div>
            </div>
          </section>
          <div className="v-forum-lists container" style={{ marginTop: "0px" }}>
            <section className="section-container">
              <div className="blob-content">
                <div className="blob-content-items">
                  <section className="blob-item ">
                    <div
                      className="blob-text-area"
                      dangerouslySetInnerHTML={{
                        __html: misc[0].properties.contentHTML,
                      }}
                    ></div>
                  </section>
                </div>
              </div>
            </section>
          </div>
        </div>
      )}
    </>
  );
};

export default compose(
  firestoreConnect([
    {
      collection: "miscellaneous",
      where: ["properties.title", "==", "Community Guidelines"],
      storeAs: "guidelines",
    },
  ])
)(CommunityGuidelines);

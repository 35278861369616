import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import validator from "validator";
const initialState = {
  crudError: null,
  isCrudLoading: null,
};

export const createPostInDB = createAsyncThunk(
  "crud/createPostInDB",
  async (data, { dispatch, extra, getState }) => {
    const { getFirebase, getFirestore } = extra;

    const firebase = getFirebase();
    const firestore = getFirestore();
    const { user } = getState().firebase.profile;
    try {
      let fileUrlFromUpload;

      //upload image first to get url
      if (data.postBanner.hasOwnProperty("name")) {
        const storageRef = firebase.storage().ref();
        const fileRef = storageRef.child(
          `Posts/${user.id}/${btoa(data.postBanner.name)}`
        );
        await fileRef.put(data.postBanner);
        fileUrlFromUpload = await fileRef.getDownloadURL();
      } else {
        fileUrlFromUpload = data.postBanner;
      }

      // firebase.firestore.Timestamp.fromDate(new Date())
      return firestore.collection("posts").add({
        interactions: {
          commentCount: 0,
          likeCount: 0,
          saveCount: 0,
          reportCount: 0,
          isCommentDisabled: !data.isCommentDisabled,
          hidden: false,
        },
        author: {
          fullName: user.fullName,
          username: user.username,
          id: user.id,
          photoURL: user.photoURL,
        },
        properties: {
          title: data.postTitle,
          subTitle: data.postSubTitle,
          contentPlain: data.postContentPlain,
          contentHTML: data.postContentHtml,
          banner: fileUrlFromUpload,
          duration: data.postDuration,
          tags: data.postTags,
        },
        review: {
          id: "",
          username: "",
          reviewedAt: new Date(),
        },
        status: data.postStatus,
        createdAt: new Date(),
      });
    } catch (error) {
      dispatch(SET__CRUD__ERROR(error));
    }
  }
);

export const updatePostInDB = createAsyncThunk(
  "crud/updatePostInDB",
  async (data, { dispatch, extra, getState }) => {
    const { getFirebase, getFirestore } = extra;

    const firebase = getFirebase();
    const firestore = getFirestore();
    const { user } = getState().firebase.profile;

    try {
      let fileUrlFromUpload;

      //upload image first to get url
      if (data.postBanner.hasOwnProperty("name")) {
        const storageRef = firebase.storage().ref();
        const fileRef = storageRef.child(
          `Posts/${user.id}/${btoa(data.postBanner.name)}`
        );
        await fileRef.put(data.postBanner);
        fileUrlFromUpload = await fileRef.getDownloadURL();
      } else {
        fileUrlFromUpload = data.postBanner;
      }

      // firebase.firestore.Timestamp.fromDate(new Date())

      const updateRef = firestore.collection("posts").doc(data.savedId);
      return updateRef.update({
        "interactions.isCommentDisabled": !data.isCommentDisabled,
        properties: {
          title: data.postTitle,
          subTitle: data.postSubTitle,
          contentPlain: data.postContentPlain,
          contentHTML: data.postContentHtml,
          banner: fileUrlFromUpload,
          duration: data.postDuration,
          tags: data.postTags,
        },
        status: data.postStatus,
        createdAt: new Date(),
      });
    } catch (error) {
      dispatch(SET__CRUD__ERROR(error));
    }
  }
);

export const createSkillDB = createAsyncThunk(
  "crud/createSkillDB",
  async (data, { extra, getState, dispatch }) => {
    const { getFirebase, getFirestore } = extra;

    const firebase = getFirebase();
    const firestore = getFirestore();
    const { user } = getState().firebase.profile;

    // firebase.firestore.Timestamp.fromDate(new Date())
    //upload image first to get url

    const getImageLink = async (file) => {
      const storageRef = firebase.storage().ref();
      const fileRef = storageRef.child(`Skills/${user.id}/${btoa(file.name)}`);
      await fileRef.put(file);
      const fileUrlFromUpload = await fileRef.getDownloadURL();
      return fileUrlFromUpload;
    };

    try {
      if (data.compFiles.length > 0) {
        const urls = await Promise.all(data.compFiles.map(getImageLink));
        return firestore.collection("skills").add({
          interactions: {
            reportCount: 0,
            likeCount: 0,
            saveCount: 0,
            reviewCount: 0,
            hidden: false,
          },
          author: {
            fullName: user.fullName,
            username: user.username,
            id: user.id,
            photoURL: user.photoURL,
          },
          properties: {
            title: data.skillTitle,
            category: data.skillCategory,
            isJob: data.isSkillAJob,
            jobStatus: data.jobStatus,
            banners: urls,
            location: data.skillLocation,
            price: data.skillPrice,
            description: data.skillDescription,
            servicePlain: data.skillServicePlain,
            serviceHTML: data.skillServiceHTML,
          },
          review: {
            id: "",
            username: "",
            reviewedAt: new Date(),
          },
          status: data.status,
          createdAt: new Date(),
        });
      } else {
        return firestore.collection("skills").add({
          interactions: {
            reportCount: 0,
            likeCount: 0,
            saveCount: 0,
            reviewCount: 0,
            hidden: false,
          },
          author: {
            fullName: user.fullName,
            username: user.username,
            id: user.id,
            photoURL: user.photoURL,
          },
          properties: {
            title: data.skillTitle,
            category: data.skillCategory,
            isJob: data.isSkillAJob,
            jobStatus: data.jobStatus,
            banners: [],
            location: data.skillLocation,
            price: data.skillPrice,
            description: data.skillDescription,
            servicePlain: data.skillServicePlain,
            serviceHTML: data.skillServiceHTML,
          },
          review: {
            id: "",
            username: "",
            reviewedAt: new Date(),
          },
          status: data.status,
          createdAt: new Date(),
        });
      }
    } catch (error) {
      dispatch(SET__CRUD__ERROR(error));
    }
  }
);

export const createHouseRecord = createAsyncThunk(
  "crud/createHouseRecord",
  async (data, { dispatch, extra, getState }) => {
    const { getFirebase, getFirestore } = extra;

    const firebase = getFirebase();
    const firestore = getFirestore();
    const { user } = getState().firebase.profile;

    // firebase.firestore.Timestamp.fromDate(new Date())
    //upload image first to get url
    const getImageLink = async (file) => {
      const storageRef = firebase.storage().ref();
      const fileRef = storageRef.child(`Ratings/${user.id}/${btoa(file.name)}`);
      await fileRef.put(file);
      const fileUrlFromUpload = await fileRef.getDownloadURL();
      return fileUrlFromUpload;
    };

    try {
      if (data.compFiles.length > 0) {
        const urls = await Promise.all(data.compFiles.map(getImageLink));
        return firestore.collection("ratings").add({
          interactions: {
            reportCount: 0,
            reviewCount: 0,
            hidden: false,
          },
          building: {
            city: data.city,
            area: data.area,
            name: data.building,
            apartment: data.apartment,
            floor: data.floor,
            photos: urls,
          },
          review: {
            id: "",
            username: "",
            reviewedAt: new Date(),
          },
          status: 0,
          createdAt: new Date(),
        });
      }
    } catch (error) {
      dispatch(SET__CRUD__ERROR(error));
    }
  }
);

export const createHouseReviewDb = createAsyncThunk(
  "crud/createHouseReviewDb",
  async (data, { dispatch, extra, getState }) => {
    const { getFirestore, getFirebase } = extra;
    const firestore = getFirestore();
    const firebase = getFirebase();
    const { user } = getState().firebase.profile;

    // console.log(data)
    try {
      return firestore
        .collection("ratings")
        .doc(data.id)
        .collection("reviews")
        .doc()
        .set({
          building: {
            id: data.id,
            city: data.city,
            area: data.area,
            name: data.building,
          },
          rating: {
            exterior: data.exterior,
            communication: data.communication,
            interior: data.interior,
            hApp: data.hApp,
            facility: data.facility,
            services: data.services,
            security: data.security,
            vfm: data.vfm,
            comment: validator.escape(data.comment),
          },
          interactions: {
            reportCount: 0,
          },
          author: {
            id: user.id,
            fullName: user.fullName,
            photoURL: user.photoURL,
            username: user.username,
          },
          createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
        });
    } catch (error) {
      dispatch(SET__CRUD__ERROR(error));
    }
  }
);

export const createComplaintDB = createAsyncThunk(
  "crud/createComplaintDB",
  async (data, { extra, getState, dispatch }) => {
    const { getFirebase, getFirestore } = extra;

    const firebase = getFirebase();
    const firestore = getFirestore();
    const { user } = getState().firebase.profile;

    // firebase.firestore.Timestamp.fromDate(new Date())
    //upload image first to get url
    const getImageLink = async (file) => {
      const storageRef = firebase.storage().ref();
      const fileRef = storageRef.child(
        `Complaints/${user.id}/${btoa(file.name)}`
      );
      await fileRef.put(file);
      const fileUrlFromUpload = await fileRef.getDownloadURL();
      return fileUrlFromUpload;
    };

    try {
      if (data.compFiles.length > 0) {
        const urls = await Promise.all(data.compFiles.map(getImageLink));
        return firestore.collection("complaints").add({
          author: {
            fullName: !data.isAnonymous ? user.fullName : data.isAnonymousName,
            username: !data.isAnonymous ? user.username : data.isAnonymousName,
            id: !data.isAnonymous ? user.id : "",
            photoURL: !data.isAnonymous ? user.photoURL : "",
          },
          properties: {
            title: data.title,
            banner: urls,
            category: data.category,
            description: data.description,
          },
          review: {
            id: "",
            username: "",
            reviewedAt: new Date(),
          },
          status: 1,
          createdAt: new Date(),
        });
      } else {
        return firestore.collection("complaints").add({
          author: {
            fullName: !data.isAnonymous ? user.fullName : data.isAnonymousName,
            username: !data.isAnonymous ? user.username : data.isAnonymousName,
            id: !data.isAnonymous ? user.id : "",
            photoURL: !data.isAnonymous ? user.photoURL : "",
          },
          properties: {
            title: data.title,
            banner: [],
            category: data.category,
            description: data.description,
          },
          review: {
            id: "",
            username: "",
            reviewedAt: new Date(),
          },
          status: 1,
          createdAt: new Date(),
        });
      }
    } catch (error) {
      dispatch(SET__CRUD__ERROR(error));
    }
  }
);

// interactions
export const likePost = createAsyncThunk(
  "crud/likePost",
  async (data, { dispatch, extra, getState }) => {
    const { getFirestore, getFirebase } = extra;
    const firestore = getFirestore();
    const firebase = getFirebase();
    const { user } = getState().firebase.profile;

    try {
      return firestore
        .collection("users")
        .doc(user.id)
        .update({
          "userLikes.posts": firebase.firestore.FieldValue.arrayUnion(data),
        })
        .then((data) => {
          dispatch(SET__CRUD__ERROR("success"));
        })
        .catch((err) => {
          // console.log(err.code);
          if (err.code === "not-found") {
            return firestore
              .collection("users")
              .doc(user.id)
              .set({
                "userLikes.posts":
                  firebase.firestore.FieldValue.arrayUnion(data),
              })
              .then((data) => {
                dispatch(SET__CRUD__ERROR("success"));
              })
              .catch((err) => {
                // console.log(err.code);
                dispatch(SET__CRUD__ERROR(err.code));
              });
          }
          dispatch(SET__CRUD__ERROR(err.code));
        });
    } catch (error) {
      dispatch(SET__CRUD__ERROR(error));
    }
  }
);

export const unLikePost = createAsyncThunk(
  "crud/unLikePost",
  async (data, { dispatch, extra, getState }) => {
    const { getFirestore, getFirebase } = extra;
    const firestore = getFirestore();
    const firebase = getFirebase();
    const { user } = getState().firebase.profile;

    try {
      return firestore
        .collection("users")
        .doc(user.id)
        .update({
          "userLikes.posts": firebase.firestore.FieldValue.arrayRemove(data),
        })
        .then((data) => {
          dispatch(SET__CRUD__ERROR("success"));
        })
        .catch((err) => {
          // console.log(err.code);
          dispatch(SET__CRUD__ERROR(err.code));
        });
    } catch (error) {
      dispatch(SET__CRUD__ERROR(error));
    }
  }
);

export const savePost = createAsyncThunk(
  "crud/savePost",
  async (data, { dispatch, extra, getState }) => {
    const { getFirestore, getFirebase } = extra;
    const firestore = getFirestore();
    const firebase = getFirebase();
    const { user } = getState().firebase.profile;

    try {
      return firestore
        .collection("users")
        .doc(user.id)
        .update({
          "userSaves.posts": firebase.firestore.FieldValue.arrayUnion(data),
        })
        .then((data) => {
          dispatch(SET__CRUD__ERROR("success"));
        })
        .catch((err) => {
          console.log(err.code);
          if (err.code === "not-found") {
            return firestore
              .collection("users")
              .doc(user.id)
              .set({
                "userSaves.posts":
                  firebase.firestore.FieldValue.arrayUnion(data),
              })
              .then((data) => {
                dispatch(SET__CRUD__ERROR("success"));
              })
              .catch((err) => {
                // console.log(err.code);
                dispatch(SET__CRUD__ERROR(err.code));
              });
          }
          dispatch(SET__CRUD__ERROR(err.code));
        });
    } catch (error) {
      dispatch(SET__CRUD__ERROR(error));
    }
  }
);

export const unSavePost = createAsyncThunk(
  "crud/unSavePost",
  async (data, { dispatch, extra, getState }) => {
    const { getFirestore, getFirebase } = extra;
    const firestore = getFirestore();
    const firebase = getFirebase();
    const { user } = getState().firebase.profile;

    try {
      return firestore
        .collection("users")
        .doc(user.id)
        .update({
          "userSaves.posts": firebase.firestore.FieldValue.arrayRemove(data),
        })
        .then((data) => {
          dispatch(SET__CRUD__ERROR("success"));
        })
        .catch((err) => {
          // console.log(err.code);
          dispatch(SET__CRUD__ERROR(err.code));
        });
    } catch (error) {
      dispatch(SET__CRUD__ERROR(error));
    }
  }
);
export const saveResources = createAsyncThunk(
  "crud/saveResources",
  async (data, { dispatch, extra, getState }) => {
    const { getFirestore, getFirebase } = extra;
    const firestore = getFirestore();
    const firebase = getFirebase();
    const { user } = getState().firebase.profile;

    try {
      return firestore
        .collection("users")
        .doc(user.id)
        .update({
          "userSaves.resources": firebase.firestore.FieldValue.arrayUnion(data),
        })
        .then((data) => {
          dispatch(SET__CRUD__ERROR("success"));
        })
        .catch((err) => {
          console.log(err.code);
          if (err.code === "not-found") {
            return firestore
              .collection("users")
              .doc(user.id)
              .set({
                "userSaves.resources":
                  firebase.firestore.FieldValue.arrayUnion(data),
              })
              .then((data) => {
                dispatch(SET__CRUD__ERROR("success"));
              })
              .catch((err) => {
                // console.log(err.code);
                dispatch(SET__CRUD__ERROR(err.code));
              });
          }
          dispatch(SET__CRUD__ERROR(err.code));
        });
    } catch (error) {
      dispatch(SET__CRUD__ERROR(error));
    }
  }
);

export const unSaveResources = createAsyncThunk(
  "crud/unSaveResources",
  async (data, { dispatch, extra, getState }) => {
    const { getFirestore, getFirebase } = extra;
    const firestore = getFirestore();
    const firebase = getFirebase();
    const { user } = getState().firebase.profile;

    try {
      return firestore
        .collection("users")
        .doc(user.id)
        .update({
          "userSaves.resources":
            firebase.firestore.FieldValue.arrayRemove(data),
        })
        .then((data) => {
          dispatch(SET__CRUD__ERROR("success"));
        })
        .catch((err) => {
          // console.log(err.code);
          dispatch(SET__CRUD__ERROR(err.code));
        });
    } catch (error) {
      dispatch(SET__CRUD__ERROR(error));
    }
  }
);

export const likeSkill = createAsyncThunk(
  "crud/likeSkill",
  async (data, { dispatch, extra, getState }) => {
    const { getFirestore, getFirebase } = extra;
    const firestore = getFirestore();
    const firebase = getFirebase();
    const { user } = getState().firebase.profile;

    try {
      return firestore
        .collection("users")
        .doc(user.id)
        .update({
          "userLikes.skills": firebase.firestore.FieldValue.arrayUnion(data),
        })
        .then((data) => {
          dispatch(SET__CRUD__ERROR("success"));
        })
        .catch((err) => {
          // console.log(err.code);
          if (err.code === "not-found") {
            return firestore
              .collection("users")
              .doc(user.id)
              .set({
                "userLikes.skills":
                  firebase.firestore.FieldValue.arrayUnion(data),
              })
              .then((data) => {
                dispatch(SET__CRUD__ERROR("success"));
              })
              .catch((err) => {
                // console.log(err.code);
                dispatch(SET__CRUD__ERROR(err.code));
              });
          }
          dispatch(SET__CRUD__ERROR(err.code));
        });
    } catch (error) {
      dispatch(SET__CRUD__ERROR(error));
    }
  }
);

export const unLikeSkill = createAsyncThunk(
  "crud/unLikeSkill",
  async (data, { dispatch, extra, getState }) => {
    const { getFirestore, getFirebase } = extra;
    const firestore = getFirestore();
    const firebase = getFirebase();
    const { user } = getState().firebase.profile;

    try {
      return firestore
        .collection("users")
        .doc(user.id)
        .update({
          "userLikes.skills": firebase.firestore.FieldValue.arrayRemove(data),
        })
        .then((data) => {
          dispatch(SET__CRUD__ERROR("success"));
        })
        .catch((err) => {
          // console.log(err.code);
          dispatch(SET__CRUD__ERROR(err.code));
        });
    } catch (error) {
      dispatch(SET__CRUD__ERROR(error));
    }
  }
);

export const createComment = createAsyncThunk(
  "crud/createComment",
  async (data, { dispatch, extra, getState }) => {
    const { getFirestore, getFirebase } = extra;
    const firestore = getFirestore();
    const firebase = getFirebase();
    const { user } = getState().firebase.profile;

    // console.log(data)

    try {
      return firestore
        .collection("posts")
        .doc(data.docId)
        .collection("comments")
        .doc()
        .set({
          body: {
            comment: validator.escape(data.comment),
          },
          interactions: {
            reportCount: 0,
          },
          status: 0,
          author: {
            id: user.id,
            fullName: user.fullName,
            photoURL: user.photoURL,
            username: user.username,
          },
          createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
        });
    } catch (error) {
      dispatch(SET__CRUD__ERROR(error));
    }
  }
);

export const createSubComment = createAsyncThunk(
  "crud/createSubComment",
  async (data, { dispatch, extra, getState }) => {
    const { getFirestore, getFirebase } = extra;
    const firestore = getFirestore();
    const firebase = getFirebase();
    const { user } = getState().firebase.profile;

    // console.log(data)
    try {
      return firestore
        .collection("posts")
        .doc(data.pid)
        .collection("comments")
        .doc(data.id)
        .collection("commentSubComments")
        .doc()
        .set({
          body: {
            comment: validator.escape(data.subcomment),
            parentId: data.id,
          },
          interactions: {
            reportCount: 0,
          },
          status: 0,
          author: {
            id: user.id,
            fullName: user.fullName,
            photoURL: user.photoURL,
            username: user.username,
          },
          createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
        });
    } catch (error) {
      dispatch(SET__CRUD__ERROR(error));
    }
  }
);

export const createHouseReview = createAsyncThunk(
  "crud/createHouseReview",
  async (data, { dispatch, extra, getState }) => {
    const { getFirestore, getFirebase } = extra;
    const firestore = getFirestore();
    const firebase = getFirebase();
    const { user } = getState().firebase.profile;

    // console.log(data)

    try {
      return firestore
        .collection("ratings")
        .doc(data.id)
        .collection("reviews")
        .doc()
        .set({
          building: {
            id: data.id,
            city: data.city,
            area: data.area,
            name: data.building,
          },
          rating: {
            exterior: data.exterior,
            communication: data.communication,
            interior: data.interior,
            hApp: data.hApp,
            facility: data.facility,
            services: data.services,
            security: data.security,
            vfm: data.vfm,
            comment: validator.escape(data.comment),
          },
          interactions: {
            reportCount: 0,
          },
          author: {
            id: user.id,
            fullName: user.fullName,
            photoURL: user.photoURL,
            username: user.username,
          },
          createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
        });
    } catch (error) {
      dispatch(SET__CRUD__ERROR(error));
    }
  }
);
export const createSkillReview = createAsyncThunk(
  "crud/createSkillReview",
  async (data, { dispatch, extra, getState }) => {
    const { getFirestore, getFirebase } = extra;
    const firestore = getFirestore();
    const firebase = getFirebase();
    const { user } = getState().firebase.profile;

    // console.log(data)

    try {
      return firestore
        .collection("skills")
        .doc(data.id)
        .collection("reviews")
        .doc()
        .set({
          rating: {
            comment: validator.escape(data.comment),
            communication: data.communication,
            quality: data.quality,
            recommend: data.recommend,
          },
          interactions: {
            reportCount: 0,
          },
          author: {
            id: user.id,
            fullName: user.fullName,
            photoURL: user.photoURL,
            username: user.username,
          },
          createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
        });
    } catch (error) {
      dispatch(SET__CRUD__ERROR(error));
    }
  }
);

// reportings
export const createPostReport = createAsyncThunk(
  "crud/createPostReport",
  async (data, { dispatch, extra, getState }) => {
    const { getFirestore, getFirebase } = extra;
    const firestore = getFirestore();
    const firebase = getFirebase();
    const { user } = getState().firebase.profile;

    // console.log(data)
    try {
      return firestore
        .collection("reports")
        .doc("types")
        .collection("posts")
        .doc(data.id)
        .set({
          properties: {
            type: data.type,
            contextId: data.id,
            reason: data.report,
          },
          review: {
            id: "",
            reviewedAt: new Date(),
          },
          author: {
            id: user.id,
          },
          createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
        });
    } catch (error) {
      dispatch(SET__CRUD__ERROR(error));
    }
  }
);

export const createCommentReport = createAsyncThunk(
  "crud/createCommentReport",
  async (data, { dispatch, extra, getState }) => {
    const { getFirestore, getFirebase } = extra;
    const firestore = getFirestore();
    const firebase = getFirebase();
    const { user } = getState().firebase.profile;

    // console.log(data)
    try {
      if (data.type === "comment") {
        return firestore
          .collection("reports")
          .doc("types")
          .collection("comments")
          .doc()
          .set({
            properties: {
              text: validator.escape(data.content),
              type: data.type,
              contextId: data.id,
              postId: data.pid,
              reason: data.report,
            },
            review: {
              id: "",
              reviewedAt: new Date(),
            },
            author: {
              id: user.id,
            },
            createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
          });
      } else {
        return firestore
          .collection("reports")
          .doc("types")
          .collection("comments")
          .doc()
          .set({
            properties: {
              text: validator.escape(data.content),
              type: data.type,
              parentId: data.pid,
              contextId: data.id,
              postId: data.postId,
              reason: data.report,
            },
            review: {
              id: "",
              reviewedAt: new Date(),
            },
            author: {
              id: user.id,
            },
            createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
          });
      }
    } catch (error) {
      dispatch(SET__CRUD__ERROR(error));
    }
  }
);

export const createSkillReport = createAsyncThunk(
  "crud/createSkillReport",
  async (data, { dispatch, extra, getState }) => {
    const { getFirestore, getFirebase } = extra;
    const firestore = getFirestore();
    const firebase = getFirebase();
    const { user } = getState().firebase.profile;

    // console.log(data)
    try {
      return firestore
        .collection("reports")
        .doc("types")
        .collection("skills")
        .doc(data.sid)
        .set({
          properties: {
            type: data.type,
            contextId: data.sid,
            reason: data.report,
          },
          review: {
            id: "",
            reviewedAt: new Date(),
          },
          author: {
            id: user.id,
          },
          createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
        });
    } catch (error) {
      dispatch(SET__CRUD__ERROR(error));
    }
  }
);

export const reportSkillReview = createAsyncThunk(
  "crud/reportSkillReview",
  async (data, { dispatch, extra, getState }) => {
    const { getFirestore, getFirebase } = extra;
    const firestore = getFirestore();
    const firebase = getFirebase();
    const { user } = getState().firebase.profile;

    // console.log(data)

    try {
      return firestore
        .collection("reports")
        .doc("types")
        .collection("comments")
        .doc()
        .set({
          properties: {
            text: validator.escape(data.content),
            type: data.type,
            contextId: data.id,
            postId: data.sid,
            reason: data.report,
          },
          review: {
            id: "",
            reviewedAt: new Date(),
          },
          author: {
            id: user.id,
          },
          createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
        });
    } catch (error) {
      dispatch(SET__CRUD__ERROR(error));
    }
  }
);

export const reportHouseReview = createAsyncThunk(
  "crud/reportHouseReview",
  async (data, { dispatch, extra, getState }) => {
    const { getFirestore, getFirebase } = extra;
    const firestore = getFirestore();
    const firebase = getFirebase();
    const { user } = getState().firebase.profile;

    // console.log(data)

    try {
      return firestore
        .collection("reports")
        .doc("types")
        .collection("comments")
        .doc()
        .set({
          properties: {
            text: validator.escape(data.content),
            type: data.type,
            contextId: data.id,
            postId: data.hid,
            reason: data.report,
          },
          review: {
            id: "",
            reviewedAt: new Date(),
          },
          author: {
            id: user.id,
          },
          createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
        });
    } catch (error) {
      dispatch(SET__CRUD__ERROR(error));
    }
  }
);

// deletes
export const deletePost = createAsyncThunk(
  "crud/deletePost",
  async (data, { dispatch, extra }) => {
    const { getFirestore } = extra;
    const firestore = getFirestore();

    // console.log(data)
    try {
      return firestore.collection("posts").doc(data.id).delete();
    } catch (error) {
      dispatch(SET__CRUD__ERROR(error));
    }
  }
);

export const deleteSkill = createAsyncThunk(
  "crud/deleteSkill",
  async (data, { dispatch, extra }) => {
    const { getFirestore } = extra;
    const firestore = getFirestore();

    // console.log(data)
    try {
      return firestore.collection("skills").doc(data.sid).delete();
    } catch (error) {
      dispatch(SET__CRUD__ERROR(error));
    }
  }
);

export const deleteComment = createAsyncThunk(
  "crud/deleteComment",
  async (data, { dispatch, extra }) => {
    const { getFirestore } = extra;
    const firestore = getFirestore();

    // console.log(data)
    try {
      return firestore
        .collection("posts")
        .doc(data.pid)
        .collection("comments")
        .doc(data.cid)
        .delete();
    } catch (error) {
      dispatch(SET__CRUD__ERROR(error));
    }
  }
);

export const deleteSubComment = createAsyncThunk(
  "crud/deleteSubComment",
  async (data, { extra, dispatch }) => {
    const { getFirestore } = extra;
    const firestore = getFirestore();
    // console.log(data);
    try {
      return firestore
        .collection("posts")
        .doc(data.pid)
        .collection("comments")
        .doc(data.cid)
        .collection("commentSubComments")
        .doc(data.scid)
        .delete();
    } catch (error) {
      dispatch(SET__CRUD__ERROR(error));
    }
  }
);

export const deleteSkillReview = createAsyncThunk(
  "crud/deleteSkillReview",
  async (data, { dispatch, extra, getState }) => {
    const { getFirestore } = extra;
    const firestore = getFirestore();

    // console.log(data)
    try {
      return firestore
        .collection("skills")
        .doc(data.sid)
        .collection("reviews")
        .doc(data.id)
        .delete();
    } catch (error) {
      dispatch(SET__CRUD__ERROR(error));
    }
  }
);

export const deleteHouseReview = createAsyncThunk(
  "crud/deleteHouseReview",
  async (data, { dispatch, extra, getState }) => {
    const { getFirestore } = extra;
    const firestore = getFirestore();

    // console.log(data)
    try {
      return firestore
        .collection("ratings")
        .doc(data.hid)
        .collection("reviews")
        .doc(data.id)
        .delete();
    } catch (error) {
      dispatch(SET__CRUD__ERROR(error));
    }
  }
);

// updating
export const disableComment = createAsyncThunk(
  "crud/disableComment",
  async (data, { dispatch, extra }) => {
    const { getFirestore } = extra;
    const firestore = getFirestore();

    // console.log(data)
    try {
      const updateRef = firestore.collection("posts").doc(data.id);
      return updateRef.update({
        "interactions.isCommentDisabled": false,
      });
    } catch (error) {
      dispatch(SET__CRUD__ERROR(error));
    }
  }
);

export const enableComment = createAsyncThunk(
  "crud/enableComment",
  async (data, { dispatch, extra }) => {
    const { getFirestore } = extra;
    const firestore = getFirestore();
    try {
      // console.log(data)
      const updateRef = firestore.collection("posts").doc(data.id);
      return updateRef.update({
        "interactions.isCommentDisabled": true,
      });
    } catch (error) {
      dispatch(SET__CRUD__ERROR(error));
    }
  }
);

export const updateUserName = createAsyncThunk(
  "crud/updateUserName",
  async (data, { dispatch, extra, getState }) => {
    const { getFirestore } = extra;
    const firestore = getFirestore();
    const { user } = getState().firebase.profile;

    try {
      return firestore
        .collection("users")
        .where("user.username", "==", data.username)
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.docs.length === 0) {
            const updateRef = firestore.collection("users").doc(user.id);
            return updateRef.update({
              "user.username": data.username,
            });
          } else {
            let errMessage = { username: "Username is not available" };
            dispatch(SET__CRUD__ERROR(errMessage));
            return errMessage;
          }
        })
        .catch((err) => {
          dispatch(SET__CRUD__ERROR({ err }));
        });
    } catch (error) {
      dispatch(SET__CRUD__ERROR(error));
    }
  }
);

export const updateUserProfile = createAsyncThunk(
  "crud/updateUserProfile",
  async (data, { dispatch, extra, getState }) => {
    const { getFirestore, getFirebase } = extra;
    const firestore = getFirestore();
    const firebase = getFirebase();
    const { user } = getState().firebase.profile;

    // console.log(data)
    const getImageLink = async (file) => {
      if (file.hasOwnProperty("name")) {
        const storageRef = firebase.storage().ref();
        const fileRef = storageRef.child(`Users/${user.id}/${btoa(file.name)}`);
        await fileRef.put(file);
        const fileUrlFromUpload = await fileRef.getDownloadURL();
        return fileUrlFromUpload;
      }
      return file;
    };

    try {
      let urls = await Promise.all(
        [data.avatar, data.banner].map(getImageLink)
      );
      const updateRef = firestore.collection("users").doc(user.id);
      return updateRef.update({
        "user.photoURL": urls[0],
        "user.banner": urls[1],
        "user.fullName": data.name,
        "user.bio": data.bio,
      });
    } catch (error) {
      dispatch(SET__CRUD__ERROR(error));
    }
  }
);

export const updateEmail = createAsyncThunk(
  "crud/updateEmail",
  async (data, { dispatch, extra, getState }) => {
    const { getFirestore } = extra;
    const firestore = getFirestore();
    const { user } = getState().firebase.profile;

    try {
      return firestore
        .collection("users")
        .where("user.email", "==", data.email)
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.docs.length === 0) {
            const updateRef = firestore.collection("users").doc(user.id);
            return updateRef
              .update({
                "user.email": data.email,
              })
              .then(() => {
                dispatch(SET__CRUD__ERROR(null));
              })
              .catch((err) => {
                dispatch(SET__CRUD__ERROR(err));
                // console.log(err.code);
              });
          } else {
            let errMessage = { email: "Email already exists" };
            dispatch(SET__CRUD__ERROR(errMessage));
            return errMessage;
          }
        })
        .catch((err) => {
          dispatch(SET__CRUD__ERROR({ err }));
        });
    } catch (error) {
      dispatch(SET__CRUD__ERROR(error));
    }
  }
);

export const updateUserSocials = createAsyncThunk(
  "crud/updateUserSocials",
  async (data, { dispatch, extra, getState }) => {
    const { getFirestore } = extra;
    const firestore = getFirestore();
    const { user } = getState().firebase.profile;

    // console.log(data)
    try {
      const userRef = firestore.collection("users").doc(user.id);
      return userRef.update({
        "socials.whatsapp": `${data.whatsapp}`,
        "socials.portfolio": `${data.portfolio}`,
        "socials.linkedIn": `${data.linkedIn}`,
        "socials.instagram": `${data.instagram}`,
        "socials.twitter": `${data.twitter}`,
        "socials.facebook": `${data.facebook}`,
      });
    } catch (error) {
      dispatch(SET__CRUD__ERROR(error));
    }
  }
);

export const updateNotificationEnabled = createAsyncThunk(
  "crud/updateNotificationEnabled",
  async (data, { dispatch, extra, getState }) => {
    const { getFirestore } = extra;
    const firestore = getFirestore();
    const { user } = getState().firebase.profile;

    const updateRef = firestore.collection("users").doc(user.id);

    if (data === "enable") {
      return updateRef.update({
        "account.notifications": true,
      });
    } else {
      return updateRef.update({
        "account.notifications": false,
      });
    }
  }
);

export const updateIsSubscribed = createAsyncThunk(
  "crud/updateIsSubscribed",
  async (data, { dispatch, extra, getState }) => {
    const { getFirestore } = extra;
    const firestore = getFirestore();
    const { user } = getState().firebase.profile;

    const updateRef = firestore.collection("users").doc(user.id);

    if (data === "enable") {
      return updateRef.update({
        "account.isMailSubscription": true,
      });
    } else {
      return updateRef.update({
        "account.isMailSubscription": false,
      });
    }
  }
);

export const updateNotifications = createAsyncThunk(
  "crud/updateNotifications",
  async (credentials, { dispatch, extra, getState }) => {
    const { getFirestore } = extra;
    const firestore = getFirestore();
    const { user } = getState().firebase.profile;
    // console.log(credentials);

    firestore
      .collection("notifications")
      .where("to.id", "==", user.id)
      .where("status", "==", 1)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((snapshot) => {
          snapshot.ref.update({
            status: 0,
          });
        });
      })
      .catch((err) => {
        dispatch(SET__CRUD__ERROR({ err }));
      });
  }
);

export const updatePasswordId = createAsyncThunk(
  "crud/updatePasswordId",
  async (data, { extra, rejectWithValue, getState }) => {
    const { getFirebase } = extra;
    const firebase = getFirebase();
    const { user } = getState().firebase.profile;
    try {
      const updatePassword = firebase
        .functions()
        .httpsCallable("updatePasswordById");
      return updatePassword({ id: user.id, password: data.password });
    } catch (error) {
      // Getting the Error details.
      rejectWithValue(error);
    }
  }
);

export const updateDisableAccount = createAsyncThunk(
  "crud/updateDisableAccount",
  async (data, { dispatch, extra, getState }) => {
    const { getFirestore } = extra;
    const firestore = getFirestore();
    const { user } = getState().firebase.profile;

    const updateRef = firestore.collection("users").doc(user.id);
    return updateRef.update({
      "account.isDisabled": data,
    });
  }
);

export const deleteUserById = createAsyncThunk(
  "crud/deleteUserById ",
  async (id, { extra, rejectWithValue }) => {
    const { getFirebase } = extra;
    const firebase = getFirebase();

    try {
      const deleteUser = firebase.functions().httpsCallable("deleteUserById");
      return deleteUser(id);
    } catch (error) {
      // Getting the Error details.
      rejectWithValue(error);
    }
  }
);

const CrudSlice = createSlice({
  name: "crud",
  initialState,
  reducers: {
    SET__CRUD__ERROR: (state, { payload }) => {
      state.crudError = payload;
    },
    RESET__CRUD__ERROR: (state) => {
      state.crudError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createPostInDB.pending, (state) => {
        state.isCrudLoading = true;
      })
      .addCase(createPostInDB.fulfilled, (state) => {
        state.isCrudLoading = false;
      })
      .addCase(createSkillDB.pending, (state) => {
        state.isCrudLoading = true;
      })
      .addCase(createSkillDB.fulfilled, (state) => {
        state.isCrudLoading = false;
      })
      .addCase(updatePostInDB.pending, (state) => {
        state.isCrudLoading = true;
      })
      .addCase(updatePostInDB.fulfilled, (state) => {
        state.isCrudLoading = false;
      })
      .addCase(createPostReport.pending, (state) => {
        state.isCrudLoading = true;
      })
      .addCase(createPostReport.fulfilled, (state) => {
        state.isCrudLoading = false;
      })
      .addCase(createComment.pending, (state) => {
        state.isCrudLoading = true;
      })
      .addCase(createComment.fulfilled, (state) => {
        state.isCrudLoading = false;
      })
      .addCase(createSkillReview.pending, (state) => {
        state.isCrudLoading = true;
      })
      .addCase(createSkillReview.fulfilled, (state) => {
        state.isCrudLoading = false;
      })
      .addCase(createHouseReview.pending, (state) => {
        state.isCrudLoading = true;
      })
      .addCase(createHouseReview.fulfilled, (state) => {
        state.isCrudLoading = false;
      })
      .addCase(createHouseRecord.pending, (state) => {
        state.isCrudLoading = true;
      })
      .addCase(createHouseRecord.fulfilled, (state) => {
        state.isCrudLoading = false;
      })
      .addCase(createHouseReviewDb.pending, (state) => {
        state.isCrudLoading = true;
      })
      .addCase(createHouseReviewDb.fulfilled, (state) => {
        state.isCrudLoading = false;
      })
      .addCase(createComplaintDB.pending, (state) => {
        state.isCrudLoading = true;
      })
      .addCase(createComplaintDB.fulfilled, (state) => {
        state.isCrudLoading = false;
      })
      .addCase(updateUserName.pending, (state) => {
        state.isCrudLoading = true;
      })
      .addCase(updateUserName.fulfilled, (state) => {
        state.isCrudLoading = false;
      })
      .addCase(updateUserProfile.pending, (state) => {
        state.isCrudLoading = true;
      })
      .addCase(updateUserProfile.fulfilled, (state) => {
        state.isCrudLoading = false;
      })
      .addCase(updateEmail.pending, (state) => {
        state.isCrudLoading = true;
      })
      .addCase(updateEmail.fulfilled, (state) => {
        state.isCrudLoading = false;
      })
      .addCase(updateUserSocials.pending, (state) => {
        state.isCrudLoading = true;
      })
      .addCase(updateUserSocials.fulfilled, (state) => {
        state.isCrudLoading = false;
      })
      .addCase(updatePasswordId.pending, (state) => {
        state.isCrudLoading = true;
      })
      .addCase(updatePasswordId.fulfilled, (state) => {
        state.isCrudLoading = false;
      })
      .addCase(updateDisableAccount.pending, (state) => {
        state.isCrudLoading = true;
      })
      .addCase(updateDisableAccount.fulfilled, (state) => {
        state.isCrudLoading = false;
      })
      .addCase(deleteUserById.pending, (state) => {
        state.isCrudLoading = true;
      })
      .addCase(deleteUserById.fulfilled, (state) => {
        state.isCrudLoading = false;
      });
  },
});

export const { SET__CRUD__ERROR, RESET__CRUD__ERROR } = CrudSlice.actions;
export default CrudSlice.reducer;

import React from 'react'

const SkeletonAnimate = () => {
  return (
    <div className="sk__animate__wrapper">
        <div className="sk__animate"></div>
    </div>
  )
}

export default SkeletonAnimate
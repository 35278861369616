import React, { useEffect, useRef } from "react";
import btnStyles from "../../../css/auth-btn.module.css";
import {
  ActiveSavedIcon,
  DownloadIcon,
  FbIcon,
  LinkIcon,
  SavedIcon,
  ShareIcon,
  XIcon,
} from "../../../assets/icons/Icons";
import { isEmpty } from "react-redux-firebase";
import { saveResources, unSaveResources } from "../../../stores/crud/CrudSlice";
import { useDispatch } from "react-redux";
import M from "materialize-css";

export const ResourceActions = ({
  resourceId,
  auth,
  navigate,
  userSaves,
  copyToClipboard,
  shareOnFacebook,
  shareOnTwitter,
  disableActions,
  isAttachment,
  attachment,
}) => {
  const dispatch = useDispatch();
  const shareRef = useRef();

  useEffect(() => {
    const share = document.querySelector(".btn__share");
    shareRef.current = M.Dropdown.init(share, {
      coverTrigger: false,
      alignment: "right",
      constrainWidth: false,
    });

    const reCalculate = () => {
      shareRef.current.recalculateDimensions();
    };

    if (share) {
      window.addEventListener("resize", reCalculate);
      return () => window.removeEventListener("resize", reCalculate);
    }
  }, []);

  // console.log(dispatch(SET__REPORT__MODAL(true)));
  return (
    <>
      <section className="blob-item ">
        <div className="blob-action-area">
          <div className="action-lr">
            <div className="l">
              {isAttachment && (
                <a
                  href={attachment}
                  target="blank"
                  referrerPolicy="noreferrer"
                  className={`${btnStyles["auth-blob-btn"]}`}
                >
                  <DownloadIcon width={20} theme={`cl-icon-light`} />
                  Download resource
                </a>
              )}
            </div>
          </div>
          <div className="action-rl">
            <div className="r">
              {isEmpty(userSaves) ? (
                <button
                  onClick={() => {
                    auth.uid
                      ? dispatch(saveResources(resourceId))
                      : navigate(
                          `/auth/signin?nav=${window.location.pathname}`
                        );
                  }}
                  className={`${btnStyles["auth-blob-btn"]}`}
                  title="Save resource"
                  disabled={disableActions}
                >
                  <SavedIcon width={22} theme={`cl-icon-light`} />
                </button>
              ) : userSaves && !userSaves.resources.includes(resourceId) ? (
                <button
                  onClick={() => {
                    auth.uid
                      ? dispatch(saveResources(resourceId))
                      : navigate(
                          `/auth/signin?nav=${window.location.pathname}`
                        );
                  }}
                  className={`${btnStyles["auth-blob-btn"]}`}
                  title="Save resource"
                  disabled={disableActions}
                >
                  <SavedIcon width={22} theme={`cl-icon-light`} />
                </button>
              ) : (
                <button
                  onClick={() => {
                    auth.uid
                      ? dispatch(unSaveResources(resourceId))
                      : navigate(
                          `/auth/signin?nav=${window.location.pathname}`
                        );
                  }}
                  className={`${btnStyles["auth-blob-btn"]}`}
                  title="Unsave resource"
                  disabled={disableActions}
                >
                  <ActiveSavedIcon width={22} theme={`cl-icon-light`} />
                </button>
              )}
            </div>
            <div className="r">
              <button
                className={`${btnStyles["auth-blob-btn"]} btn__share`}
                title="Share resource"
                data-target={`btn-share`}
                disabled={disableActions}
              >
                <ShareIcon width={22} theme={`cl-icon-light`} />
              </button>
              <ul id="btn-share" className="dropdown-content">
                <li
                  className="custom__list"
                  style={{ width: "100%", gap: "10px", padding: "7px 14px" }}
                >
                  <button
                    className={`${btnStyles["auth-blob-btn"]}`}
                    style={{ width: "100%", justifyContent: "flex-start" }}
                    title="Copy link"
                    onClick={copyToClipboard}
                  >
                    <LinkIcon width={20} />
                    Copy link
                  </button>
                </li>
                <li
                  className="custom__list"
                  style={{ width: "100%", gap: "10px", padding: "7px 14px" }}
                >
                  <button
                    className={`${btnStyles["auth-blob-btn"]}`}
                    style={{ width: "100%", justifyContent: "flex-start" }}
                    title="Share to facebook"
                    onClick={shareOnFacebook}
                  >
                    <FbIcon width={20} />
                    Share on Facebook
                  </button>
                </li>
                <li
                  className="custom__list"
                  style={{ width: "100%", gap: "10px", padding: "7px 14px" }}
                >
                  <button
                    className={`${btnStyles["auth-blob-btn"]}`}
                    style={{ width: "100%", justifyContent: "flex-start" }}
                    title="Share to twitter"
                    onClick={shareOnTwitter}
                  >
                    <XIcon width={20} />
                    Share on X (Twitter)
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

import React, { useEffect, useRef, useState } from "react";
import "../../css/settings.css";
import M from "materialize-css";
import ChangeEmail from "./sections/ChangeEmail";
import ChangeSocials from "./sections/ChangeSocials";
import { BecomeMember } from "./sections/BecomeMember";
import BecomeVolunteer from "./sections/BecomeVolunteer";
import Notification from "./sections/Notification";
import Subscriptions from "./sections/Subscriptions";
import ResetSection from "./sections/ResetSection";
import DeactivateSection from "./sections/DeactivateSection";
import DeleteSection from "./sections/DeleteSection";
import SignoutSection from "./sections/SignoutSection";
import { useDispatch, useSelector } from "react-redux";
import { isLoaded } from "react-redux-firebase";
import AbortableToast from "../../general/components/AbortableToast";
import {
  SET__CRUD__ERROR,
  updateDisableAccount,
  updateEmail,
  updateIsSubscribed,
  updateNotificationEnabled,
  updatePasswordId,
  updateUserSocials,
} from "../../stores/crud/CrudSlice";
import Loader from "../../general/components/Loader";
import validator from "validator";
import { signOutAndDelete } from "../../stores/auth/AuthSlice";

const Settings = () => {
  const collapseRef = useRef();
  const dispatch = useDispatch();
  const { isCrudLoading, crudError } = useSelector((state) => state.crud);
  const { auth } = useSelector((state) => state.firebase);
  const user = useSelector((state) => state.firebase.profile);
  const [view, setView] = useState(false);

  useEffect(() => {
    const elems = document.querySelectorAll(".collapsible");
    collapseRef.current = M.Collapsible.init(elems, {});
  }, [user]);

  const [errors, setErrors] = useState({
    password: "",
    repassword: "",
  });

  const [locUser, setLocUser] = useState({
    email: "",
    facebook: "",
    linkedIn: "",
    password: "",
    repassword: "",
    portfolio: "",
    instagram: "",
    whatsapp: "",
    twitter: "",
    delete: "",
    disable: "",
    isNotification: "",
    isSubscription: "",
  });

  useEffect(() => {
    if (isLoaded(user)) {
      setLocUser((state) => ({
        ...state,
        email: user.user.email,
        facebook: user.socials.facebook,
        instagram: user.socials.instagram,
        linkedIn: user.socials.linkedIn,
        portfolio: user.socials.portfolio,
        twitter: user.socials.twitter,
        whatsapp: user.socials.whatsapp,
        isNotification: user.account.notifications,
        isSubscription: user.account.isMailSubscription,
      }));
    }
  }, [user]);

  const handleNotificationChange = (evt) => {
    if (evt.target.checked) {
      dispatch(updateNotificationEnabled("enable"))
        .then((res) => {
          setTimeout(() => {
            AbortableToast("Notifications enabled.");
          }, 2000);
        })
        .catch((error) => {
          AbortableToast("Error updating. Try again later");
        });
    } else {
      dispatch(updateNotificationEnabled("disable"))
        .then((res) => {
          setTimeout(() => {
            AbortableToast("Notifications disabled.");
          }, 2000);
        })
        .catch((error) => {
          AbortableToast("Error updating. Try again later");
        });
    }
  };

  const handleSubscription = (evt) => {
    if (evt.target.checked) {
      dispatch(updateIsSubscribed("enable"))
        .then((res) => {
          setTimeout(() => {
            AbortableToast("Mail subscription enabled.");
          }, 2000);
        })
        .catch((error) => {
          AbortableToast("Error updating. Try again later");
        });
    } else {
      dispatch(updateIsSubscribed("disable"))
        .then((res) => {
          setTimeout(() => {
            AbortableToast("Mail subscription disabled.");
          }, 2000);
        })
        .catch((error) => {
          AbortableToast("Error updating. Try again later");
        });
    }
  };

  const updateFormValue = (evt) => {
    if (
      evt.target.name === "isNotification" ||
      evt.target.name === "isSubscription"
    ) {
      setLocUser((state) => ({
        ...state,
        [evt.target.name]: evt.target.checked,
      }));
      return;
    }
    setLocUser((state) => ({
      ...state,
      [evt.target.name]: evt.target.value,
    }));
  };

  const handleChangeEmail = () => {
    dispatch(updateEmail(locUser))
      .then((res) => {
        if (res.payload === undefined) {
          dispatch(SET__CRUD__ERROR(null));
          AbortableToast("Email update successful. Signing out");
          setTimeout(() => {
            window.location.replace("/");
          }, 2000);
        }
      })
      .catch((err) => {
        AbortableToast("Error updating profile. Try again later");
      });
  };

  const handleSocialUpdate = () => {
    dispatch(updateUserSocials(locUser))
      .then((res) => {
        setTimeout(() => {
          AbortableToast("Socials update successful.");
        }, 2000);
      })
      .catch((err) => {
        AbortableToast("Error updating profile. Try again later");
      });
  };

  const handleChangePassword = () => {
    if (locUser.password.length > 0 || locUser.repassword.length > 0) {
      setErrors((state) => ({
        ...state,
        password: "",
        repassword: "",
      }));
    }

    if (locUser.password.length < 6 || locUser.repassword.length < 6) {
      setErrors((state) => ({
        ...state,
        password: "Enter at least 6 characters.",
      }));
      return;
    }
    if (!validator.equals(locUser.repassword, locUser.password)) {
      setErrors((state) => ({
        ...state,
        password: "Passwords do not match",
        repassword: "Passwords do not match",
      }));
      return;
    }

    // console.log(locUser);
    dispatch(updatePasswordId({ password: locUser.password })).then(
      (result) => {
        AbortableToast("Your password update was successful.");
        setTimeout(() => {
          window.location.replace("/auth/signout");
        }, 2000);
      }
    );
  };

  const handleDisableAccount = () => {
    dispatch(updateDisableAccount(true))
      .then((res) => {
        window.location.replace("/auth/signout");
      })
      .catch((error) => {
        AbortableToast("Disable error. Try again later");
      });
  };

  const handleDeleteAccount = () => {
    dispatch(signOutAndDelete(user.user.id))
      .then((result) => {
        window.location.href = "/auth/signin";
      })
      .catch((error) => {
        AbortableToast("Delete error. Try again later");
      });
  };

  return (
    <>
      {!isLoaded(user) ? (
        <div
          style={{
            width: "100%",
            height: "calc(100vh - 120px)",
          }}
        >
          <Loader type={`cover`} />
        </div>
      ) : (
        isLoaded(user) &&
        auth.uid && (
          <div className="page-wrapper">
            <div className="page-content container max-area">
              <h1 className="page-header">Settings</h1>
              <div className="page-items">
                {/* each box should be a component */}
                <div className="page-box">
                  <h4 className="page-box-header">Account Information</h4>
                  <div className="box-collapsible">
                    <ul className="collapsible wrapper">
                      <ChangeEmail
                        authMail={user.user.email}
                        email={locUser.email}
                        updateFormValue={updateFormValue}
                        handleChangeEmail={handleChangeEmail}
                        isCrudLoading={isCrudLoading}
                        crudError={crudError}
                      />
                      <ChangeSocials
                        userSocials={user.socials}
                        locUser={locUser}
                        isCrudLoading={isCrudLoading}
                        updateFormValue={updateFormValue}
                        handleSocialUpdate={handleSocialUpdate}
                      />
                    </ul>
                  </div>
                </div>
                <div className="page-box">
                  <h4 className="page-box-header">Organization</h4>
                  <div className="box-collapsible">
                    <ul className="collapsible wrapper">
                      <BecomeMember />
                      <BecomeVolunteer />
                    </ul>
                  </div>
                </div>
                <div className="page-box">
                  <h4 className="page-box-header">Notifications</h4>
                  <div className="box-collapsible">
                    <ul className="no-collapsible wrapper">
                      <Notification
                        updateFormValue={handleNotificationChange}
                        isNotification={locUser.isNotification}
                      />
                      <Subscriptions
                        updateFormValue={handleSubscription}
                        isSubscription={locUser.isSubscription}
                      />
                    </ul>
                  </div>
                </div>
                <div className="page-box">
                  <h4 className="page-box-header">Account Management</h4>
                  <div className="box-collapsible">
                    <ul className="collapsible wrapper">
                      <ResetSection
                        locUser={locUser}
                        updateFormValue={updateFormValue}
                        handleChangePassword={handleChangePassword}
                        isCrudLoading={isCrudLoading}
                        errors={errors}
                      />
                      <DeactivateSection
                        locUser={locUser}
                        updateFormValue={updateFormValue}
                        handleDisableAccount={handleDisableAccount}
                        isCrudLoading={isCrudLoading}
                      />
                      <DeleteSection
                        locUser={locUser}
                        updateFormValue={updateFormValue}
                        handleDeleteAccount={handleDeleteAccount}
                        view={view}
                        setView={setView}
                        isCrudLoading={isCrudLoading}
                      />
                      <SignoutSection />
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      )}
    </>
  );
};

export default Settings;

import React from "react";
import MultVerifiedInfo from "../../../../general/components/MultVerifiedInfo";

const SubInfo = ({ userId, index }) => {
  return (
    <span className="user-badge">
      <MultVerifiedInfo userId={userId} index={index} />
    </span>
  );
};

export default SubInfo;

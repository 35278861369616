import React from "react";
import Header from "./components/Header";
import {
  AndroidIcon,
  AppleIcon,
  FBIcon,
  InstaIcon,
  LinkedInIcon,
  TubeIcon,
  XIcon,
} from "../assets/icons/Icons";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import { useSelector } from "react-redux";

const ErrorLayout = ({ children }) => {
  const navAttr = { width: 25, theme: "cl-black" };
  const posts = useSelector(({ firestore: { ordered } }) => ordered.homeposts);

  const propChildren = React.Children.map(children, (c) =>
    React.cloneElement(c, {
      posts,
    })
  );
  return (
    <>
      <Header type={`contain-layout`} />
      <div className="root-layout">
        <div className="main-layout">{propChildren}</div>
        <footer className={`main-footer`}>
          <div className="footer-content container">
            <div className="footer-row row">
              <div className="footer-col col s12 m6 l6 xl6">
                <div className="footer-copy">© 2023 VOIS Cyprus.</div>
                <ul className="foot-links">
                  <li>
                    <a href="/privacy-policy">Privacy Policy</a>
                  </li>
                  <li>
                    <a href="/terms-of-use">Terms of Use</a>
                  </li>
                  <li>
                    <a href="/community-guidelines">Community Guidelines</a>
                  </li>
                </ul>
              </div>
              <div className="footer-col col s12 m6 l6 xl6">
                <div className="footer-links">
                  <ul className="nav-socials">
                    <li>
                      <a
                        href="https://twitter.com/voiscyprus"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <XIcon {...navAttr} />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/company/voiscyprus/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <LinkedInIcon {...navAttr} />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.facebook.com/VOISCyprus/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FBIcon {...navAttr} />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/voiscyprus/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <InstaIcon {...navAttr} />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.youtube.com/VOIS%20Cyprus"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <TubeIcon {...navAttr} />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.youtube.com/VOIS%20Cyprus"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <AndroidIcon {...navAttr} />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.youtube.com/VOIS%20Cyprus"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <AppleIcon {...navAttr} />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default compose(
  firestoreConnect(() => {
    return [
      {
        collection: "posts",
        orderBy: ["createdAt", "desc"],
        storeAs: "homeposts",
      },
    ];
  })
)(ErrorLayout);

import React from "react";
import authStyles from "../css/auth.module.css";
import { EmailIcon, FacebookIcon, GoogleIcon } from "../assets/icons/Icons";
import { useNavigate } from "react-router";
import { CookieControl } from "../config/cookieConfig";

const AuthInit = () => {
  const navigate = useNavigate();
  const cookieObj = new CookieControl();

  const handleNoAuth = (e) => {
    e.preventDefault();
    cookieObj.setCookie(1);
    navigate("/", { replace: true });
  };

  return (
    <main className={`${authStyles["auth-main"]}`}>
      <div
        className={`${authStyles["auth-main-items"]}`}
        style={{ padding: " 0 2rem" }}
      >
        <h4 className={`${authStyles["auth-main-title"]}`}>
          How would you like to continue?
        </h4>
        <div className={`${authStyles["auth-main-content"]}`}>
          <ul className={`${authStyles["auth-lists"]}`}>
            <li className={`${authStyles["auth-list"]}`}>
              <a href="auth/signin">
                <p className={`${authStyles["auth-option"]}`}>
                  With authentication via:
                  <span>
                    <EmailIcon width={20} />
                    <GoogleIcon width={20} />
                    <FacebookIcon width={20} />
                  </span>
                </p>
                <span className={`${authStyles["auth-list-info"]}`}>
                  Log in or create an account via the above methods. This will
                  let you post and interact with the app.
                </span>
              </a>
            </li>
            <li className={`${authStyles["auth-list"]}`}>
              <a href="/" onClick={handleNoAuth}>
                <p className={`${authStyles["auth-option"]}`}>
                  Without authentication:
                </p>
                <span className={`${authStyles["auth-list-info"]}`}>
                  You can browse through the VOIS app without a profile, but you
                  wont be able to post or interact with the app.
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </main>
  );
};

export default AuthInit;

import React from "react";
import { LogOffIcon } from "../../../assets/icons/Icons";

const SignoutSection = () => {
  return (
    <a href="/auth/signout">
      <li className="collapsible-item">
        <div className="collapsible-header">
          <LogOffIcon width={20} theme={`cl-icon-light`} />
          <p className="tx-warning">
            Sign out
            <span>Log out of this account</span>
          </p>
        </div>
        <div className="collapsible-body">
          <span>Lorem ipsum dolor sit amet.</span>
        </div>
      </li>
    </a>
  );
};

export default SignoutSection;

import React from "react";

const Notification = ({ isNotification, updateFormValue }) => {
  return (
    <li className="collapsible-item">
      <div className="collapsible-header">
        <div className="switch page-switch">
          <label htmlFor="isNotification">
            <input
              type="checkbox"
              name="isNotification"
              id="isNotification"
              checked={isNotification}
              onChange={updateFormValue}
            />
            <span className="lever"></span>
          </label>
        </div>
        <p>
          Enable Notifications
          <span>Receive in-app notifications only.</span>
        </p>
      </div>
    </li>
  );
};

export default Notification;
